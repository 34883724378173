import { Box, Dialog } from "@mui/material";
import React from "react";
// import HeaderBasic from "STM/layout/LayoutComponent/HeaderComponent/HeaderBasic";
import HeaderBasic from "layout/LayoutComponent/HeaderComponent/HeaderBasic";
import { Close } from "@mui/icons-material";
import LayoutNav from "layout/Layout_Main/Front/LayoutNav";
import '../../../../STM/layout/Fontend01/MinimalLayout.css'
import NewsComponent from "./NewsComponent";
import NewsDetailComponent from "./NewsDetailComponent";
import NewsActivityDetail from "view/NewsActivity/NewsActivityDetail";
import Footer from "view/Home/Footer/Footer";

export default function NewsPreview({ open, handleClose, data }) {
  return (
    <Dialog
      fullScreen
      sx={{ ".MuiDialog-paperScrollPaper": { overflowX: "hidden" } }}
      open={open}
      onClose={handleClose}
    >
      <Box
        className="main-container-banner"
        sx={{ minWidth: "100vw", overflowX: "hidden", minHeight: "100vh" }}
      >
        <Box
          onClick={handleClose}
          sx={{
            cursor: "pointer",
            bgcolor: "rgb(23, 193, 232)",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "1.7em",
            height: "1.7em",
            borderRadius: "50%",
            position: "absolute",
            zIndex: 9999,
            top: "20px",
            right: "20px",
          }}
        >
          <Close sx={{ fontSize: "1em", alignSelf: "center" }} />
        </Box>
        <HeaderBasic isPreview={true} setFooterMenu={null} />
        {/* <Box className="app-menu navbar-menu">
          <Box id="scrollbar">
            <Box className="container-fluid dropdown-custom-right">
              <LayoutNav isPreview={true} lstMenu={[]}/>
            </Box>
          </Box>
        </Box> */}
        <NewsActivityDetail data={data} sID={""} IsPreview />
        <Footer isPreview={true} FooterMenu={[]} />
      </Box>
    </Dialog>
  );
}
