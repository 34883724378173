export const newsData = {
    newsForm: [
        {
            id:1,
            type: "input",
            size: 12,            
            name: "sNewsName",
            header: "newsname",
            label: "",
            disabled: false,
            required: true,
            guideKey:"sNameGuideline",
            multiline:false,
            rows:1,
            length:150
        },
        {
            id:2,
            type: "input",
            size:12,
            name: "sDescription",
            header: "introduction",
            label: "",
            disabled: false,
            required: false,
            guideKey:"sDesGuideline",
            multiline:true,
            rows:3,
            length:200
        },
        {
            id:3,
            type: "input",
            size:6,
            name: "sSEONewsName",
            header: "newsnameseo",
            label: "",
            disabled: false,
            required: false,
            guideKey:"sSEONameGuideline",
            multiline:false,
            rows:1,
            length:150
        },
        {
            id:4,
            type: "input",
            size:6,
            name: "sSEODescription",
            header: "newsintroductionseo",
            label: "",
            disabled: false,
            required: false,
            guideKey:"sSEODesGuideline",
            multiline:false,
            rows:1,
            length:200
        }
    ]
}