
import LayoutSide from 'layout/Backend/layout_Side';
import LanguageForm from 'view/STM/Lang/LanguageForm'
import LanguageList from 'view/STM/Lang/LanguageList'
const route = [
    {
        exact: true,
        path: "/LanguageList",
        component: LanguageList,
        layout: LayoutSide,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/LanguageForm",
        component: LanguageForm,
        layout: LayoutSide,
        props: { MenuID_Selected: 1 }
    }
];
export default route;