import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./themes";
import { Routes, Route,useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import route from "router";
import { Provider } from "react-redux";

import { useTranslation } from "react-i18next";
import "./App.css";
import storeRedux from "storeIntarnet";
import Template from "STM/components/DialogAlert/TemplateDialog";
import BlockUI from "STM/components/BlockUI/BlockUI";
import ErrorBoundary from "STM/components/ErrorBoundary/ErrorBoundary";
import { initialI18next } from "STM/utilities/ST_Function";

const store = storeRedux();

initialI18next();
const App: React.FC = () => {
  let { lang } = useParams();
  const { i18n } = useTranslation();
  i18n.changeLanguage(lang);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Template />
        <Routes>
          {route.map((o) => {
            return (
              <Route
                path={o.path}
                key={o.path}
                element={
                  o.layout ? (
                    <o.layout {...o.props}>
                      <ErrorBoundary>
                        <o.component />
                      </ErrorBoundary>
                    </o.layout>
                  ) : (
                    <o.component />
                  )
                }
              />
            );
          })}
        </Routes>
        <BlockUI />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
