import {useState } from "react";
////ImportPage
import './Footer.css'
////import { ShowUnderConstraction } from "view/UnderConstraction/UnderConstraction";
import PopupUnderConstraction from "view/UnderConstraction/UnderConstraction2";
import { enumMenu } from "enum/enumSystem2";
import { Link, useLocation } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

/// react icon
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function Footer({ isPreview, FooterMenu }) {
  const location = useLocation();
  //// isPreview = true;
  const [OpenUnderContraction, setOpenUnderContraction] = useState(false);

  const FunctionCloseDialog = () => {
    setOpenUnderContraction(false);
  }

  const cookiePUrlTemp = `${process.env.REACT_APP_Front_URL}Cookiespolicy?tab=2`
  const url = new URL(cookiePUrlTemp, window.location.href);
  const cookiePUrl = url.origin + url.pathname + url.search;
  return (
    <>

      <Box className="footer-menu">
        <Accordion className="tag-site-menu">
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
            sx={{
              display: "inline-flex",
              p: 0,
              minHeight: "30px",
              ".MuiAccordionSummary-content": { margin: 0 }
            }}>
            <Typography sx={{ textTransform: "uppercase", fontSize: "1rem" }}>
              SITE MAP
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ borderTop: "solid 1px", padding: "8px 0px 16px" }}>
            <Grid container className="footer-detail" >
              {
                FooterMenu.map((value, index) => {
                  const fullUrlTemp = `${process.env.REACT_APP_Front_URL}${value.sLink}`;
                  const url = new URL(fullUrlTemp, window.location.href);
                  const fullUrl = url.origin + url.pathname + url.search;
                  return (
                    <Box key={index}>
                      {
                        isPreview === true ?
                          <><Box>{value.sMenuName}</Box></>
                          :
                          <>
                            {
                              value.sLink === enumMenu.UnderConstruction.toString() ?
                                <Box onClick={() => { setOpenUnderContraction(true) }}
                                  className={location.pathname && location.pathname.includes(value.sLink) ?
                                    "footer-menu-name active"
                                    :
                                    "footer-menu-name"}>
                                  {value.sMenuName}
                                </Box>
                                :
                                /* footer-menu-name active */
                                <Link to={fullUrl}>
                                  <Box className={location.pathname && location.pathname.includes(value.sLink) ? "footer-menu-name active" : "footer-menu-name"}>
                                    {value.sMenuName}
                                  </Box>
                                </Link>
                            }
                          </>
                      }

                    </Box>
                  )
                })
              }
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Box className="tag-coppy-right">
          <Typography className="footertext-copy-right">
            Copyright © PTT Global LNG Company Limited
          </Typography>
          <Typography className="footertext-privacy">
            <Link to={cookiePUrl} target="_blank" style={{ color: "white" }}>
              Privacy & Conditions
            </Link>
          </Typography>
        </Box>
      </Box>

      <PopupUnderConstraction open={OpenUnderContraction} handleClose={FunctionCloseDialog} />
    </>
  )
}

export default Footer