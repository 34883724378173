import axios from "axios";
import { IsNullOrUndefined} from "./ST_Function"
import { SwAlert } from 'STM/components/Alert';
import secureLocalStorage from "react-secure-storage";
import cookies from "js-cookie";

import {AlertTitle} from 'config/AlertMsgConfig'
export const APIStatusCode = {
    /**
     * Server ได้รับคำขอในส่วน Headers แล้ว ทาง Client ควรดำเนินการต่อเพื่อส่งคำขอในส่วนของ Body อย่างไรก็ตาม การส่งคำขอ Body ขนาดใหญ่ตามไปหลังจากที่คำขอถูกปฏิเสธ เนื่องจากคำขอของ Header ไม่เหมาะสม จะเป็นวิธีที่ไม่มีประสิทธิภาพ ทาง Client ควรจะส่งคำขอ Header ที่ถูกต้องไปใหม่
     */
    Continue: 100,
    /**
     * ผู้ส่งคำขอได้แจ้งให้ Server เปลี่ยนโปรโตคอล และทาง Server ก็ยอมทำตามคำขอ
     */
    SwitchingProtocols: 101,
    /**
     * Server ได้รับคำขอ และกำลังประมวลผลตามคำขออยู่ แต่ยังไม่มีการตอบสนองกลับมาในเวลานี้ และยังช่วยป้องกันไม่ให้ทาง Client เข้าใจว่าคำขอเดินทางไม่ไปถึง Serve
     */
    Processing: 102,
    /**
     * ใช้ในการส่งคืนคำตอบสนองของ Headers บางส่วนมาก่อนที่ข้อมูลทั้งหมดจะส่งมา
     */
    EarlyHints: 103,
    /**
     * เป็นการตอบสนองแบบมาตรฐานเมื่อคำขอ HTTP ประสบความสำเร็จ โดยการตอบสนองจะขึ้นอยู่กับวิธีที่ใช้ในการส่งคำขอด้วย
     */
    OK: 200,
    /**
     * คำขอได้รับการตอบสนองแล้ว เริ่มสร้างทรัพยากรใหม่ขึ้นมา
     */
    Created: 201,
    /**
     * คำขอได้รับและกำลังประมวลผลอยู่ แต่ยังดำเนินการไม่เสร็จ ท้ายที่สุดแล้วคำขออาจจะดำเนินการต่อจนเสร็จ หรือไม่สำเร็จก็ได้
     */
    Accepted: 202,
    /**
     * Server เป็น Transforming proxy (เช่น มีการใช้ Web accelerator) ที่ได้รับ 200 OK จากต้นฉบับ แต่มีการส่งคืนค่าการตอบสนองแบบที่ได้รับการแก้ไขกลับม
     */
    NonAuthoritativeInformation: 203,
    /**
     * Server ประมวลผลคำขอเสร็จแล้ว และยังไม่ส่งคืนเนื้อหาใด ๆ กลับมา
     */
    NoContent: 204,
    /**
     * Server ประมวลผลคำขอเสร็จแล้ว และเรียกร้องให้ผู้ส่งคำขอรีเซ็ตมุมมองเอกสาร และยังไม่ส่งคืนเนื้อหาใด ๆ กลับมา
     */
    ResetContent: 205,
    /**
     * Server กำลังส่งมอบทรัพยากรมาเพียงบางส่วนเท่านั้น เนื่องจากขอบเขตของ Header ที่ Client ส่งมา มีการกำหนดให้ดาวน์โหลดต่อได้ แม้ถูกขัดจังหวะ หรือแยกการดาวน์โหลดออกเป็นหลายส่วนพร้อมกัน
     */
    PartialContent: 206,
    /**
     * เนื้อหาของข้อความที่ตามมา เป็นข้อความเริ่มต้นแบบ XML และมีรหัสการตอบกลับอยู่หลายแบบ ขึ้นอยู่กับจำนวน Sub-requests ที่มีอยู่
     */
    MultiStatus: 207,
    /**
     * คำขอที่มีอยู่ใน DAV ที่ได้รับการแจกแจงการตอบสนองไปแล้ว จะไม่ถูกนำกลับมาอีกครั้ง
     */
    AlreadyReported: 208,
    /**
     * Server ได้ดำเนินการคำขอทรัพยากรให้เรียบร้อยแล้ว และคำตอบสนองได้แสดงให้เห็นถึงผลลัพธ์ทั้งหมด หรือบางส่วนของทรัพยากร
     */
    IMUsed: 226,
    /**
     * เป็นรหัสที่บ่งชี้ว่ามีตัวเลือกหลายทางในทรัพยากรที่ทาง Client สามารถเลือกได้ (ผ่านระบบ Agent-driven content negotiation) ตัวอย่างเช่น มีตัวเลือกสำหรับฟอร์แมทวิดีโอ, รายการส่วนขยายไฟล์ ฯลฯ
     */
    Ambiguous: 300,
    MultipleChoices: 300,
    /**
     * หมายความว่าคำขอในปัจจุบันรวมถึงในอนาคต จะถูกพาไปยังตำแหน่ง URI (Uniform Resource Identifier : มาตรฐานการอ้างอิงรูปแบบการเข้าถึงทรัพยากรต่าง ๆ) ที่กำหนด
     */
    Moved: 301,
    MovedPermanently: 301,
    /**
     * 302 Found หรือที่ก่อนหน้านี้ใช้คำว่า Moved temporarily เป็นการบอกให้ Client ค้นหา URL อื่น ก่อนหน้านี้ รหัสเคยถูกแทนที่ด้วย 303 และ 307
     */
    Found: 302,
    Redirect: 302,
    /**
     * เป็นการตอบสนองไปยังคำขอที่พบได้ใน URI อื่น เมื่อมีการใช้ GET mothod เมื่อได้รับการตอบสนองไปยัง POST (หรือ PUT/DELETE) ทาง Client จะสันนิษฐานว่า Server ได้รับข้อมูลแล้ว และกำลังส่งคำขอใหม่ไปยัง URI ที่กำหนด
     */
    RedirectMethod: 303,
    SeeOther: 303,
    /**
     * บ่งชี้ว่าทรัพยากรไม่มีความเปลี่ยนแปลงตั้งแต่ที่มีคำขอจาก Headers ทำให้ทาง Client ไม่จำเป็นต้องดาวน์โหลดข้อมูลใหม่อีกครั้ง
     */
    NotModified: 304,
    /**
     * ทรัพยากรที่ร้องขอจำเป็นต้องส่งผ่าน Proxy เท่านั้น อย่างไรก็ตาม ด้วยเหตุผลด้านความปลอดภัย HTTP Clients หลายราย เช่น โปรแกรม Google Chrome, โปรแกรม Mozilla Firefox จะไม่ยอมรับรหัสนี้
     */
    UseProxy: 305,
    /**
     * ปัจจุบัน ไม่มีการใช้งานแล้ว เดิมหมายถึง คำขอที่ต้องใช้ Proxy ที่ถูกกำหนดเอาไว้
     */
    Unused: 306,
    /**
     * ในกรณีนี้ คำขอจะมีการทำซ้ำด้วย URI ตัวอื่น อย่างไรก็ตาม คำขอที่เกิดขึ้นตามมาในอนาคตควรจะใช้ URI ดั้งเดิม
     */
    RedirectKeepVerb: 307,
    TemporaryRedirect: 307,
    /**
     * คำขอ และคำขอที่จะเกิดขึ้นในอนาคต ควรดำเนินการซ้ำโดยใช้ URI ที่แตกต่างไปจากเดิม รหัส 307 และ 308 จะมีการทำงานที่คล้ายคลึงกับรหัส 301 และ 302 แต่มันจะไม่อนุญาตให้เปลี่ยน HTTP Method
     */
    PermanentRedirect: 308,
    /**
     * Server ไม่สามารถ หรือไม่ดำเนินการตามคำขอเนื่องจากมีข้อผิดพลาดจากทาง Client (เช่น คำขอผิดรูปแบบ, ขนาดใหญ่เกินไป ฯลฯ)
     */
    BadRequest: 400,
    /**
     * มีความเหมือนกับ 403 Forbidden (หวงห้าม) แต่ 401 Unauthorized จะใช้เฉพาะเมื่อจำเป็นต้องมีการตรวจสอบสิทธิ์ (Authentication) แต่ว่าล้มเหลว หรือยังไม่ได้รับการยืนยัน
     */
    Unauthorized: 401,
    /**
     * รหัสนี้ถูกสงวนไว้ใช้งานในอนาคต โดยมีความตั้งใจว่ารหัสนี้จะเป็นส่วนหนึ่งของการใช้ในระบบจ่ายเงินดิจิทัล หรือชำระเงินเล็ก ๆ น้อย ๆ (Micropayment)
     */
    PaymentRequired: 402,
    /**
     * หัสนี้บ่งบอกว่า Server เข้าใจ และมีข้อมูลที่คำขอต้องการอยู่ แต่ทาง Server ปฏิเสธที่จะดำเนินการต่อ โดยอาจจะเป็นเพราะผู้ส่งคำขอไม่ได้รับการอนุญาต หรือพยายามส่งคำขอที่ละเมิดสิทธิ์บางอย่างที่ถูกตั้งกฏเอาไว้
     */
    Forbidden: 403,
    /**
     * นี่เป็นโค้ดยอดฮิตที่แวะเวียนมาให้เห็นหน้าค่าตากันอยู่บ่อย ๆ เวลาที่เรากรอกที่อยู่ URL ผิด หรือที่อยู่เว็บไซต์นั้นไม่มีอยู่จริง รหัสนี้เป็นการบ่งบอกว่าทรัพยากรที่ถูกส่งคำขอไม่มีอยู่บน Server
     */
    NotFound: 404,
    /**
     * หมายความว่าทรัพยากรที่มีอยู่ไม่รองรับกับวิธีการที่คำขอใช้ เช่น ใช้คำขอแบบ PUT กับทรัพยากรที่อ่านได้อย่างเดียว (Read-Only Resource)
     */
    MethodNotAllowed: 405,
    /**
     * คำร้องขอทรัพยากร ยอมรับเฉพาะด้านการสร้างเนื้อหาเท่านั้น ไม่ยอมรับคำขอที่เป็น Header
     */
    NotAcceptable: 406,
    /**
     * ตัว Client ต้องตรวจสอบสิทธิ์ของตัวมันเองผ่าน Proxy ก่อน
     */
    ProxyAuthenticationRequired: 407,
    /**
     * รหัสนี้หมายความว่า Client ไม่ได้ส่งคำขอมาภายในระยะเวลาที่ Server ได้กำเนิดเอาไว้ ทาง Client อาจจะส่งคำขอซ้ำใหม่ได้อีกครั้งในภายหลัง
     */
    RequestTimeout: 408,
    /**
     * บ่งชี้ว่าคำขอไม่สามารถดำเนินการได้เพราะมีความขัดแย้งเกิดขึ้นกับทรัพยากร เช่น มีการแก้ไขข้อมูล ในขณะที่มีการอัปเดตข้อมูลพร้อมกันหลายรายการ
     */
    Conflict: 409,
    /**
     * บอกว่าทรัพยากรที่ร้องขอนั้นไม่มีอยู่แล้ว และจะไม่มีการนำกลับมาอีกครั้ง รหัสนี้จะใช้ในกรณีที่ข้อมูลดังกล่าวถูกลบออกจาก Server อย่างถาวร ทาง Client ไม่ควรส่งคำร้องมาอีกในอนาคต ตามหลักการ Client อย่าง Search engine ควรลบข้อมูลดังกล่าวออกจากดรรชนี แต่ในทางปฏิบัติเครื่องมือค้นหา (Search Engine) ก็ไม่จำเป็นต้องลบก็ได้ แล้วใช้รหัส 404 Not Found แทน
     */
    Gone: 410,
    /**
     * คำขอนี้ไม่ได้ระบุความยาวของเนื้อหา ซึ่งจำเป็นต่อการขอทรัพยากร
     */
    LengthRequired: 411,
    /**
     * คุณสมบัติของ Server ไม่ตรงตามเงื่อนไขที่ผู้ส่งคำขอกำหนดเอาไว้ใน Header
     */
    PreconditionFailed: 412,
    /**
     * คำขอมีขนาดใหญ่เกินกว่าที่ Server จะยินยอม หรือสามารถดำเนินการให้ได้ เดิมที รหัสนี้ถูกเรียกว่า "Request Entity Too Large"
     */
    RequestEntityTooLarge: 413,
    /**
     * ความยาวของ URI ที่ถูกกำหนดเอาไว้มีความยาวเกินกว่าที่ Server จะดำเนินการได้ มักจะเกิดขึ้นบ่อยครั้งเมื่อมีคำขอ GET ที่มีการเข้ารหัส Query-String แบบเข้ารหัสเอาไว้เป็นจำนวนมาก เดิมที รหัสนี้ถูกเรียกว่า "Request-URI Too Long"
     */
    RequestUriTooLong: 414,
    /**
     * ในคำขอมีไฟล์มัลติมีเดียที่ทาง Server ไม่สนับสนุนอยู่ ตัวอย่างเช่น ทาง Client อัปโหลดไฟล์ภาพ svg+xml ขึ้นไป แต่ Server ไม่รองรับไฟล์นามสกุลดังกล่าว
     */
    UnsupportedMediaType: 415,
    /**
     * ทาง Client ร้องขอส่วนหนึ่งของไฟล์ (Byte Serving) แต่ Server ไม่สามารถส่งไฟล์ได้ตามที่ร้องขอมาได้ ตัวอย่างเช่น Client ยื่นคำร้องขอดูไฟล์ในตำแหน่งที่ไกลเกินกว่าไฟล์จริงที่มีอยู่
     */
    RequestedRangeNotSatisfiable: 416,
    /**
     * Server ไม่สามารถทำตามความต้องการตามที่คำขอ Header ถูกส่งเข้ามาได้
     */
    ExpectationFailed: 417,
    /**
     * คำขอที่ส่งไปยัง Server ที่ไม่สามารถตอบสนองต่อคำขอได้
     */
    MisdirectedRequest: 421,
    /**
     * รูปแบบคำขอมีความถูกต้อง แต่ไม่สามารถทำตามที่ต้องการได้เนื่องจาก Semantic errors
     */
    UnprocessableEntity: 422,
    /**
     * ทรัพยากรที่ต้องการเข้าถึงถูกล็อคเอาไว้อยู่
     */
    Locked: 423,
    /**
     * คำขอล้มเหลวเพราะมันต้องพึ่งพาคำขออื่นด้วย และคำขอนั้นล้มเหลว
     */
    FailedDependency: 424,
    /**
     * Client ควรเปลี่ยนไปใช้โปรโตคอลตัวอื่น อย่างเช่น TLS/1.3 ซึ่งถูกระบุเอาไว้ใน Upgrade Header
     */
    UpgradeRequired: 426,
    /**
     * Server ต้นทางต้องการให้คำขอตรงตามเงื่อนไขที่กำหนดไว้ เพื่อป้องกันไม่ให้การอัปเดตเกิดข้อผิดพลาด
     */
    PreconditionRequired: 428,
    /**
     * ผู้ใช้ส่งคำขอมากเกินไปภายในเวลาที่กำหนด มีไว้เพื่อใช้ในการลดภาระของ Server
     */
    TooManyRequests: 429,
    /**
     * Server ไม่ยินยอมดำเนินการตามคำขอ เนื่องจาก Header บางส่วน หรือทั้งหมด มีขนาดใหญ่เกินไป
     */
    RequestHeaderFieldsTooLarge: 431,
    /**
     * การทำงานของ Server ถูกข้อบังคับด้านกฏหมาย ให้ปฏิเสธการเข้าถึงทรัพยากรบางส่วน หรือทั้งหมด
     */
    UnavailableForLegalReasons: 451,
    /**
     * ข้อความแจ้งเตือนที่มักถูกใช้อยู่เป็นประจำ เมื่ออยู่ในสถานการณ์ที่ไม่คาดคิด และไม่มีข้อความใดที่เหมาะสม
     */
    InternalServerError: 500,
    /**
     * Server ไม่เข้าใจวิธีการที่คำขอส่งเข้ามา หรือขาดความสามารถที่จะทำตามคำขอนั้นได้ มักใช้กับคุณสมบัติที่กำลังจะเพิ่มเข้ามาในอนาคต
     */
    NotImplemented: 501,
    /**
     * Server ที่ทำตัวเป็น Gateway หรือ Proxy ได้รับการตอบสนองที่ไม่ถูกต้องจาก Server ต้นทาง
     */
    BadGateway: 502,
    /**
     * Server ไม่สามารถดำเนินการตามคำขอได้ สาเหตุอาจจะมาจากมีภาระการทำงานหนักเกินกว่าจะรับไหว หรืออยู่ในช่วงบำรุงรักษา ส่วนใหญ่แล้วจะเป็นแค่เพียงชั่วคราว
     */
    ServiceUnavailable: 503,
    /**
     * Server ที่ทำตัวเป็น Gateway หรือ Proxy ไม่ได้รับการตอบสนองภายในเวลาที่กำหนดจาก Server ต้นทาง 
     */
    GatewayTimeout: 504,
    /**
     * Server ไม่รองรับ HTTP protocol เวอร์ชันที่คำขอใช้ส่งเข้ามา
     */
    HttpVersionNotSupported: 505,
    /**
     * Transparent Content Negotiation สำหรับตอนสนองต่อคำขอแบบ Circular Reference (การกำหนดตำแหน่งอ้างอิงแบบวงกลม)
     */
    VariantAlsoNegotiates: 506,
    /**
     * Server ไม่สามารถจัดเก็บ "ตัวแทน" ที่จำเป็นในการดำเนินการตามคำขอได้ 
     */
    InsufficientStorage: 507,
    /**
     * erver ตรวจพบการทำงานแบบลูปในขณะที่กำลังประมวลผลคำขอ (ใช้แทนรหัส 208 Already Reported)
     */
    LoopDetected: 508,
    /**
     * จำเป็นต้องมีส่วนขยายเพิ่มเติมสำหรับคำขอ เพื่อให้ Server ดำเนินการตามคำขอได้สำเร็จ
     */
    NotExtended: 510,
    /**
     * Client จำเป็นต้องผ่านการรับรองก่อน (Authentication) ในการเข้าถึงเครือข่าย
     */
    NetworkAuthenticationRequired: 511,
    /**
     * Warning Data
     */
    Warning: 227,
    /**
     * Success
     */
    Success: 200,
    /**
     * Failed
     */
    Failed: 404,
    /**
     * Confirm data
     */
    Confirm: 202,
    /**
     * Error
     */
    Error: 500,
}
let xsrfToken = "";
//Get Antiforgery Token 
export const GetAntiforgeryToken = async () => {
    let IsPass = false;
    const apiBaseUrl = process.env.REACT_APP_API_URL;
    const antiforgery = 'antiforgery/token';
    const sPathApi1 = `${apiBaseUrl}api/${antiforgery}`;
    const url = new URL(sPathApi1,window.location.href);
    const sPathApi = url.origin + url.pathname + url.search;
    const localJwtKey = process.env.REACT_APP_JWT_KEY;

    let auth_token = secureLocalStorage.getItem(localJwtKey) != null ? (secureLocalStorage.getItem(localJwtKey) as string) : undefined;

    let Config = { Authorization: `Bearer ${auth_token}` } as any;
    const tokenKey = await axios.get(sPathApi, { headers: Config });
    if (tokenKey.status === 200) {
    IsPass = true;
    xsrfToken = tokenKey.data.token;
    }
    return IsPass;
};


export const AxiosPost = async (
    sWebMetodName,
    objJSON,
    fnSuccess?,
    fnError?,
    fnComplete?
) => {
    const isGetToken = await GetAntiforgeryToken() as any;
    if(isGetToken){
    let localJwtKey = process.env.REACT_APP_JWT_KEY;
    let i18next = process.env.REACT_APP_JWT_i18next;
    let sLang = cookies.get(i18next);
    let auth_token = secureLocalStorage.getItem(localJwtKey) != null? (secureLocalStorage.getItem(localJwtKey) as string) : undefined;
    let configHeader = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Company-Token": `${localJwtKey}`,
        "Authorization": IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
        "sLang":sLang,
    };
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.post['X-CSRF-TOKEN'] = xsrfToken;
    const baseUrl = process.env.REACT_APP_API_URL;

    const sPathApi2 = `${baseUrl}api/${sWebMetodName}`;
    const url = new URL(sPathApi2, window.location.href);
    const sPathApi = url.origin + url.pathname + url.search;
    if (sPathApi) {
        await axios
            .post(sPathApi, objJSON, { headers: configHeader })
            .then((res) => {
                let respData = {};
                if (res.data.objData != null && res.data.objData !== undefined) {
                    respData = res.data.objData;
                } else {
                    respData = res.data;
                }
                
                switch (res.status) {
                    case 200:
                        if (res.data.StatusCode === 500) {
                            ////SwAlert.Warning(AlertTitle.Warning,res.data.Message, () => { });
                            if (fnError) {
                                fnError(respData);
                            }else{
                                SwAlert.Warning(AlertTitle.Warning,res.data.Message, () => { 
                                });            
                            }
                        } 
                        else if (res.data.StatusCode === 419) {
                            UserTimeOut(respData);
                        }
                        else if (res.status === 200 || res.status === "200") {
                            if (fnSuccess) {
                                fnSuccess(respData);
                            }
                        } else if (res.status === 500 || res.status === "500") {
                            if (fnError) {
                                fnError(respData);
                            }
                        } else if (res.status === 419 || res.status === "419") {
                            if (fnError) {
                                fnError(respData);
                            }else{
                                UserTimeOut(respData);     
                            }
                        } else {
                            if (fnError) fnError(respData);
                        }
                        break;
                    case 401:
                        ////Unauthorize();
                        break;
                    case 404:
                    case 500:
                        ////alert(res.data.Message);
                        break;
                    default:
                        break;
                }
            })
            .catch((errors) => {
                if (errors && errors.response && errors.response.status === 401) {
                    secureLocalStorage.clear();
                }
                if (fnError) {
                    fnError(errors);
                } else {
                    console.error(errors);
                }
            })
            .finally(() => {
                if (fnComplete) fnComplete();
            });
        }
    }
};

/**
 * Http Get
 * @param sWebMetodName [controller]/[action] 
 * @param objJSON data 
 * @param fnSuccess status code 200 
 * @returns Data
 * @param fnWarning status code 227 
 * @returns All Result
 * @param fnFailed status code 404 
 * @returns All Result
 * @param fnError status code 500 
 * @returns All Result
 * @param fnComplete จบขบวนการ
 * @returns All Result
 */
export const AxiosGet = async (sWebMetodName, objJSON, fnSuccess?: (data: any) => void, fnWarning?: (data: any) => void, fnFailed?: (data: any) => void, fnError?: (data: any) => void, fnComplete?) => {
    let localJwtKey = process.env.REACT_APP_JWT_KEY;
    let i18next = process.env.REACT_APP_JWT_i18next;
    let sLang = cookies.get(i18next);
    let auth_token = secureLocalStorage.getItem(localJwtKey) != null? (secureLocalStorage.getItem(localJwtKey) as string) : undefined;
    let ConfigJwt = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
        "X-Requested-With": 'XMLHttpRequest',
        withCredentials: true,
        sLang:sLang,
    };

    const baseUrl = process.env.REACT_APP_API_URL;
    const sPathApi2 = `${baseUrl}api/${sWebMetodName}`;
    const url = new URL(sPathApi2, window.location.href);
    const sPathApi = url.origin + url.pathname + url.search;
    if(sPathApi){
    await axios
        .get(sPathApi, { headers: ConfigJwt, params: objJSON, })
        .then((res) => {
            getResult(res.status, res, fnSuccess, fnWarning, fnFailed, fnError, fnComplete);
        })
        .catch((errors) => {
            if (errors.response?.data?.Message === undefined || errors.response?.data?.Message == null || errors.response?.data?.Message == "") {
                errors["response"]["data"]["Message"] = errors.message;
            }
            getResult(errors.response.status, errors.response, fnSuccess, fnWarning, fnFailed, fnError, fnComplete);
        })
        .finally(() => {
            if (fnComplete) fnComplete();
        });
    }
};


export const UserTimeOut = (respData) => {
    let localJwtKey = process.env.REACT_APP_JWT_KEY;
    secureLocalStorage.removeItem(localJwtKey);
    let fonturi = `${process.env.REACT_APP_Front_URL}`;
    SwAlert.Warning(AlertTitle.Warning,respData.Message, () => { 
        let anchor = document.createElement("a");
        anchor.href = fonturi;
        anchor.click(); 
    });
}


const getResult = (statusCode: number, resultData: any, fnSuccess?: (data: any) => void, fnWarning?: (data: any) => void, fnFailed?: (data: any) => void, fnError?: (data: any) => void, fnComplete?) => {
    let respData = {};
    if (resultData.data.Result != null && resultData.data.Result !== undefined) {
        respData = resultData.data.Result;
    } else {
        respData = resultData.data;
    }

    switch (statusCode) {
        case APIStatusCode.Success:
            if (resultData.data.StatusCode === 500) {
                ////SwAlert.Warning(AlertTitle.Warning,res.data.Message, () => { });
                if (fnError) {
                    fnError(respData);
                }else{
                    let fonturi = `${process.env.REACT_APP_Front_URL}`
                    SwAlert.Warning(AlertTitle.Warning,resultData.data.Message, () => { 
                        let anchor = document.createElement("a");
                        anchor.href = fonturi;
                        anchor.click(); 
                    });    
                }
            } 
            else if (resultData.data.StatusCode === 419) {
                UserTimeOut(respData);
            }
            else if (resultData.status === 200 || resultData.status === "200") {
                if (fnSuccess) {
                    fnSuccess(respData);
                }
            } else if (resultData.status === 500 || resultData.status === "500") {
                if (fnError) {
                    fnError(respData);
                }
            } else if (resultData.status === 419 || resultData.status === "419") {
                if (fnError) {
                    fnError(respData);
                }else{
                    UserTimeOut(respData);     
                }
            } else {
                if (fnError) fnError(respData);
            }
            break;
        case APIStatusCode.Warning:
            if (fnWarning) {
                fnWarning(resultData.data)
            } else {
                SwAlert.Warning("Warning", resultData.data.Message);
            }
            break;
        case APIStatusCode.Failed:
            if (fnFailed) {
                fnFailed(resultData.data);
            } else {
                SwAlert.Error("Error", resultData.data.Message);
            }
            break;
        case APIStatusCode.Error:
            if (fnError) {
                fnError(resultData.data);
            } else {
                SwAlert.Error("Error", resultData.data.Message);
            }
            break;
        case APIStatusCode.BadRequest:
            SwAlert.Error("Error", resultData.data.Message);
            break;
        default:
            if (fnComplete) {
                fnComplete(resultData.data);
            }
            break;
    }
}

