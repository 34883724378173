import React, { useState, useEffect, useRef, Fragment } from "react";
import { Dialog, DialogContent, DialogTitle, Divider, Grid, Box, IconButton, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FnBlock_UI } from 'STM/components/BlockUI';
import { AxiosPost } from 'STM/utilities/ST_Axios';
import { SelectFormItem, RadioForm, TextBoxForm } from 'STM/components/Input';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import yupFormSchemas from 'STM/components/FormItem/yupFormSchemas';
import { SwAlert } from 'STM/components/Alert/index';
import { HeaderInput } from "STM/components/Input";
import { AlertTitle, AlertMsg } from 'config/AlertMsgConfig'
import { useTranslation } from 'react-i18next';
import SlotContent from './SlotContent';
import PopupChooseTemplate from './PopupChooseTemplate';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentManagementFrame from './ContentManagementFrame';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Container } from '@mui/material';
import styled from '@emotion/styled';
import Accordion from 'STM/components/Accordion';
import { FnDialog } from "STM/utilities/ST_Function";

import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Search, Print,
    Save,
    DeleteForever,
    Add,
    Edit,
    HighlightOffRounded, Delete,
    SaveAlt,
    Quiz,
    TableRowsRounded,
    Image

} from "@mui/icons-material";
import { BtnBack, BtnEditOnTable, BtnPreview, BtnDelete, BtnCreate, BtnDeleteOnTable, BtnChooseTemplate, BtnAddMoreTemplate } from 'STM/components/Button';
import { SxProps, Select, MenuItem } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 700,
            lg: 1200,
            xl: 1536,
        },
    },
});


const ContentManagement = (prop) => {
    const DialogFn = FnDialog();
    const { t, i18n } = useTranslation();
    const { CMSData, setCMSData, isRequired, nDefaultTemplateID } = prop;
    const [nTemplateID, setnTemplateID] = useState(0);
    const [nOrder, setnOrder] = useState(0);
    const [openPopup, setopenPopup] = useState(false);
    const [openPopupPreview, setopenPopupPreview] = useState(false);
    const [lstTemplateData, setlstTemplateData] = useState([] as any);
    const [lstOrder, setlstOrder] = useState([] as any);
    const [lstSlot, setlstSlot] = useState([] as any);
    const objSchema = yup.object().shape({
    });

    useEffect(() => {
        console.log("isRequired", isRequired)
        console.log("CMSData", CMSData)
        if (isRequired === true && CMSData === null) {
            let param = {
                isRequired: isRequired,
                nDefaultTemplateID: nDefaultTemplateID
            };
            AxiosPost("CMSComponent/AddDefaultLayout", param, (res) => {
                console.log("AddDefaultLayout ", res);
                setCMSData(res)
            }, (err) => {
                if (!err.response) {
                    DialogFn.UnBlockUI();
                    DialogFn.Warning(err.Message);
                }
            })
        }
    }, [isRequired]);

    useEffect(() => {
        console.log("CMSDatax", CMSData)
        if (CMSData !== null) {
            var arr = [];
            for (var i = 1; i <= CMSData.lstCMS.length; i++) {
                var item = {
                    label: i.toString(),
                    value: i.toString()
                };
                arr.push(item);
            }
            setlstOrder(arr);

            //console.log("CMSData.lstSlot", CMSData.lstSlot)
            //console.log("CMSData.length", CMSData.length)
            //setnTemplateID(CMSData.nTemplateContentID)
            //for (const item of CMSData.lstSlot) {
            //    form.setValue("nContentType" + item.nSlotNo, item.nType + "");
            //}
            for (const item of CMSData.lstCMS) {
                form.setValue("sConfigType" + item.sContentID, item.nOrder + "");
                //form.setValue("nContentType" + item.nSlotNo, item.nType + "");
            }
        }
    }, [CMSData]);

    const AddMoreTemplate = () => {
        let param = {
            lstCMS: CMSData === null ? null : CMSData.lstCMS,
            isRequired: isRequired,
            nDefaultTemplateID: nDefaultTemplateID
        };
        console.log("param", param)
        AxiosPost("CMSComponent/AddMoreTemplate", param, (res) => {            
            console.log("AddMoreTemplate ", res);
            setCMSData(res)
        }, (err) => {
            if (!err.response) {
                DialogFn.UnBlockUI();
                DialogFn.Warning(err.Message);
            }
        })
    };

    const DeleteTemplate = (nOrder) => {
        let param = {
            isRequired: isRequired !== null && isRequired !== undefined ? isRequired : false ,
            nOrder: nOrder,
            lstCMS: CMSData === null ? null : CMSData.lstCMS
        };
        console.log("param", param)
        DialogFn.Submit(t("deletemsg"), async () => {
            DialogFn.BlockUI();
            AxiosPost("CMSComponent/DeleteTemplate", param, (res) => {
                console.log("AddMoreTemplate ", res);
                setCMSData(res)
                DialogFn.Success(t('deletesuccess'));
                DialogFn.UnBlockUI();
            }, (err) => {
                if (!err.response) {
                    DialogFn.UnBlockUI();
                    DialogFn.Warning(err.Message);
                }
            })
        });
    };

    const ChangeOrderTemplate = (nOrder,nChangeTo) => {
        let param = {
            nOrder: nOrder,
            nChangeOrderTo: nChangeTo,
            lstCMS: CMSData === null ? null : CMSData.lstCMS
        };
        console.log("param", param)
        AxiosPost("CMSComponent/ChangeOrderTemplate", param, (res) => {
            console.log("AddMoreTemplate ", res);
            setCMSData(res)
        }, (err) => {
            if (!err.response) {
                DialogFn.UnBlockUI();
                DialogFn.Warning(err.Message);
            }
        })
    };

    const GetListLayout = () => {
        let param = {
        };
        AxiosPost("CMSComponent/GetListLayout", param, (res) => {
            console.log("GetListLayout ", res);
            setlstTemplateData(res)
        }, (err) => {
            if (!err.response) {
                DialogFn.UnBlockUI();
                DialogFn.Warning(err.Message);
            }
        })
    };
    //useEffect(() => {
    //    console.log("CMSDataCMSData", CMSData)
    //}, [CMSData]);

    useEffect(() => {
        GetListLayout();
    }, []);

    const form = useForm({
        resolver: yupResolver(objSchema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const handleClosePopup = () => {
        setopenPopup(false);
    };

    const handleClosePopupPreview = () => {
        setopenPopupPreview(false);
    };

    const handleOpenPopup = (CMSHead) => {
        setopenPopup(true);
        setnOrder(CMSHead.nOrder);
        setnTemplateID(CMSHead.nTemplateContentID);
    };

    return (
        <div>
            <PopupChooseTemplate
                open={openPopup}
                handleClose={handleClosePopup}
                nTemplateID={nTemplateID}
                setCMSData={setCMSData}
                CMSData={CMSData}
                nOrder={nOrder}
                lstTemplateData={lstTemplateData}
            />

            <Dialog
                open={openPopupPreview}
                onClose={handleClosePopupPreview}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"xl"}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography
                        sx={{ color: "rgb(52, 71, 103)" }}
                        className="Header-title-form"
                    >
                        {"Preview"}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClosePopupPreview}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    
                    <ContentManagementFrame
                        CMSData={CMSData}
                    />
                </DialogContent>
            </Dialog>

            <CssBaseline />
            <ThemeProvider theme={theme}>
            <FormProvider {...form}>
                {CMSData !== null && CMSData.lstCMS !== null ? CMSData.lstCMS.map((CMSHead, i) => (
                    <Grid xs={12} style={{marginTop:"1rem"}}>
                        <Accordion header={t('templateLayout') + " " + t('order') + " " + CMSHead.nOrder}>
                            <Grid container spacing={2} direction="row" >
                                <Grid item xs={12} style={{ marginBottom: "1rem", display: "flex", justifyContent: "end" }}>
                                    <Grid item xs={3} md={1} style={{ display: "flex" }}>
                                        <SelectFormItem
                                            id={"sConfigType" + CMSHead.sContentID}
                                            name={"sConfigType" + CMSHead.sContentID}
                                            label={"Order"}
                                            required={false}
                                            isClearable={true}
                                            disabled={false}
                                            options={lstOrder}
                                            onChange={(e) => {
                                                console.log("E",e)
                                                ChangeOrderTemplate(CMSHead.nOrder,Number(e.value))
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={"auto"}  style={{ marginLeft: "1rem" }}>
                                            <BtnDeleteOnTable onClick={()=> DeleteTemplate(CMSHead.nOrder)} />
                                    </Grid>
                                </Grid>
                            <Grid item md={3}>
                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{  }}>
                                        <Grid container className={""} xs={12} style={{}} onClick={(e) => { }}>
                                            {lstTemplateData.filter(f => f.nTemplateID === CMSHead.nTemplateContentID).map((item, i) => (
                                                <Grid container className={""} xs={12} style={{ height: 200, display: "flex", margin: 10, border: "1px solid black", cursor: "pointer" }} onClick={(e) => { handleOpenPopup(CMSHead); }}>
                                                    {item.lstSlot !== null ?item.lstSlot.map((itemSlot, i) => (
                                                        <Grid xs={itemSlot.nGridSize_Lg} md={itemSlot.nGridSize_Lg} lg={itemSlot.nGridSize_Lg} style={{ display: 'flex' }}>
                                                            <Grid container style={itemSlot.lstChild !== null && itemSlot.lstChild !== undefined && itemSlot.lstChild.length > 0 ? {} : { display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#e7ebf0", border: "1px solid black" }}>
                                                                {itemSlot.sSlot !== null ? itemSlot.sSlot : ""}
                                                                {itemSlot.lstChild !== null && itemSlot.lstChild !== undefined ? itemSlot.lstChild.map((child, j) => (
                                                                    <Grid xs={child.nGridSize_Lg} md={child.nGridSize_Lg} lg={child.nGridSize_Lg} style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#e7ebf0", border: "1px solid black" }}>
                                                                        {child.sSlot !== null ? child.sSlot : ""}
                                                                    </Grid>
                                                                )) : null}
                                                            </Grid>
                                                        </Grid>
                                                    )) : null}
                                                </Grid>
                                            ))}
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "center", justifyContent: "space-evenly", display: "flex" , flexWrap:"wrap"}}>
                                        <BtnChooseTemplate txt={t('templateLayout')}
                                            onClick={() => {
                                                handleOpenPopup(CMSHead)
                                            }} />
                                    <Fab color="primary" className="preview-cms" aria-label="add" size="small" style={{ backgroundColor: "orange", color: "white" }} onClick={(e) => { setopenPopupPreview(true) }}>
                                        <VisibilityIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                                <Grid item md={9} style={{paddingTop:0}}>
                                    {CMSHead.lstSlot !== null ? CMSHead.lstSlot.map((item, i) => (
                                    <Grid container className={""} xs={12} style={{}} onClick={(e) => { }}>
                                            <SlotContent
                                                isRequired={isRequired}
                                                AllData={CMSData}
                                                CMSData={item}
                                                CMSHead = {CMSHead}
                                            />
                                    </Grid>
                                ))
                                    : null}

                            </Grid>
                        </Grid>
                        </Accordion>
                    </Grid>
                    ))
                    : null}
                <BtnAddMoreTemplate txt={t('AddMoreTemplate')} onClick={() => { AddMoreTemplate() }} />
                </FormProvider>
            </ThemeProvider>
        </div>
    )
};
export default ContentManagement;