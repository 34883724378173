import { Grid, Stack } from "@mui/material";
import {
  TextBoxForm,
  SelectFormItem,
  SelectMultipleFormItem,
  DatePickerForm
} from "STM/components/Input";
import { BtnSearchCustom,BtnClearCustom } from "STM/components/Button";
import { options } from "STM/enum/enumSystem";
import { useTranslation } from "react-i18next";

const FilterUserPerm = ({ onSearch, onClear, arrDropdownRole = [],isRole }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        maxHeight: 200,
        overflow: "auto",
        width: "100%",
        marginBottom: "1em",
      }}
    >
      <Stack
        style={{ padding: "10px" }}
        justifyContent={"end"}
        direction={"row"}
        spacing={1}
        sx={{
          border: "1px #0168cc solid",
          //   mx: "1em",
          borderRadius: "10px",
          // minWidth: "800px",
          ":before": {
            content: '""',
            position: "absolute",
            left: "12%",
            top: "2.5em",
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderBottom: " 5px solid #0168cc",
          },
        }}
      >
        <Grid container justifyContent={"flex-end"} spacing={1}>
          <Grid item md={2} xs={12}>
            <TextBoxForm
              name="sEmployeeID"
              label={t('id')}
              isshowTextCont={false}
              disabled={false}
              shrink
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextBoxForm
              name="sUsername"
              label={t('name')}
              isshowTextCont={false}
              disabled={false}
              shrink
            />
          </Grid>
          <Grid item xs={2.6}>
            <SelectMultipleFormItem
              id={"lstUserGroup"}
              name={"lstUserGroup"}
              label={isRole ? t("userrole") : t("usergroup")}
              required={false}
              disabled={false}
              options={arrDropdownRole}
              shrink
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <DatePickerForm
              name={"sUpdateDate"}
              label={t("lastupdate")}
              required={false}
              fullWidth={true}
              shrink
            />
          </Grid>
          <Grid item xs={2}>
            <SelectFormItem
              id={"sStatus"}
              name={"sStatus"}
              label={t('status')}
              required={false}
              disabled={false}
              options={options.status}
              shrink
            />
          </Grid>
          <Grid item md={0.7} xs={2}>
            <BtnClearCustom onClick={onClear} />
          </Grid>
          <Grid item md={0.7} xs={2}>
            <BtnSearchCustom onClick={onSearch} />
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default FilterUserPerm;
