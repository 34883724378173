import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import CropFreeIcon from '@mui/icons-material/CropFree';
import IconButton from '@mui/material/IconButton';

import { Box, Grid, Typography, Button } from "@mui/material";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import parse from 'html-react-parser';

export default function Organization({ TagHtml, jsonOrganization }) {
   const frontURL = process.env.REACT_APP_Front_URL;


   const [open, setOpen] = React.useState(false);
   const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('lg');

   const handleClickOpen = () => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
   };

   return (
      <Grid container className="sec_organization">

         <Grid item md={12} sm={12}>
            {parse(TagHtml.sContents)}
            {/* <div className="text-topic">about us</div>
            <div className="text-title">Organization <br></br>Chart</div>
            <div className="box-organizer-img">
               <img className="organizer_Img" src={frontURL + "assets/aboutus/chart.png"}></img>
            </div> */}

            <Grid className="organizer-box-dialog">
               <Button className="organization-btn-viewchart" color="primary" variant="outlined" disabled={false} endIcon={<CropFreeIcon />}
                  onClick={handleClickOpen}>
                  view full size
               </Button>

               <Dialog maxWidth={maxWidth} aria-labelledby="responsive-dialog-title" open={open}
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  BackdropProps={{ style: { backgroundColor: "#000" } }}>
                  <DialogActions className="box-DialogActions">
                     <IconButton aria-label="delete" size="medium" onClick={handleClose}>
                        <Box className="organizer-dialog-close">Close</Box>
                        <CloseIcon fontSize="inherit" />
                     </IconButton>
                  </DialogActions>

                  {/* <DialogContent className="box-organizer-ImgDialog"> */}
                  <DialogContent className="box-DialogContent" sx={{ overflow: "hidden" }}>
                     {/* <Box className="organizer_ImgDialog" sx={{ backgroundImage: `url(${frontURL + "assets/aboutus/chart.png"})` }} /> */}
                     {/* <img className="organizer_ImgDialog" src={frontURL + "assets/aboutus/chart.png"}></img> */}
                     <img className="organizer_ImgDialog2" width="460" height="345" src={frontURL + "assets/aboutus/chart.png"} />
                  </DialogContent>
               </Dialog>
            </Grid>
            {
               jsonOrganization.map((items, Index) => (
                  <div key={Index}>
                     <div className="text-title">{items.position}</div>
                     <Grid container justifyContent="center">
                        {
                           items.detail.map((subItems, sIndex) => (
                              <Grid key={sIndex} item md={6} sm={6} xs={12} className="organization-diagram">
                                 <div style={{ overflow: "hidden" }}>
                                    <img className="organization-image" src={subItems.img}></img>
                                 </div>
                                 <div className="organization-name">{subItems.fullmane}</div>
                                 {
                                    subItems.positions.map((ItemsPosition, pIndex) =>
                                       <div key={pIndex} className="organization-position">
                                          {ItemsPosition.positionName}
                                          {(subItems.positions.length - 1) === pIndex ? '' : ', '}
                                       </div>)
                                 }
                              </Grid>
                           ))
                        }
                     </Grid>
                  </div>
               ))
            }
         </Grid>

      </Grid>
   )
}
