import { Grid, Typography, Box, IconButton } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import yupFormSchemas from "STM/components/FormItem/yupFormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect } from "react";
import {
  BtnNext,
} from "STM/components/Button";
import { HeaderInput } from "STM/components/Input";
import {
  InputPassword
} from "STM/components/Input";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { AxiosPost } from "STM/utilities/ST_Axios";

export default function ChangePassword() {
  const [sPwd, setPwd] = useState("");
  const [uppercase, setUppercase] = useState(false);
  const [lowercase, setLowercase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [numberChar, setNumberChar] = useState(false);
  const [lenghtChar, setLenghtChar] = useState(false);
  const [isAutoComplete, setIsAutoComplete] = useState(false);
  const [isNonDupUser, setIsNonDupUser] = useState(false);
  const [isLengthPass, setIsLengthPass] = useState(false);
  const [isCheckPass, setIsCheckPass] = useState(false);
  const [isEncrypt, setIsEncrypt] = useState(false);
  const [oldPass, setOldPass] = useState("");
  const objSchema = yup.object().shape({
    sCurrentPassword: yupFormSchemas.string("Current Password", {
      required: false,
    })
    .oneOf([oldPass], "password is incorrect")
    ,
    sPassword: yupFormSchemas
      .string("Password", { required: false })
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])/,
        `Must Contain ${isLengthPass ? "8 Characters," : ""} One Uppercase, One Lowercase, One Number and One Special Case Character`
      )
      .min(isLengthPass ? 8 : 0)
      .notOneOf([yup.ref("sCurrentPassword")], "The current password and the new password cannot be duplicated.")
      ,
      sPasswordNon: yupFormSchemas
      .string("Password", { required: false })
      .min(isLengthPass ? 8 : 0, isLengthPass ? "Password must be at 8 char long" : "")
      .notOneOf([yup.ref("sCurrentPassword")], "The current password and the new password cannot be duplicated.")
      ,
    sConfirmPassword: yupFormSchemas
      .string("Confirm Password", {
        required: false,
      })
      .oneOf([yup.ref(isCheckPass ? "sPassword" : "sPasswordNon")], "Passwords does not match"),
  });

  const form = useForm({
    resolver: yupResolver(objSchema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const OnCheckPolicy = (input) => {
    setPwd(input);
    setUppercase(containsUppercase(input));
    setLowercase(containsLowercase(input));
    setSpecialChar(containsSpecialChars(input));
    setNumberChar(containsNumbers(input));
    setLenghtChar(input.length > 7);
  };

  function containsUppercase(str) {
    return Boolean(str.match(/[A-Z]/));
  }
  function containsLowercase(str) {
    return Boolean(str.match(/[a-z]/));
  }
  function containsNumbers(str) {
    return /\d/.test(str);
  }
  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }
  const renderStatus = (pass) => {
    return (
      <IconButton
        sx={{ p: "0 3px" }}
        aria-label="fingerprint"
        color={pass ? "success" : "error"}
      >
        {pass ? (
          <CheckCircleOutlineIcon sx={{ fontSize: "1.2rem" }} />
        ) : (
          <CancelOutlinedIcon sx={{ fontSize: "1.2rem" }} />
        )}
      </IconButton>
    );
  };

  const OnGetPolicy = async () => {
    await AxiosPost("ChangePassword/GetPolicyPassword", {}, (res) => {
      setIsAutoComplete(res.isAutoComplete);
      setIsNonDupUser(res.isNonDupUser);
      setIsLengthPass(res.isLengthPass);
      setIsCheckPass(res.isCheckPass);
      setIsEncrypt(res.isEncrypt);
      setOldPass(res.sOldPass);
    });
  };

  useEffect(() => {
    OnGetPolicy();
  }, []);

  const onSubmit = (e) => {
    let objParam = {
      sID: "",
      sCurrentPassword: e["sCurrentPassword"],
      sPassword: e["sPassword"],
      sConfirmPassword: e["sConfirmPassword"],
    };
  };
  return (
    <FormProvider {...form}>
      <Grid
        container
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexFlow: "row wrap",
          marginTop: "24px",
          width: "calc(100% - 24px)",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          <Grid item xs={12} className="Card-form">
            <Grid
              item
              xs={12}
              sx={{
                color: "rgb(52, 71, 103)",
              }}
              className="Header-grid-form"
            >
              <Typography
                sx={{ color: "rgb(52, 71, 103)" }}
                className="Header-title-form"
              >
                Change Password
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1.2 }}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={"Current Password"} />
                    <InputPassword
                      name="sCurrentPassword"
                      label={"********"}
                      isshowTextCont={false}
                      type={"password"}
                      autoComplete={isAutoComplete ? "off" : "on"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={"Password"} />
                    <InputPassword
                      name={isCheckPass ?"sPassword" : "sPasswordNon"}
                      label={"********"}
                      isshowTextCont={false}
                      type={"password"}
                      autoComplete={isAutoComplete ? "off" : "on"}
                      //   onBlur={(e) => {
                      //     OnCheckPolicy(e.target.value)
                      //   }}
                      onChange={(e) => {
                        OnCheckPolicy(e);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={"Confirm Password"} />
                    <InputPassword
                      name="sConfirmPassword"
                      label={"********"}
                      isshowTextCont={false}
                      type={"password"}
                      autoComplete={isAutoComplete ? "off" : "on"}
                    />
                  </Box>
                </Grid>
                {isLengthPass ||
                  isCheckPass ? (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          marginTop: "32px",
                          marginBottom: "8px",
                          opacity: 1,
                          background: "transparent",
                          color: "rgb(52, 71, 103)",
                        }}
                      >
                        <Typography
                          sx={{
                            m: 0,
                            fontSize: "1.25em",
                            lineHeight: 1.375,
                            letterSpacing: "0",
                            opacity: 1,
                            textTransform: "none",
                            verticalAlign: "unset",
                            textDecoration: "none",
                            color: "rgb(52, 71, 103)",
                            fontWeight: 500,
                          }}
                        >
                          Password requirements
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          marginBottom: "8px",
                          opacity: 1,
                          background: "transparent",
                          color: "rgb(52, 71, 103)",
                        }}
                      >
                        <Typography
                          sx={{
                            m: 0,
                            fontSize: "1em",
                            lineHeight: 1.6,
                            letterSpacing: "0.01071em",
                            opacity: 1,
                            textTransform: "none",
                            verticalAlign: "unset",
                            textDecoration: "none",
                            color: "rgb(103, 116, 142)",
                            fontWeight: 400,
                          }}
                        >
                          Please follow this guide for a strong password
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          flexWrap: "wrap",
                          opacity: 1,
                          background: "transparent",
                          color: "rgb(52, 71, 103)",
                        }}
                      >
                        <ul
                          style={{
                            margin: "0",
                            paddingLeft: "26px",
                            opacity: 1,
                            background: "transparent",
                            color: "rgb(52, 71, 103)",
                          }}
                        >
                            {
                                isCheckPass && (
                                    <li>
                            <Typography
                              sx={{
                                m: 0,
                                fontSize: "0.875rem",
                                lineHeight: 1.5,
                                letterSpacing: "0.02857em",
                                opacity: 1,
                                textTransform: "none",
                                verticalAlign: "unset",
                                textDecoration: "none",
                                color: "rgb(103, 116, 142)",
                                fontWeight: 400,
                                padding: "3px 0",
                              }}
                            >
                              One special characters
                              {sPwd !== "" && renderStatus(specialChar)}
                            </Typography>
                          </li>
                                )
                            }
                          {
                            isLengthPass && (
                                <li>
                            <Typography
                              sx={{
                                m: 0,
                                fontSize: "0.875rem",
                                lineHeight: 1.5,
                                letterSpacing: "0.02857em",
                                opacity: 1,
                                textTransform: "none",
                                verticalAlign: "unset",
                                textDecoration: "none",
                                color: "rgb(103, 116, 142)",
                                fontWeight: 400,
                                padding: "3px 0",
                              }}
                            >
                              Min 8 characters
                              {sPwd !== "" && renderStatus(lenghtChar)}
                            </Typography>
                          </li>
                            )
                          }
                          {
                            isCheckPass && (
                                <li>
                            <Typography
                              sx={{
                                m: 0,
                                fontSize: "0.875rem",
                                lineHeight: 1.5,
                                letterSpacing: "0.02857em",
                                opacity: 1,
                                textTransform: "none",
                                verticalAlign: "unset",
                                textDecoration: "none",
                                color: "rgb(103, 116, 142)",
                                fontWeight: 400,
                                padding: "3px 0",
                              }}
                            >
                              One number (2 are recommended)
                              {sPwd !== "" && renderStatus(numberChar)}
                            </Typography>
                          </li>
                            )
                          }
                          {
                            isCheckPass && (
                                <li>
                            <Typography
                              sx={{
                                m: 0,
                                fontSize: "0.875rem",
                                lineHeight: 1.5,
                                letterSpacing: "0.02857em",
                                opacity: 1,
                                textTransform: "none",
                                verticalAlign: "unset",
                                textDecoration: "none",
                                color: "rgb(103, 116, 142)",
                                fontWeight: 400,
                                padding: "3px 0",
                              }}
                            >
                              One uppercase characters
                              {sPwd !== "" && renderStatus(uppercase)}
                            </Typography>
                          </li>
                            )
                          }
                          {
                            isCheckPass && (
                                <li>
                            <Typography
                              sx={{
                                m: 0,
                                fontSize: "0.875rem",
                                lineHeight: 1.5,
                                letterSpacing: "0.02857em",
                                opacity: 1,
                                textTransform: "none",
                                verticalAlign: "unset",
                                textDecoration: "none",
                                color: "rgb(103, 116, 142)",
                                fontWeight: 400,
                                padding: "3px 0",
                              }}
                            >
                              One lowercase characters
                              {sPwd !== "" && renderStatus(lowercase)}
                            </Typography>
                          </li>
                            )
                          }
                        
                        </ul>
                      </Box>
                    </Grid>
                  ):null}
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex" }}
                  justifyContent={"end"}
                >
                  <BtnNext
                    txt={"Update Password"}
                    onClick={form.handleSubmit((e) => onSubmit(e))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
