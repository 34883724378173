import { Box, Grid, Typography } from "@mui/material";


export default function NewsDetailSkeleton() {
  const frontURL = process.env.REACT_APP_Front_URL;
  return (
    <Grid
      container
      className="news-activity-container"
      sx={{
        backgroundImage: `url(${frontURL + "images/newsbg.png"})`,
        backgroundSize: "cover",
        backgroundPosition: "50% 55%",
        position: "relative",
        overflow:"hidden"
      }}
    >
      <Grid container className="news-detail-container">
        <Grid item xs={12} sx={{ opacity: 0 }}>
          <Box className="top-news-header">
            <Typography
              sx={{
                m: 0,
                fontSize: "0.875rem",
                lineHeight: 1.25,
                letterSpacing: "0.0333em",
                opacity: 1,
                textTransform: "none",
                verticalAlign: "unset",
                textDecoration: "none",
                color: "rgb(52, 71, 103)",
                fontWeight: 400,
              }}
            >
              Published in{" "}
            </Typography>
            <Typography
              sx={{
                margin: "0 0.5rem",
                fontSize: "0.875rem",
                lineHeight: 1.25,
                letterSpacing: "0.0333em",
                opacity: 1,
                textTransform: "none",
                verticalAlign: "unset",
                textDecoration: "none",
                color: "rgb(52, 71, 103)",
                fontWeight: 700,
              }}
            >
              PTT Global LNG
            </Typography>
            {/* <Box
            sx={{
              width: "0.5rem",
              height: "0.5rem",
              borderRadius: "50%",
              background: "#b0bcd4",
              marginRight: "0.3rem",
            }}
          ></Box> */}
            {/* <Typography
            sx={{
              m: 0,
              fontSize: "0.875rem",
              lineHeight: 1.25,
              letterSpacing: "0.0333em",
              opacity: 1,
              textTransform: "none",
              verticalAlign: "unset",
              textDecoration: "none",
              color: "rgb(52, 71, 103)",
              fontWeight: 400,
            }}
          >
            21 June 2023
          </Typography> */}
          </Box>
        </Grid>
        <Grid item xs={12} sx={{mb:2}}>
          <div
            className="_skeleton"
            style={{
              height: "45px",
              width: "83%",
              borderTopRightRadius: "10px",
            }}
          ></div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            className="_skeleton"
            style={{
              height: "400px",
              width: "100%",
              //   borderTopRightRadius: "10px",
            }}
          ></div>
        </Grid>
        <Grid item xs={12} md={6} sx={{ padding: "1rem" }}>
          <div
            className="_skeleton"
            style={{
              height: "26px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "1rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "55%",
              borderTopRightRadius: "5px",
              marginBottom: "1rem",
            }}
          ></div>

          <div
            className="_skeleton"
            style={{
              height: "26px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "1rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "100%",
              borderTopRightRadius: "5px",
              marginBottom: "0.5rem",
            }}
          ></div>
          <div
            className="_skeleton"
            style={{
              height: "16px",
              width: "35%",
              borderTopRightRadius: "5px",
              marginBottom: "0.5rem",
            }}
          ></div>
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={1} sx={{mt:1}}>
                <Grid item lg={2.4} md={3} sm={4} xs={12}>
                <div
            className="_skeleton"
            style={{
              height: "150px",
              width: "100%",
            }}
          ></div>
                </Grid>
                <Grid item lg={2.4} md={3} sm={4} xs={12}>
                <div
            className="_skeleton"
            style={{
              height: "150px",
              width: "100%",
            }}
          ></div>
                </Grid>
                <Grid item lg={2.4} md={3} sm={4} xs={12}>
                <div
            className="_skeleton"
            style={{
              height: "150px",
              width: "100%",
            }}
          ></div>
                </Grid>
                <Grid item lg={2.4} md={3} sm={0} xs={0}>
                <div
            className="_skeleton"
            style={{
              height: "150px",
              width: "100%",
            }}
          ></div>
                </Grid>
                <Grid item lg={2.4} md={0} sm={0} xs={0}>
                <div
            className="_skeleton"
            style={{
              height: "150px",
              width: "100%",
            }}
          ></div>
                </Grid>
            </Grid>

        </Grid>
        <Grid item xs={12} sx={{mt:2}}>
        <span
            className="badge-news _skeleton"
            style={{ height: "23px", width: "55px" }}
          >
          </span>
          <span
            className="badge-news _skeleton"
            style={{ height: "23px", width: "85px" }}
          >
          </span>
          <span
            className="badge-news _skeleton"
            style={{ height: "23px", width: "35px" }}
          >
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
}
