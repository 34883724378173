
/// impoer Page Conponent
import "./MiniMal.css";
///import LayoutNav from "./LayoutNav";
///import LayoutNavSide from "./LayoutNavSide";
/// import LayoutNavClass from "./LayoutNavClass";
import HeaderBasic from "layout/LayoutComponent/HeaderComponent/HeaderBasic";
import Footer from "view/Home/Footer/Footer";

/// STM
import { SwAlert } from "STM/components/Alert/index";
import { AxiosPost } from "STM/utilities/ST_Axios";
//// MUI
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
////import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AlertTitle, AlertMsg } from "config/AlertMsgConfig";
import {
  alpha,
  Menu,
  MenuProps,
  styled,
} from "@mui/material";

import { CookieConsentCP } from "utilities/CookieConsent/CookieConsentCP";



const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0",
      textAlign: "center",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledMenuNoti = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 320,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100vw",
      left: "0 !important",
      top: "62px !important",
    },
    "& .MuiMenu-list": {
      padding: "0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const MinimalLayout = (props) => {
  let i18next = process.env.REACT_APP_JWT_i18next;
  const location = useLocation();
  ////const navigate = useNavigate();
  ////const MenuID_Selected: Number = 0;
  ////const [FullName1, FullName1_Set] = React.useState<string>();
  ////const [sActiveFlag, setsActiveFlag] = React.useState<string>();
  ////const [FullCompanyName, FullCompanyName_Set] = React.useState<string>();
  const [openBar, setOpenBar] = useState(false);
  const [sNotiMessage, setsNotiMessage] = useState("");
  const [oTranslationParam, setoTranslationParam] = useState({
    sKey: "",
    sMain: "",
    sSecond: "",
  });


  ////const [oCountryDropdown, setoCountryDropdown] = useState([] as any);
  //// let [MenuBar2, MenuBar2_Set] = React.useState<Array<ILayout.Menu>>([]);
  ////const { t, i18n } = useTranslation();
  // // const handleCloseBar = (
  // //   event?: React.SyntheticEvent | Event,
  // //   reason?: string
  // // ) => {
  // //   if (reason === "clickaway") {
  // //     return;
  // //   }
  // //   setOpenBar(false);
  // // };
  useEffect(() => {
    if (sNotiMessage) setOpenBar(true);
  }, [sNotiMessage]);

  useEffect(() => {
    if (oTranslationParam.sKey !== "") {
      SwAlert.Confirm(AlertTitle.Confirm, AlertMsg.ConfirmSave, () => {
        //AxiosPost("LanguageTranslation/OnQuickSaveTranslation", param, (res) => {
        //    console.log("resx ", res);
        //    setlstData(res);

        AxiosPost(
          "LanguageTranslation/OnSave_QuickSave",
          oTranslationParam,
          (res) => {
            navigator.clipboard.writeText(
              "t('" + oTranslationParam.sKey + "')"
            );
            setsNotiMessage(
              "Copy t('" + oTranslationParam.sKey + "') To Clipboard!"
            );
            window.location.reload();
          }
        );
      });
    }
  }, [oTranslationParam]);

  ////const [isOpenMenuSide, setOpenMenuSide] = useState(false);
  const [FooterMenu, setFooterMenu] = useState([]);

  useEffect(() => {
    const elementt = document.getElementById("page-topbar");
    elementt.scrollIntoView({ behavior: "smooth" });

  }, [location.pathname]);


  return (
    <Box id="LAYOUT_2">
      {/* <video id="videoBG" muted autoPlay loop> 
        <source src={require("../../../assets/videos/ship-demo.mp4")} type="video/mp4"></source>
      </video> */}
      <HeaderBasic isPreview={false} setFooterMenu={setFooterMenu} />

      {/* <SlideBanner /> */}

      {/* <Box className="app-menu navbar-menu">
        <Box id="scrollbar">
          <Box className="container-fluid dropdown-custom-right">
            <LayoutNav isPreview={true} />
          </Box>
        </Box>
      </Box> */}

      {/* body */}
      <div id="BODY" className="Body2" style={props.BODY_style}>
        {props.children}
      </div>

      <Footer isPreview={false} FooterMenu={FooterMenu} />
      {/* TEST Footer */}
      {/* <Grid container md={12} sx={{ width: "100%", height: "700px", backgroundColor: "black" }} >
        <Grid item md={12}>
          <Box>Site Map TEST</Box>
        </Grid>
        <Grid item md={12}>
          <Box>Cookie TEST</Box>
        </Grid>
      </Grid> */}

      <CookieConsentCP />
    </Box>
  );
};

export default MinimalLayout;