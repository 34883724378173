
import "./CookiesPolicy.css";
import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CookiesPolicyDetail from "./CookiesPolicyDetail";
import {useSearchParams } from "react-router-dom";
import PrivacyNotice from "./PrivacyNotice";

export const CookiesPolicy = () => {
  let tabs = [
    { id: "1", label: "Cookies Policy" },
    { id: "2", label: "Privacy Notice" },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  let tabIndex = "1";
  let sTab = searchParams.get("tab");
  if(sTab != null && sTab != ""){
    tabIndex =  sTab;
  }
  const [value, setValue] = React.useState(tabIndex);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const frontURL = process.env.REACT_APP_Front_URL;
  return (
    <>
    <div>
      <div className="wrap_banner-inside">
        <figure>
          <img src={frontURL + "images/policy-bg.jpg"} />
        </figure>
        <div className="txt-lowercase caption-inside">
          <h1 style={{color:"white"}}>Data Subject Requests</h1>
        </div>
        <div className="banner-curve">
        <img src={frontURL + "images/curve.svg"} />
        </div>
      </div>
    </div>
    <div className="cookiesPolicy-container">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{display: "flex",
                    justifyContent: "center",}}>
            <TabList onChange={handleChange}>
              <Tab className="tabs-label" label="Cookies Policy" value="1" />
              <Tab className="tabs-label"  label="Privacy Notice" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <CookiesPolicyDetail />
          </TabPanel>
          <TabPanel value="2">
            <PrivacyNotice/>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
    </>
  );
};
