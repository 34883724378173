import UserGroupList from 'view/STM/UserGroup/UserGroupList';
import UserGroupForm from 'view/STM/UserGroup/UserGroupForm'
import LayoutSide from 'STM/layout/layout_Side';
import UserRoleForm from 'view/STM/UserRole/UserRoleForm';
import UserRoleList from 'view/STM/UserRole/UserRoleList';
import UserPermissionForm from 'view/STM/UserPermission/UserPermissionForm';
import UserPermission from 'view/STM/UserPermission/UserPermission';
import ChangePasswordForm from 'view/STM/ChangePassword';
import ConfigSetting from 'view/STM/ConfigSetting';

const route = [
    {
        exact: true,
        path: "/UserRole",
        component: UserRoleList,
        layout: LayoutSide,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/UserRoleForm",
        component: UserRoleForm,
        layout: LayoutSide,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/UserGroup",
        component: UserGroupList,
        layout: LayoutSide,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/UserGroupForm",
        component: UserGroupForm,
        layout: LayoutSide,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/UserPermissionForm",
        component: UserPermissionForm,
        layout: LayoutSide,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/UserPermission",
        component: UserPermission,
        layout: LayoutSide,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/ChangePassword",
        component: ChangePasswordForm,
        layout: LayoutSide,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/ConfigSetting",
        component: ConfigSetting,
        layout: LayoutSide,
        props: { MenuID_Selected: 1 }
    },
];
export default route;