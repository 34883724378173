export const enum EnumMenu {
    Home="Home",
    CreateRequest="CreateRequest",
    Dashboard="Dashboard",
    ContractList="ContractList",
    ApprovalList="ApprovalList",
    Search="Search",
    Report="Report",
    Setup="Setup",
    MasterDataManagment="MasterDataManagment",
    GroupPermissionForm="GroupPermissionForm",
    UserManagment="UserManagment",
    Notification="Notification",
    Contract="Contract",
    DelegateOwner="DelegateOwner",
    JobDetails="JobDetails",
    NotiSetting="NotiSetting",
}
export const enum EnumUserType{
    PTT = "PTT",
    Contract = "Contract",
    Other = "Other",
}

export const enum EnumPermission{
    Disable = 0,
    ReadOnly = 1,
    Enable = 2,
}
export const enum EnumPeriod{
    AllDay = 1,
    SomeDay = 2,
    Monthly = 3,
}
export const enum EnumWeekDay{
    Day = 1,
    WeekOn = 2,
}
export const enum EnumTypeUpload{
    Image = 1,
    Video = 2,
}

export const enum EnumStatus{
    Draft = 23,
    Submit = 24,
}
export const enum EnumTypeLink{
    External = 1,
    Internal = 2,
}
export const enum EnumLang{
    MainLang = "1",
    MainLangCode = "en-US",
}

export const enum EnumSubmit{
    Confirm = "C",
    Auto = "A",
    Draft = "D",
}

export const enum EnumMenuType{
    MenuContent = "1",
    MenuSub = "2",
    MenuLink = "3",
}

export const options = {
    status: [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
      ],
    pin: [
        { label: "Pin", value: "1" },
        { label: "Unpin", value: "0" },
      ],
      type: [
        { label: "Image", value: "1" },
        { label: "Video", value: "2" },
      ],
    sortby: [
        { label: "Create Date", value: "1" },
        { label: "Last Update", value: "2" },
    ]
}