import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState, forwardRef, useRef, useEffect } from "react";
import { HeaderInputProps } from "./HeaderInputProps";

export default function HeaderInput(props: HeaderInputProps)  {
  return (
    <Box
      sx={{
        marginBottom: "8px",
        marginLeft: "4px",
        // marginTop: "24px",
        lineHeight: 0,
        display: "inline-block",
        opacity: 1,
        background: "transparent",
        color: "rgb(52, 71, 103)",
      }}
    >
      <Typography
        sx={{
          m: 0,
          fontSize: "0.875rem",
          lineHeight: 1.25,
          letterSpacing: "0.0333em",
          opacity: 1,
          textTransform: "none",
          verticalAlign: "unset",
          textDecoration: "none",
          color: "rgb(52, 71, 103)",
          fontWeight: 700,
        }}
      >
        {props.text}
        {
          props.required && props.required === true ? (
            <span style={{color:"red"}}> *</span>
          ): null
        }
      </Typography>
      {
        props.subtext && (
          <Typography
        className='Sub-title-form'
      >
        {props.subtext}
      </Typography>
        )
      }
    </Box>
  );
}


