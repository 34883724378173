import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import ItemRow from "./ItemRow";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  lstFile: {
    listStyle: "none",
    paddingLeft: 0,
  },
}));

const DisplayListRow = (props: any) => {
  const classes = useStyles();

  const arrFile = props.arrFile ? props.arrFile : [];

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(arrFile);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    props.SetarrFile(items);
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="lstFile">
            {(provided) => (
              <ul
                className={classes.lstFile}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {arrFile.map((f, i) => {
                  let sKey = f.nFile_ID+"";
                  return (
                    <Draggable
                      key={sKey}
                      draggableId={sKey}
                      index={i}
                      isDragDisabled={!props.IsDrag || props.disabled}
                    >
                      {(provided) => (
                        <li
                          style={{padding:'4px 0px'}}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ItemRow
                            key={i}
                            IsopenPopUp={props.IsopenPopUp}
                            setIsopenPopUp={props.setIsopenPopUp}
                            IsCrop={props.IsCrop}
                            arrObjFile={props.arrObjFile}
                            sFileType={f.sFileType}
                            sFileName={f.sFileName}
                            sPath={f.sPath}
                            sSize={f.sSizeName}
                            IsCompleted={f.IsComplete}
                            IsProgress={f.IsProgress}
                            sProgress={f.sProgress}
                            sFolderName={f.sFolderName}
                            onDelete={props.onDelete}
                            nFile_ID={f.nFile_ID}
                            onLoad={props.onLoad}
                            sUrl={f.sUrl}
                            sCropFileLink={f.sCropFileLink}
                            sFileLink={f.sFileLink}
                            disabled={props.disabled}
                            onOpenFile={props.onOpenFile}
                            IsHiddenUploadBox={props.IsHiddenUploadBox}
                            setStartVideoOn={props.setStartVideoOn}
                            nStartVideoOn={props.nStartVideoOn}
                            CannotSkipForward={props.CannotSkipForward}
                            onVideoEnd={props.onVideoEnd}
                          />
                          <hr style={{margin:'0',borderColor:'#ebeef1',opacity:0.4}}/>
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </Fragment>
  );
};
export default DisplayListRow;