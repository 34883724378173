import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FnBlock_UI } from 'STM/components/BlockUI';
import { AxiosPost } from 'STM/utilities/ST_Axios';
import { SelectFormItem } from 'STM/components/Input';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import yupFormSchemas from 'STM/components/FormItem/yupFormSchemas';
import { BtnSubmitForm } from 'STM/components/Button';
import { SwAlert } from 'STM/components/Alert/index';
import { AlertTitle, AlertMsg } from 'config/AlertMsgConfig'
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useTranslation } from 'react-i18next';

const PopupCreateLanguage = (prop) => {
    const { t, i18n } = useTranslation();
    const { open, handleClose} = prop;
    const [lstData, setlstData] = useState([] as any);

    const objSchema = yup.object().shape({
        oLanguage: yupFormSchemas.string("Language", { required: true }),
    });

    const GetDropdownList = () => {
        let param = {
        };
        AxiosPost("LanguageTranslation/GetDropdownCountryLanguage", param, (res) => {
            console.log("res ", res);
            setlstData(res)
        },
        );
    };

    useEffect(() => {
        GetDropdownList();
    }, []);

    const form = useForm({
        resolver: yupResolver(objSchema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const OnSaveLanguage = () => {
        let param = {
            sLangCode: form.getValues("oLanguage"),
        };
        console.log("param", param)
        SwAlert.Confirm(t("confirmation"), t('confirmsave'), () => {
            AxiosPost("LanguageTranslation/OnSave_NewLanguage", param, (res) => {
                window.location.reload();
            }, (err) => {
                if (!err.response) {
                    SwAlert.Warning("Warning", err.Message, () => { })
                }
            })
        })
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} >
                    <Typography
                        sx={{ color: "rgb(52, 71, 103)" }}
                        className="Header-title-form"
                    >
                        {t('NewLanguage')}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* <hr /> */}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center" style={{ marginTop: 10 }}>
                        {/* Table Head */}
                        <Grid item md={12} xs={12}>
                                    <FormProvider {...form}>
                                        <SelectFormItem
                                            id={"oLanguage"}
                                            name={"oLanguage"}
                                            label={"Language"}
                                            required={true}
                                            disabled={false}
                                            options={lstData}
                                        />
                                    </FormProvider>
                        </Grid>
                        <Grid item md={12} xs={12} style={{ display: "flex", justifyContent:"end" }}>
                            {/*        <Button sx={{ marginLeft: 2 }} variant="contained" color="success" onClick={form.handleSubmit((e) => { OnSaveLanguage() })}>*/}
                            {/*            Save*/}
                            {/*</Button>*/}
                            <BtnSubmitForm
                                icon={<SaveAltIcon />}
                                onClick={form.handleSubmit((e) => { OnSaveLanguage() })}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
};
export default PopupCreateLanguage;