import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BtnEditOnTable } from "STM/components/Button";
import { GridColumns } from "@mui/x-data-grid-pro";
import { initRows, PaginationInterface } from "STM/components/DataGrid";
import { DataGridMui } from "STM/components/DataGrid";
import { AxiosPost, AxiosGet } from "STM/utilities/ST_Axios";
import { useNavigate } from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import DialogPermission from "STM/components/Dialog/DialogPermission";
import FilterUserPerm from "./FilterUserPerm";

import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { FnDialog, insert } from "STM/utilities/ST_Function";

export default function UserPermission() {
  const navigate = useNavigate();
  const DialogFn = FnDialog();

  const [open, setOpen] = useState<boolean>(false);
  const [isRole, setIsRole] = useState<boolean>(false);
  const [isPerm, setIsPerm] = useState<boolean>(false);
  const [column, setColumn] = useState([]);
  const [lstPerm, setLstPerm] = useState([]);
  const { t } = useTranslation();
  const [arrDropdownRole, setArrDropDownRole] = useState([]);

  const handleClose = (value: boolean) => {
    setOpen(value);
  };

  const schema = yup.object().shape({});
  const formResolver = yupResolver(schema);
  const form = useForm({
    resolver: formResolver,
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: {} as any,
  });
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "nNo",
    sSortDirection: "asc",
  });

  const OnGetDataTable = (p) => {
    let param = {
      ...p,
      sEmployeeID: form.getValues("sEmployeeID"),
      sUsername: form.getValues("sUsername"),
      sStatus: form.getValues("sStatus"),
      lstUserGroup: form.getValues("lstUserGroup"),
      sUpdate: form.getValues("sUpdateDate")
        ? moment(form.getValues("sUpdateDate")).format("DD/MM/YYYY")
        : null,
    };
    DialogFn.BlockUI();
    setLoadingTable(true);
    AxiosPost("User/GetList", param, (res) => {
      setLoadingTable(false);
      setIsRole(res.IsRole);
      setIsPerm(res.IsPerm);
      setDataRow({
        ...dataRow,
        arrRows: res.lstData || [],
        nDataLength: res.nDataLength,
        nPageIndex: res.nPageIndex,
      });
      DialogFn.UnBlockUI();
    });
  };

  const OnGetDropdownUser = () => {
    AxiosGet(
      isRole ? "DropDown/GetUserRole" : "DropDown/GetUserGroup",
      {},
      (res) => {
        let result = res ?? [];
        setArrDropDownRole(result);
      }
    );
  };

  useEffect(() => {
    OnGetDataTable(dataRow);
    OnGetDropdownUser();
  }, []);

  const goToEdit = (sID: string) => {
    navigate(`/UserPermissionForm`, {
      state: {
        sID: sID,
      },
    });
  };

  const dataColumn: GridColumns = [
    {
      headerName: t("action"),
      field: t("action"),
      type: "actions",
      resizable: false,
      sortable: false,
      width: 100,
      getActions: (item) => {
        return [
          <BtnEditOnTable
            key={item.row.sID}
            txt="Edit"
            onClick={() => goToEdit(item.row.sID)}
          />,
        ];
      },
    },
    {
      field: "nNo",
      headerName: t("no"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 80,
    },
    {
      field: "sEmpID",
      headerName: t("id"),
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      width: 120,
    },
    {
      field: "sUserName",
      headerName: t("name"),
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 2,
      // hide: true,
      // editable: true
    },
    {
      field: "sUserGroup",
      headerName: isRole ? t("userrole") : t("usergroup"),
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 2,
    },
    {
      field: "sUpdateDate",
      headerName: t("lastupdate"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 160,
      renderCell: (item) => {
        return [
          <Box
            key={item.row.sID}
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: "0.8rem" }}>
                {moment(item.row.sUpdateDate, "DD-MM-YYYY HH:mm")
                  .format("DD/MM/YYYY")
                  .toString()}
              </Typography>

              <Tooltip
                title={
                  <span style={{ textAlign: "center" }}>
                    {[
                      item.row.sUpdateDate,
                      <br key={item.row.sID} />,
                      item.row.sCreateBy,
                    ]}
                  </span>
                }
              >
                <AccessTimeIcon
                  sx={{
                    fontSize: "1.2rem",
                    ml: 1,
                    color: "rgb(45, 206, 137)",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Box>
          </Box>,
        ];
      },
    },
    {
      field: "IsActive",
      headerName: t("status"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 100,
      renderCell: (item) => {
        return [
          item.row.IsActive ? (
            <Typography className="typography-status">
              <span className="span-status">
                <span
                  className="span-text-status"
                  style={{
                    background:
                      "linear-gradient(310deg, rgb(45, 206, 137), rgb(45, 206, 204))",
                    color: "#fff",
                  }}
                >
                  {t("active")}
                </span>
              </span>
            </Typography>
          ) : (
            <Typography className="typography-status">
              <span className="span-status">
                <span
                  className="span-text-status"
                  style={{
                    background: "linear-gradient(310deg, #B4324A, #DA5A73)",
                    color: "#fff",
                  }}
                >
                  {t("inactive")}
                </span>
              </span>
            </Typography>
          ),
        ];
      },
    },
  ];

  const OnDelete = (e) => {
    DialogFn.Submit(t("deletemsg"), async () => {
      DialogFn.BlockUI();
      setLoadingTable(true);
      await AxiosPost("User/OnDelete", { lstUserDelete: e }, (res) => {
        OnGetDataTable(dataRow);
        DialogFn.UnBlockUI();
      });
    });
  };

  const OnClearFilter = () => {
    form.reset();
    OnGetDataTable(dataRow);
  };

  useEffect(() => {
    if (dataRow.arrRows.length > 0 && isPerm) {
      setColumn(AddPermCol(dataColumn, 4));
    } else {
      setColumn(dataColumn);
    }
  }, [dataRow]);

  const AddPermCol = (dataCol, indexMap) => {
    let arrCol: GridColumns = dataCol;
    let arrNewCol = [];
    let objColAdd = {
      field: "nPerm",
      headerName: t("permission"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 110,
      renderCell: (item) => {
        return [
          <>
            <Typography>{item.row.nPerm}</Typography>
            <ListIcon
              onClick={() => {
                setOpen(true);
                setLstPerm(item.row.lstPermission);
              }}
              sx={{
                fontSize: "1.2rem",
                ml: 0.3,
                color: "rgb(52, 71, 103)",
                cursor: "pointer",
              }}
            />
          </>,
        ];
      },
    };
    arrNewCol = insert(arrCol, indexMap, objColAdd);
    arrCol = arrNewCol;

    return arrCol;
  };
  return (
    <Grid
      container
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexFlow: "row wrap",
        marginTop: "24px",
        width: "calc(100% - 24px)",
        justifyContent: "center",
      }}
    >
      <DialogPermission open={open} onClose={handleClose} lstPerm={lstPerm} />
      <Grid item xs={12}>
        <Grid item xs={12} className="Card-form">
          <Grid
            item
            xs={12}
            sx={{
              color: "rgb(52, 71, 103)",
            }}
            className="Header-grid-form"
          >
            <Grid item xs={12}>
              <DataGridMui
                isLoading={loadingTable}
                columns={column}
                rows={dataRow}
                onLoadData={(e) => {
                  OnGetDataTable(e);
                }}
                isNotShowPagination={false}
                isNotShowTotal={true}
                isShowCheckBox={true}
                onCickRow={() => {}}
                modeFilter={1}
                onDelete={(d) => OnDelete(d)}
                isAddData
                linkAdd={"/UserPermissionForm"}
                customFilterComponent={
                  <FormProvider {...form}>
                    <FilterUserPerm
                      arrDropdownRole={arrDropdownRole}
                      onSearch={(e) => OnGetDataTable(dataRow)}
                      onClear={() => OnClearFilter()}
                      isRole={isRole}
                    />
                  </FormProvider>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
