import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import {
  SwitchForm,
  RadioForm,
  SelectMultipleFormItem,
  DatePickerForm,
  TimePicker,
} from "STM/components/Input";
import { HeaderInput } from "STM/components/Input";
import { useFormContext } from "react-hook-form";
import { ShowDateProp } from "./ShowDateProps";
import moment from "moment";
import { AxiosPost } from "STM/utilities/ST_Axios";
import { EnumPeriod, EnumWeekDay } from "STM/enum/enumSystem";
import yupFormSchemas from "STM/components/FormItem/yupFormSchemas";
import { useTranslation } from "react-i18next";
import { FnDialog, insert } from "STM/utilities/ST_Function";

const ShowDateForm = (props: ShowDateProp) => {
  const form = useFormContext();
  const DialogFn = FnDialog();
  const [nPeriod, setPeriod] = useState(1);
  const [nOptionMonth, setOptionMonth] = useState(1);
  const [DropdownDay, setDropdownDay] = useState([]);
  const [DropdownDate, setDropdownDate] = useState([]);
  const [DropdownWeek, setDropdownWeek] = useState([]);
  const [DropdownMonth, setDropdownMonth] = useState([]);
  const [IsDisableWeekDate, setIsDisableWeekDate] = useState(true);
  const [IsDisableWeekDay, setIsDisableWeekDay] = useState(true);
  const [IsLoadDataComplete, setIsLoadDataComplete] = useState(false);
  const [IsAllTime, setIsAllTime] = useState(false);
  const { t } = useTranslation();
  const { objDate, setObjDate, setYupDate, IsReset, setIsReset, IsShowPeriod } =
    props;

  const yupDate = {
    sStartDate: yupFormSchemas.date(t("startdate"), {
      required: true,
      translation: t,
    }),
    sStartTime: yupFormSchemas.string(t("starttime"), {
      required: true,
      translation: t,
    }),
    sEndDate: yupFormSchemas.date(t("enddate"), {
      required: !IsAllTime,
      translation: t,
    }),
    sEndTime: yupFormSchemas.string(t("endtime"), {
      required: !IsAllTime,
      translation: t,
    }),
    lstDays: yupFormSchemas.stringArray(t("day"), {
      required: nPeriod === 2 || (nPeriod === 3 && nOptionMonth === 2),
      translation: t,
    }),
    lstDate: yupFormSchemas.stringArray(t("date"), {
      required: nPeriod === 3 && nOptionMonth === 1,
      translation: t,
    }),
    lstWeekly: yupFormSchemas.stringArray(t("week"), {
      required: nPeriod === 3 && nOptionMonth === 2,
      translation: t,
    }),
    lstMonth: yupFormSchemas.stringArray(t("month"), {
      required: nPeriod === 3,
      translation: t,
    }),
  };

  const [oDate, setoDate] = useState({ dS_Max: null, dE_Min: null });

  const onGetDropDown = () => {
    let params = {
      dStart:
        form.getValues("sStartDate") !== undefined
          ? moment(form.getValues("sStartDate")).add(
              moment.duration(7, "hours")
            )
          : null,
      dEnd:
        form.getValues("sEndDate") !== undefined
          ? moment(form.getValues("sEndDate")).add(moment.duration(7, "hours"))
          : null,
      IsAllTime: IsAllTime,
      lstMonth: form.getValues("lstMonth"),
      lstWeek: form.getValues("lstWeekly"),
      lstDay: form.getValues("lstDays"),
      lstDate: form.getValues("lstDate"),
    };
    DialogFn.BlockUI();
    AxiosPost(
      "ShowTime/ChangeDate",
      params,
      (result) => {
        DialogFn.UnBlockUI();
        setDropdownMonth(result.dropdownMonth);
        if (result.dropdownDate != null) {
          setDropdownDate(result.dropdownDate);
        }
        if (result.dropdownDay != null) {
          setDropdownDay(result.dropdownDay);
        }
        if (result.dropdownWeek != null) {
          setDropdownWeek(result.dropdownWeek);
        }
        setIsLoadDataComplete(true);
      },
      (err) => {
        if (!err.response) {
          DialogFn.Warning(err.Message);
        }
      }
    );
  };
  useEffect(() => {
    if (IsReset === true) {
      setPeriod(1);
      setOptionMonth(1);
      setIsAllTime(false);
      setIsReset(false);
    }
  }, [IsReset]);

  useEffect(() => {
    if(IsShowPeriod){
      onGetDropDown();
    } 
  }, []);

  useEffect(() => {
    setYupDate(yupDate);
    if(IsAllTime){
      form.clearErrors("sEndDate");
      form.clearErrors("sEndTime");
    }
  }, [nPeriod, nOptionMonth, IsAllTime]);

  useEffect(() => {
    // if (IsLoadDataComplete) {
    let lstData = props.lstData;
    if (lstData && Object.keys(lstData).length > 0) {
      let dStart = moment(lstData.sStartDate, "YYYY-MM-DD HH:mm:ss");
      let dEnd = moment(lstData.sEndDate, "YYYY-MM-DD HH:mm:ss");
      form.setValue("sStartDate", dStart.isValid() ? dStart : null);
      form.setValue("sEndDate", dEnd.isValid() ? dEnd : null);

      form.setValue(
        "sStartTime",
        dStart.isValid() ? new Date(dStart.toDate()) : null
      );
      form.setValue(
        "sEndTime",
        dEnd.isValid() ? new Date(dEnd.toDate()) : null
      );

      objDate.sStartDate = dStart.isValid()
        ? dStart.format("YYYY-MM-DD HH:mm:ss").toString()
        : null;
      objDate.sEndDate = dEnd.isValid()
        ? dEnd.format("YYYY-MM-DD HH:mm:ss").toString()
        : null;

      objDate.IsAllTime = lstData.IsAllTime;
      setIsAllTime(lstData.IsAllTime);
      form.setValue("IsAllTime", lstData.IsAllTime);

      if (IsShowPeriod) {
        objDate.nWeekDay = lstData.nWeekDay;
        form.setValue("nWeekDay", lstData.nWeekDay);
        setOptionMonth(lstData.nWeekDay);

        if (
          lstData.nWeekDay === 1 &&
          lstData.lstMonth != null &&
          lstData.lstMonth.length > 0
        ) {
          setIsDisableWeekDate(false);
        } else if (
          lstData.nWeekDay === 2 &&
          lstData.lstMonth != null &&
          lstData.lstMonth.length > 0
        ) {
          setIsDisableWeekDate(false);
          setIsDisableWeekDay(false);
        }

        objDate.lstDate = lstData.lstDate;
        form.setValue("lstDate", lstData.lstDate);

        objDate.lstDays = lstData.lstDays;
        form.setValue("lstDays", lstData.lstDays);

        objDate.nPeriod = lstData.nPeriod;
        form.setValue("nPeriod", lstData.nPeriod);
        if (lstData.nPeriod && lstData.nPeriod !== 0) {
          setPeriod(lstData.nPeriod);
        }

        objDate.lstWeekly = lstData.lstWeekly;
        form.setValue("lstWeekly", lstData.lstWeekly);

        objDate.lstMonth = lstData.lstMonth;
        form.setValue("lstMonth", lstData.lstMonth);

        setObjDate(objDate);
        onGetDropDown();
      }
    }
    // }
  }, [props.lstData]);

  const formatArrayToString = (data) => {
    let arrString = data.map(function (item) {
      return item["value"];
    });
    return arrString;
  };

  const ClearSelect = () => {
    form.setValue("lstDays", []);
    form.setValue("lstMonth", []);
    form.setValue("lstDate", []);
    form.setValue("lstWeekly", []);
  };

  const HandleChangeTime = (val, type) => {
    if (val && moment(val, "YYYY-MM-DD HH:mm:ss").isValid()) {
      let time = moment(val).format("HH:mm:ss").toString();

      let dStart = moment(form.getValues("sStartDate"), "YYYY-MM-DD");
      let dEnd = moment(form.getValues("sEndDate"), "YYYY-MM-DD");

      let IsSameDate = dStart.isSame(dEnd);

      let dateS = moment().format("YYYY-MM-DD").toString();
      let dateE = moment().format("YYYY-MM-DD").toString();

      if (dStart.isValid()) {
        dateS = dStart.format("YYYY-MM-DD").toString();
      }

      if (dEnd.isValid()) {
        dateE = dEnd.format("YYYY-MM-DD").toString();
      }

      if (type === "S") {
        let dDateTimeS = moment(dateS + " " + time, "YYYY-MM-DD HH:mm:ss");

        if (oDate.dS_Max) {
          let min = val;
          let max = oDate.dS_Max;

          objDate.sStartDate = dDateTimeS.isValid()
            ? dDateTimeS.format("YYYY-MM-DD HH:mm:ss").toString()
            : null;

          if (min && max && min.getTime() > max.getTime() && IsSameDate) {
            // start > end => ให้ end มีเวลาเหมือน start
            form.setValue("sEndTime", val);

            let dDateTimeE = moment(dateE + " " + time, "YYYY-MM-DD HH:mm:ss");
            objDate.sEndDate = dDateTimeE.isValid()
              ? dDateTimeE.format("YYYY-MM-DD HH:mm:ss").toString()
              : null;
          }
        } else {
          objDate.sStartDate = dDateTimeS.isValid()
            ? dDateTimeS.format("YYYY-MM-DD HH:mm:ss").toString()
            : null;
        }

        setoDate({ ...oDate, dE_Min: val });
      } else if (type === "E") {
        let dDateTimeE = moment(dateE + " " + time, "YYYY-MM-DD HH:mm:ss");

        if (oDate.dE_Min) {
          let min = oDate.dE_Min;
          let max = val;

          objDate.sEndDate = dDateTimeE.isValid()
            ? dDateTimeE.format("YYYY-MM-DD HH:mm:ss").toString()
            : null;

          if (min && max && min.getTime() > max.getTime() && IsSameDate) {
            // start < end => ให้ start มีเวลาเเหมือน end
            form.setValue("sStartTime", val);

            let dDateTimeS = moment(dateS + " " + time, "YYYY-MM-DD HH:mm:ss");
            objDate.sStartDate = dDateTimeS.isValid()
              ? dDateTimeS.format("YYYY-MM-DD HH:mm:ss").toString()
              : null;
          }
        } else {
          objDate.sEndDate = dDateTimeE.isValid()
            ? dDateTimeE.format("YYYY-MM-DD HH:mm:ss").toString()
            : null;
        }

        setoDate({ ...oDate, dS_Max: val });
      }

      setObjDate(objDate);
    }
  };

  const handleChangeDate = (val, type) => {
    if (val && moment(val, "YYYY-MM-DD HH:mm:ss").isValid()) {
      ClearSelect();

      let timeS = moment(form.getValues("sStartTime"))
        .format("HH:mm:ss")
        .toString();
      let timeE = moment(form.getValues("sEndTime"))
        .format("HH:mm:ss")
        .toString();
      if (type === "S") {
        if (moment(form.getValues("sEndDate")) < moment(val)) {
          form.setValue("sEndDate", null);
          objDate.sEndDate = "";
        }

        let sStartDate = moment(val).format("YYYY-MM-DD").toString();
        let dDateTimeS = moment(
          sStartDate + " " + timeS,
          "YYYY-MM-DD HH:mm:ss"
        );

        objDate.sStartDate = dDateTimeS.isValid()
          ? dDateTimeS.format("YYYY-MM-DD HH:mm:ss").toString()
          : null;
      } else if (type === "E") {
        if (moment(form.getValues("sStartDate")).format("YYYY-MM-DD") > moment(val).format("YYYY-MM-DD")) {
          form.setValue("sStartDate", null);
          objDate.sStartDate = "";
        }
        let sEndDate = moment(val).format("YYYY-MM-DD").toString();
        let dDateTimeE = moment(sEndDate + " " + timeE, "YYYY-MM-DD HH:mm:ss");
        objDate.sEndDate = dDateTimeE.isValid()
          ? dDateTimeE.format("YYYY-MM-DD HH:mm:ss").toString()
          : null;
      }
      setObjDate(objDate);

      // if(IsShowPeriod){
        onGetDropDown();
      // }
      let sStart = moment(form.getValues("sStartDate")).format("YYYY-MM-DD");
      let sEnd = moment(form.getValues("sEndDate")).format("YYYY-MM-DD");
      console.log("sStart",sStart)
      console.log("sEnd",sEnd)
      if (sStart === sEnd) {
        console.log("sTime",moment(form.getValues("sStartTime")).format("HH:mm"))
        console.log("eTime",moment(form.getValues("sEndTime")).format("HH:mm"))
        if (
          moment(form.getValues("sStartTime")).format("HH:mm") >
          moment(form.getValues("sEndTime")).format("HH:mm")
        ) {
          console.log("if")
          form.setValue("sEndTime", form.getValues("sStartTime"));
        }
      }
    }
  };

  return (
    <>
      <Grid item md={5} xs={6}>
        <Box
          sx={{
            color: "rgb(52, 71, 103)",
          }}
          className=".Box-content-form"
        >
          <HeaderInput text={t("startdate")} required />
          <DatePickerForm
            name={"sStartDate"}
            label="DD/MM/YYYY"
            required={false}
            fullWidth={true}
            onChange={(e) => {
              handleChangeDate(e, "S");
            }}
          />
        </Box>
      </Grid>
      <Grid item md={5} xs={6}>
        <Box
          sx={{
            color: "rgb(52, 71, 103)",
          }}
          className=".Box-content-form"
        >
          <HeaderInput text={t("starttime")} required />
          <TimePicker
            name={"sStartTime"}
            label={"HH:mm"}
            required={false}
            icon={"pi pi-clock"}
            iconPosition="right"
            showIcon={true}
            maxDate={
              moment(form.getValues("sStartDate")).format("YYYY-MM-DD") ===
              moment(form.getValues("sEndDate")).format("YYYY-MM-DD")
                ? oDate.dS_Max
                : null
            }
            onChange={(e) => {
              if (moment(e, "YYYY-MM-DD HH:mm:ss").isValid()) {
                HandleChangeTime(e, "S");
              }
            }}
          />
        </Box>
      </Grid>
      <Grid item md={2} xs={12}></Grid>
      <Grid item md={5} xs={6}>
        <Box
          sx={{
            color: "rgb(52, 71, 103)",
          }}
          className=".Box-content-form"
        >
          <HeaderInput text={t("enddate")} required />
          <DatePickerForm
            minDate={
              form.getValues("sStartDate")
                ? moment(form.getValues("sStartDate"))
                : undefined
            }
            name={"sEndDate"}
            label="DD/MM/YYYY"
            required={false}
            fullWidth={true}
            disabled={IsAllTime}
            onChange={(e) => {
              handleChangeDate(e, "E");
            }}
          />
        </Box>
      </Grid>
      <Grid item md={5} xs={6}>
        <Box
          sx={{
            color: "rgb(52, 71, 103)",
          }}
          className=".Box-content-form"
        >
          <HeaderInput text={t("endtime")} required />
          <TimePicker
            name={"sEndTime"}
            label={"HH:mm"}
            required={false}
            disabled={IsAllTime}
            icon={"pi pi-clock"}
            iconPosition="right"
            showIcon={true}
            minDate={
              moment(form.getValues("sStartDate")).format("YYYY-MM-DD") ===
              moment(form.getValues("sEndDate")).format("YYYY-MM-DD")
                ? oDate.dE_Min
                : null
            }
            onChange={(e) => {
              if (moment(e, "YYYY-MM-DD HH:mm:ss").isValid()) {
                HandleChangeTime(e, "E");
              }
            }}
          />
        </Box>
      </Grid>
      <Grid item md={2} xs={12}>
        <Box sx={{ width: "auto" }}>
          <Box
            sx={{
              color: "rgb(52, 71, 103)",
            }}
            className=""
          >
            <HeaderInput text={t("alltime")} subtext={""} />
            <Box sx={{ height: "5px" }}></Box>
            <SwitchForm
              name={"IsAllTime"}
              label=""
              required={false}
              disabled={false}
              width={60}
              onChange={(e) => {
                setIsAllTime(e);
                if (e === true) {
                  form.setValue("sEndTime", "");
                  form.setValue("sEndDate", "");
                  objDate[`sEndTime`] = "";
                  objDate[`sEndDate`] = "";
                }
                objDate[`IsAllTime`] = e;
                setObjDate({ ...objDate });
              }}
            />
          </Box>
        </Box>
      </Grid>
      {IsShowPeriod && (
        <>
          <Grid item xs={3}>
            <Box
              sx={{
                color: "rgb(52, 71, 103)",
              }}
              className=".Box-content-form"
            >
              <HeaderInput text={t("perioddisplay")} subtext={""} required />
              <RadioForm
                name="nPeriod"
                required={false}
                row={true}
                options={[
                  { value: EnumPeriod.AllDay, label: t("allday") },
                  { value: EnumPeriod.SomeDay, label: t("someday") },
                  { value: EnumPeriod.Monthly, label: t("monthly") },
                ]}
                disabled={false}
                isNoLabel
                onChange={(e) => {
                  ClearSelect();
                  setPeriod(parseInt(e));
                  objDate[`nPeriod`] = parseInt(e);
                  setObjDate({ ...objDate });
                }}
              />
            </Box>
          </Grid>
          {nPeriod === EnumPeriod.AllDay && <Grid item xs={9}></Grid>}
          <Grid
            item
            xs={8}
            sx={{ display: nPeriod === EnumPeriod.Monthly ? "block" : "none" }}
          >
            <Box
              sx={{
                color: "rgb(52, 71, 103)",
              }}
              className=".Box-content-form"
            >
              <HeaderInput text={t("selectmonth")} />

              <SelectMultipleFormItem
                name={"lstMonth"}
                required={false}
                label={""}
                options={DropdownMonth}
                onChange={(e) => {
                  setIsDisableWeekDate(e.length === 0);
                  form.setValue("lstDays", []);
                  form.setValue("lstDate", []);
                  form.setValue("lstWeekly", []);
                  onGetDropDown();
                  objDate[`lstMonth`] = formatArrayToString(e);
                  setObjDate({ ...objDate });
                }}
                id="lstMonth"
              />
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <RadioForm
                    name="nWeekDay"
                    required={false}
                    row={true}
                    options={[
                      { value: EnumWeekDay.Day, label: t("day") },
                      { value: EnumWeekDay.WeekOn, label: t("weekon") },
                    ]}
                    onChange={(e) => {
                      setOptionMonth(parseInt(e));
                      if (EnumWeekDay.Day === parseInt(e)) {
                        form.setValue("lstWeekly", []);
                        form.setValue("lstDays", []);
                      } else if (EnumWeekDay.WeekOn === parseInt(e)) {
                        form.setValue("lstDate", []);
                      }
                      objDate[`nWeekDay`] = parseInt(e);
                      setObjDate({ ...objDate });
                    }}
                    disabled={false}
                    isNoLabel
                  />
                </Grid>
                <Grid item xs={8}></Grid>
                {nOptionMonth === EnumWeekDay.Day && (
                  <Grid item xs={6}>
                    <HeaderInput text={t("selectdate")} />

                    <SelectMultipleFormItem
                      name={"lstDate"}
                      required={false}
                      label={""}
                      options={DropdownDate}
                      onChange={(e) => {
                        objDate[`lstDate`] = formatArrayToString(e);
                        setObjDate({ ...objDate });
                      }}
                      disabled={IsDisableWeekDate}
                      id="lstDate"
                    />
                  </Grid>
                )}
                {nOptionMonth === EnumWeekDay.WeekOn && (
                  <>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          color: "rgb(52, 71, 103)",
                        }}
                        className=".Box-content-form"
                      >
                        <HeaderInput text={t("selectweek")} />

                        <SelectMultipleFormItem
                          name={"lstWeekly"}
                          required={false}
                          label={""}
                          options={DropdownWeek}
                          onChange={(e) => {
                            form.setValue("lstDays", []);
                            setIsDisableWeekDay(e.length === 0);
                            onGetDropDown();
                            objDate[`lstWeekly`] = formatArrayToString(e);
                            setObjDate({ ...objDate });
                          }}
                          disabled={IsDisableWeekDate}
                          id="lstWeekly"
                        />
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Grid>
          {nPeriod === EnumPeriod.Monthly &&
            nOptionMonth === EnumWeekDay.WeekOn && <Grid item xs={3}></Grid>}
          <Grid
            item
            xs={8}
            sx={{
              display:
                nPeriod === EnumPeriod.SomeDay ||
                (nPeriod === EnumPeriod.Monthly &&
                  nOptionMonth === EnumWeekDay.WeekOn)
                  ? "block"
                  : "none",
            }}
          >
            <Box
              sx={{
                color: "rgb(52, 71, 103)",
              }}
              className=".Box-content-form"
            >
              <HeaderInput text={t("selectdays")} />

              <SelectMultipleFormItem
                name={"lstDays"}
                required={false}
                label={""}
                options={DropdownDay}
                onChange={(e) => {
                  objDate[`lstDays`] = formatArrayToString(e);
                  setObjDate({ ...objDate });
                }}
                id="lstDays"
                disabled={IsDisableWeekDay && nPeriod === EnumPeriod.Monthly}
              />
            </Box>
          </Grid>
          {nPeriod !== EnumPeriod.AllDay && <Grid item xs={1}></Grid>}
        </>
      )}
    </>
  );
};

const defaultProp: ShowDateProp = {
  setObjDate: () => {},
  objDate: {
    sStartDate: null,
    sEndDate: "",
    IsAllTime: false,
    nPeriod: 1,
    nWeekDay: 1,
    lstDays: null,
    lstMonth: null,
    lstDate: null,
    lstWeekly: null,
  },
  IsShowPeriod: true,
};
ShowDateForm.defaultProps = defaultProp;

export default ShowDateForm;

