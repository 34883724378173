import Crypto from "crypto-js";
import { AxiosGet } from "STM/utilities/ST_Axios";
import { keyCrypto } from "config/AppConfig";
import parse from "html-react-parser";
import Moment from "moment";
import { Tooltip } from "@mui/material";
import { EnumStatus } from "STM/enum/enumSystem";
import { GridColumns } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { Trans } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";
////import cookies from "js-cookie";

import { STMService } from "STM/utilities/ST_Function";

export const GetInitialInfo = () => {
  let info = process.env.REACT_APP_InitialInfo;
  let json = secureLocalStorage.getItem(info) as any;
  ////alert(JSON.stringify(json));
  return json;
}
//#region Function
export const IsNullOrUndefined = (Value: any) => {
  let Result = true;
  if (Value) {
    Result = false;
  }
  return Result;
};
export const ToInt = (Value: string) => {
  let Result = 0;
  if (Value) {
    Result = parseInt(Value);
  }
  return Result;
};
/**
 * แปลง string เป็น html
 * @param val
 * @returns Tag html
 */
export const ParseHtml = (val: string) => {
  if (val) return parse(val);
  else return val;
};
/**
 * แปรง Date เป็น String
 * @param value
 * @param format
 * @returns Date string
 */
export const DateTimeToString = (value, format = "DD/MM/YYYY") => {
  Moment.locale("en");
  return Moment(value).format(format);
};

export const DynamicLangCol = (lstData, dataCol, indexMap) => {
  let arrCol: GridColumns = dataCol;
  let arrNewCol = [];
  lstData.forEach((item, index) => {
    let objColAdd = {
      field: `sLanguageName${item.label}`,
      headerName: item.label,
      headerAlign: "center",
      align: "center",
      width: 70,
      sortable: false,
      renderCell: (p) => {
        let status = p.row.lstLanguageCode.find(
          (f) => f.label === item.label
        ).value;
        return [
          status === EnumStatus.Submit.toString() && (
            <Tooltip title="Complete">
              <CheckCircleIcon
                sx={{
                  fontSize: "1.5rem",
                  color: "rgb(45, 206, 137)",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          ),
          status === EnumStatus.Draft.toString() && (
            <Tooltip title="draft">
              <HourglassBottomIcon
                sx={{
                  fontSize: "1.5rem",
                  color: "rgb(223, 173, 21)",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          ),
        ];
      },
    };
    arrNewCol = insert(arrCol, indexMap + index, objColAdd);
    arrCol = arrNewCol;
  });
  return arrCol;
};

const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];
//#endregion

//#region Crypto
export const Encrypt = (dataEncrypt: any) => {
  let data = dataEncrypt.toString();
  let result = Crypto.AES.encrypt(data, keyCrypto).toString();
  result = result.replace(/\//g, "s14sh").replace(/\+/g, "p1u5");

  return result;
};
export const Decrypt = (dataDecrypt: any) => {
  if (dataDecrypt && dataDecrypt != null && dataDecrypt !== "null") {
    dataDecrypt = dataDecrypt.replace(/s14sh/g, "/").replace(/p1u5/g, "+");
    let bytes = Crypto.AES.decrypt(dataDecrypt, keyCrypto);
    let result = bytes.toString(Crypto.enc.Utf8);
    return result;
  } else {
    return "";
  }
};

export const GetTranslation = (name: any) => {
  return <Trans>{name}</Trans>;
};
//#endregion

//#region Extension Type For Upload file

export const Extension = {
  Image: ["jpg", "jpeg", "png", "gif", "JPG"],
  Video: ["mov", "wmv", "avi", "mp4"],
  PDF: ["pdf"],
  Document: ["doc", "docx", "xls", "xlsx", "txt"],
  Word: ["doc", "docx"],
  Excel: ["xls", "xlsx"],
  PowerPoint: ["pptx", "ppt"],
  txt: ["txt"],
  Email: ["msg"],
  Other: ["rar", "zip"],
  AllType: null,
  Image_New: ["jpg", "jpeg", "png"],
  Video_New: ["mp4"],
};
//#endregion

//#region getdata session
export const GetDataSession = () => {
  const localJwtKey = process.env.REACT_APP_JWT_KEY;
  const auth_token = secureLocalStorage.getItem(localJwtKey) != null ? (secureLocalStorage.getItem(localJwtKey) as string) : undefined;

  if (auth_token !== undefined) {
    let param = {};
    AxiosGet("Utility2/GetUserSession", param, (res) => {
      secureLocalStorage.setItem("UserSession", res.userModel);
    })
  }
}


export const myLogout = () => {
  const { logout } = STMService();

  const Lockout = () => {
    logout(() => {
      secureLocalStorage.removeItem("UserSession");
      const front = `${process.env.REACT_APP_Front_URL}`;
      window.location.href = front;
    });
  }

  return Lockout;
};