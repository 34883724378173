import React from "react";
import { useEffect, useState } from "react";

//Page Import
import LayoutNavSide from "layout/Layout_Main/Front/LayoutNavSide";
import LayoutNav from 'layout/Layout_Main/Front/LayoutNav';
//// import DialogLogin from 'view/Home/components/LoginModal';
import DialogLogin from 'view/Home/components/login/Login';

import { myLogout } from '../../../utilities/ST_Function';
import secureLocalStorage from "react-secure-storage";
import { BtnLoguout } from "STM/components/Button";
import { AxiosPost, AxiosGet } from "STM/utilities/ST_Axios";
import { Link, useNavigate } from "react-router-dom";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
// Mui
import { Box } from "@mui/system";
import {
   alpha,

   Menu,

   MenuProps,
   styled,

   Button,
   Hidden,
} from "@mui/material";
import { IoIosMenu, IoMdClose } from "react-icons/io";
import cookies from "js-cookie";
import { FaUnlockAlt } from "react-icons/fa";

const StyledMenu = styled((props: MenuProps) => (
   <Menu
      elevation={0}
      anchorOrigin={{
         vertical: "bottom",
         horizontal: "right",
      }}
      transformOrigin={{
         vertical: "top",
         horizontal: "right",
      }}
      {...props}
   />
))(({ theme }) => ({
   "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
         theme.palette.mode === "light"
            ? "rgb(55, 65, 81)"
            : theme.palette.grey[300],
      boxShadow:
         "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
         padding: "0",
         textAlign: "center",
      },
      "& .MuiMenuItem-root": {
         "& .MuiSvgIcon-root": {
            fontSize: 18,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5),
         },
         "&:active": {
            backgroundColor: alpha(
               theme.palette.primary.main,
               theme.palette.action.selectedOpacity
            ),
         },
      },
   },
}));


function HeaderBasic({ isPreview, setFooterMenu }) {
   let i18next = process.env.REACT_APP_JWT_i18next as string;
   const frontURL = process.env.REACT_APP_Front_URL;

   const localJwtKey = process.env.REACT_APP_JWT_KEY as string;
   const [auth_token, setAuth_token] = useState("");
   const [oCountryDropdown, setoCountryDropdown] = useState([] as any);
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const [openMenu, setOpenMenu] = useState<any>(Boolean(anchorEl));
   const [anchorProfile, setAnchorProfile] = React.useState<null | HTMLElement>(null);

   const [lstMenu, setLstMenu] = useState([]);
   const navigate = useNavigate();

   const open = Boolean(anchorProfile);
   const [anchorNoti, setAnchorNoti] = React.useState<null | HTMLElement>(null);
   const openNoti = Boolean(anchorNoti);
   const [sActiveFlag, setsActiveFlag] = React.useState<string>();

   const [isOpenMenuSide, setOpenMenuSide] = useState(false);
   const [OpenLogin, setOpenLogin] = useState(false);

   const [checkToken, setCheckToken] = useState("");
   const [UserSession, setUserSession] = useState<any>({});

   const Lockout = myLogout();

   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOpenMenu(!openMenu);
   };
   const handleClose = () => {
      setAnchorEl(null);
      setOpenMenu(false);
   };

   const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorProfile(event.currentTarget);
   };
   const handleCloseProfile = () => {
      setAnchorProfile(null);
   };

   const handleClickNoti = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorNoti(event.currentTarget);
   };
   const handleCloseNoti = () => {
      setAnchorNoti(null);
   };

   const OnGetFlagData = () => {
      let param = {};
      AxiosGet("LanguageTranslation/GetDropdownCountryFlag", param, (res) => {
         //// console.log("oCountryDropdown ", res);
         setoCountryDropdown(res);
      });
   };

   const OnGetMenu = () => {
      let param = {
         sID: "",
         isFront: true,
         sLang: "en-US",
      };
      AxiosGet("User/GetMenuByUser", param, (res) => {
         setLstMenu(res);
         setFooterMenu(res)
         //// console.log("HeadderBasic GetMenuByUser", res);
      });
   };

   useEffect(() => {
      // setCheckToken(auth_token);
      // GetDataSession();
      const auth_token = secureLocalStorage.getItem(localJwtKey) != null ? (secureLocalStorage.getItem(localJwtKey) as string) : undefined;
      setAuth_token(auth_token);
      checkUserTimeOut();
      // setUserSession(secureLocalStorage.getItem("UserSession"));
      ////OnGetFlagData();
      ////setsActiveFlag(cookies.get(i18next));
      OnGetMenu();
   }, []);

   const openDialogLogin = () => {
      setOpenLogin(true);
   }

   const checkUserTimeOut = () => {
      const jwtkey = secureLocalStorage.getItem(localJwtKey) != null ? (secureLocalStorage.getItem(localJwtKey) as string) : undefined;
      if (jwtkey != undefined) {
         //ถ้า token หมดอายุจะ แสดง dialog กรุณาลอกอิน ซึ่งดักจาก function AxiosPost
         AxiosPost("login/CheckLoginTimeOut", {}, (res) => {
            if (res !== false) {
               const jwtkey = process.env.REACT_APP_JWT_KEY;
               secureLocalStorage.setItem(jwtkey, "");
            } else {
               ///setAuth_token(auth_token);
            }
         });
      }
   }
   // // // const { logout } = STMService();
   // // // const myLogout = () => {
   // // //    logout(() => {
   // // //       secureLocalStorage.removeItem("UserSession");
   // // //       const front = combineFrontUrl("");
   // // //       window.location.href = front;
   // // //    });
   // // // };

   // console.log("UserSession", UserSession)

   useEffect(() => {
      const GetUserSession = () => {
         let param = {};
         AxiosGet("Utility2/GetUserSession", param, (res) => {
            setUserSession(res);
         });
      };

      GetUserSession();
   }, [])
   ////console.log("isPreview:xxxx" , isPreview)
   return (
      <div>
         <Hidden lgUp>
            <Box sx={{
               position: "fixed", left: 0, backgroundColor: "rgba(0,0,0,0.7)", height: "100vh",
               zIndex: 9998,
               transition: " all 0.5s",
               width: isOpenMenuSide ? "100vw" : 0,
               opacity: isOpenMenuSide ? "1" : "0",
               overflowX: "hidden",
            }}>

               <Box className="box-tabs-navbar d-flex align-items-center justify-content-between"
                  sx={{ backgroundColor: "white", height: "auto", width: "100vw", maxWidth: "100vw" }}>
                  <Box>
                     <Link to="/Homepage" className="logo">
                        <img className="ptt-logo-system" alt="" src={`${process.env.REACT_APP_Front_URL}ptt-logo.png`} />
                     </Link>
                  </Box>

                  {/* ปุ่มกากบาท */}
                  <Box>
                     <Button id="sideMenuBtnClose" sx={{ minWidth: "45px" }} onClick={() => setOpenMenuSide(false)} >
                        <IoMdClose style={{ fontSize: "1.5rem", color: "#212121" }} />
                     </Button>
                  </Box>
               </Box>

               <Box sx={{ backgroundColor: "white", height: "auto", width: "100vw", maxWidth: "100vw" }}>
                  <Box className="os-viewport os-viewport-scrollbar-hidden lst-menu-mobile">
                     <Box sx={{ padding: "0 1.5rem 1.5rem" }}>
                        <LayoutNavSide lstMenu={lstMenu} />
                     </Box>
                  </Box>
               </Box>

            </Box>
         </Hidden>

         <Box id="page-topbar">
            <Box className="layout-width">
               <Box className="navbar-header">

                  {/* hamberger */}
                  <Box className="menu-responsive">
                     <Button sx={{ minWidth: "45px" }} onClick={() => setOpenMenuSide(true)}>
                        <IoIosMenu style={{ fontSize: "1.5rem", color: "#000" }} />
                     </Button>
                  </Box>

                  <Box className="mMenu d-flex align-items-center">
                     <Box className="menu-header d-flex align-items-center">
                        <Box className="navbar-brand-box horizontal-logo">
                           {
                              isPreview === true ?
                                 <Box className="logo">
                                    <img className="ptt-logo-system" alt="" src={`${process.env.REACT_APP_Front_URL}ptt-logo.png`} />
                                 </Box>
                                 :
                                 <Link to="/Homepage" className="logo">
                                    <img className="ptt-logo-system" alt="" src={`${process.env.REACT_APP_Front_URL}ptt-logo.png`} />
                                 </Link>
                           }
                        </Box>
                     </Box>

                     <Box className="system-name" sx={{ height: "100%" }}>
                        <LayoutNav isPreview={isPreview} lstMenu={lstMenu} />
                     </Box>
                  </Box>


                  <Box className="menu-login d-flex align-items-center">

                     <Box className="dropdown ms-1 topbar-head-dropdown header-item">
                        <Menu anchorEl={anchorEl} id="account-menu" open={openMenu}
                           onClose={handleClose}
                           onClick={handleClose}
                           PaperProps={{
                              elevation: 0,
                              sx: {
                                 overflow: "visible", filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))", mt: 1.5,
                                 "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                 },
                                 "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                 },
                              }
                           }}
                           transformOrigin={{ horizontal: "right", vertical: "top" }}
                           anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                           {oCountryDropdown.map((lang, index) => {
                              return (
                                 <Box key={index} sx={{ pl: 0, fontSize: "0.9rem" }}
                                    className="langSelect"
                                    onClick={() => {
                                       console.log("X", lang);
                                       cookies.set(i18next, lang.value);
                                       window.location.reload();
                                    }}>
                                    <Box className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                       style={{ height: "25px", width: "42px" }}>
                                       {getUnicodeFlagIcon(lang.sFlag)}
                                    </Box>
                                    {lang.label}
                                 </Box>
                              );
                           })}
                        </Menu>
                     </Box>

                     {
                        auth_token == undefined ?
                           <Button className="btn-login" variant="outlined" startIcon={<FaUnlockAlt />}
                              onClick={openDialogLogin}>
                              <Box className="text-login">Login</Box>
                           </Button>
                           :
                           <Box className="dropdown ms-sm-3 header-item topbar-user">
                              <Box className="page-header-user-dropdown" onClick={handleClickProfile}>
                                 <span className="d-flex align-items-center" style={{ columnGap: "10px" }}>
                                    <img className="rounded-circle header-profile-user"
                                       src={`${process.env.REACT_APP_Front_URL}profile.png`}
                                       alt="Header Avatar" />
                                    <span className="profile-detail text-start ms-xl-2">
                                          {
                                             (UserSession !== null && UserSession.length !== 0) && (
                                             <span className="d-none ms-1 fw-medium user-name-text text-infomation-setting">
                                                {UserSession.userModel?.sFullNameTH}
                                             </span>
                                             )
                                          }
                                          {
                                             (UserSession !== null && UserSession.length !== 0) && (
                                                <span className="d-none ms-1 fs-12 text-muted user-name-sub-text text-infomation-setting">
                                                {UserSession.userModel?.sPositionName}
                                                </span>
                                             )
                                          }
                                    </span>
                                    {
                                    isPreview !== true ?
                                    <Box className="dropdown-IconLogout" >
                                       <BtnLoguout onClick={Lockout} />
                                       {/* <BtnLoguout onClick={myLogout} /> */}
                                    </Box>
                                    :
                                    <Box className="dropdown-IconLogout" >
                                       <BtnLoguout />
                                    </Box>
                                    }
                                 </span>
                              </Box>
                              <StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
                                 anchorEl={anchorProfile}
                                 open={open}
                                 onClose={handleCloseProfile}>
                                 {/* <Typography sx={{ fontSize: "0.85rem", mt: 1, ml: 1, textAlign: "left" }}>
                                    Administrator
                                 </Typography> */}
                                 {
                                    <Box className="profile-item profile-infomation">
                                    (UserSession !== null && UserSession.length !== 0) && (
                                       <Box className="profile-username">{UserSession.userModel?.sFullNameTH}</Box>
                                       <Box className="profile-positionname">{UserSession.userModel?.sPositionName}</Box>
                                    )
                                    </Box>
                                 }
                                 <Box className="profile-item" sx={{ columnGap: "8px" }} onClick={Lockout}>
                                    {
                                       isPreview === true ?
                                          <BtnLoguout />
                                          :
                                          <BtnLoguout onClick={Lockout} />
                                    }
                                    <Box sx={{ fontWeight: "500", fontSize: "14px" }}>Logout</Box>
                                 </Box>



                              </StyledMenu>
                           </Box>
                     }


                     {/* button login */}
                     {/* <Button className="btn-login" variant="outlined" startIcon={<FaUnlockAlt />}
                        onClick={openDialogLogin}>
                        <Box className="text-login">Login</Box>
                     </Button> */}
                     <DialogLogin OpenLogin={OpenLogin} setOpenLogin={setOpenLogin} />

                  </Box>

               </Box>
            </Box>
         </Box>
      </div>
   )
}

export default HeaderBasic