import { useEffect, useState } from "react";
import NewsActivityDetail from "./NewsActivityDetail";
import { useLocation } from "react-router-dom";
import { GetContentNews } from "./CallAPI";
import { FnDialog, SetSeoToHtml } from "STM/utilities/ST_Function";
import NewsDetailSkeleton from "./NewsDetailSkeleton";

function NewsDetail() {
  const [data, setData] = useState(null);
  const location = useLocation();
  const sIDParams = location.state ? location.state["sID"] : null;
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const DialogFn = FnDialog();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
    const onGetContentNews = () => {
      GetContentNews(
        { sID: sIDParams ?? sID },
        (res) => {
          let seo =  {
            Description: res.sDetailSeo,
            Keywords:res.sNewsNameSeo,
            Tags:res.lstTag,
          };
          SetSeoToHtml(seo);
          setData(res);
        },
        (err) => {
          if (!err.response) {
            DialogFn.Warning(err.Message);
          }
        }
      );
    };
    onGetContentNews();
  }, []);
  return <>
  {data != null ? (<NewsActivityDetail data={data} sID={sIDParams ?? sID} IsPreview={false} />) : ( <NewsDetailSkeleton/> )}
  </>;
}

export default NewsDetail;
