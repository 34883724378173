import React, { useState, useEffect, useRef, Fragment } from "react";
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FnBlock_UI } from 'STM/components/BlockUI';
import { AxiosPost } from 'STM/utilities/ST_Axios';
import { SelectFormItem, RadioForm, TextBoxForm } from 'STM/components/Input';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import yupFormSchemas from 'STM/components/FormItem/yupFormSchemas';
import { SwAlert } from 'STM/components/Alert/index';
import { AlertTitle, AlertMsg } from 'config/AlertMsgConfig'
import { useTranslation } from 'react-i18next';
import { FnDialog } from "STM/utilities/ST_Function";
import Accordion from 'STM/components/Accordion';
import JoditEditor from 'STM/components/Jodit/JoditEditor';
import JoditReact from "jodit-react";
import UploadFile from 'STM/components/Input/UploadFile/UploadFile';
import { Extension } from 'STM/utilities/ST_Function';
import "./CMSStyle.css"

export const editorConfig = {
    readonly: false,
    autofocus: true,
    tabIndex: 1,

    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: 'insert_clear_html',

    placeholder: 'Type something...',
    beautyHTML: true,
    toolbarButtonSize: "large",
    buttons: [
        'source',
        '|', 'bold', 'italic',
        '|', 'ul', 'ol',
        '|', 'font', 'fontsize', 'brush', 'paragraph',
        '|', 'video', 'table', 'link',
        '|', 'left', 'center', 'right', 'justify',
        '|', 'undo', 'redo',
        '|', 'hr', 'eraser', 'fullsize'
    ],
    limitChars: 1800,
    limitHTML: true
};

const config = {
    readonly: false,
    zIndex: 0,
    toolbarButtonSize: 'middle',
    theme: 'default',
    saveModeInCookie: false,
    spellcheck: true,
    editorCssClass: false,
    triggerChangeEvent: true,
    width: 'auto',
    height: '50vh',
    minHeight: 100,
    direction: '',
    language: 'auto',
    debugLanguage: false,
    i18n: 'en',
    tabIndex: -1,
    toolbar: true,
    enter: "P",
    useSplitMode: false,
    enableDragAndDropFileToEditor: true,
    colorPickerDefaultTab: 'background',
    imageDefaultWidth: 300,
    extraButtons: [],
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    buttons: ['bold', 'italic', 'underline', '|', 'align', 'ul', 'ol', '|', 'outdent', 'indent', '|', 'font', 'fontsize', 'brush', 'paragraph', '|',  'table', 'link',
        '|', 'hr', 'eraser', 'copyformat', 'preview', '|',  'fullsize', 'print', 'about', 'source', '|', 'undo', 'redo',],
    buttonsXS: ['bold', 'italic', 'underline', '|', 'brush', 'paragraph', '|', 'align', '|', 'undo', 'redo', '|', 'eraser', 'dots',],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ['symbol', 'about','image'],
    showXPathInStatusbar: false,
    showCharsCounter: true,
    showWordsCounter: false,
    toolbarAdaptive: true,
    toolbarSticky: true,
    disablePlugins: ['paste'],
    events: {},
    textIcons: false,
    limitChars: 10000,
    limitHTML: false,
    countHTMLChars:false,
    placeholder: 'Start typing something...',
};

const SlotContent = (prop) => {
    const { t, i18n } = useTranslation();
    const { CMSData, AllData, isRequired,CMSHead } = prop;
    const DialogFn = FnDialog();
    const [sJoditValue, setsJoditValue] = useState("");
    const [sJoditValue2, setsJoditValue2] = useState("");
    const [arrPicture, setarrPicture] = useState([] as any)
    const [arrClip, setarrClip] = useState([] as any)
    const onClearFile = useRef(null);
    const onClearFile2 = useRef(null);

    const objSchema = yup.object().shape({
    });

    useEffect(() => {
        console.log("CMSData", CMSData)
        console.log("CMSData123", CMSData)

        setsJoditValue(CMSData.sText)
        form.setValue("nContentType" + CMSData.nSlotNo + CMSHead.sContentID, CMSData.nType + "");
        form.setValue("sURLVideo" + CMSData.nSlotNo + CMSHead.sContentID, CMSData.sUrlLinkVideo);
        
        if (CMSData.lstClipVideo !== null && CMSData.lstClipVideo.length > 0) {
            setarrClip(CMSData.lstClipVideo)
        }
        if (CMSData.lstPicture !== null && CMSData.lstPicture.length > 0) {
            console.log("lstPicture", CMSData.lstPicture[0])
            setarrPicture(CMSData.lstPicture)
        }
    }, [CMSData]);


    useEffect(() => {
        CMSData.lstPicture = arrPicture;
        CheckValidate(AllData);
    }, [arrPicture]);

    useEffect(() => {
        CMSData.lstClipVideo = arrClip;
        console.log(134,CMSData);
        CheckValidate(AllData);
    }, [arrClip]);

    const form = useForm({
        resolver: yupResolver(objSchema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const isValidURL = (url)  => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // IP address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(url);
    }

    const CheckValidate = (AllData) => {
        console.log("AllData", AllData)
        let nTextLimit = 65535;
        if (isRequired === true) {
            let sError = 0;
            for (const lstSlot of AllData.lstCMS) {
                for (const oSlot of lstSlot.lstSlot) {
                    if (oSlot.nType === 1) {
                        let replacedStr = oSlot.sText.replace(/&nbsp; /g, "").replace(/&nbsp;/g, "").replace(/<p><br><\/p>/g, "");
                        if (oSlot.sText.length > nTextLimit) {
                            AllData.sError = t('ErrorLayout') + " " + lstSlot.nOrder + oSlot.sSlotName + ": " + t('ErrorCMSText')
                            sError++;
                        }

                        if (replacedStr === "") {
                            AllData.sError = t('ErrorLayout') + " " + lstSlot.nOrder + oSlot.sSlotName + ": " + t('ErrorCMSOne')
                            sError++;
                        }
                    }

                    if (oSlot.nType === 2) {
                        if (oSlot.lstPicture === null || oSlot.lstPicture.length === 0) {
                            AllData.sError = t('ErrorLayout') + " " + lstSlot.nOrder + oSlot.sSlotName + ": " + t('ErrorCMSTwo')
                            sError++;
                        }
                    }

                    if (oSlot.nType === 3) {
                        if (oSlot.lstClipVideo === null || oSlot.lstClipVideo.length === 0) {
                            AllData.sError = t('ErrorLayout') + " " + lstSlot.nOrder + oSlot.sSlotName + ": " + t('ErrorCMSThree')
                            sError++;
                        }
                    }

                    if (oSlot.nType === 4) {
                        if (oSlot.sUrlLinkVideo === null || oSlot.sUrlLinkVideo === "") {
                            AllData.sError = t('ErrorLayout') + " " + lstSlot.nOrder + oSlot.sSlotName + ": " + t('ErrorCMSFour')
                            sError++;
                        }
                        else {
                            const urlPattern = /^(https?:\/\/)?([^\s.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
                            let testURL = urlPattern.test(oSlot.sUrlLinkVideo);
                            if (testURL === false) {
                                AllData.sError = t('ErrorLayout') + " " + lstSlot.nOrder + oSlot.sSlotName + ": " + t('ErrorCMSURL')
                                sError++;
                            }
                        }
                    }
                }
            }

            if (sError === 0)
                AllData.sError = "";
        }
    };

    return (
        <>
            <Grid style={{ marginTop: "1rem",width:"100%" }}>
                <Accordion header={CMSData.sSlotName}>
                    <Grid container direction="row" spacing={2} justifyContent="left" alignItems="center" display={"block"}>
                        <FormProvider {...form}>
                            <Grid item md={12}>
                                <RadioForm
                                    name={"nContentType" + CMSData.nSlotNo + CMSHead.sContentID}
                                    label={t('type')}
                                    required={true}
                                    row={true}
                                    options={[
                                        { value: "1", label: t('Text') },
                                        { value: "2", label: t('photo') },
                                        { value: "3", label: t('ClipVideo') },
                                        { value: "4", label: t('URLVideo') },
                                    ]}
                                    onChange={(e) => {
                                        console.log("E",e)
                                        CMSData.nType = Number(e);
                                        setsJoditValue2(e)
                                        //if (e.target.value === "1") {                                           
                                        //    //setarrPicture([]);
                                        //    //setarrClip([]);
                                        //    //form.setValue("sURLVideo" + CMSData.nSlotNo + CMSHead.sContentID, "")
                                        //    //CMSData.sUrlLinkVideo = "";
                                        //    //onClearFile.current();
                                        //    //onClearFile2.current();
                                        //}
                                        //else if (e.target.value === "2") {
                                        //    //setsJoditValue("")
                                        //    //CMSData.sText = "";
                                        //    //setarrClip([]);
                                        //    //form.setValue("sURLVideo" + CMSData.nSlotNo + CMSHead.sContentID, "")
                                        //    //CMSData.sUrlLinkVideo = "";
                                        //    //onClearFile.current();
                                        //}
                                        //else if (e.target.value === "3") {
                                        //    //setsJoditValue("")
                                        //    //CMSData.sText = "";
                                        //    //setarrPicture([]);
                                        //    //form.setValue("sURLVideo" + CMSData.nSlotNo + CMSHead.sContentID, "")
                                        //    //CMSData.sUrlLinkVideo = "";
                                        //    //onClearFile2.current();
                                        //}
                                        //else if (e.target.value === "4") {
                                        //    //setsJoditValue("")
                                        //    //CMSData.sText = "";
                                        //    //setarrClip([]);
                                        //    //setarrPicture([]);
                                        //    //onClearFile.current();
                                        //    //onClearFile2.current();
                                        //}
                                        CheckValidate(AllData)
                                    }}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item sm={12} md={12} style={form.getValues("nContentType" + CMSData.nSlotNo + CMSHead.sContentID) === "1" ? {} : { display: "none" }}>
                                <JoditReact
                                    value={sJoditValue}

                                    config={config}
                                    onChange={(e) => {
                                        
                                    }}
                                    onBlur={(e) => {
                                        setsJoditValue(e);
                                        CMSData.sText = e;
                                        CheckValidate(AllData)
                                    }}
                                />

                            </Grid>
                            <Grid item sm={12} md={12} style={form.getValues("nContentType" + CMSData.nSlotNo + CMSHead.sContentID) === "2" ? {} : { display: "none" }}>
                                <UploadFile
                                    id={"sPicture"}
                                    name={"arrPicture"}
                                    keyID={"arrPicture" + CMSData.nSlotNo + CMSHead.sContentID}
                                    IsDrag={true}
                                    sTitle={`Upload File`}
                                    IsRequired={false}
                                    arrFile={arrPicture}
                                    setarrFile={setarrPicture}
                                    IsFolder={false}
                                    IsMultiple={false}
                                    Extension={[ ...Extension.Image]}
                                    nLimitFile={3}
                                    sLimitFile={"MB"}
                                    onClearFile={onClearFile}
                                    modeDisplay={"gallery"}
                                    sFolderTemp={"Review"}
                                    IsDragDrop={false}
                                    IsCrop={true}
                                    IsFile = {true}
                                />

                            </Grid>
                            <Grid item sm={12} md={12} style={form.getValues("nContentType" + CMSData.nSlotNo + CMSHead.sContentID) === "3" ? {} : { display: "none" }}>
                                <UploadFile
                                    id={"sClip"}
                                    name={"arrClip"}
                                    keyID={"arrClip" + CMSData.nSlotNo + CMSHead.sContentID}
                                    IsDrag={true}
                                    sTitle={`Upload File`}
                                    IsRequired={false}
                                    arrFile={arrClip}
                                    setarrFile={setarrClip}
                                    IsFolder={false}
                                    IsMultiple={false}
                                    Extension={[...Extension.Video]}
                                    nLimitFile={30}
                                    sLimitFile={"MB"}
                                    onClearFile={onClearFile2}
                                    modeDisplay={"gallery"}
                                    sFolderTemp={"Review"}
                                    IsDragDrop={false}
                                    IsCrop={true}
                                    IsFile = {true}
                                />
                            </Grid>
                            <Grid item sm={12} md={12} style={form.getValues("nContentType" + CMSData.nSlotNo + CMSHead.sContentID) === "4" ? {} : { display: "none" }}>
                                <TextBoxForm name={"sURLVideo" + CMSData.nSlotNo + CMSHead.sContentID} label={"URl Link Video"}
                                    onBlur={(e) => {
                                        let sValueSet = e.target.value;
                                        console.log("E",sValueSet)
                                        if (isValidURL(sValueSet)) {
                                            CMSData.sUrlLinkVideo = sValueSet;
                                            CheckValidate(AllData);
                                        }
                                        else {
                                            form.setValue("sURLVideo" + CMSData.nSlotNo + CMSHead.sContentID, "")
                                            if (sValueSet !== "")
                                                DialogFn.Warning(t('ErrorCMSURL'));
                                        }
                                    }}
                                    isshowTextCont={false} />
                            </Grid>
                        </FormProvider>
                    </Grid>
                </Accordion>
            </Grid>


        </>
    )
};
export default SlotContent;