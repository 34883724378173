import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FnBlock_UI } from 'STM/components/BlockUI';
import { AxiosPost } from 'STM/utilities/ST_Axios';
import { SelectFormItem, RadioNoForm } from 'STM/components/Input';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import yupFormSchemas from 'STM/components/FormItem/yupFormSchemas';
import { BtnSubmitForm, BtnBackForm } from 'STM/components/Button';
import { SwAlert } from 'STM/components/Alert/index';
import { AlertTitle, AlertMsg } from 'config/AlertMsgConfig'
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useTranslation } from 'react-i18next';
import "./CMSStyle.css"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import { FnDialog } from "STM/utilities/ST_Function";
import Badge from '@mui/material/Badge';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 700,
            lg: 1200,
            xl: 1536,
        },
    },
});

const PopupChooseTemplate = (prop) => {
    const DialogFn = FnDialog();
    const { t, i18n } = useTranslation();
    const { open, handleClose, nTemplateID, setCMSData, lstTemplateData, CMSData, nOrder } = prop;
    const [nPage, setnPage] = useState(1);
    const [nID, setnID] = useState(0);
    const [imageArray, setImageArray] = useState([]);
    
    const objSchema = yup.object().shape({
    });

    useEffect(() => {
        setnPage(1);
        setnID(nTemplateID);
    }, [open]);

    const form = useForm({
        resolver: yupResolver(objSchema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    
    const OnChooseTemplate = () => {
        let param = {
            nTemplateID: nID,
            CMSData: CMSData,
            nOrder: nOrder
        };
        console.log("param", param)
        
        if (nID === 0) {
            SwAlert.Warning(t('warning'), t('ChooseLayout'), () => { })
        }
        else {
            AxiosPost("CMSComponent/ChooseTemplate", param, (res) => {
                console.log("ChooseTemplate", res)
                setnID(res.nTemplateContentID);
                setCMSData(res);
                handleClose();
            }, (err) => {
                if (!err.response) {
                    DialogFn.UnBlockUI();
                    DialogFn.Warning(err.Message);
                }
            })
        }
        
    };

    const ChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        console.log("E", value)
        setnPage(value);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"lg"}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography
                        sx={{ color: "rgb(52, 71, 103)" }}
                        className="Header-title-form"
                    >
                        {"Template Layout"}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <CssBaseline />
                    <ThemeProvider theme={theme}>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
                        {lstTemplateData.filter(f => f.nPage === nPage).map((item, i) => (
                            <Grid container className={nID === item.nTemplateID ? "overlay narongrit" : "hovernow narongrit"} lg={3} md={5} xs={5} style={nID === item.nTemplateID ? { height: 200, display: "flex", margin: 10, border: "1px solid black", cursor: "pointer" } : { height: 200, display: "flex", margin: 10, border: "1px solid black", cursor: "pointer" }} onClick={(e) => {
                                setnID(item.nTemplateID);
                            }}>
                                {item.lstSlot !== null ? item.lstSlot.map((itemSlot, i) => (
                                    <Grid xs={itemSlot.nGridSize_Lg} md={itemSlot.nGridSize_Lg} lg={itemSlot.nGridSize_Lg} style={{ display: 'flex' }}>
                                        <Grid container style={itemSlot.lstChild !== undefined && itemSlot.lstChild !== null && itemSlot.lstChild.length > 0 ? {} : { display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#e7ebf0", border: "1px solid black" }}>
                                            {itemSlot.sSlot !== null ? itemSlot.sSlot : ""}
                                            {itemSlot.lstChild !== undefined && itemSlot.lstChild !== null ? itemSlot.lstChild.map((child, j) => (
                                                <Grid xs={child.nGridSize_Lg} md={child.nGridSize_Lg} lg={child.nGridSize_Lg} style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#e7ebf0", border: "1px solid black" }}>
                                                    {child.sSlot !== null ? child.sSlot : ""}

                                                </Grid>
                                            )) : null}
                                        </Grid>
                                    </Grid>
                                )) : null}
                            </Grid>
                        ))}
                    </Grid>
                    </ThemeProvider>
                    <Grid container spacing={2} justifyContent="center" style={lstTemplateData.length < 10  ? {display:"none"} : {}}>
                        <Grid item md={12} xs={12} style={{ display: "flex", justifyContent: "end", marginTop: 10 }}>
                            <Stack spacing={2}>
                                <Pagination count={lstTemplateData.length / 9} variant="outlined" color="primary" onChange={ChangePage} />
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justifyContent="center" style={{}}>
                        <Grid item md={6} xs={6} style={{ display: "flex", justifyContent: "end" }}>
                            <BtnBackForm
                                txt={t('back')}
                                onClick={() => {
                                    handleClose()

                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={6} style={{ display: "flex", justifyContent: "start" }}>
                            <BtnSubmitForm
                                icon={<SaveAltIcon />}
                                onClick={form.handleSubmit((e) => { OnChooseTemplate() })}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
        </>
    )
};
export default PopupChooseTemplate;