import { useState, forwardRef, useRef, useEffect } from "react";
import { FormHelperText, TextField, InputAdornment, IconButton } from "@mui/material";
import { TextBoxFormprop, TextBoxNoFormprop, TextBoxNumberNoFormprop, TextBoxNumberFormprop } from "./TextBoxProps";
import { SxProps } from "@mui/material";
import { Controller, useWatch, useFormContext } from "react-hook-form";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const TextBoxForm = forwardRef((props: TextBoxFormprop, ref) => {
    const {
        name,
        startAdornment,
        endAdornment,
        fullWidth = true,
        isshowTextCont = true,
        autoComplete = "off",
    } = props;

    const {
        control,
        register,
        setValue,
        getValues,
        watch,
    } = useFormContext();

    const FieldName = useWatch({
        control,
        name: name,
    });

    const [Focus, SetFocus] = useState(false);
    const [sValueText, setValueText] = useState<string>(null);

    const maxLengthText: SxProps = {
        // "& input":{
        "::after": {
            content:
                isshowTextCont && props.maxLength
                    ? `"${getValues(name) ? getValues(name).length : 0}/${props.maxLength}"`
                    : '""',
            position: "absolute",
            bottom: "5px",
            right: "10px",
            color: "#989898",
            fontSize: "10px",
        },
        // }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, error }, }) => (
                <>
                    <TextField
                        {...props}
                        key={name}
                        id={props.id}
                        inputRef={ref}
                        {...register(name)}
                        name={name}
                        defaultValue={props.defaultValue}
                        value={sValueText || null}
                        label={props.shrink && props.shrink === true ? props.label : ""}
                        placeholder={!props.shrink ? props.label : ""}
                        required={props.required || false}
                        error={error?.message != null}
                        type={props.type || "text"}
                        disabled={props.disabled}
                        variant={props.variant || "outlined"}
                        fullWidth={fullWidth}
                        margin={props.margin}
                        style={props.style}
                        size={"small"}
                        autoComplete={props.autoComplete ? props.autoComplete : autoComplete}
                        sx={[{ ...maxLengthText }, {
                            // padding:"8.5px",
                            ".MuiInputBase-inputSizeSmall": {
                                border: error ? "1px solid red !important" : "",
                                padding: "8.5px"
                            },
                            "label.MuiInputLabel-shrink": {
                                backgroundColor: "rgb(255 255 255 / 18%)",
                                top: "0px",
                            },
                            ".MuiInputLabel-outlined": {
                                top: "0px",
                            },
                            ".MuiInputLabel-asterisk": {
                                color: "red"
                            }
                        }]}
                        inputProps={{
                            maxLength: props.maxLength ? props.maxLength : null,
                            name,
                        }}
                        InputLabelProps={{
                            shrink: props.shrink ? (FieldName || Focus) && props.shrink === true ? true : false : false,
                        }}
                        InputProps={props.startAdornment ? { startAdornment } : { endAdornment }}
                        onChange={(e) => {
                            onChange(e);

                            let sValueSet = e.target.value;

                            if (props.type === "email" || props.mode === "email") {
                                sValueSet = sValueSet?.replace(/[^A-Za-z0-9@._]/gi, "");
                            } else if (props.lang == "en") {
                                // sValueSet = sValueSet?.replace(/[^A-Za-z0-9_.,'"!?;:&@ ]/gi, "");

                                if (props.mode === "name") {
                                    sValueSet = sValueSet?.replace(/[^A-Za-z ]/gi, "");
                                } else {
                                    sValueSet = sValueSet?.replace(/[^A-Za-z0-9 ]/gi, "");
                                }

                            } else if (props.lang === "th") {
                                if (props.mode === "name") {
                                    sValueSet = sValueSet?.replace(/[^\u0E00-\u0E7F ]/gi, "");
                                } else {
                                    sValueSet = sValueSet?.replace(/[^\u0E00-\u0E7F0-9 ]/gi, "");
                                }

                            }

                            setValueText(sValueSet);
                            setValue(name, sValueSet, { shouldValidate: true });
                            props.onChange && props.onChange(sValueSet);
                        }}
                        onBlur={(event) => {
                            SetFocus(false);
                            onBlur();

                            setValue(name, event.target.value, { shouldValidate: true });
                            props.onBlur && props.onBlur(event);
                        }}
                        onKeyPress={(event) => {
                            props.onKeyPress && props.onKeyPress(event);
                        }}
                        onKeyDown={(event) => {
                            props.onKeyDown && props.onKeyDown(event);
                        }}
                        onKeyUp={(event) => {
                            props.onKeyPress && props.onKeyPress(event);
                        }}
                        onFocus={() => {
                            SetFocus(true);
                        }}
                    />
                    {error ? (
                        <FormHelperText sx={{ color: "red" }}>
                            {error.message}
                        </FormHelperText>
                    ) : null}
                </>
            )
            }
        />
    );
});

export default TextBoxForm;

export const TextBoxNoForm = forwardRef((props: TextBoxNoFormprop, ref) => {
    const {
        startAdornment,
        endAdornment,
        multiline = false,
        fullWidth = true,
        isshowTextCont = true,
    } = props;
    const [value, setValue] = useState<string>(props.value);

    const maxLengthText: SxProps = {
        // "& input":{
        "::after": {
            content:
                isshowTextCont && props.maxLength
                    ? `"${value ? value.length : 0}/${props.maxLength}"`
                    : '""',
            position: "absolute",
            bottom: "5px",
            right: "10px",
            color: "#989898",
            fontSize: "10px",
        },
        // }
    };
    return (
        <TextField
            {...props}
            key={props.id}
            inputRef={ref}
            name={props.id}
            value={value}
            label={props.shrink && props.shrink === true ? props.label : ""}
            placeholder={!props.shrink ? props.label : ""}
            type={props.type || "text"}
            disabled={props.disabled}
            variant={props.variant || "outlined"}
            fullWidth={fullWidth}
            margin={props.margin || "normal"}
            style={props.style}
            size={"small"}
            autoComplete={"off"}
            multiline={multiline}
            rows={props.rows}
            inputProps={{
                maxLength: props.maxLength ? props.maxLength : null,
                name: props.id,
            }}
            InputProps={props.startAdornment ? { startAdornment } : { endAdornment }}
            onChange={(e) => {
                setValue(e.target.value);
                props.onChange && props.onChange(e.target.value);
            }}
            onBlur={(event) => {
                props.onBlur && props.onBlur(event);
            }}
            onKeyPress={(event) => {
                props.onKeyPress && props.onKeyPress(event);
            }}
            onKeyDown={(event) => {
                props.onKeyDown && props.onKeyDown(event);
            }}
            onKeyUp={(event) => {
                props.onKeyPress && props.onKeyPress(event);
            }}
            sx={[{ ...maxLengthText }, {
                mt: 0,
                "label.MuiInputLabel-shrink": {
                    backgroundColor: "rgb(255 255 255 / 18%)",
                    // backgroundColor: "rgba(255,255,255,0.95)",
                    top: "0px",
                },
                ".MuiInputLabel-outlined": {
                    top: "0px",
                },
                ".MuiInputLabel-asterisk": {
                    color: "red"
                }
            }]}
        />
    );
});

export const InputNumberNoForm = forwardRef((props: TextBoxNumberNoFormprop) => {
    const {
        maxInteger = 25,
        maxDigits = 8,
        startAdornment,
        endAdornment,
    } = props;

    const inputEl = useRef(null)
    let inputEl2 = null;

    const onChange = (e) => {
        let sValue = e.target.value;
        let nVal = (sValue + "").replaceAll(/,/g, '');
        nVal = nVal.replaceAll(",", "")
        if (isNumber(nVal)) {

            if (maxInteger != undefined && maxInteger != null && maxInteger > 0) {
                let arrDig = nVal.split(".");
                let valInteger = arrDig[0].substring(0, maxInteger)
                if (arrDig.length > 1) {
                    nVal = valInteger + "." + arrDig[1];
                } else {
                    nVal = valInteger;
                }
            }
            if (maxDigits == 0) {
                let arrDig = nVal.split(".");
                nVal = arrDig[0];
            }


            let nCheck = parseFloat(nVal);
            if (nCheck >= 1 || nCheck == 0) {
                e.target.value = nVal;
            }
            else if (nCheck > 0 && nCheck < 1) {
                e.target.value = nVal;
            }
            else {
                e.target.value = '';
            }
        } else {
            if (nVal == "NA" || nVal == "Na" || nVal == "nA" || nVal == "N/a" || nVal == "N/A" || nVal == "na" || nVal == "n/a") {
                e.target.value = "N/A";
            } else if (nVal == "n" || nVal == "N") {
                e.target.value = "N"
            } else if (nVal == "a" || nVal == "A") {
                e.target.value = 'A'
            } else if (nVal == "N/") {
                e.target.value = "N/"
            } else {
                e.target.value = '';
            }
        }
        inputEl2 = e;
        props.onChange && props.onChange(e.target.value, inputEl2)
    }

    const onBlur = (e) => {
        let sValue = e.target.value;
        let nVal = (sValue + "").replaceAll(/,/g, '');
        nVal = nVal.replaceAll(",", "")
        if (isNumber(nVal)) {
            let nCheck = parseFloat(nVal);
            if (nCheck >= 1 || nCheck == 0) {
                e.target.value = commaSeparateNumber(nVal, props.maxDigits, maxInteger);
            }
            else if (nCheck > 0 && nCheck < 1) {
                e.target.value = commaSeparateNumber(nVal, props.maxDigits, maxInteger);
            }
            else {
                e.target.value = '';
            }
        } else {
            if (nVal == "NA" || nVal == "Na" || nVal == "nA" || nVal == "N/a" || nVal == "N/A" || nVal == "na" || nVal == "n/a") {
                e.target.value = "N/A";
            } else if (nVal == "n" || nVal == "N") {
                e.target.value = ''
            } else if (nVal == "a" || nVal == "A") {
                e.target.value = ''
            } else if (nVal == "N/") {
                e.target.value = ''
            } else {
                e.target.value = '';
            }
        }
        inputEl2 = e;
        props.onBlur && props.onBlur(e.target.value, inputEl2)
    }

    return (
        <TextField type={"text"}
            style={{ width: "100%", ...props?.style }}
            {...props}
            ref={inputEl}
            variant={props.variant || "outlined"}
            size="small"
            label={props.label}
            inputProps={{
                style: { textAlign: props.textAlign || "right", },
            }}
            InputProps={props.startAdornment ? { startAdornment } : { endAdornment }}
            defaultValue={
                props.isExponential ?
                    ConvertToExponential(props.value, props.ShowDigits)
                    :
                    commaSeparateNumber(props.value, maxDigits, props.ShowDigits)
            }
            onChange={onChange}
            onBlur={onBlur}
            placeholder={props.placeholder}
            disabled={props.disabled || false}
            onKeyPress={(event) => {
                props.onKeyPress && props.onKeyPress(event);
            }}
            onKeyDown={(event) => {
                props.onKeyDown && props.onKeyDown(event);
            }}
            onKeyUp={(event) => {
                props.onKeyPress && props.onKeyPress(event);
            }}
        />
    );

});

export const InputNumberForm = forwardRef((props: TextBoxNumberFormprop) => {
    const {
        maxInteger = 25,
        maxDigits = 8,
        startAdornment,
        endAdornment,
    } = props;
    const {
        control,
        register,
        getValues
    } = useFormContext();

    const FieldName = useWatch({
        control,
        name: props.name,
    });
    const [Focus, SetFocus] = useState(false);

    const inputEl = useRef(null)
    let inputEl2 = null;

    const onChange = (e) => {
        let sValue = e.target.value;
        let nVal = (sValue + "").replaceAll(/,/g, '');
        nVal = nVal.replaceAll(",", "")
        if (isNumber(nVal)) {

            if (maxInteger != undefined && maxInteger != null && maxInteger > 0) {
                let arrDig = nVal.split(".");
                let valInteger = arrDig[0].substring(0, maxInteger)
                if (arrDig.length > 1) {
                    nVal = valInteger + "." + arrDig[1];
                } else {
                    nVal = valInteger;
                }
            }
            if (maxDigits == 0) {
                let arrDig = nVal.split(".");
                nVal = arrDig[0];
            }


            let nCheck = parseFloat(nVal);
            if (nCheck >= 1 || nCheck == 0) {
                e.target.value = nVal;
            }
            else if (nCheck > 0 && nCheck < 1) {
                e.target.value = nVal;
            }
            else {
                e.target.value = '';
            }
        } else {
            if (nVal == "NA" || nVal == "Na" || nVal == "nA" || nVal == "N/a" || nVal == "N/A" || nVal == "na" || nVal == "n/a") {
                e.target.value = "N/A";
            } else if (nVal == "n" || nVal == "N") {
                e.target.value = "N"
            } else if (nVal == "a" || nVal == "A") {
                e.target.value = 'A'
            } else if (nVal == "N/") {
                e.target.value = "N/"
            } else {
                e.target.value = '';
            }
        }
        inputEl2 = e;
        props.onChange && props.onChange(e.target.value, inputEl2)
    }

    const _onBlur = (e) => {
        let sValue = e.target.value;
        let nVal = (sValue + "").replaceAll(/,/g, '');
        nVal = nVal.replaceAll(",", "")
        if (isNumber(nVal)) {
            let nCheck = parseFloat(nVal);
            if (nCheck >= 1 || nCheck == 0) {
                e.target.value = commaSeparateNumber(nVal, props.maxDigits, maxInteger);
            }
            else if (nCheck > 0 && nCheck < 1) {
                e.target.value = commaSeparateNumber(nVal, props.maxDigits, maxInteger);
            }
            else {
                e.target.value = '';
            }
        } else {
            if (nVal == "NA" || nVal == "Na" || nVal == "nA" || nVal == "N/a" || nVal == "N/A" || nVal == "na" || nVal == "n/a") {
                e.target.value = "N/A";
            } else if (nVal == "n" || nVal == "N") {
                e.target.value = ''
            } else if (nVal == "a" || nVal == "A") {
                e.target.value = ''
            } else if (nVal == "N/") {
                e.target.value = ''
            } else {
                e.target.value = '';
            }
        }
        inputEl2 = e;
        props.onBlur && props.onBlur(e.target.value, inputEl2)
    }

    return (
        <Controller
            name={props.name}
            control={control}
            render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, error }, }) => (
                <>
                    <TextField type={"text"}
                        style={{ width: "100%", ...props?.style }}
                        {...props}
                        ref={inputEl}
                        variant={props.variant || "outlined"}
                        size="small"
                        label={props.label}
                        inputProps={{
                            style: { textAlign: props.textAlign || "right", },
                        }}
                        InputProps={props.startAdornment ? { startAdornment } : { endAdornment }}
                        defaultValue={
                            props.isExponential ?
                                ConvertToExponential(props.defaultValue, props.ShowDigits)
                                :
                                commaSeparateNumber(props.defaultValue, maxDigits, props.ShowDigits)
                        }
                        onChange={onChange}
                        onBlur={(e) => {
                            onBlur();
                            _onBlur(e);
                        }}
                        placeholder={props.placeholder}
                        disabled={props.disabled || false}
                        onKeyPress={(event) => {
                            props.onKeyPress && props.onKeyPress(event);
                        }}
                        onKeyDown={(event) => {
                            props.onKeyDown && props.onKeyDown(event);
                        }}
                        onKeyUp={(event) => {
                            props.onKeyPress && props.onKeyPress(event);
                        }}
                        onPaste={(event) => {
                            props.onPaste && props.onPaste(event);
                        }}
                    />
                    {error ? (
                        <FormHelperText sx={{ color: "red" }}>
                            {error.message}
                        </FormHelperText>
                    ) : null}
                </>
            )} />
    );

});

export const InputPassword = forwardRef((props: TextBoxFormprop, ref) => {
    const {
        name,
        startAdornment,
        endAdornment,
        fullWidth = true,
        isshowTextCont = true,
        autoComplete = "off"
    } = props;

    const {
        control,
        register,
        setValue,
        getValues,
        watch
    } = useFormContext();

    const FieldName = useWatch({
        control,
        name: name,
    });

    const [IsTypePass, setIsTypePass] = useState(true);
    const [sValueText, setValueText] = useState<string>(watch(name));

    useEffect(() => {
        setValueText(getValues(name));
    }, [watch(name)]);

    const maxLengthText: SxProps = {
        // "& input":{
        "::after": {
            content:
                isshowTextCont && props.maxLength
                    ? `"${getValues(name) ? getValues(name).length : 0}/${props.maxLength}"`
                    : '""',
            position: "absolute",
            bottom: "5px",
            right: "10px",
            color: "#989898",
            fontSize: "10px",
        },
        // }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, error }, }) => (
                <>
                    <TextField
                        id="txtPassword"
                        size="small"
                        fullWidth={true}
                        value={sValueText || ""}
                        label={props.shrink && props.shrink === true ? sValueText ? "" : props.label : ""}
                        placeholder={!props.shrink ? props.label : ""}
                        type={!IsTypePass ? "text" : "password"}
                        sx={{ backgroundColor: "rgba(255,255,255,0)" }}
                        required={props.required || false}
                        autoComplete="new-password"
                        InputProps={{
                            startAdornment: (
                                props.startAdornment
                            ),
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        style={{ padding: '0px' }}
                                        onClick={(() => { setIsTypePass(!IsTypePass) })}
                                    >
                                        {IsTypePass ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => {
                            onChange(e);
                            let sValueSet = e.target.value;

                            setValueText(sValueSet);
                            setValue(name, sValueSet, { shouldValidate: true });
                            props.onChange && props.onChange(sValueSet);
                        }}
                        onKeyPress={(e) => {
                            props.onKeyPress && props.onKeyPress(e);
                        }}
                        onKeyDown={(e) => {
                            props.onKeyDown && props.onKeyDown(e);
                        }}
                    />
                    {error ? (
                        <FormHelperText sx={{ color: "red" }}>
                            {error.message}
                        </FormHelperText>
                    ) : null}
                </>
            )
            }
        />
    );
});

//#region Fun
export const isNumber = (n) => {
    return typeof (n) != "boolean" && !isNaN(n);
}

export const convertStringToNumber = (val) => {
    if (val != undefined && val != null) {
        let values = (val + "").replaceAll(",", "")
        if (values && values != "" && values != null && isNumber(values)) {
            let valConve = parseFloat(values);
            if ((valConve + "") != "NaN") {
                return valConve;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

export const commaSeparateNumber = (value: any, _MaxDigits?, _MaxInteger?) => {
    let MaxDigits = _MaxDigits || null;
    let MaxInteger = _MaxInteger || 25;
    if (value != undefined && value != null && (value + "") != "" && value != "N/A") {
        let val = parseFloat((value + "").replaceAll(",", ""))
        let sign = 1;
        if (val < 0) {
            sign = -1;
            val = -val;
        }

        let num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString();
        num = num.substring(0, MaxInteger)

        if (val.toString().includes('.')) {

            if (MaxDigits) {
                // num = num + '.' + (val.toString().split('.')[1]).substring(0, MaxDigits); //ตัดเศษแบบไม่ปัด
                //.toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 2});
                num = (+val).toLocaleString(undefined, { maximumFractionDigits: MaxDigits }).replaceAll(",", ""); //ตัดเศษแบบปัดเศษ
            } else {
                num = num + '.' + (val.toString().split('.')[1]);
            }
        }
        //ตัดเศษแบบปัดเศษ กรณีส่ง Digits และมีค่า
        if (MaxDigits && num != undefined && num != null && num != "") {
            if (MaxDigits) {
                num = (+num).toLocaleString(undefined, { maximumFractionDigits: MaxDigits }).replaceAll(",", "");
            }
        }


        if (num != undefined && num != null && num != "NaN" && num != "") {
            let indValue = (num + "").split(".");
            let valueResult = indValue[0];
            if ((num + "") != "0") {
                while (/(\d+)(\d{3})/.test(valueResult)) {
                    valueResult = valueResult.replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
                }
                if (indValue.length > 1) {
                    valueResult = valueResult + "." + indValue[1]
                }
                return sign < 0 ? '-' + valueResult : valueResult;
            } else {
                return sign < 0 ? '-' + "0" : "0";
            }
        } else {
            return "";
        }

    } else {
        if (value == "N/A") {
            return value;
        } else {
            return "";
        }
    }
}

export const ConvertToExponential = (value, digit = 3, MinIntegerCount = 1) => {
    if (value != "N/A") {
        let value_ = (value + "").replaceAll(",", "");

        let num = Number.parseFloat(value_)
        if (num < MinIntegerCount && num != 0) {
            let val = num
            let sign = 1;
            if (val < 0) {
                sign = -1;
                val = -val;
            }
            if (sign < 0) {
                if (val < MinIntegerCount) {
                    return ConvertToExponential_(value, digit);
                } else {
                    return commaSeparateNumber(value, digit);
                }
            } else {
                return ConvertToExponential_(value, digit);

            }
        } else {
            return num == 0 ? (digit != undefined && digit != null) ? "0." + (("0").padStart(digit, "0")) : "0" : commaSeparateNumber(value, digit);
        }
    } else {
        return value;
    }
}

const ConvertToExponential_ = (value, digit = 3) => {
    let result = ""
    let value_ = (value + "").replaceAll(",", "");
    let num = Number.parseFloat(value_)
    if (!isNaN(num)) {
        result = num.toExponential(digit).replace(/e/g, 'E');
    }
    return result
}

//#endregion