
import { GoogleMap, Marker,InfoWindow, useJsApiLoader  } from "@react-google-maps/api";
import {useState,useEffect } from "react";
import "./ContactUS.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import { GetInitialInfo } from "utilities/ST_Function";
import { AxiosGet } from "utilities/ST_Axios";
import parse from 'html-react-parser';
import { enumCMSCode } from 'enum/enumSystem2';
import { SetSeoMenuToHtml } from "STM/utilities/ST_Function";

function ContactUs() {
  useEffect(()=>{
    SetSeoMenuToHtml();
  },[]);
  const { t, i18n } = useTranslation();
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(true);

  const handleMarkerMouseOver = () => {
    setIsInfoWindowOpen(true);
  };
  
  const handleMarkerMouseOut = () => {
    setIsInfoWindowOpen(false);
  };

  const renderInfoWindowContent = () => {
    return (
      <div className="googlemap-desc-box">
        <div className="googlemap-text">PTT Global LNG Co,Ltd;</div>
        <div className="googlemap-view-large-map" style={{verticalAlign:"middle"}}>
          {/* <AssistantDirectionIcon style={{
                color: "rgb(47 39 229)", width: '16px',
                height: '16px',
                verticalAlign:"middle",marginRight:'5px', 
          }}/> */}
          <a href={`${linkGoogleMap}`} style={{verticalAlign:"middle"}} className="link-googlemap" target="_blank" rel="noopener noreferrer" >View larger map</a>
        </div>
      </div>
    );
  };

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  const frontURL = process.env.REACT_APP_Front_URL;
  const info = GetInitialInfo();
  const mapInfo =  info.googleMap;
  const googleMapsApiKey = mapInfo.Key;
  /////const center = useMemo(() => ({ lat: mapInfo.Info.Lat, lng:mapInfo.Info.Lng }), []);
  const center = { lat: mapInfo.Info.Lat, lng:mapInfo.Info.Lng };
  const linkGoogleMap = `https://goo.gl/maps/mFP8dzPVuayEVG358`;
  const openGoogleMap = () =>{ 
    window.open('https://www.google.com/maps', '_blank','noopener');
  }

  const [contents1, setcontents1] = useState("");
  useEffect(() => {
    AxiosGet("Utility2/GetCMS", {sCMSCode:enumCMSCode.ContactUs1.toString()}, (res) => {
      setcontents1(res.sContents);
    });
  },[]);
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
  });
  return (
    <div className="column fixed-height-contact contact-container" style={{ width:"100%",display:"flex" }}>
      <div
        className="container-contact-area"
        style={{
          backgroundImage: `url(${frontURL + "images/white.png"})`,
        }}
      >
      </div>
      <div
        className="container-detail-area"
        style={{
          background: "linear-gradient(157deg, rgba(0,51,102,1) 8%, rgba(34,78,122,1) 51%, rgba(91,126,162,1) 88%)"
        }}
      >
        <div
          className="box-contact-detail"
        >
        
         {parse(contents1)}

          <div className="fade-map" style={{ width: "100%", height: "200px", background: "white",marginTop:"1rem" }} >
          { isLoaded ? 
          <GoogleMap
              mapContainerClassName="map-container"
              center={center}
              zoom={13}
              options={{
                zoomControl: true,
                fullscreenControl:true,
                mapTypeControl:true,
                scaleControl:true,
                streetViewControl:false,
                rotateControl:true,
              }}
          >
          <Marker 
            position={center}
            onClick={openGoogleMap}
          >
             {isInfoWindowOpen && (
            <InfoWindow
              position={center}
              onCloseClick={() => setIsInfoWindowOpen(false)}
              options={{ pixelOffset: new google.maps.Size(0, -30)}}
            >
              {renderInfoWindowContent()}
            </InfoWindow>
              )}
          </Marker>
        </GoogleMap>
        :<></>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;