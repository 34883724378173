import { useEffect } from "react";
///Libraby
///MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { IconButton, Typography } from '@mui/material';
import { Link } from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';


export default function HomePageIPad() {
   const frontURL = process.env.REACT_APP_Front_URL;

   // // const openUnderConstruction = () => {
   // //    ShowUnderConstraction();
   // // }

   useEffect(() => {
      AOS.init();
   }, []);

   return (
      <>
         <Grid container className="home-page-IPad" sx={{ position: "absolute", display: "flex", justifyContent: "space-around", bottom: "5%" }}>

            <Grid item xs={3.8} sx={{ filter: "drop-shadow(3px 3px 2px #000)" }} data-aos="fade-right" data-aos-duration="1500">
               <Link to={"/AboutUs"}>
                  {/* <Box className="home-IPad" onClick={openUnderConstruction} */}
                  <Box className="home-IPad"
                     sx={{
                        backgroundImage: `url(${frontURL + "home/AboutUs.png"})`,
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between"
                     }}>
                     <Box sx={{ color: "#fff" }}>
                        <Typography sx={{ fontSize: "1.2rem", fontWeight: 500, lineHeight: 1 }} >
                           ABOUT US
                        </Typography>
                     </Box>
                     <Box className="" sx={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
                        <IconButton className="ipad-icons icons-boat"
                           sx={{
                              backgroundImage: `url(${frontURL + "home/boat.png"})`,
                           }}>
                        </IconButton>
                        <IconButton className="ipad-icons icons-electric-pole"
                           sx={{
                              backgroundImage: `url(${frontURL + "home/electric-pole.png"})`
                           }}>
                        </IconButton>
                     </Box>
                  </Box>
               </Link>
            </Grid>

            <Grid item xs={3.8} sx={{ filter: "drop-shadow(3px 3px 2px #000)" }} data-aos="fade-up" data-aos-duration="2000">
               <Link to={"/Network"}>
                  <Box className="home-IPad"
                     // onClick={openUnderConstruction}
                     sx={{
                        backgroundImage: `url(${frontURL + "home/network.jpg"})`,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                     }}>
                     <Box sx={{ color: "#fff" }}>
                        <Typography sx={{ fontSize: "1.2rem", fontWeight: 500 }}>
                           NETWORKS
                        </Typography>
                     </Box>
                     <Box>
                        <IconButton className="ipad-icons icons-gas-tank"
                           sx={{ backgroundImage: `url(${frontURL + "home/gas-tank.png"})` }}>
                        </IconButton>
                     </Box>
                  </Box>
               </Link>
            </Grid>

            <Grid item xs={3.8} sx={{ filter: "drop-shadow(3px 3px 2px #000)" }} data-aos="fade-left" data-aos-duration="1500">
               <Link to={"/NewsActivity"}>
                  {/* <Box className="home-IPad" onClick={openUnderConstruction} */}
                  <Box className="home-IPad"
                     sx={{
                        backgroundImage: `url(${frontURL + "home/news.png"})`,
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between"
                     }}>
                     <Box sx={{ color: "#fff" }}>
                        <Typography sx={{ fontSize: "1.2rem", fontWeight: 500, lineHeight: 1 }} >
                           NEWS&
                           <br></br>
                           ACTIVITIES
                        </Typography>
                     </Box>
                     <Box>
                        <IconButton className="ipad-icons icons-faucet"
                           sx={{
                              backgroundImage: `url(${frontURL + "home/faucet.png"})`
                           }}>
                        </IconButton>
                     </Box>
                  </Box>
               </Link>
            </Grid>

         </Grid>
      </>
   )
}