import { SxProps, Select, MenuItem } from "@mui/material";
import React from "react";

const ChangeOrder = (props) => {
  const { item, onChangeOrder, optOrder } = props;
  const sxSelectOrder: SxProps = {
    height: "25px",
    "& .MuiSelect-select": { padding: "4px 12px" },
  };
  return (
    <Select
      fullWidth
      size="small"
      sx={sxSelectOrder}
      value={item.row.nSortOrder}
      onChange={(e) => {
        onChangeOrder(item.row.sID, e.target.value);
      }}
      key={item.row.sID}
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 375,
            "&::-webkit-scrollbar": {
              width: 7,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
              backgroundColor: "#0c1e3522",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(58 58 60) ",
              border: `1px solid rgba(48, 48, 48, 0)`,
              borderRadius: "10px",
            },
          },
        },
      }}
    >
      {optOrder.map((Item, inx) => (
        <MenuItem key={Item.value} value={Item.value}>
          {Item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ChangeOrder;
