import React from 'react'
import Swal, { SweetAlertIcon } from 'sweetalert2'
import {AlertTitle} from 'config/AlertMsgConfig'
import "./sweetAlert.css";

export const AlertIcon = {
    info: "info" as SweetAlertIcon,
    success: "success" as SweetAlertIcon,
    error: "error" as SweetAlertIcon,
    warning: "warning" as SweetAlertIcon,
    question: "question" as SweetAlertIcon,
};

export const AlertButtonText = {
    OK: "ok",
    Cancel: "cancel",
    Close: "close",
    Yes: "yes",
    No: "no",
    Confirm: "confirm"
};


const SwAlert_Title = (sTitle: string) => {
    return "<strong>" + sTitle + "</strong>";
};

export const SwAlert = {
    Common: (sTitle: string | undefined, sMessage: string, fnOK?: Function) => {
        Swal.fire({
            title: SwAlert_Title(!sTitle ? "" : sTitle + ""),
            html: sMessage,
            confirmButtonText: AlertButtonText.Close,
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (result.value) {
                if (fnOK) fnOK();
            }
        });
    },
    Info: (sTitle: string | undefined, sMessage: string, fnOK?: Function) => {
        Swal.fire({
            title: SwAlert_Title(!sTitle ? AlertTitle.Success : sTitle + ""),
            html: sMessage,
            icon: AlertIcon.info,
            confirmButtonText: AlertButtonText.Close,
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (result.value) {
                if (fnOK) {
                    fnOK();
                } else {
                    Swal.close();
                }
            }
        });
    },
    Success: (sTitle: string | undefined, sMessage: string, fnOK?: Function) => {
        Swal.fire({
            title: SwAlert_Title(!sTitle ? AlertTitle.Success : sTitle + ""),
            html: sMessage,
            icon: AlertIcon.success,
            confirmButtonText: AlertButtonText.Close,
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (result.value) {
                if (fnOK) {
                    fnOK();
                } else {
                    Swal.close();
                }
            }
        });
    },
    Error: (sTitle: string | undefined, sMessage: string, fnOK?: Function) => {
        Swal.fire({
            icon: AlertIcon.error,
            title: SwAlert_Title(!sTitle ? AlertTitle.Error : sTitle + ""),
            html: sMessage,
            confirmButtonText: AlertButtonText.Close,
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (result.value) {
                if (fnOK) {
                    fnOK();
                } else {
                    Swal.close();
                }
            }
        });
    },
    Warning: (sTitle: string | undefined, sMessage: string, fnOK?: Function) => {
        const customAlertHTML = `
        <h2 class="MuiTypography-root MuiTypography-h6 MuiDialogTitle-root my-swal2-head" id=":r0:">
        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="HelpOutlineIcon" style="font-size: 10rem; font-weight: bold; color: rgb(255, 255, 255);">
        <circle cx="12" cy="12" r="8" stroke="currentColor" fill="none" stroke-width="2"></circle>
        <text x="12" y="17" text-anchor="middle" font-size="14" fill="currentColor" font-family="kanit" font-weight="bold">!</text>
        </svg>
        </h2>
        <div class="MuiDialogContent-root css-fj98du"><p class="MuiTypography-root MuiDialogContentText-root MuiTypography-body1 MuiDialogContentText-root css-webx17" style="font-size: 1.5rem; font-weight: bold; color: rgb(0, 0, 0);">${AlertTitle.Warning}</p><p class="MuiTypography-root MuiDialogContentText-root MuiTypography-body1 MuiDialogContentText-root css-webx17" style="color: rgb(0, 0, 0); white-space: pre-wrap;">${sMessage}</p></div>
        <div class="MuiDialogActions-root MuiDialogActions-spacing css-1lps6fz">
        <div style="display: flex; justify-content: center;">
            <button id="confirmButton" class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall css-confirmButton" tabindex="0" type="button" style="background: rgb(40, 167, 69); color: white; border-radius: 20px;"><span class="MuiButton-startIcon MuiButton-iconSizeSmall css-u0g51i">
                </span>OK<span class="MuiTouchRipple-root css-w0pj6f"></span>
            </button>
        <div>
        </div>
        `;


        Swal.fire({
            //icon: AlertIcon.warning,
            /////title: SwAlert_Title(!sTitle ? AlertTitle.Warning : sTitle + ""),
            html: customAlertHTML,
            showConfirmButton: false,
            //confirmButtonText: AlertButtonText.Close,
            allowOutsideClick: false,
            allowEscapeKey: false,
            width:"310px",
            customClass: {
                htmlContainer:'my-swal2-container',
                popup: 'my-swal2-popup',
                title:'my-swal2-title',
                confirmButton:'my-swal2-cofirmbutton',
            },
            showClass: {
                popup: 'custom-show-animation',
                icon: 'custom-icon-animation',
            },
            didOpen: () => {
                const confirmButton = Swal.getPopup().querySelector('#confirmButton');
                confirmButton.addEventListener('click', () => {
                    if (fnOK) {
                        fnOK();
                        Swal.close();
                    } else {
                        Swal.close();
                    }
                });
              },
            
        }).then((result) => {
            if (result.value) {
                if (fnOK) {
                    fnOK();
                } else {
                    Swal.close();
                }
            }
        });
    },
    Confirm: (
        sTitle: string | undefined,
        sMessage: string,
        fnYes?: Function,
        fnNo?: Function
    ) => {
        Swal.fire({
            title: SwAlert_Title(!sTitle ? AlertTitle.Confirm : sTitle + ""),
            html: sMessage,
            icon: AlertIcon.question,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: AlertButtonText.OK,
            cancelButtonText: AlertButtonText.Cancel,
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: () => {
                return new Promise(() => {
                    //resolve, reject
                    Swal.showLoading(Swal.getDenyButton());

                    if (fnYes) {
                        fnYes();
                        Swal.close();
                    } else {
                        Swal.close();
                    }
                });
            },
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel) {
                if (fnNo) {
                    fnNo();
                    Swal.close();
                } else {
                    Swal.close();
                }
            }
        });
    },
    ConfirmYN: (
        sTitle: string | undefined,
        sMessage: string,
        fnYes?: Function,
        fnNo?: Function
    ) => {
        Swal.fire({
            title: SwAlert_Title(!sTitle ? AlertTitle.Confirm : sTitle + ""),
            html: sMessage,
            icon: AlertIcon.question,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: AlertButtonText.Yes,
            cancelButtonText: AlertButtonText.No,
        }).then((result) => {
            if (result.value) {
                if (fnYes) fnYes();
                else Swal.close();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                if (fnNo) fnNo();
                else Swal.close();
            }
        });
    },
    HtmlWarning: (sTitle: string | undefined, sMessage: string, fnOK?: Function) => {
        Swal.fire({
            icon: AlertIcon.warning,
            title: SwAlert_Title(!sTitle ? AlertTitle.Warning : sTitle + ""),
            html: sMessage,
            confirmButtonText: AlertButtonText.Close,
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (result.value) {
                if (fnOK) {
                    fnOK();
                } else {
                    Swal.close();
                }
            }
        });
    },
};