import React, { useState, useEffect } from "react";
import {
  Box,
  CSSObject,
  List,
  ListItemIcon,
  styled,
  Theme,
  Typography,
  Divider,
  ListItem,
  ListItemText,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { Link, useLocation } from "react-router-dom";
import "./LayoutSide.css";
import ReactIcons from "STM/components/reactIcons";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import { AxiosGet } from "STM/utilities/ST_Axios";


const drawerWidthMenu = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidthMenu,
  transition:
    "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  //   overflowX: "hidden",
  boxShadow: "none",
  backgroundColor: "transparent",
  left: 0,
  marginBottom: 0,
  transform: "translateX(0px)",
  height: "calc(100vh - 2rem)",
  margin: "1rem",
  borderRadius: "1rem",
  backdropFilter: "saturate(200%) blur(1.875rem)",
  border: "none",
  "&::-webkit-scrollbar": {
    width: 5,
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: `inset 0 0 5px #c1c1c1`,
    borderRadius: 5,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#c1c1c1cc",
    borderRadius: 5,
    // outline: `1px solid slategrey`,
  },
});

const closedMixin = (theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  overflowX: "hidden",
  backgroundColor: "transparent",
  // borderRight: "1px solid #31374a",
  border: "none",
  "&::-webkit-scrollbar": {
    width: 5,
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: `inset 0 0 5px #c1c1c1`,
    borderRadius: 5,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#c1c1c1",
    borderRadius: 5,
    // outline: `1px solid slategrey`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidthMenu,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#f7f7f7",
  border: "none",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  ...(open && {
    // ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const LayoutSide = (props) => {
  ////let i18next = process.env.REACT_APP_JWT_i18next;
  const [open, setOpen] = useState(true);
  ////const [sHeadMenu, setHeadMenu] = useState("");
  const [sCurrentMenu, setCurrentMenu] = useState("");
  const [sIconMenu, setIconMenu] = useState("RiSettings5Fill");
  ////const [openSub, setOpenSub] = React.useState(-1);
  const location = useLocation();
  ////const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  ////const [openMenu, setOpenMenu] = useState<any>(Boolean(anchorEl));
  ////const [oCountryDropdown, setoCountryDropdown] = useState([] as any);
  ////const [sActiveFlag, setsActiveFlag] = React.useState<string>();

  const [lstMenu, setLstMenu] = useState([]);
  const [lstBreadcrumbs, setLstBreadcrumbs] = useState([]);

  // // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  // //   setAnchorEl(event.currentTarget);
  // //   setOpenMenu(!openMenu);
  // // };
  // // const handleClose = () => {
  // //   setAnchorEl(null);
  // //   setOpenMenu(false);
  // // };

  // // const OnGetFlagData = () => {
  // //   let param = {};
  // //   AxiosGet("LanguageTranslation/GetDropdownCountryFlag", param, (res) => {
  // //     console.log("oCountryDropdown ", res);
  // //     setoCountryDropdown(res);
  // //   });
  // // };

  // useEffect(() => {
  //   OnGetFlagData();
  //   setsActiveFlag(cookies.get(i18next));
  // }, []);

  const OnGetMenu = () => {
    let param = {
      sID: "",
      isFront: false,
      sLang: "en-US",
    };
    AxiosGet("User/GetMenuByUser", param, (res) => {
      setLstMenu(res);
      console.log("res", res);
    });
  };

  // // const setActiveMenu = () => {
  // //   if (lstMenu.length > 0) {
  // //     let objMenu = lstMenu.filter((f) =>
  // //       f.lstSubMenu.some((s) => location.pathname.includes(s.sLink))
  // //     );
  // //     let objCurrentMenu = objMenu.map((m) =>
  // //       m.lstSubMenu.filter((s) => location.pathname.includes(s.sLink))
  // //     );
  // //     setHeadMenu(objMenu.map((m) => m.sMenuName)[0]);
  // //     setCurrentMenu(objCurrentMenu[0].map((m) => m.sMenuName)[0]);
  // //     setIconMenu(objCurrentMenu[0].map((m) => m.sIcon)[0]);
  // //   }
  // // };

  useEffect(() => {
    let param = {
      sID: "",
      isFront: false,
      sLang: "en-US",
      sPath: location.pathname,
    };

    AxiosGet("User/GetBreadCrumb", param, (res) => {
      setLstBreadcrumbs(res);
      let currentMenu = res
        .filter((m) => location.pathname.includes(m.sLink))
        .map((obj) => obj)[0];
      setCurrentMenu(currentMenu.sMenuName);
      setIconMenu(currentMenu.sIcon);
    });


    //// setActiveMenu();
  }, [location.pathname]);

  useEffect(() => {
    OnGetMenu();
  }, []);
  ////const {logout} = STMService();
  // // const myLogout = ()=>{
  // //   logout(()=>{
  // //     const front = combineFrontUrl("");
  // //     window.location.href = front;
  // //   });
  // // };

  const urlIconSystem = `${process.env.REACT_APP_Front_URL}assets/STM/images/IconSystem.png`;
  return (
    <>
      <Box className="DrawerHead" sx={{ display: "flex" }}>
        <Drawer variant="permanent" open={open}>
          <Box className="head-box-menu">
            <Link to={"#"} className="main-system-link">
              <img
                src={urlIconSystem}
                alt=""
                className="system-logo"
              />
              <Typography
                className="system-name-side"
                sx={{ fontSize: ".9rem", fontWeight: 700 }}
              >
                PTT Global LNG
              </Typography>
            </Link>
          </Box>
          <Divider className="system-divider" />
          <List
            sx={{
              p: 0,
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: 5,
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 5px #c1c1c1`,
                borderRadius: 5,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#c1c1c1cc",
                borderRadius: 5,
                // outline: `1px solid slategrey`,
              },
            }}
          >
            {lstMenu.map((menu, i) => {
              return (
                <>
                  <span className="title-menu-side">{menu.sMenuName}</span>
                  {menu.lstSubMenu ? (
                    <>
                      {menu.lstSubMenu.map((submenu, index) => {
                        return (
                          <ListItem sx={{ p: 0 }} key={submenu.sID}>
                            <Link
                              to={submenu.sLink}
                              className={
                                location.pathname && location.pathname.includes(submenu.sLink)
                                  ? "menu-box menu-box-active"
                                  : "menu-box menu-box-inactive"
                              }
                              // "menu-box menu-box-inactive"
                            >
                              <ListItemIcon
                                className={
                                  location.pathname &&
                                  location.pathname.includes(submenu.sLink)
                                    ? "menu-icon icon-active"
                                    : "menu-icon icon-inactive"
                                }
                              >
                                <ReactIcons
                                  IconName={submenu.sIcon}
                                  className={""}
                                />
                              </ListItemIcon>

                              <ListItemText
                                sx={{ mt: 0, mb: 0 }}
                                className="menu-text"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "0.875rem",
                                    fontWeight: 400,
                                    lineHeight: 1.5,
                                  }}
                                >
                                  {submenu.sMenuName}
                                </Typography>
                              </ListItemText>
                            </Link>
                          </ListItem>
                        );
                      })}
                    </>
                  ) : null}
                </>
              );
            })}
          </List>
        </Drawer>
        <Box
          sx={{
            // padding: "2 0 2 0",
            pl: 3,
            pr: 3,
            pt: 1.5,
            pb: 3,
            display: "flex",
            flex: 1,
            minHeight: "100vh",
            borderRadius: "5px",
            bgcolor: "#f5f5f5cc",
            flexDirection: "column",
            // backgroundColor:"##f4f4f4"
            transition:
              "transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin-right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
          }}
        >
          {/* header-sticky */}
          <Box className="header-page header-sticky">
            <Box className="tabs-header-page">
              <Box className="breadcrumbs-page">
                <Box
                  sx={{
                    opacity: 1,
                    background: "transparent",
                    color: "#344767",
                    mr: "32px",
                  }}
                >
                  <Box
                    sx={{
                      m: 0,
                      fontSize: "1.25rem",
                      fontWeight: 400,
                      lineHeight: 1.625,
                      letterSpacing: "0.00938em",
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    <Breadcrumbs className="custom-breadcrumbs">
                      <Link to={"#"} className="custom-breadcrumbs-list-first">
                        <span className="breadcrumbs-icons">
                          <ReactIcons
                            IconName={sIconMenu}
                            className={
                              "material-icons-round breadcrumbs-icon-dynamic"
                            }
                          />
                        </span>
                      </Link>
                      {lstBreadcrumbs?.map((b, i) => {
                        const keyLink = i;
                        return (
                          <Link key={keyLink}
                            to={b.sLink}
                            className={
                              b.nLevel === 1
                                ? `custom-breadcrumbs-list default`
                                : `custom-breadcrumbs-list current-page-bc`
                            }
                          >
                            {b.sMenuName}
                          </Link>
                        );
                      })}
                    </Breadcrumbs>
                  </Box>
                  <Typography className="current-menu-name">
                    {sCurrentMenu}
                  </Typography>
                </Box>
              </Box>

            </Box>
          </Box>
          {props.children}
        </Box>
      </Box>
    </>
  );
};

export default LayoutSide;
