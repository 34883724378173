import React, { useEffect } from "react";
import { DialogPermissionProp } from "./DialogPermission";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CloseIcon from "@mui/icons-material/Close";

const DialogPermission = (props: DialogPermissionProp) => {
  const { onClose, open, lstPerm } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id="alert-dialog-title">
        <Typography
          sx={{ color: "rgb(52, 71, 103)" }}
          className="Header-title-form"
        >
          {"Menu Permission"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: "500px" }}>
        <List sx={{ p: 0 }}>
          {lstPerm.map((f) => {
            return (
              <ListItem sx={{ p: 0 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ListItemText className="menu-text">
                    <Typography
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: f.nMenuHeadID ? 400 : 700,
                        lineHeight: 0,
                        color: "rgb(52, 71, 103)",
                      }}
                    >
                      {f.nMenuHeadID && (
                        <FiberManualRecordIcon
                          sx={{ fontSize: "0.5rem", ml: 0.5, mr: 0.5 }}
                        />
                      )}
                      {f.sMenuName}
                    </Typography>
                  </ListItemText>
                    <ListItemText sx={{ textAlign: "right" }}>
                      <Typography
                        sx={{
                          m: 0,
                          fontSize: "0.875rem",
                          lineHeight: 1.5,
                          color: "rgb(131, 146, 171)",
                          display: "inline-block",
                          width: "max-content",
                        }}
                      >
                        {!f.isHasSub && f.isManagePRM && f.nMenuHeadID && (
                          <span
                            style={{
                              display: "inline-flex",
                              position: "relative",
                              verticalAlign: "middle",
                              flexShrink: 0,
                            }}
                          >
                            <span
                              className="custom-status"
                              style={
                                f.oDisable?.IsPermission
                                  ? {
                                      background:
                                        "linear-gradient(310deg, rgb(98, 117, 148), rgb(168, 184, 216))",
                                    }
                                  : f.oReadOnly?.IsPermission
                                  ? {
                                      background:
                                        "linear-gradient(310deg, #f4a21a, #ffd694)",
                                    }
                                  : f.oEnable?.IsPermission
                                  ? {
                                      background:
                                        "linear-gradient(310deg, rgb(45, 206, 137), rgb(45, 206, 204))",
                                    }
                                  : {
                                      background:
                                        "linear-gradient(310deg, rgb(98, 117, 148), rgb(168, 184, 216))",
                                    }
                              }
                            >
                              {f.oDisable?.IsPermission
                                ? "Disable"
                                : f.oReadOnly?.IsPermission
                                ? "Read Only"
                                : f.oEnable?.IsPermission
                                ? "Enable"
                                : "Disable"}
                            </span>
                          </span>
                        )}
                      </Typography>
                    </ListItemText>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default DialogPermission;
