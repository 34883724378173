/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Tooltip from "@mui/material/Tooltip";
import { SwAlert } from "STM/components/Alert";
import { AlertMsg, AlertTitle } from "config/AlertMsgConfig";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import FilePopup from "../PopUp/FilePopup";
import { Extension } from "STM/utilities/ST_Function";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageIcon from "@mui/icons-material/Image";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";
import { BsFillFileEarmarkWordFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { SiMicrosoftpowerpoint } from "react-icons/si";

const DisplayTableFile = (props) => {
    const classes = useStyles();

    const { SendCropImage, IsopenPopUp, setIsopenPopUp } = props;

    const [arrFile, setarrFile] = useState([] as any)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [oItemClick, setoItemClick] = useState({} as any)

    useEffect(() => {
        if (props.arrFile) {
            setarrFile(props.arrFile)
        }
    }, [props.arrFile])

    useEffect(() => {
        if (props.nRowperpageTable) { setRowsPerPage(props.nRowperpageTable) }
    }, [props.nRowperpageTable])

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrFile.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const ClosePopUp = () => {
        setoItemClick({})
        setIsopenPopUp(false);
    };
    const OpenPopUp = () => {
        setIsopenPopUp(true);
    };

    const onDownload = (itemClick) => {
        let a = document.createElement("a");
        a.href = itemClick.sFileLink + "";
        a.download = itemClick.sFileName;
        a.click();
    };

    const onClickFile = (itemClick) => {
        setoItemClick(itemClick)
        props.onOpenFile && props.onOpenFile(itemClick.nFile_ID);
        let isCheck = Extension.Image.indexOf(itemClick.sFileType) > -1 || Extension.Video.indexOf(itemClick.sFileType) > -1 || Extension.PDF.indexOf(itemClick.sFileType) > -1
        isCheck ? OpenPopUp() : onDownload(itemClick)
    };

    return (
        <>
            <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item xs={12}>
                    <TableContainer component={Paper} elevation={1} style={{ borderRadius: '16px' }}>
                        <Table sx={{ minWidth: 500 }}>
                            <TableHead>
                                <TableRow style={{ background: "linear-gradient(135deg,#3a8ffe 0,#9658fe 100%)" }}>
                                    <StyledTableCell align="center" style={{ width: '5%', padding: '12px 16px' }}>No</StyledTableCell>
                                    <StyledTableCell align="center" style={{ width: '70%', padding: '12px 16px' }}>File name</StyledTableCell>
                                    <StyledTableCell align="center" style={{ width: '10%', padding: '12px 16px' }}>Image</StyledTableCell>
                                    <StyledTableCell align="center" style={{ width: '10%', padding: '12px 16px' }}>Progress</StyledTableCell>
                                    <StyledTableCell align="center" style={{ width: '5%', padding: '12px 16px' }}></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {
                                arrFile.length > 0 ?
                                    <TableBody>
                                        {(rowsPerPage > 0
                                            ? arrFile.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : arrFile
                                        ).map((row, index) => {
                                            let nNo = (page * rowsPerPage) + (index + 1);
                                            return (
                                                <TableRow key={nNo}>
                                                    <StyledTableCell align="center" component="th" scope="row" style={{ padding: '4px 16px' }}>
                                                        {nNo}
                                                    </StyledTableCell>
                                                    <StyledTableCell className="underline-on-hover" align="left" onClick={() => { onClickFile(row) }} style={{ padding: '4px 16px' }}>
                                                        {row.sFileName}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row" style={{ padding: '4px 16px' }}>
                                                        <>
                                                            {Extension.Image.indexOf(row.sFileType.toLowerCase()) > -1 ? (
                                                                <Fragment>
                                                                    {row.sCropFileLink ? (
                                                                        <img
                                                                            src={row.sCropFileLink.search("http") > -1 ? row.sCropFileLink : process.env.REACT_APP_API_URL + row.sCropFileLink || ""}
                                                                            alt=""
                                                                            width={30}
                                                                            height={30}
                                                                            style={{ borderRadius: '6px', objectFit: 'cover' }}
                                                                        />
                                                                    ) : (
                                                                        <Tooltip title="">
                                                                            <IconButton
                                                                                className={classes.WordColor}
                                                                                size="small"
                                                                                onClick={() => { onClickFile(row) }}
                                                                            >
                                                                                <ImageIcon className={classes.IConColor} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                </Fragment>
                                                            ) : Extension.Video.indexOf(row.sFileType.toLowerCase()) > -1 ? (
                                                                <Tooltip title="">
                                                                    <IconButton
                                                                        className={classes.WordColor}
                                                                        size="small"
                                                                        onClick={() => { onClickFile(row) }}
                                                                    >
                                                                        <VideoLibraryIcon className={classes.IConColor} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : Extension.PDF.indexOf(row.sFileType.toLowerCase()) > -1 ? (
                                                                <Tooltip title="">
                                                                    <IconButton
                                                                        className={classes.PDFColor}
                                                                        size="small"
                                                                        onClick={() => { onClickFile(row) }}
                                                                    >
                                                                        <PictureAsPdfIcon className={classes.IConColor} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : Extension.Word.indexOf(row.sFileType.toLowerCase()) > -1 ? (
                                                                <Tooltip title="">
                                                                    <IconButton
                                                                        className={classes.WordColor}
                                                                        size="small"
                                                                        onClick={() => { onClickFile(row) }}
                                                                    >
                                                                        <BsFillFileEarmarkWordFill className={classes.IConColor} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : Extension.Excel.indexOf(row.sFileType.toLowerCase()) > -1 ? (
                                                                <Tooltip title="">
                                                                    <IconButton
                                                                        className={classes.ExcelColor}
                                                                        size="small"
                                                                        onClick={() => { onClickFile(row) }}
                                                                    >
                                                                        <SiMicrosoftexcel className={classes.IConColor} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : Extension.PowerPoint.indexOf(row.sFileType.toLowerCase()) > -1 ? (
                                                                <Tooltip title="">
                                                                    <IconButton
                                                                        className={classes.PowerPointColor}
                                                                        size="small"
                                                                        onClick={() => { onClickFile(row) }}
                                                                    >
                                                                        <SiMicrosoftpowerpoint className={classes.IConColor} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : Extension.txt.indexOf(row.sFileType.toLowerCase()) > -1 ? (
                                                                <Tooltip title="">
                                                                    <IconButton
                                                                        className={classes.WordColor}
                                                                        size="small"
                                                                        onClick={() => { onClickFile(row) }}
                                                                    >
                                                                        <DownloadIcon className={classes.IConColor} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="">
                                                                    <IconButton
                                                                        className={classes.WordColor}
                                                                        size="small"
                                                                        onClick={() => { onClickFile(row) }}
                                                                    >
                                                                        <DownloadIcon className={classes.IConColor} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" style={{ padding: '4px 16px' }}>
                                                        {
                                                            parseInt(row.sProgress) === 100 ?
                                                                <>
                                                                    <CheckCircleIcon className="icon-pass" />
                                                                </>
                                                                :
                                                                <LinearProgressWithLabel value={parseInt(row.sProgress) || 0} />
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" style={{ padding: '4px 16px' }}>
                                                        {row.IsComplete && !props.disabled && !props.IsHiddenUploadBox ? (
                                                            <Tooltip title="ลบ" placement="top">
                                                                <IconButton
                                                                    // className={classes.DangerColor}
                                                                    className={"btn-icon-delete"}
                                                                    size="small"
                                                                    style={{
                                                                        padding: '2px'
                                                                    }}
                                                                    onClick={() => { props.onDelete(row.nFile_ID); }}
                                                                >
                                                                    <DeleteForeverIcon className={"icon-delete"} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </StyledTableCell>
                                                </TableRow>
                                            )
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 36.8 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    :
                                    <StyledTableRow key={"r-no-data"} >
                                        <StyledTableCell align="center" colSpan={5}>No data</StyledTableCell>
                                    </StyledTableRow>
                            }
                            {
                                arrFile.length > 0 ?
                                    <TableFooter style={{ height: '37px' }}>
                                        <StyledTableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                colSpan={5}
                                                count={arrFile.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </StyledTableRow>
                                    </TableFooter>
                                    :
                                    null
                            }
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <FilePopup
                file={{
                    sFileType: oItemClick.sFileType,
                    sFileLink: oItemClick.sFileLink,
                    sFileName: oItemClick.sFileName,
                    sCropFileLink: oItemClick.sCropFileLink,
                    sCropPath: oItemClick.sCropPath,
                    sUrl: oItemClick.sUrl,
                    nFile_ID: oItemClick.nFile_ID,
                }}
                IsCrop={props.IsCrop}
                IsopenPopUp={IsopenPopUp}
                ClosePopUp={ClosePopUp}
                SendCropImage={SendCropImage}
                setStartVideoOn={props.setStartVideoOn}
                nStartVideoOn={props.nStartVideoOn}
                CannotSkipForward={props.CannotSkipForward}
                onVideoEnd={props.onVideoEnd}
                onDelete={props.onDelete}
            />
        </>
    );
};


function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    WordColor: {
        color: "#fff !important",
        backgroundColor: "#2372ba !important",
        borderColor: "#2372ba !important",
        margin: theme.spacing(1),
        "&:hover": {
            backgroundColor: "rgb(56, 151, 234) !important",
        },
    },
    ExcelColor: {
        color: "#fff !important",
        backgroundColor: "#14a73c !important",
        borderColor: "#14a73c !important",
        margin: theme.spacing(1),
        "&:hover": {
            backgroundColor: "rgb(66, 201, 127) !important",
        },
    },
    PDFColor: {
        color: "#fff !important",
        backgroundColor: "#ff0000 !important",
        borderColor: "#ff0000 !important",
        margin: theme.spacing(1),
        "&:hover": {
            backgroundColor: "rgb(255, 79, 79) !important",
        },
    },
    PowerPointColor: {
        color: "#fff !important",
        backgroundColor: "#f26522 !important",
        borderColor: "#f26522 !important",
        margin: theme.spacing(1),
        "&:hover": {
            backgroundColor: "rgb(255, 79, 79) !important",
        },
    },

    FileItem: {
        margin: "0 !important",
        animationDuration: ".6s !important",
    },
    ColumnThumbnail: {
        paddingLeft: "10px",
    },
    ColumnTitle: {
        flex: "1 !important",
        paddingLeft: "16px !important",
        paddingRight: "16px !important",
        color: "#74809d !important",
        overflow: "hidden !important",
        backgroundColor: "#fffff !important",
        cursor: "pointer !important",
    },
    ColumnAction: {
        margin: "0 16px",
    },
    Title: {
        fontWeight: 600,
    },
    DangerColor: {
        color: "#fff !important",
        backgroundColor: "#ed3847 !important",
        borderColor: "#ed3847 !important",
        margin: theme.spacing(1),
        padding: "2px",
        "&:hover": {
            backgroundColor: "rgb(220, 53, 69) !important",
        },
    },
    IConColor: {
        fill: "currentColor",
        width: "1em",
        height: "1em",
        display: "inline-block",
        fontSize: "1.5rem",
        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
}));

export default DisplayTableFile;

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
