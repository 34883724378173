import React, { useEffect } from "react";
import { useState, forwardRef, useRef, Fragment } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { RadioFromProps, RadioNoFromProps, } from "./RadioProps";
import { SxProps } from "@mui/material";
import { FormLabel, RadioGroup, Radio, FormControlLabel, FormControl, FormHelperText, InputLabel, TextField} from "@mui/material";

export const RadioForm = forwardRef((props: RadioFromProps, ref) => {
    const {
        name,
        options,
        label,
        required,
        onChange,
        disabled,
        row,
        isNoLabel,
        styleRadio,
        isshowTextCont = true,
    } = props;
    
    const {
        control,
        register,
        getValues,
        setValue,
    } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, error }, }) => (
                <>
                    <FormLabel
                        component="legend"
                        style={{display: isNoLabel ? "none" : 'block'}}
                        sx={{
                            color: error && error[name] ? "red !important" : "",
                            fontFamily : `"Kanit", "Helvetica", "Kanit", sans-serif`,
                            ".MuiFormLabel-root": {
                            asterisk: {
                                color: '#db3131',
                                '&$error': {
                                color: '#db3131'
                                },
                            }
                            },
                        }}
                    >{label} <span style={{ color: '#FF0000', fontSize: '15px', marginLeft: '-4px' }}>{required === true ? " *" : ""}</span>
                    </FormLabel>
                    <RadioGroup
                        id={name}
                        {...props}
                        key={name}
                        // inputRef={ref}
                        {...register(name)}
                        name={name}
                        value={String(value || '')}
                        onChange={(e) => {
                            setValue(name, e.target.value, { shouldValidate: true });
                            onChange(e);
                            props.onChange && props.onChange(e.target.value);
                        }}
                        onBlur={(event) => {
                            props.onBlur && props.onBlur(event);
                        }}
                        row={props.row ? props.row : false}
                    >
                        {options.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={String(option.value)}
                                style={{ color: option.color ? option.color : '#000000', paddingRight: '2%' }}
                                // style={props.style}
                                control={<Radio size="small" />}
                                label={option.label}
                                checked={String(value) !== "" ? (String(value) === String(option.value) ? true : false) : false}
                                disabled={disabled ? true : (option.disabled == true ? true : false)}
                                labelPlacement={"end"}
                            />
                        ))}
                    </RadioGroup>
                    {error ? (
                        <FormHelperText sx={{ color: "red" }}>
                            {error.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
});

export default RadioForm;

export const RadioNoForm = forwardRef((props: RadioNoFromProps, ref) => {
    const {
        name,
        options,
        label,
        value,
        onChange,
        onBlur,
        disabled,
        row,
        isNoLabel,
        styleRadio,
        isshowTextCont = true,
    } = props;

    return (
        <Fragment>
            <FormLabel 
                style={{display: isNoLabel ? "none" : 'block'}}
                component="legend"
            >
                {props.label}
            </FormLabel>
            <RadioGroup
                {...props}
                key={props.name}
                // inputRef={ref}
                name={name}
                id={name}
                value={String(value || false)}
                onChange={(e) => {
                    //SetValue(name, e.target.value, { shouldValidate: true });
                    props.onChange && props.onChange(e.target.value);
                }}
                onBlur={(event) => {
                    props.onBlur && props.onBlur(event);
                }}
                row={props.row ? props.row : false}
            >
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        value={String(option.value)}
                        style={{ color: option.color ? option.color : '#000000', paddingRight: '2%' }}
                        // style={props.styleRadio}
                        control={<Radio size="small" />}
                        label={option.label}
                        checked={String(props.value) !== "" ? (String(props.value) === String(option.value) ? true : false) : false}
                        disabled={disabled ? true : (option.disabled == true ? true : false)}
                        labelPlacement={"end"}
                    />
                ))}
            </RadioGroup>
        </Fragment>
    );
});