
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import AuthenSelectors from "storeIntarnet/selectors/BlockUISelectoe";
export default function BlockUI(props) {
  const OpenBackDrop = useSelector(AuthenSelectors.IsOpen);
  const HandleClose = useSelector(AuthenSelectors.HandleClose);

  return (
    <Backdrop
      sx={{
        // color: "#fff",
        // zIndex: (theme) => 9999,
        // ...(OpenBackDrop ? {} : { display: "none" }),
        color: "#fff",
          zIndex: 9999,
          cursor: OpenBackDrop ? "progress" : "",
          opacity: OpenBackDrop ? "1 !important" : "0 !important",
          visibility : OpenBackDrop ? "visible !important" : "hidden !important"
      }} //theme.zIndex.drawer + 1,
      open={OpenBackDrop}
      onClick={HandleClose}
    >
      <div className="block-ui-container">
          {/* Loading 2 */}
          <div className="Main-Box">
            <div className="container-block-ui">
              <div className="📦"></div>
              <div className="📦"></div>
              <div className="📦"></div>
              <div className="📦"></div>
              <div className="📦"></div>
            </div>
            <div className="loading-process">Loading in progress...</div>
          </div>
        </div>
    </Backdrop>
  );
}
