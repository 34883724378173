import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {
    Search,
    DeleteForever,
    Edit,
    Image

} from "@mui/icons-material";
import Fab from "@mui/material/Fab";
import CheckIcon from '@mui/icons-material/Check';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ViewCozyIcon from '@mui/icons-material/ViewCozy';
import LockResetIcon from '@mui/icons-material/LockReset';
import "./button.css"
import { Box } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import PublishIcon from '@mui/icons-material/Publish';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddIcon from '@mui/icons-material/Add';
import { TiPin } from "react-icons/ti";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


import LogoutIcon from '@mui/icons-material/Logout';
export const BtnSearch = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "ค้นหา",
    isCircleWithOutText = false,
    Color = "#169bd5"
}) => {
    return (
        <Tooltip title={txt}>
            {!isCircleWithOutText ? (
                <Button

                    variant="contained"
                    style={{ backgroundColor: Color, zIndex: 1 }}
                    onClick={onClick}
                    disabled={isDisabled ? true : false}
                    hidden={isHidden ? true : false}
                >
                    <Search style={{ color: "#ffffff" }} />
                    {txt}
                </Button>
            ) : (
                <Fab
                    sx={{ width: 40, height: 40, zIndex: 1 }}
                    color="primary"
                    onClick={onClick}
                    aria-label="search"
                >
                    <Search />
                </Fab>
            )}
        </Tooltip>
    );
};

export const BtnSearchCustom = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "Search",
    isCircleWithOutText = false,
    Color = "#169bd5"
}) => {
    return (
        <Tooltip title={txt}>
            <Box className="btn-custom-filter" onClick={onClick}>
                <Search style={{ color: "#ffffff" }} />
            </Box>
        </Tooltip>
    );
};

export const BtnClearCustom = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "Clear filter",
    isCircleWithOutText = false,
    Color = "#169bd5"
}) => {
    return (
        <Tooltip title={txt}>
            <Box className="btn-clear-filter" onClick={onClick}>
                <HighlightOffIcon style={{ color: "rgb(33, 82, 255)" }} />
            </Box>
        </Tooltip>
    );
};

export const BtnLoading = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "Search",
    Color = "#169bd5",
    isLoading = false
}) => {
    return (
        <Box className={`btn-loading ${isLoading ? "loading" : ""}`} onClick={onClick}>
            <AutorenewIcon className="load-icons" />
            <span>{isLoading ? "Loading" : "Submit"}</span>
        </Box>
    );
};

export const BtnDelete = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "delete",
    isCircleWithOutText = false,
    Color = "#169bd5"

}) => {
    const { t } = useTranslation();
    return (
        <Tooltip title={t(txt)}>
            {!isCircleWithOutText ? (
                <Button
                    startIcon={<DeleteForever />}
                    variant="contained"
                    // color="error"
                    onClick={onClick}
                    disabled={isDisabled ? true : false}
                    hidden={isHidden ? true : false}
                    sx={{ backgroundImage: "linear-gradient(310deg, rgb(234, 6, 6), rgb(255, 102, 124))", color: "#fff" }}
                >
                    {t(txt)}
                </Button>
            ) : (
                <IconButton
                    sx={{ width: 40, height: 40 }}
                    color="error"
                    onClick={onClick}
                    aria-label="delete"
                >
                    <DeleteForever />
                </IconButton>
            )}
        </Tooltip>
    );
};

export const BtnBack = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "กลับ",
    Color = "#dcdbdb"
}) => {
    return (
        <Tooltip title={txt}>
            <Button
                startIcon={<ArrowBackIosRoundedIcon />}
                variant="contained"
                color="warning"
                onClick={onClick}
                disabled={isDisabled ? true : false}
                hidden={isHidden ? true : false}
                style={{ backgroundColor: Color, color: "#000000" }}
            >
                {txt}
            </Button>
        </Tooltip>
    );
};

export const BtnCreate = ({
    isDisabled = false,
    isHidden = false,
    tranformY = "",
    onClick = () => { },
    txt = "สร้างข้อมูล",
    isCircleWithOutText = false,
    sx = {},
}) => {
    const { t } = useTranslation();

    return (
        <Tooltip title={txt}>
            {!isCircleWithOutText ? (
                <Button
                    startIcon={<AddCircleOutlineIcon />}
                    variant="contained"
                    color="inherit"
                    onClick={onClick}
                    disabled={isDisabled ? true : false}
                    hidden={isHidden ? true : false}
                    // style={tranformY !== "" ? { transform: "translateY(" + tranformY + "px)", borderRadius: 50 } : { display: isHidden ? "none" : "", borderRadius: 50 }}
                    sx={{ sx }}
                >
                    {txt === "สร้างข้อมูล" ? t('createButton') : txt}
                </Button>
            ) : (
                <Fab
                    sx={{ width: 40, height: 40 }}
                    color="default"
                    onClick={onClick}
                    aria-label="save"
                >
                    <AddCircleOutlineIcon />
                </Fab>
            )}
        </Tooltip>
    );
};

export const BtnSave = ({
    isDisabled = false,
    isHidden = false,
    tranformY = "",
    onClick = () => { },
    txt = "บันทึกข้อมูล",
    isCircleWithOutText = false,
    sx = {},
}) => {
    return (
        <Tooltip title={txt}>
            {!isCircleWithOutText ? (
                <Button
                    startIcon={<TaskAltOutlinedIcon />}
                    variant="contained"
                    color="success"
                    onClick={onClick}
                    disabled={isDisabled ? true : false}
                    hidden={isHidden ? true : false}
                    // style={tranformY !== "" ? { transform: "translateY(" + tranformY + "px)", borderRadius: 50 } : { display: isHidden ? "none" : "", borderRadius: 50 }}
                    sx={{ sx }}
                >
                    {txt}
                </Button>
            ) : (
                <Fab
                    sx={{ width: 40, height: 40 }}
                    color="primary"
                    onClick={onClick}
                    aria-label="save"
                >
                    <TaskAltOutlinedIcon />
                </Fab>
            )}
        </Tooltip>
    );
};

export const BtnPreview = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "",
    isCircleWithOutText = true,
    sx = {},
}) => {
    return (
        <Tooltip title={"ดู"}>
            {!isCircleWithOutText ? (
                <Button
                    startIcon={<VisibilityIcon />}
                    variant="contained"
                    color="primary"
                    onClick={onClick}
                    disabled={isDisabled ? true : false}
                    hidden={isHidden ? true : false}
                    style={{ display: isHidden ? "none" : "", borderRadius: 25 }}
                    sx={{ sx }}
                >
                    {txt}
                </Button>
            ) : (
                <Fab
                    sx={{ width: 40, height: 40 }}
                    color="primary"
                    onClick={onClick}
                    aria-label="save"
                >
                    <VisibilityIcon />
                </Fab>
            )}
        </Tooltip>
    );
};

export const BtnViewImageOnTable = ({
    IsDisabled = false,
    IsHisabled = false,
    txt = "View Image",
    onClick = () => { },
}) => (
    <Tooltip title={txt} placement={"bottom"}>
        <GridActionsCellItem
            disabled={IsDisabled}
            hidden={IsHisabled}
            icon={<Image />}
            label={txt}
            sx={{
                backgroundColor: "tranparent",
                color: "#4f5bef",
                ":hover": { bgcolor: "#d7d7d7" },
            }}
            onClick={onClick}
            aria-label={txt}
        />
    </Tooltip>
);

export const BtnEditOnTable = ({
    IsDisabled = false,
    IsHisabled = false,
    txt = "edit", //"Edit",
    onClick = () => { },
}) => {
    const { t } = useTranslation();
    return (
        <Tooltip title={t(txt)}>
            <GridActionsCellItem
                disabled={IsDisabled}
                hidden={IsHisabled}
                icon={<Edit />}
                label={t(txt)}
                sx={
                    IsHisabled !== true ? {
                        boxShadow: "0px 3px 3px -2px rgb(175 191 255 / 20%), 0px 3px 4px 0px rgb(175 191 255 / 14%), 0px 1px 8px 0px rgb(175 191 255 / 12%)",
                        backgroundImage: "linear-gradient(310deg, rgb(98, 117, 148), rgb(168, 184, 216))",
                        color: "white",
                        ":hover": { bgcolor: "rgb(20, 23, 39)" },
                        margin: "1%",
                    }
                        :
                        {
                            display: "none",
                            backgroundColor: "#ffc107",
                            color: "white",
                            ":hover": { bgcolor: "#cc9900" },
                            margin: "1%",
                        }
                }
                onClick={onClick}
                aria-label={t(txt)}
            />
        </Tooltip>
    )
}

export const BtnMainLang = ({
    IsDisabled = false,
    IsHisabled = false,
    txt = "แก้ไข", //"Edit",
    onClick = () => { },
}) => (
    <Tooltip title={txt}>
        <GridActionsCellItem
            disabled={IsDisabled}
            hidden={IsHisabled}
            icon={<CheckIcon />}
            label={txt}
            sx={
                IsHisabled !== true ? {
                    boxShadow: "0px 3px 3px -2px rgb(175 191 255 / 20%), 0px 3px 4px 0px rgb(175 191 255 / 14%), 0px 1px 8px 0px rgb(175 191 255 / 12%)",
                    backgroundColor: "#ffc107",
                    color: "white",
                    ":hover": { bgcolor: "#cc9900" },
                    margin: "1%",
                }
                    :
                    {
                        display: "none",
                        backgroundColor: "#ffc107",
                        color: "white",
                        ":hover": { bgcolor: "#cc9900" },
                        margin: "1%",
                    }
            }
            onClick={onClick}
            aria-label={txt}
        />
    </Tooltip>
)

export const BtnPinOnTable = ({
    IsDisabled = false,
    IsHisabled = false,
    txt = "pin", //"Edit",
    onClick = () => { },
    IsPin = false
}) => {
    const { t } = useTranslation();
    return (
        <Tooltip title={IsPin ? t('clickfor') + " " + t('unpin') : t('clickfor') + " " + t('pin')}>
            <GridActionsCellItem
                disabled={IsDisabled}
                hidden={IsHisabled}
                icon={<TiPin />}
                label={IsPin ? t('pin') : t('unpin')}
                sx={
                    IsHisabled !== true ? {
                        boxShadow: "0px 3px 3px -2px rgb(175 191 255 / 20%), 0px 3px 4px 0px rgb(175 191 255 / 14%), 0px 1px 8px 0px rgb(175 191 255 / 12%)",
                        backgroundImage: IsPin ? "linear-gradient(310deg, #2765b6, #14a0d3)" : "linear-gradient(310deg, #b3afaf, #d7d7d7)",
                        color: "white",
                        ":hover": { bgcolor: "rgb(20, 23, 39)" },
                        margin: "1%",
                    }
                        :
                        {
                            display: "none",
                            backgroundColor: "#ffc107",
                            color: "white",
                            ":hover": { bgcolor: "#cc9900" },
                            margin: "1%",
                        }
                }
                onClick={onClick}
                aria-label={IsPin ? t('pin') : t('unpin')}
            />
        </Tooltip>
    )
}

export const BtnDeleteOnTable = ({
    IsDisabled = false,
    IsHisabled = false,
    txt = "delete", //"Edit",
    onClick = () => { },
}) => {
    const { t } = useTranslation();
    return (
        <Tooltip title={t(txt)}>
            <GridActionsCellItem
                disabled={IsDisabled}
                hidden={IsHisabled}
                icon={<DeleteForeverIcon sx={{ fontSize: "1.5rem" }} />}
                label={t(txt)}
                sx={
                    IsHisabled !== true ? {
                        boxShadow: "0px 3px 3px -2px rgb(175 191 255 / 20%), 0px 3px 4px 0px rgb(175 191 255 / 14%), 0px 1px 8px 0px rgb(175 191 255 / 12%)",
                        backgroundImage: "linear-gradient(310deg, rgb(234, 6, 6), rgb(234, 6, 6))",
                        color: "white",
                        ":hover": { bgcolor: "rgb(20, 23, 39)" },
                        // margin: "1%",
                    }
                        :
                        {
                            display: "none",
                            backgroundColor: "#ffc107",
                            color: "white",
                            ":hover": { bgcolor: "#cc9900" },
                            margin: "1%",
                        }
                }
                onClick={onClick}
                aria-label={t(txt)}
            />
        </Tooltip>
    )
}

export const BtnAddOnTable = ({
    IsDisabled = false,
    IsHisabled = false,
    txt = "เพิ่ม", //"Edit",
    onClick = () => { },
}) => (
    <Tooltip title={txt}>
        <GridActionsCellItem
            disabled={IsDisabled}
            hidden={IsHisabled}
            icon={<AddIcon />}
            label={txt}
            sx={
                IsHisabled !== true ? {
                    boxShadow: "0px 3px 3px -2px rgb(175 191 255 / 20%), 0px 3px 4px 0px rgb(175 191 255 / 14%), 0px 1px 8px 0px rgb(175 191 255 / 12%)",
                    backgroundImage: "linear-gradient(310deg, rgb(45, 206, 137), rgb(45, 206, 204))",
                    color: "white",
                    ":hover": { bgcolor: "rgb(20, 23, 39)" },
                    margin: "1%",
                }
                    :
                    {
                        display: "none",
                        backgroundColor: "#ffc107",
                        color: "white",
                        ":hover": { bgcolor: "#cc9900" },
                        margin: "1%",
                    }
            }
            onClick={onClick}
            aria-label={txt}
        />
    </Tooltip>
)

export const BtnPreviewOnTable = ({
    IsDisabled = false,
    IsHisabled = false,
    txt = "ดู",
    onClick = () => { },
}) => (
    <Tooltip title={txt}>
        <GridActionsCellItem
            disabled={IsDisabled}
            hidden={IsHisabled}
            icon={<VisibilityIcon />}
            label={txt}
            sx={
                IsHisabled !== true ? {
                    boxShadow: "0px 3px 3px -2px rgb(175 191 255 / 20%), 0px 3px 4px 0px rgb(175 191 255 / 14%), 0px 1px 8px 0px rgb(175 191 255 / 12%)",
                    backgroundImage: "linear-gradient(310deg, rgb(105 189 224), rgb(99 204 235))",
                    color: "white",
                    ":hover": { bgcolor: "rgb(20, 23, 39)" },
                    margin: "1%",
                }
                    :
                    {
                        display: "none",
                        backgroundColor: "#ffc107",
                        color: "white",
                        ":hover": { bgcolor: "#cc9900" },
                        margin: "1%",
                    }
            }
            onClick={onClick}
            aria-label={txt}
        />
    </Tooltip>
)


export const BtnNext = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "Next",
    sx = {},
}) => {
    return (
        <Button
            sx={{
                position: "relative",
                boxSizing: "border-box",
                verticalAlign: "middle",
                appearance: "none",
                textDecoration: "none",
                letterSpacing: "0.02857em",
                minWidth: "64px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.75rem",
                fontWeight: "700",
                borderRadius: "0.5rem",
                lineHeight: 1.4,
                textAlign: "center",
                textTransform: "uppercase",
                userSelect: "none",
                transition: "all 150ms ease-in 0s",
                minHeight: "2.5rem",
                boxShadow:
                    "rgba(0, 0, 0, 0.09) 0rem 0.1875rem 0.3125rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.3125rem -0.0625rem",
                padding: "0.75rem 1.5rem",
                backgroundImage:
                    "linear-gradient(310deg, rgb(20, 23, 39), rgb(58, 65, 111))",
                color: "#fff",
                backgroundSize: "150%",
                backgroundPositionX: "25%",
            }}
            onClick={onClick}
            disabled={isDisabled}
            hidden={isHidden}
        >
            {txt}
        </Button>
    );
};

export const BtnBackForm = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "back",
    sx = {},
    icon = <ArrowBackIosIcon />
}) => {
    const { t } = useTranslation();
    return (
        <Button
            sx={{
                position: "relative",
                boxSizing: "border-box",
                outline: 0,
                border: 0,
                m: 0,
                cursor: "pointer",
                verticalAlign: "middle",
                appearance: "none",
                textDecoration: "none",
                letterSpacing: "0.02857em",
                minWidth: "64px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.75rem",
                fontWeight: "700",
                borderRadius: "0.5rem",
                lineHeight: 1.4,
                textAlign: "center",
                textTransform: "uppercase",
                userSelect: "none",
                transition: "all 150ms ease-in 0s",
                minHeight: "2.5rem",
                boxShadow:
                    "rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
                padding: "0.75rem 1.5rem",
                backgroundColor: "rgb(233, 236, 239)",
                color: "rgb(58, 65, 111)",
                backgroundSize: "150%",
                backgroundPositionX: "25%",
            }}
            onClick={onClick}
            disabled={isDisabled}
            hidden={isHidden}
            startIcon={icon}
        >
            {t(txt)}
        </Button>
    );
};

export const BtnDanger = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "Reset Password",
    sx = {},
    icon = <LockResetIcon />
}) => {
    return (
        <Button
            sx={{
                position: "relative",
                boxSizing: "border-box",
                outline: 0,
                border: 0,
                m: 0,
                cursor: "pointer",
                verticalAlign: "middle",
                appearance: "none",
                textDecoration: "none",
                letterSpacing: "0.02857em",
                minWidth: "64px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.75rem",
                fontWeight: "700",
                borderRadius: "0.5rem",
                lineHeight: 1.4,
                textAlign: "center",
                textTransform: "uppercase",
                userSelect: "none",
                transition: "all 150ms ease-in 0s",
                minHeight: "2rem",
                boxShadow:
                    "rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
                padding: "0.5rem 2rem",
                backgroundImage: "linear-gradient(310deg, rgb(234, 6, 6), rgb(255, 102, 124))",
                color: "#fff",
                backgroundSize: "150%",
                backgroundPositionX: "25%",
            }}
            onClick={onClick}
            disabled={isDisabled}
            hidden={isHidden}
            startIcon={icon}
        >
            {txt}
        </Button>
    );
};

export const BtnPreviewForm = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = 'preview',
    sx = {},
    icon = <VisibilityIcon />
}) => {
    const { t } = useTranslation();
    return (
        <Button
            sx={{
                position: "relative",
                boxSizing: "border-box",
                outline: 0,
                border: 0,
                m: 0,
                cursor: "pointer",
                verticalAlign: "middle",
                appearance: "none",
                textDecoration: "none",
                letterSpacing: "0.02857em",
                minWidth: "64px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.75rem",
                fontWeight: "700",
                borderRadius: "0.5rem",
                lineHeight: 1.4,
                textAlign: "center",
                textTransform: "uppercase",
                userSelect: "none",
                transition: "all 150ms ease-in 0s",
                minHeight: "2rem",
                boxShadow:
                    "rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
                padding: "0.5rem 2rem",
                backgroundImage: "linear-gradient(310deg, rgb(245, 57, 57), rgb(251, 207, 51))",
                color: "#fff",
                backgroundSize: "150%",
                backgroundPositionX: "25%",
            }}
            onClick={onClick}
            disabled={isDisabled}
            hidden={isHidden}
            startIcon={icon}
        >
            {t(txt)}
        </Button>
    );
};

export const BtnSubmitForm = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "submit",
    sx = {},
    icon = <PublishIcon />
}) => {
    const { t } = useTranslation();
    return (
        <Button
            sx={{
                position: "relative",
                boxSizing: "border-box",
                outline: 0,
                border: 0,
                m: 0,
                cursor: "pointer",
                verticalAlign: "middle",
                appearance: "none",
                textDecoration: "none",
                letterSpacing: "0.02857em",
                minWidth: "64px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.75rem",
                fontWeight: "700",
                borderRadius: "0.5rem",
                lineHeight: 1.4,
                textAlign: "center",
                textTransform: "uppercase",
                userSelect: "none",
                transition: "all 150ms ease-in 0s",
                minHeight: "2rem",
                boxShadow:
                    "rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
                padding: "0.5rem 2rem",
                backgroundImage: "linear-gradient(310deg, rgb(23, 173, 55), rgb(152, 236, 45))",
                color: "#fff",
                backgroundSize: "150%",
                backgroundPositionX: "25%",
            }}
            onClick={onClick}
            disabled={isDisabled}
            hidden={isHidden}
            startIcon={icon}
        >
            {t(txt)}
        </Button>
    );
};

export const BtnCreateForm = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "Create User",
    sx = {},
    icon = <LockResetIcon />
}) => {
    return (
        <Button
            sx={{
                position: "relative",
                boxSizing: "border-box",
                outline: 0,
                border: 0,
                m: 0,
                cursor: "pointer",
                verticalAlign: "middle",
                appearance: "none",
                textDecoration: "none",
                letterSpacing: "0.02857em",
                minWidth: "64px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.75rem",
                fontWeight: "700",
                borderRadius: "0.5rem",
                lineHeight: 1.4,
                textAlign: "center",
                textTransform: "uppercase",
                userSelect: "none",
                transition: "all 150ms ease-in 0s",
                minHeight: "2.5rem",
                boxShadow:
                    "rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
                padding: "0.75rem 1.5rem",
                backgroundImage:
                    "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
                color: "#fff",
                backgroundSize: "150%",
                backgroundPositionX: "25%",
            }}
            onClick={onClick}
            disabled={isDisabled}
            hidden={isHidden}
            startIcon={icon}
        >
            {txt}
        </Button>
    );
};
export const BtnAddWithIcon = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "Create",
    sx = {},
}) => {
    return (
        <Button
            sx={{
                position: "relative",
                boxSizing: "border-box",
                outline: 0,
                border: 0,
                m: 0,
                cursor: "pointer",
                verticalAlign: "middle",
                appearance: "none",
                textDecoration: "none",
                letterSpacing: "0.02857em",
                minWidth: "64px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.75rem",
                fontWeight: "700",
                borderRadius: "0.5rem",
                lineHeight: 1.4,
                textAlign: "center",
                textTransform: "uppercase",
                userSelect: "none",
                transition: "all 150ms ease-in 0s",
                minHeight: "2rem",
                boxShadow:
                    "rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
                padding: "0.5rem 1.2rem",
                backgroundImage:
                    "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
                color: "#fff",
                backgroundSize: "150%",
                backgroundPositionX: "25%"
            }}
            onClick={onClick}
            disabled={isDisabled}
            hidden={isHidden}
            startIcon={<AddCircleIcon />}
        >
            {txt}
        </Button>
    );
};

export const BtnChooseTemplate = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "Template Layout",
    sx = {},
}) => {
    return (
        <Button
            sx={{
                position: "relative",
                boxSizing: "border-box",
                outline: 0,
                border: 0,
                m: 0,
                cursor: "pointer",
                verticalAlign: "middle",
                appearance: "none",
                textDecoration: "none",
                letterSpacing: "0.02857em",
                minWidth: "64px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.75rem",
                fontWeight: "700",
                borderRadius: "0.5rem",
                lineHeight: 1.4,
                textAlign: "center",
                textTransform: "uppercase",
                userSelect: "none",
                transition: "all 150ms ease-in 0s",
                minHeight: "2rem",
                boxShadow:
                    "rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
                padding: "0.5rem 1.2rem",
                backgroundImage:
                    "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
                color: "#fff",
                backgroundSize: "150%",
                backgroundPositionX: "25%"
            }}
            onClick={onClick}
            disabled={isDisabled}
            hidden={isHidden}
            startIcon={<ViewCozyIcon />}
        >
            {txt}
        </Button>
    );
};

export const BtnAddMoreTemplate = ({
    isDisabled = false,
    isHidden = false,
    onClick = () => { },
    txt = "Add More Template",
    sx = {},
}) => {
    return (
        <Button
            sx={{
                position: "relative",
                boxSizing: "border-box",
                outline: 0,
                border: 0,
                m: 0,
                cursor: "pointer",
                verticalAlign: "middle",
                appearance: "none",
                width: "100%",
                marginTop: "1rem",
                textDecoration: "none",
                letterSpacing: "0.02857em",
                minWidth: "64px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.75rem",
                fontWeight: "700",
                borderRadius: "0.5rem",
                lineHeight: 1.4,
                textAlign: "center",
                textTransform: "uppercase",
                userSelect: "none",
                transition: "all 150ms ease-in 0s",
                minHeight: "2rem",
                boxShadow:
                    "rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
                padding: "0.5rem 1.2rem",
                backgroundImage:
                    "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
                color: "#fff",
                backgroundSize: "150%",
                backgroundPositionX: "25%"
            }}
            onClick={onClick}
            startIcon={<AddCircleIcon />}
            disabled={isDisabled}
            hidden={isHidden}
            endIcon={null}
        >
            {txt}
        </Button>
    );
};

export const BtnClear = ({
    IsDisabled = false,
    IsHisabled = false,
    onClick = () => { },
    txt = "Clear",
    isCircleWithOutText = false,
    tooltipPlacement = "bottom",
}) => {
    return (
        <Tooltip placement={tooltipPlacement as any} title={txt}>
            {!isCircleWithOutText ? (
                <Button
                    variant="contained"
                    startIcon={<ClearIcon />}
                    disabled={IsDisabled === true ? true : false}
                    hidden={IsHisabled === true ? true : false}
                    onClick={onClick}
                    sx={{
                        color: "#fff",
                        backgroundColor: "#DCDCDC",
                        "&:hover": { backgroundColor: "#CCCCCC", },
                        width: 40, height: 40,
                    }}
                    style={{
                        borderRadius: 50,
                        boxShadow: "0px 3px 3px -2px rgb(175 191 255 / 20%), 0px 3px 4px 0px rgb(175 191 255 / 14%), 0px 1px 8px 0px rgb(175 191 255 / 12%)",
                    }}
                >
                    {txt}
                </Button>
            ) : (
                <Fab
                    sx={{
                        color: "#fff",
                        backgroundColor: "#DCDCDC",
                        "&:hover": { backgroundColor: "#CCCCCC", },
                        width: 40, height: 40,
                    }}
                    onClick={onClick}
                    aria-label="clear"
                >
                    <ClearIcon />
                </Fab>
            )}
        </Tooltip>
    );
};

export const BtnLoguout = ({
    isDisabled = false,
    isHidden = false,
    tranformY = "",
    onClick = () => { },
    sx = {},
}) => {
    const { t } = useTranslation();
    return (
        <Tooltip title={t('logout')}>
            {
                <div style={{
                    color: "#ff0004",
                    cursor: "pointer",
                }}
                    onClick={onClick}
                    hidden={isHidden ? true : false}
                >
                    <LogoutIcon sx={{ verticalAlign: "middle" }} />
                </div>
            }
        </Tooltip>
    );
};