import { Box, Grid } from "@mui/material";

export default function NewsCardSkeleton() {
  return (
    <Grid item xs={12} sm={6} md={4} className="height-card">
      <Box
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "flex-start",
          cursor: "pointer",
        }}
      >
        <Box
          style={{
            // overflow: "hidden",
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            className=" _skeleton"
            style={{
              overflow: "hidden",
              height: "250px",
            }}
          ></Box>
          <Box sx={{ position: "absolute", width: "100%", mt: 0.75 }}>
            <div
              className="_skeleton"
              style={{ height: "12px", width: "100%", borderTopRightRadius:"5px" }}
            ></div>
            <div
              className="_skeleton"
              style={{ height: "12px", width: "78%", marginTop: "0.5rem", borderTopRightRadius:"5px" }}
            ></div>
            <div
              className="_skeleton"
              style={{
                width: "90px",
                marginTop: "0.5rem",
                borderRadius: "15px",
                border: "none",
                padding: "2px 0",
                height: "20px",
              }}
            ></div>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
