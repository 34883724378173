import { Grid, Stack } from "@mui/material";
import {
  TextBoxForm,
  DatePickerForm,
  SelectMultipleFormItem,
} from "STM/components/Input";
import { BtnSearchCustom, BtnClearCustom } from "STM/components/Button";
import { options } from "STM/enum/enumSystem";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import './FilterNews.css'


export default function FilterNews({ onSearch,onClear,dropdownTag }) {
  const form = useFormContext();
  const [sMinDate, setMinDate] = useState(null);
  const { t } = useTranslation();

  return (
    <div
      style={{
        // maxHeight: 200,
        overflow: "auto",
        width: "100%",
        marginBottom: "1em",
      }}
    >
      <Stack
        className="box-filter"
        style={{ padding: "10px" }}
        justifyContent={"end"}
        direction={"column"}
        spacing={1}
        sx={{
          border: "1px #0168cc solid",
          borderRadius: "10px",
          ":before": {
            content: '""',
            position: "absolute",
            left: "12%",
            top: "2.5em",
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderBottom: " 5px solid #0168cc",
          },
        }}
      >
        <Grid container justifyContent={"flex-start"} spacing={1}>
          <Grid item md={5} xs={12}>
            <TextBoxForm
              name="sNewsName"
              label={t("newsname")}
              isshowTextCont={false}
              disabled={false}
              shrink
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSearch()
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={4}>
          <SelectMultipleFormItem
              id={"sTag"}
              name={"sTag"}
              label={t('tags')}
              required={false}
              disabled={false}
              options={dropdownTag}
              limitTags={3}
              shrink
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSearch()
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <SelectMultipleFormItem
              id={"sPin"}
              name={"sPin"}
              label={t('pin')}
              required={false}
              disabled={false}
              options={options.pin}
              limitTags={2}
              shrink
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSearch()
                }
              }}
            />
          </Grid>
          <Grid item md={3.5} sm={6} xs={12}>
            <DatePickerForm
              name={"sStartDate"}
              label={t('startdate')}
              required={false}
              fullWidth={true}
              onChange={(e) => {
                let dEnd = moment(form.getValues("sEndDate"));
                let dStart = moment(e);

                if (
                  dEnd.isValid() &&
                  dStart.isValid() &&
                  dStart.isAfter(dEnd)
                ) {
                  form.setValue("sEndDate", null);
                }
                setMinDate(e);
              }}
              shrink
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSearch()
                }
              }}
            />
          </Grid>
          <Grid item md={3.5} sm={6} xs={12}>
            <DatePickerForm
              name={"sEndDate"}
              label={t('enddate')}
              required={false}
              fullWidth={true}
              minDate={moment(sMinDate)}
              onChange={(e) => {
                let dStart = moment(form.getValues("sStartDate"));
                let dEnd = moment(e);

                if (
                  dEnd.isValid() &&
                  dStart.isValid() &&
                  dEnd.isBefore(dStart)
                ) {
                  form.setValue("sStartDate", null);
                }
              }}
              shrink
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSearch()
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={3}>
            <SelectMultipleFormItem
              id={"sStatus"}
              name={"sStatus"}
              label={t('status')}
              required={false}
              disabled={false}
              options={options.status}
              limitTags={2}
              shrink
              
            />
          </Grid>
          
          <Grid item md={"auto"} xs={"auto"}>
            <BtnClearCustom onClick={onClear} />
          </Grid>
          <Grid item md={"auto"} xs={"auto"}>
            <BtnSearchCustom onClick={onSearch} />
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
}
