import React from "react";
import { useEffect, useState } from "react";

import PopupUnderConstraction from "view/UnderConstraction/UnderConstraction2";

import { Box } from "@mui/material";
import {
  MdDashboard,
  MdOutlineProductionQuantityLimits,
  MdOutlineContactSupport,
} from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import { ShowUnderConstraction } from "view/UnderConstraction/UnderConstraction";
import { enumMenu } from "enum/enumSystem2";
import { $$ } from "jodit/types/core/helpers";

import { BtnLoguout } from "STM/components/Button";
import { myLogout } from '../../../utilities/ST_Function';
import secureLocalStorage from "react-secure-storage";

function LayoutNavSide({ lstMenu }) {
  const navigate = useNavigate();
  const Lockout = myLogout();
  const [openSub, setOpenSub] = React.useState(-1);
  const [openChild, setOpenChild] = React.useState(-1);
  const MenuID_Selected: Number = 0;
  const frontURL = process.env.REACT_APP_Front_URL;

  const [UserSession, setUserSession] = React.useState<any>({});
  const [OpenUnderContraction, setOpenUnderContraction] = useState(false);

  const FunctionCloseDialog = () => {
    setOpenUnderContraction(false);
  }
  const FunctionOpenDialog = () => {
    document.getElementById("sideMenuBtnClose").click();
    setOpenUnderContraction(true);
  }

  useEffect(() => {
    setUserSession(secureLocalStorage.getItem("UserSession"));
  }, []);

  const CloseMobileMenu = () => {
    document.getElementById("sideMenuBtnClose").click();
    Lockout();
  }

  const renderMenu = () => {

    return lstMenu.map((menu, index) => {

      if (menu.sLink === enumMenu.UnderConstruction.toString()) {
        return (
          <Box key={index} className="ptt-tabs-menu-mobile">
            <Box className={menu.isActive ? "menu-mobile menu-active" : "menu-mobile"}
              onClick={() => { FunctionOpenDialog() }}>
              <Box sx={{ display: "flex", alignItems: "center", mr: 0.75, textTransform: 'uppercase' }}>
                <span style={{ position: "relative", zIndex: 1, fontSize: "1rem", }}>
                  {menu.sMenuName}
                </span>
              </Box>
            </Box>
          </Box>
        )
      } else {
        return (
          <a key={index} href={frontURL + menu.sLink}>
            <Box className="ptt-tabs-menu-mobile">
              <Box className={menu.isActive ? "menu-mobile menu-active" : "menu-mobile"}
                onClick={() => { setOpenSub(openSub === index ? -1 : index); setOpenChild(-1); }}>
                <Box sx={{ display: "flex", alignItems: "center", mr: 0.75, textTransform: 'uppercase' }}>
                  <span style={{ position: "relative", zIndex: 1, fontSize: "1rem", }}>
                    {menu.sMenuName}
                  </span>
                </Box>
              </Box>
            </Box>
          </a>
        );
      }

    });
  };
  return <Box sx={{ mt: 0.5 }}>
    {
      renderMenu()
    }
    {
      UserSession == null ?
        <></>
        :
        <Box className="ptt-tabs-menu-mobile">
          <Box className={"menu-mobile menu-active"} onClick={CloseMobileMenu}>
            <Box sx={{ mr: "10px" }}>
              <BtnLoguout />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mr: 0.75, textTransform: 'uppercase' }}>
              <span style={{ position: "relative", zIndex: 1, fontSize: "1rem", }}>
                Logout
              </span>
            </Box>
          </Box>
        </Box>
    }
    <PopupUnderConstraction open={OpenUnderContraction} handleClose={FunctionCloseDialog} />
  </Box>
}

export default LayoutNavSide;