import { Grid, Box } from "@mui/material";
import { TextBoxForm } from "STM/components/Input";
import { HeaderInput } from "STM/components/Input";
import GuideLine from "STM/components/Guideline/Guideline";
import { EnumLang } from "STM/enum/enumSystem";
import { t } from "i18next";
import { newsData } from "./NewsInfoSetting";

const NewsForm = ({ lang = "th-TH",guideline }) => {
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      {newsData.newsForm.map((item) => (
        <Grid key={item.id} item xs={12} md={item.size}>
          <Box
            sx={{
              color: "rgb(52, 71, 103)",
            }}
            className=".Box-content-form"
          >
            <HeaderInput text={t(item.header)} required={item.required} />
            <TextBoxForm
              name={item.name}
              label={item.label}
              disabled={item.disabled}
              multiline={item.multiline}
              rows={item.rows}
              maxLength={item.length}
            />
            {lang !== EnumLang.MainLangCode && <GuideLine text={guideline[item.guideKey]} />}
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export default NewsForm