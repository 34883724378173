
import LayoutSide from 'layout/Backend/layout_Side';
import NewsForm from 'view/STM/News/NewsForm';
import News from 'view/STM/News/News';
import Admin from 'view/Admin/Admin';
import AnimateLayout from 'STM/layout/AnimateLayout/AnimateLayout';
const route = [
    {
        exact: true,
        path: "/NewsForm",
        component: NewsForm,
        layout: AnimateLayout,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/News",
        component: News,
        layout: AnimateLayout,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/Admin",
        component: Admin,
        layout: LayoutSide,
        props: { MenuID_Selected: 1 }
    }
];
export default route;