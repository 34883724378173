import React from "react";
import { motion } from "framer-motion";
import "./Item.css";
function Item({ icon, name, active }) {
  const subheading = {
    true: {
      opacity: 1,
    },
    false: {
      opacity: 0,
      display: "none",
    },
  };
  return (
    <motion.div
      className={`item ${active ? "item-active" : "item-inactive"}`}
      // whileHover={{
      //   backgroundColor: "rgba(255, 255, 255, 0.3)",
      //   boxShadow: "0 8px 32px 0 rgba(176, 179, 215, 0.37)",
      //   backdropFilter: "blur(5.5px)",
      //   WebkitBackdropFilter: "blur(5.5px)",
      //   border: "1px solid rgba( 255, 255, 255, 0.18 )",
      //   cursor: "pointer",
      // }}
      transition={{
        type: "none",
        duration: 0.1,
      }}
    >
      <motion.div className="icon">{icon}</motion.div>
      <motion.span variants={subheading} className="animate-menu-name">{name}</motion.span>
    </motion.div>
  );
}

export default Item;
