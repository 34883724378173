
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
///import {AppTitle} from 'config/AppConfig'
import './index.css';
import App from './App';
import { GetAPISystemInfo} from 'STM/utilities/CallAPI';
import {GetSystemInfo} from 'STM/utilities/ST_Function';
import { AxiosGet } from 'utilities/ST_Axios';
import secureLocalStorage from 'react-secure-storage';
(async function () {
   await GetAPISystemInfo();

   await AxiosGet("Utility2/GetInitialData", {}, (res) => {
      let info = process.env.REACT_APP_InitialInfo;
      ////alert(JSON.stringify(res));
      secureLocalStorage.setItem(info,res);
   });

   ////let systemInfo = GetSystemInfo();
   ////document.title =systemInfo.SystemName;
   const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
   const container = document.getElementById('root');
   const root = createRoot(container);
   root.render(
     <BrowserRouter basename={baseUrl} >
       < App />
     </BrowserRouter>
   );
 })();
