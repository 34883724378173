import {
  useMediaQuery,
  FormHelperText,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import {
  DateRangePicker,
  SingleInputDateRangeField,
  SingleInputDateRangeFieldProps,
} from "@mui/x-date-pickers-pro";
import { language } from "config/AppConfig";
import { forwardRef, useState, useCallback } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { DateRangePickerProFromProps } from "./DateRangePickerProps";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import React from "react";
import { Dayjs } from "dayjs";

const customTextField = () => {
  return (
    <TextField
      sx={{
        ".MuiOutlinedInput-root": {
          padding: "0px 15px 0px 0px!important",
          " fieldset > legend > span": {
            padding: "0px !important",
          },
        },
        ".MuiInputBase-inputSizeSmall": {
          padding: "8.5px",
        },
        fontWeight: 600,
      }}
    />
  );
};

const localeMap = (language) => {
  switch (language) {
    case "th":
      moment.locale("th");
      break;
    case "en":
      moment.locale("en");
      break;
    default:
      break;
  }
};

class CustomString extends String {
  charAt(_: number): string {
    return this.valueOf();
  }
}

/** class CustomAdapter */
class CustomAdapter extends AdapterMoment {
  constructor({ locale, formats, instance }) {
    super({ locale, formats, instance });
  }

  getWeekdays = (): string[] =>
    this.locale === "th"
      ? ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."].map(
        (day) => new CustomString(day) as string
      )
      : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
        (day) => new CustomString(day) as string
      );

  calYear(moment) {
    switch (this.locale) {
      case "th":
        return (parseInt(moment.format("YYYY")) + 543).toString();
      // return (parseInt(moment.format('YYYY'))).toString();
      case "en":
        return parseInt(moment.format("YYYY"));
      default:
        return parseInt(moment.format("YYYY"));
    }
  }

  renderLabel(moment, format) {
    switch (format) {
      case "year":
        return this.calYear(moment);
      case "month":
        return moment.format("MMMM");
      case "fullDate":
        return moment.format("DDMMYYYY");
      case "dayOfMonth":
        return moment.format("D");
      case "monthShort":
        return moment.format("MMMM");
      case "monthAndYear":
        return `${moment.format("MMMM ")} ${this.calYear(moment)}`;
      case "normalDate":
        return `${moment.format("dd")} ${moment.format("MMM")} ${moment.format(
          "D"
        )}`;
      default:
        return moment.format(format);
    }
  }
  startOfMonth = (date) =>
    date ? date.clone().startOf("month") : moment(new Date());

  format = (date, formatKey) => {
    return date ? this.renderLabel(date, formatKey) : moment(new Date());
  };
}

export const DateRangePickerProFrom = forwardRef(
  (props: DateRangePickerProFromProps, ref) => {
    LicenseInfo.setLicenseKey(
      "049fdd25559575ef04f65a9e1ed5aabaTz02NDk0MCxFPTE3MTM2NzY2MjYyNzIsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
    );

    const {
      name,
      view,
      format = "DD/MM/YYYY - DD/MM/YYYY",
      minDate,
      maxDate,
      defaultCalendarMonth = null,
      labelStart = "",
      labelEnd = "",
      required = false,
      calendarsCount = 2,
      disabled = false,
      disablePast = false,
      isAllPopup = false,
      IsShrink = true,
    } = props;

    const {
      register,
      control,
      formState: { errors },
    } = useFormContext();

    const matches700 = useMediaQuery("(min-width:700px)");
    const [locale] = useState<keyof typeof maskMap>(language);
    const ReplaceThFormat = (text: string) => {
      text
        .toLowerCase()
        .replaceAll("d", "ว")
        .replaceAll("m", "ด")
        .replaceAll("y", "ป");
    };

    const formatDefalut = format;
    const maskMap = {
      en: formatDefalut,
      th: ReplaceThFormat(formatDefalut),
    };

    const [objCheckReplace] = useState(() => {
      localeMap(locale);
      let objReturn = { isReplaceYear: false, indexArr: 0, splitText: "" };
      var formatLower = formatDefalut.toLowerCase();
      if (formatLower.includes("yyyy")) {
        var splitText = formatDefalut
          .substr(formatLower.indexOf("yyyy") - 1, 1)
          .toLowerCase();
        if (splitText !== "y") {
          objReturn.splitText = splitText;
          objReturn.indexArr = formatLower
            .split(splitText)
            .findIndex((e) => e === "yyyy");
        }
        objReturn.isReplaceYear = true;
      }
      return objReturn;
    });

    const onSetMomentLocale = useCallback((startProps: any, endProps: any) => {
      if (locale === "th") {
        //Start
        if (
          startProps.inputProps.value &&
          objCheckReplace.isReplaceYear &&
          startProps.inputProps.value.length === 10
        ) {
          let year = objCheckReplace.splitText
            ? startProps.inputProps.value.split(objCheckReplace.splitText)[
            objCheckReplace.indexArr
            ]
            : startProps.inputProps.value.substr(0, 4);
          let addyear =
            parseInt(year) > 2500 ? parseInt(year) : parseInt(year) + 543;
          startProps.inputProps.value = startProps.inputProps.value.replaceAll(
            `${year}`,
            `${addyear}`
          ); //ปิดเพราะทำให้พิมพ์แล้ว error
        } else if (
          format === "YYYY" &&
          startProps.inputProps.value &&
          objCheckReplace.isReplaceYear &&
          startProps.inputProps.value.length === 4
        ) {
          let addyear =
            parseInt(startProps.inputProps.value) > 2500
              ? parseInt(startProps.inputProps.value)
              : parseInt(startProps.inputProps.value) + 543;
          startProps.inputProps.value = addyear;
        }
        //End
        if (
          endProps.inputProps.value &&
          objCheckReplace.isReplaceYear &&
          endProps.inputProps.value.length === 10
        ) {
          let year = objCheckReplace.splitText
            ? endProps.inputProps.value.split(objCheckReplace.splitText)[
            objCheckReplace.indexArr
            ]
            : endProps.inputProps.value.substr(0, 4);
          let addyear =
            parseInt(year) > 2500 ? parseInt(year) : parseInt(year) + 543;
          endProps.inputProps.value = endProps.inputProps.value.replaceAll(
            `${year}`,
            `${addyear}`
          ); //ปิดเพราะทำให้พิมพ์แล้ว error
        } else if (
          format === "YYYY" &&
          endProps.inputProps.value &&
          objCheckReplace.isReplaceYear &&
          endProps.inputProps.value.length === 4
        ) {
          let addyear =
            parseInt(endProps.inputProps.value) > 2500
              ? parseInt(endProps.inputProps.value)
              : parseInt(endProps.inputProps.value) + 543;
          endProps.inputProps.value = addyear;
        }
      }
    }, []);

    type FieldComponent = (<TDate>(
      props: SingleInputDateRangeFieldProps<TDate> &
        React.RefAttributes<HTMLInputElement>,
    ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>) & { fieldType?: string };

    const WrappedSingleInputDateRangeField = React.forwardRef(
      (
        props: SingleInputDateRangeFieldProps<Dayjs>,
        ref: React.Ref<HTMLInputElement>
      ) => {
        return (
          <SingleInputDateRangeField
            size="small"
            {...props}
            ref={ref}
            label="Start Date - End Date"
            sx={{
              width: "100%",
              ".MuiInputBase-inputSizeSmall": {
                border: "",
                padding: "8.5px",
              },
              "label.MuiInputLabel-shrink": {
                backgroundColor: "rgb(255 255 255 / 18%)",
                top: "0px",
              },
              ".MuiInputLabel-outlined": {
                top: "0px",
              },
              ".MuiInputLabel-asterisk": {
                color: "red",
              },
            }}
          />
        );
      }
    ) as FieldComponent;

    WrappedSingleInputDateRangeField.fieldType = 'single-input';

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={[null, null]}
        key={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <LocalizationProvider
            // locale={"DD/MM/YYYY"}
            dateAdapter={CustomAdapter}
          >
            <DateRangePicker
              {...register(name)}
              value={value}
              key={name}
              inputRef={ref}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              format="DD/MM/YYYY"
              slots={{ field: WrappedSingleInputDateRangeField }}
              disabled={disabled}
              defaultCalendarMonth={defaultCalendarMonth}
              minDate={minDate || null}
              maxDate={maxDate || null}
              calendars={calendarsCount}
              disablePast={disablePast}
            />
            {errors && errors[name] ? (
              <FormHelperText sx={{ color: "red" }}>
                {errors[name].message + ""}
              </FormHelperText>
            ) : null}
          </LocalizationProvider>
        )}
      />
    );
  }
);

export default DateRangePickerProFrom;
