import React, { useState, useRef, useEffect } from "react";
import NewsForm from "./NewsInfo";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import yupFormSchemas from "STM/components/FormItem/yupFormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  BtnBackForm,
  BtnCreateForm,
  BtnDanger,
  BtnPreviewForm,
  BtnSubmitForm,
} from "STM/components/Button";
import { SwitchForm, CheckboxFormItem } from "STM/components/Input";
import { HeaderInput } from "STM/components/Input";
import UploadFile from "STM/components/Input/UploadFile/UploadFile";
import { Extension } from "STM/utilities/ST_Function";
import { Box, Divider, Grid, Tab, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ShowDateForm from "STM/components/ShowDate";
import { objDateData } from "STM/components/ShowDate/ShowDateProps";
import { AxiosGet } from "STM/utilities/ST_Axios";
import { SwAlert } from "STM/components";
import { TabContext, TabList } from "@mui/lab";
import { EnumLang, EnumStatus, EnumSubmit } from "STM/enum/enumSystem";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { t } from "i18next";
import Reference from "STM/components/Reference/Reference";
import InputChip from "STM/components/Chip/InputChip";
import ContentManagement from "STM/components/ContentManagement";
import { FnDialog } from "STM/utilities/ST_Function";
import { GetData, Save } from "./CallAPI";
import NewsPreview from "STM/layout/LayoutPreview/NewsLayout/NewsPreview";
import { BtnPreview,BtnBack,BtnCancel,BtnSave,BtnDraft } from "components/Button/Button";

export default function EditPage() {
  const fontColor = "rgb(52, 71, 103)";
  const [CMSContent, setCMSContent] = useState({} as any);
  const [isComplete, setIsComplete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [newsCoverFile, setnewsCoverFile] = useState([] as any);
  const [arrChipDataEdit, setChipDataEdit] = useState([]);
  const [arrSetChip, setArrSetChip] = useState([]);
  const [arrFileAlbum, setarrFileAlbum] = useState([] as any);
  const [objGuideLine, setObjGuideLine] = useState({});
  const [tabs, setTabs] = useState([]);
  const [sLang, setLang] = useState("th-TH");
  const navigate = useNavigate();
  const location = useLocation();
  const [nStatusID, setStatusID] = useState(EnumStatus.Draft);
  const [selectedTab, setSelectedTab] = useState("1");
  const [previewData, setPreviewData] = useState({} as any);
  const onClearFile = useRef(null);
  const onClearFileAlbum = useRef(null);
  const [dataDate, setDataDate] = useState({});
  const [yupObject, setYupObject] = useState({});
  const [yupRef, setYupRef] = useState({});
  const [prevData, setPrevData] = useState({} as any);
  const [oldRef, setOldRef] = useState([]);
  const [nTotalPin, setTotalPin] = useState(0);
  const [nMaxPin, setMaxPin] = useState(5);
  const [isPin, setIsPin] = useState(false);
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
  const [isReference, setIsRefernce] = useState(false);
  const [isAlbum, setIsAlbum] = useState(false);
  const [isShowRef, setIsShowRef] = useState(false);
  const [isShowPeriod, setIsShowPeriod] = useState(false);
  const [isShowAlbum, setIsShowAlbum] = useState(false);
  const [isActive, setIsActive] = React.useState(true);
  const [isReset, setIsReset] = useState(false);
  const sIDParams = location.state ? location.state["sID"] : null;
  const [sID, setsID] = useState("");
  const DialogFn = FnDialog();
  const [arrTopicRef, setArrTopicRef] = useState([
    {
      nID: 1,
      sText: "",
      sUrl: "",
      nRefID: null,
      sRefMode: "N",
    },
  ] as any);
  const [objDate, setObjDate] = useState<objDateData>({
    sStartDate: null,
    sEndDate: "",
    IsAllTime: false,
    nPeriod: 1,
    nWeekDay: 1,
    lstDays: null,
    lstMonth: null,
    lstDate: null,
    lstWeekly: null,
  });

  const objSchema = {
    sNewsName: yupFormSchemas.string(t("newsname"), {
      required: true,
      translation: t,
    }),
    arrNewsFile: yupFormSchemas.string(t("coverimg"), {
      required: true,
      translation: t,
    }),
    arrNewsAlbum: yupFormSchemas.string(t("album"), {
      required: isAlbum,
      translation: t,
    }),
    ...yupObject,
    ...yupRef,
  };
  const schema = yup.object().shape(objSchema);
  const formResolver = yupResolver(schema);
  const form = useForm({
    resolver: formResolver,
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: {
      sNewsName: "",
      sDescription: "",
      sSEONewsName: "",
      sSEODescription: "",
      IsAlbum: false,
      IsReference: false,
      sStatus: true,
      sPin: false,
      sAllTime: false,
      nPeriod: 1,
      nWeekDay: 1,
      sTypeBanner: 1,
      sTypeLink: 1,
    } as any,
  });

  //? Change Language event
  const handleChange = (event, newValue) => {
    if (GetNewsForm().equal !== true && isEdit) {
      onSubmit(nStatusID, EnumSubmit.Auto, newValue);
    } else {
      setSelectedTab(newValue);
    }
  };

  useEffect(() => {
    const getTabLanguage = () => {
      AxiosGet("DropDown/GetLanguage", {}, (res) => {
        setLang(res.find((f) => f.value === selectedTab).label);
        setTabs(res);
        GetInitData(res.find((f) => f.value === selectedTab).label);
      });
    };
    getTabLanguage();
  }, [selectedTab]);
  //? end event language

  const GetNewsForm = () => {
    let oNewsCheck = {
      data: {},
      equal: false,
      preview: {},
    };

    console.log("GetnewForm");

    let lstReference = arrTopicRef;
    if (oldRef != null && oldRef.length > 0) {
      oldRef.forEach((ref, i) => {
        if (
          arrTopicRef.find((f) => f.nRefID === ref.nRefID) === undefined ||
          arrTopicRef.find((f) => f.nRefID === ref.nRefID) == null
        ) {
          ref.sRefMode = "D";
          lstReference.push(ref);
        }
      });
    }
    let lstCMS = CMSContent;
    if (
      prevData.lstCMSContent != null &&
      prevData.lstCMSContent.lstCMS.length > 0
    ) {
      prevData.lstCMSContent.lstCMS.forEach((content, i) => {
        if (
          CMSContent.lstCMS.find((f) => f.nContentID === content.nContentID) ===
            undefined ||
          CMSContent.lstCMS.find((f) => f.nContentID === content.nContentID) ==
            null
        ) {
          content.sContentMode = "Delete";
          lstCMS.lstCMS.push(content);
        }
      });
    }

    let lstAlbum = arrFileAlbum;
    console.log("arrFileAlbum", arrFileAlbum);
    if (prevData.lstAlbum != null && prevData.lstAlbum.length > 0) {
      prevData.lstAlbum.forEach((file, i) => {
        let checkFile = arrFileAlbum.find((f) => f.nFile_ID === file.nFile_ID);
        if (checkFile === undefined || checkFile == null) {
          file.sFileMode = "Delete";
          lstAlbum.push(file);
        }
      });
    }

    let newsForm = {
      sNewsName: form.getValues("sNewsName"),
      sDescription: form.getValues("sDescription"),
      sSEONewsName: form.getValues("sSEONewsName"),
      sSEODescription: form.getValues("sSEODescription"),
      fCover: newsCoverFile,
      lstCMSContent: CMSContent,
      IsAlbum: form.getValues("IsAlbum"),
      IsReference: form.getValues("IsReference"),
      lstAlbum: lstAlbum,
      lstTag: arrChipDataEdit,
      lstReference: lstReference,
      sPin: form.getValues("sPin"),
      sStatus: form.getValues("sStatus"),
      objTimeData: objDate,
    };
    let previewDetail = {
      sNewsName: form.getValues("sNewsName"),
      sDescription: form.getValues("sDescription"),
      lstTag: arrChipDataEdit,
      lstCMSContent: CMSContent,
      lstReference: isReference ? lstReference : [],
      lstAlbum: isAlbum ? lstAlbum : [],
      sDate: objDate.sStartDate != null ? objDate.sStartDate.split(" ")[0] : "",
      fCover: newsCoverFile,
    };
    oNewsCheck.data = newsForm;
    oNewsCheck.equal = _.isEqual(newsForm, prevData);
    oNewsCheck.preview = previewDetail;
    return oNewsCheck;
  };

  const onCheckCMS = (nStatusID, mode, langVal) => {
    if (
      CMSContent.sError === null ||
      CMSContent.sError === undefined ||
      CMSContent.sError === ""
    ) {
      onSubmit(nStatusID, mode, langVal);
    } else {
      DialogFn.Warning(CMSContent.sError);
    }
  };
  const onSubmit = (nStatusID, mode, langVal) => {
    if (nStatusID === 0) {
      nStatusID = EnumStatus.Draft;
    }
    console.log("objSchema", objSchema);
    if (
      nStatusID === EnumStatus.Draft &&
      (form.getValues("sNewsName") === null ||
        form.getValues("sNewsName") === "")
    ) {
      form.setError(
        "sNewsName",
        { type: "custom", message: t("pleasespecify") + " " + t("newsname") },
        { shouldFocus: true }
      );
      return false;
    }

    DialogFn.Submit(
      mode === EnumSubmit.Auto ? t("changemsg") : t('doyouwant') + " " + (nStatusID === EnumStatus.Draft ? t('savedraft') : t('submit')) + " ?",
      async () => {
        let objParam = {
          sID: sIDParams ?? sID,
          newsForm: GetNewsForm().data,
          sLang: sLang,
          nStatusID: nStatusID,
          lstOldRef: [],
        };
    
        DialogFn.BlockUI();
        await Save(
          objParam,
          (result) => {
            DialogFn.Success(EnumSubmit.Confirm === mode ? t("formsuccess") : "Form has been successfully Save Draft"  );
            setsID(result);
            switch (mode) {
              case EnumSubmit.Confirm:
                return navigate(`/News`);
              case EnumSubmit.Auto:
                return setSelectedTab(langVal);
              case EnumSubmit.Draft:
                return GetInitData(sLang,result);
            }
          },
          (err) => {
            if (!err.response) {
              DialogFn.Warning(err.Message);
            }
          }
        ).then(
          () => {
            DialogFn.UnBlockUI();
          },
          () => {}
        );
      }
    );
  };

  const GetInitData = (Lang,sid = "") => {
    onGetData(Lang,sid).then(
      (data) => {
        DialogFn.UnBlockUI();
      },
      (err) => {}
    );
  };

  const onGetData = async (Lang,sid) => {
    DialogFn.BlockUI();
    let objParam = {
      sID: sIDParams ?? sid,
      sLang: Lang,
    };
    await GetData(
      objParam,
      (result) => {
        setTotalPin(result.nTotalPin);
        setMaxPin(result.nMaxPin);
        setIsShowPeriod(result.IsShowPeriod);
        setIsShowRef(result.IsShowRef);
        setIsShowAlbum(result.IsShowAlbum);
        if (result.isEdit) {
          form.setValue("sNewsName", result.newsForm.sNewsName);
          form.setValue("sDescription", result.newsForm.sDescription);
          form.setValue("sSEONewsName", result.newsForm.sSEONewsName);
          form.setValue("sSEODescription", result.newsForm.sSEODescription);
          form.setValue("IsReference", result.newsForm.IsReference);
          form.setValue("IsAlbum", result.newsForm.IsAlbum);
          form.setValue("sPin", result.newsForm.sPin);
          form.setValue("sStatus", result.newsForm.sStatus);

          setArrSetChip(result.newsForm.lstTag);
          setIsRefernce(result.newsForm.IsReference);
          setIsAlbum(result.newsForm.IsAlbum);
          setArrTopicRef(result.lstOldRef);
          setOldRef(result.newsForm.lstReference);
          setarrFileAlbum(result.newsForm.lstAlbum);
          if (result.newsForm.lstCMSContent != null) {
            setCMSContent(result.newsForm.lstCMSContent);
          }
          setsID(result.sID);

          setPrevData(result.newsForm);
          setnewsCoverFile(result.newsForm.fCover);
          setDataDate(result.newsForm.objTimeData);
          setIsPin(result.newsForm.sPin);
          setStatusID(result.nStatusID);
          setIsActive(result.newsForm.sStatus);
          setIsComplete(result.IsComplete);
          setObjGuideLine(result.newsGuideline);
        }
        setIsEdit(result.isEdit);
      },
      (err) => {
        if (!err.response) {
          DialogFn.Warning(err.Message);
        }
      }
    );
  };

  const CancelForm = () => {
    DialogFn.Submit(t("cancelmsg"), () => {
      if (isEdit) {
        GetInitData(sLang);
      } else {
        setnewsCoverFile([]);
        setarrFileAlbum([]);
        setChipDataEdit([]);
        setArrSetChip([]);
        setArrTopicRef([]);
        setIsReset(true);
        setCMSContent({
          isRequired: false,
          lstCMS: [
            {
              lstSlot: [
                {
                  lstClipVideo: [],
                  lstPicture: [],
                  nSlotNo: 1,
                  nType: 1,
                  sSlotName: "A",
                  sText: "",
                  sUrlLinkVideo: null,
                },
              ],
              nContentID: 0,
              nMaxSlot: 1,
              nOrder: 1,
              nTemplateContentID: 1,
              sContentID: "DumysContentID",
              sContentMode: "Insert",
            },
          ],
          nDefaultTemplateID: null,
          sError: "",
        });
        form.reset();
      }
    });
  };

  return (
    <FormProvider {...form}>
      <NewsPreview
        open={isShowPreview}
        handleClose={() => setIsShowPreview(false)}
        data={previewData}
      />
      <Grid container className="container-form">
        <Grid item xs={12}>
          <Grid item xs={12} className="Card-form">
            <Grid
              item
              xs={12}
              sx={{
                color: fontColor,
              }}
              className="Header-grid-form"
            >
              <Typography
                sx={{ color: fontColor }}
                className="Header-title-form"
              >
                {t("createnews")}
              </Typography>
              {tabs.length > 1 && (
                <TabContext value={selectedTab}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <TabList onChange={handleChange}>
                      {tabs.map((tabs, i) => (
                        <Tab
                          key={tabs.value}
                          label={tabs.label.substring(3)}
                          value={tabs.value}
                          disabled={
                            !isComplete && tabs.value !== EnumLang.MainLang
                          }
                        />
                      ))}
                    </TabList>
                  </Box>
                </TabContext>
              )}
              <NewsForm guideline={objGuideLine} lang={sLang} />
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <HeaderInput text={t("coverimg")} required />
                  <Divider sx={{ marginBottom: "1rem" }} />
                  <UploadFile
                    id="sDocFile_News"
                    name={"arrNewsFile"}
                    keyID={2}
                    IsDrag={true}
                    sTitle={``}
                    IsRequired={false}
                    arrFile={newsCoverFile}
                    setarrFile={setnewsCoverFile}
                    IsFolder={false}
                    IsFile={true}
                    IsMultiple={false}
                    Extension={[...Extension.Image_New]}
                    nLimitFile={3}
                    sLimitFile={"MB"}
                    onClearFile={onClearFile}
                    modeDisplay={"gallery"}
                    sFolderTemp={"Review"}
                    IsDragDrop={false}
                    IsCrop={true}
                    sRemark={"Recommend : 800*550 px"}
                    
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderInput text={t("newsdetail")} required />
                  <ContentManagement
                    setCMSData={setCMSContent}
                    CMSData={
                      Object.keys(CMSContent).length > 0 ? CMSContent : null
                    }
                    isRequired={true}
                    nDefaultTemplateID={1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <HeaderInput text={t("tags")} />
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        border: "1px solid #f4f4f4",
                        margin: "0.5rem",
                        pr: 1.5,
                        pb: 1.5,
                      }}
                    >
                      <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <InputChip
                            name="lstTags"
                            label=""
                            setArrTags={setChipDataEdit}
                            arrTags={arrSetChip}
                            isEditTag
                            maxLength={150}
                            
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={!isShowAlbum ? { display: "none" } : {}}>
                  <Grid item xs={12}>
                    <CheckboxFormItem
                      name="IsAlbum"
                      label={
                        <Typography
                          sx={{
                            m: 0,
                            fontSize: "0.8rem",
                            lineHeight: 1.25,
                            letterSpacing: "0.0333em",
                            opacity: 1,
                            textTransform: "none",
                            verticalAlign: "unset",
                            textDecoration: "none",
                            color: "rgb(52, 71, 103)",
                            fontWeight: 700,
                          }}
                        >
                          {t("album")}
                        </Typography>
                      }
                      required={false}
                      disabled={false}
                      id="IsAlbum"
                      onChange={(e) => setIsAlbum(e)}
                    />
                  </Grid>
                  <Divider sx={{ marginBottom: "1rem" }} />
                  <UploadFile
                    id="sAlbum_News"
                    name={"arrNewsAlbum"}
                    keyID={3}
                    IsDrag={true}
                    sTitle={``}
                    IsRequired={false}
                    arrFile={arrFileAlbum}
                    setarrFile={setarrFileAlbum}
                    IsFolder={true}
                    IsFile={true}
                    IsMultiple={true}
                    Extension={[...Extension.Image_New]}
                    nLimitFile={3}
                    sLimitFile={"MB"}
                    onClearFile={onClearFileAlbum}
                    modeDisplay={"gallery"}
                    sFolderTemp={"Review"}
                    IsDragDrop={false}
                    IsCrop={true}
                    disabled={false}
                    IsCanAddDetail
                    sRemark={"Recommend : 550*500 px"}
                  />
                </Grid>
                {isShowRef && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <CheckboxFormItem
                          name="IsReference"
                          label={
                            <Typography
                              sx={{
                                m: 0,
                                fontSize: "0.8rem",
                                lineHeight: 1.25,
                                letterSpacing: "0.0333em",
                                opacity: 1,
                                textTransform: "none",
                                verticalAlign: "unset",
                                textDecoration: "none",
                                color: "rgb(52, 71, 103)",
                                fontWeight: 700,
                              }}
                            >
                              {t("reference")}
                            </Typography>
                          }
                          required={false}
                          disabled={false}
                          id="IsReference"
                          onChange={(e) => setIsRefernce(e)}
                        />
                      </Grid>
                      <Box
                        sx={{
                          // display: isReference ? "flex" : "none",
                          width: "100%",
                        }}
                      >
                        <Reference
                          arrTopicRef={arrTopicRef}
                          setArrTopicRef={setArrTopicRef}
                          IsReference={isReference}
                          setYupRef={setYupRef}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}
                <ShowDateForm
                  lstData={dataDate}
                  setObjDate={setObjDate}
                  objDate={objDate}
                  setYupDate={setYupObject}
                  IsShowPeriod={isShowPeriod}
                  IsReset={isReset}
                  setIsReset={setIsReset}
                />
                <Grid item xs={"auto"}>
                  <Box
                    sx={{
                      color: fontColor,
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput
                      text={t("pin")}
                      subtext={`${t("maximum")} ${nTotalPin}/${nMaxPin} ${t(
                        "pin"
                      )}`}
                    />
                    <Box sx={{ width: "60px" }}>
                      <SwitchForm
                        name={"sPin"}
                        label=""
                        required={false}
                        disabled={nTotalPin === nMaxPin && (!isEdit || !isPin)}
                        width={60}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={"auto"}>
                  <Box
                    sx={{
                      color: fontColor,
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput
                      text={t("status")}
                      subtext={isActive ? t("active") : t("inactive")}
                      required
                    />
                    <Box sx={{ width: "60px" }}>
                      <SwitchForm
                        name={"sStatus"}
                        label=""
                        required={false}
                        disabled={false}
                        width={60}
                        IsClassName={false}
                        onChange={(e) => setIsActive(e)}
                        onColor="#8bc34a"
                        offColor="#c41411"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", mt: 1.5 }}
                justifyContent={"space-between"}
              >
                <BtnBack
                  txt={t('back')}
                  onClick={() => {
                    DialogFn.Submit(t("cancelmsg"), () => {
                      navigate("/News");
                    });
                  }}
                  isRadius={false}
                />
                <Box sx={{ display: "flex" }}>
                  <BtnPreview
                    onClick={() => {
                      setIsShowPreview(true);
                      setPreviewData(GetNewsForm().preview);
                    }}
                    txt={t('preview')}
                    isRadius={false}
                  />
                  <Box sx={{ width: "10px" }}></Box>
                  <BtnCancel
                    txt={t('cancel')}
                    onClick={() => CancelForm()}
                    isRadius={false}
                  />
                  <Box sx={{ width: "10px" }}></Box>
                  {nStatusID !== EnumStatus.Submit && (
                    <>
                      <BtnDraft
                        txt={t('savedraft')}
                        onClick={() =>
                          onSubmit(EnumStatus.Draft, EnumSubmit.Draft, "0")
                        }
                        isRadius={false}
                      />
                      <Box sx={{ width: "10px" }}></Box>
                    </>
                  )}
                  <BtnSave
                    txt={t('submit')}
                    onClick={form.handleSubmit((e) => {
                      onCheckCMS(EnumStatus.Submit, EnumSubmit.Confirm, "0");
                    })}
                    isRadius={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
