import { Grid, Stack } from "@mui/material";
import {
    TextBoxForm,
    DatePickerForm,
    SelectMultipleFormItem,
} from "STM/components/Input";
import { BtnSearchCustom, BtnClearCustom } from "STM/components/Button";
import { options } from "STM/enum/enumSystem";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageFilter = ({ onSearch, onClear }) => {
    const form = useFormContext();
    const [sMinDate, setMinDate] = useState(null);
    const { t } = useTranslation();

    return (
        <div
            style={{
                maxHeight: 200,
                overflow: "auto",
                width: "100%",
                marginBottom: "1em",
            }}
        >
            <Stack
                style={{ padding: "10px" }}
                justifyContent={"end"}
                direction={"column"}
                spacing={1}
                sx={{
                    border: "1px #0168cc solid",
                    borderRadius: "10px",
                    ":before": {
                        content: '""',
                        position: "absolute",
                        left: "12%",
                        top: "2.5em",
                        borderLeft: "5px solid transparent",
                        borderRight: "5px solid transparent",
                        borderBottom: " 5px solid #0168cc",
                    },
                }}
            >
                <Grid
                    container
                    justifyContent={"flex-end"}
                    spacing={1}
                    sx={{ m: "0 !important" }}
                >
                    <Grid item md={7.6} xs={12}>
                        <TextBoxForm
                            name="sLangName"
                            label={t('LangName')}
                            isshowTextCont={false}
                            disabled={false}
                            shrink
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <SelectMultipleFormItem
                            id={"sStatus"}
                            name={"sStatus"}
                            label={t('status')}
                            required={false}
                            disabled={false}
                            options={options.status}
                            limitTags={1}
                            shrink
                        />
                    </Grid>
                    <Grid item md={0.7} xs={2}>
                        <BtnClearCustom onClick={onClear} />
                    </Grid>
                    <Grid item md={0.7} xs={2}>
                        <BtnSearchCustom onClick={onSearch} />
                    </Grid>
                </Grid>
            </Stack>
        </div>
    );
};

export default LanguageFilter;
