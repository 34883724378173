import React from 'react';
import { useState, useEffect, forwardRef, useImperativeHandle, Fragment } from "react";
import { AutocompleteFormprop, AutocompleteFormItemProp, AutocompleteNoFormprop, AsyncAutoCompleteProps, AsyncAutoCompleteFormProps, AsyncAutoCompleteNoFormProps } from './AutocompleteProps';
import { useFormContext, Controller } from "react-hook-form";
import { Autocomplete, TextField, FormHelperText, IconButton, Popper, Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import qs from "qs";
import { AuthToken } from "config/AxiosConfig";
import FormErrors from "STM/components/FormItem/FormErrors";
import { IsNullOrUndefined } from 'STM/utilities/ST_Function';
import { parse, stringify } from 'qs'

export const AutocompleteFormItem = forwardRef((props: AutocompleteFormprop, ref) => {
    const {
        name,
        fullWidth = true,
        fnLoadData,
        onSelect,
    } = props;

    const {
        control,
        getValues,
    } = useFormContext();

    const [txtOption, settxtOption] = useState("กรอกเพื่อค้นหา")
    const [options, setOptions] = useState([] as any[]);
    const [loaded, setloaded] = useState(false);
    const [inputValue, setInputValue] = useState('')
    
    const loadData = async (inputValue_) => {
        settxtOption("กำลังค้นหา")
        if (inputValue_.length >= props.TextFilterCount) {
            let result = await fnLoadData(inputValue_, setloaded);
            result = result.filter(op => op.label.toLowerCase().indexOf(inputValue_.toLowerCase()) !== -1);
            if (result.length > 0) {
                setOptions(result)
            } else {
                setOptions([])
                settxtOption("ไม่พบข้อมูล")
            }
        } else {
            setOptions([])
            settxtOption("ไม่พบข้อมูล")
        }
    };

    useEffect(() => {
        if (inputValue === '' || inputValue === null) {
            setOptions([])
            settxtOption("ไม่พบข้อมูล");
        }
        else {
            if (inputValue.length >= props.TextFilterCount) {
                let val = getValues(name);
                let v = val != null ? val.label : "";
                if (inputValue !== v) {
                    loadData(inputValue);
                }
            }
        }
    }, [inputValue]);

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error } }) => {
                    return (
                        <>
                            <Autocomplete
                                ref={ref}
                                id={name}
                                disabled={props.disabled || null}
                                fullWidth={fullWidth}
                                size={"small"}
                                autoComplete
                                options={options}
                                value={value || null}
                                clearOnBlur
                                noOptionsText={txtOption}
                                disableClearable={props.isClearable || null}
                                inputValue={inputValue}
                                onInputChange={async (event, newInputValue) => {
                                    settxtOption("กรอกเพื่อค้นหา")
                                    setInputValue(newInputValue)
                                    if (newInputValue === "") {
                                        setOptions([])
                                        settxtOption("กรอกเพื่อค้นหา")

                                        props.onClear && props.onClear(event);

                                        return undefined;
                                    } else if (newInputValue.length >= props.TextFilterCount) {
                                        //loadData(newInputValue);
                                    } else {
                                        setOptions([])
                                        settxtOption("ไม่พบข้อมูล")
                                    }
                                }}
                                getOptionLabel={(itemOption: any) => {
                                    return `${itemOption.label}`
                                }}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                sx={{
                                    "label.MuiInputLabel-shrink": {
                                        backgroundColor: "rgba(255,255,255,0.95)",
                                        top: "0px",
                                    },
                                    ".MuiInputLabel-outlined": {
                                        top: "0px",
                                    },
                                    ".MuiInputLabel-asterisk": {
                                        color: "red"
                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name={name}
                                        error={error?.message != null}
                                        required={props.required}
                                        disabled={props.disabled}
                                        // label={props.label}
                                        label={
                                            props.shrink === true
                                              ? props.label
                                              : ""
                                          }
                                        placeholder={!props.shrink ? props.label : ""}
                                        size={"small"}
                                        fullWidth={fullWidth}
                                        style={{ width: '100%' }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Fragment>
                                                    <IconButton style={{ padding: '0px' }} disabled={props.disabled}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </Fragment>
                                            )
                                        }}
                                        InputLabelProps={{
                                            disabled: props.disabled || false,

                                        }}
                                        sx={{
                                            "label.MuiInputLabel-shrink": {
                                                backgroundColor: "rgba(255,255,255,0.95)",
                                                top: "0px",
                                            },
                                            ".MuiInputLabel-outlined": {
                                                top: "0px",
                                            },
                                            ".MuiInputLabel-asterisk": {
                                                color: "red"
                                            },
                                            ".MuiInputBase-adornedEnd": {
                                                paddingRight: "12px !important"
                                            }
                                        }}

                                    />}
                                onBlur={onBlur}
                                onChange={(event, value) => {
                                    onSelect && onSelect(event, value);
                                    onChange(value != null ? value : null);

                                }}
                            />
                            {error ? (
                                <FormHelperText sx={{ color: "red" }}>
                                    {error.message}
                                </FormHelperText>
                            ) : null}
                        </>
                    )
                }
                }
            />
        </>
    );
});
export default AutocompleteFormItem;

export const AutocompleteNoFormItem = forwardRef((props: AutocompleteNoFormprop, ref) => {
    const {
        name,
        fullWidth = true,
        fnLoadData,
        onSelect,
        TextFilterCount = 3,
        isClearable = true
    } = props;
    const [t] = useTranslation();
    const [value, setValue] = useState<string>(props.value);
    const [txtOption, settxtOption] = useState(t("enterToSearch"))
    const [options, setOptions] = useState([] as any[]);
    const [loaded, setloaded] = useState(false);

    const loadData = async (inputValue_) => {
        settxtOption(t("searching"))
        if (inputValue_.length >= TextFilterCount) {
            let result = await fnLoadData(inputValue_, setloaded);
            result = result.filter(op => op.label.indexOf(inputValue_) !== -1);
            if (result.length > 0) {
                setOptions(result || [])
            } else {
                setOptions([])
                settxtOption(t("datanotfound"))
            }
        } else {
            setOptions([])
            settxtOption(t("datanotfound"))
        }
    };
    useEffect(() => {
        setValue(props.value);
        if (props.IsClearList) {
            setOptions([])
            settxtOption(t("datanotfound"))
        }
    }, [props.value])

    useImperativeHandle(
        ref,
        () => ({
            ClearOption(val: AutocompleteFormItemProp) {
                setOptions([])
                settxtOption(t("datanotfound"))
            }
        }),
    )

    function Value() {
        if (value != undefined && value != null) {
            if (typeof (value) != "string") {
                return value[(props.ShowLabel || "label")]
            } else {
                return value
            }
        } else {
            return ""
        }
    }
    return (
        <Autocomplete
            ref={ref}
            id={props.id}
            disabled={props.disabled || null}
            fullWidth={fullWidth}
            size={"small"}
            autoComplete
            options={options}
            value={Value()}
            noOptionsText={txtOption}
            onChange={(event, value) => {
                setValue(value);
                props.onSelect && props.onSelect(event, value);
            }}
            onInputChange={async (event, newInputValue) => {
                settxtOption(t("enterToSearch"))
                if (newInputValue === "") {
                    setOptions([])
                    settxtOption(t("enterToSearch"))
                    setValue(null);
                    return undefined;
                } else if (newInputValue.length >= TextFilterCount) {
                    loadData(newInputValue);
                } else {
                    setOptions([])
                    settxtOption(t("datanotfound"))
                    setValue(null);
                }

            }}
            sx={{
                "label.MuiInputLabel-shrink": {
                    backgroundColor: "rgba(255,255,255,0.95)",
                    top: "0px",
                },
                ".MuiInputLabel-outlined": {
                    top: "0px",
                },
                ".MuiInputLabel-asterisk": {
                    color: "red"
                }
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    name={name}
                    disabled={props.disabled}
                    label={props.label}
                    size={"small"}
                    fullWidth={fullWidth}
                    style={{ width: '100%' }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {value && isClearable &&
                                    <IconButton style={{ padding: '3px', visibility: "hidden" }} disabled={props.disabled} id={props.id + "CloseIcon"}
                                        onClick={(event) => {
                                            setValue(null);
                                            props.onSelect && props.onSelect(event, null);
                                        }}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                }
                                <IconButton style={{ padding: '0px' }} disabled={props.disabled}>
                                    <SearchIcon />
                                </IconButton>
                            </Fragment>
                        )
                    }}

                    onMouseMove={() => {
                        let btCloseIcon = document.getElementById(props.id + "CloseIcon");
                        if (btCloseIcon) {
                            btCloseIcon.style.visibility = "visible";
                        }
                    }}

                    onMouseOut={() => {
                        let btCloseIcon = document.getElementById(props.id + "CloseIcon");
                        if (btCloseIcon) {
                            btCloseIcon.style.visibility = "hidden";
                        }
                    }}

                    InputLabelProps={{
                        disabled: props.disabled || false,
                    }}
                    sx={{
                        "label.MuiInputLabel-shrink": {
                            backgroundColor: "rgba(255,255,255,0.95)",
                            top: "0px",
                        },
                        ".MuiInputLabel-outlined": {
                            top: "0px",
                        },
                        ".MuiInputLabel-asterisk": {
                            color: "red"
                        },
                        ".MuiInputBase-adornedEnd": {
                            paddingRight: "12px !important"
                        }
                    }}
                />
            }
        />
    );
});


export const AsyncAutoCompleteForm = forwardRef((props: AsyncAutoCompleteFormProps, ref) => {
    const {
        name,
        isClearable = true,
        label,
        disabled = false,
        limitTag = 1,
        required = false,
        fullWidth = false,
        onChange,
        onBlur,
        sUrlAPI,
        sParam,
        TextFilterCont = 1,
        externalErrorMessage,
        ParamUrl,
        sMethodAxios = 'GET',
        shrink = false
    } = props;
 
    const {
        control,
        watch,
        setValue,
    } = useFormContext();

    const [inputValue, setInputValue] = useState(null);
    const [optionsValueAuto, setOptionsValueAuto] = useState([] as any);
    const [txtOption, settxtOption] = useState("Enter To Search") as any; //กรอกเพื่อค้นหา

    useEffect(() => {        
        setInputValue(watch(name) ? watch(name).label : null)
        if (watch(name) === null || watch(name) == undefined || watch(name) == "") {
            setOptionsValueAuto([])
        }
    }, [watch(name)])

    useEffect(() => {
        if (inputValue === '' || inputValue === null) {
            setOptionsValueAuto([])
        }
        else {
            const source = axios.CancelToken.source();
            const paramSearch = sParam === undefined ? { "strSearch": inputValue } : { "strSearch": inputValue, "sParam": sParam };
            const paramObj = { ...ParamUrl, ...paramSearch };
            const auth_token = AuthToken.get();
            const ConfigJwt = {
                Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
                Accept: "application/json",
                "Content-Type": "application/json",                
            };

            const newParam = sUrlAPI
            const baseUrl = process.env.REACT_APP_API_URL
            const sPathApi = `${baseUrl}api/${newParam}`
            const url = new URL(sPathApi, window.location.href);
          
            const sNewPath = url.origin + url.pathname + url.search;

            settxtOption("Searching")//กำลังค้นหา
            if(sMethodAxios === "POST")
            {
                axios.post(sNewPath, paramObj,{
                    headers: ConfigJwt,
                    cancelToken: source.token,
                    paramsSerializer: (params) => {
                        return qs.stringify(params);
                      },
                    }).then((response) => {
                        if (response.data != null && response.data.length > 0) {
                            setOptionsValueAuto(response.data || [])
                        }
                        else {
                            setOptionsValueAuto([])
                            settxtOption("No Information Found") //ไม่พบข้อมูล
                        }
                    }).catch((error) => {
                        if (axios.isCancel(error)) return;
                    });
            }
            else  //GET
            {
                axios.get(sNewPath, {
                    headers: ConfigJwt,
                    params: paramObj,
                    cancelToken: source.token,
                    paramsSerializer: (params) => {
                        return qs.stringify(params);
                    },
                    }).then((response) => {
                        if (response.data != null && response.data.length > 0) {
                            setOptionsValueAuto(response.data || [])
                        }
                        else {
                            setOptionsValueAuto([])
                            settxtOption("No Information Found")//ไม่พบข้อมูล
                        }
                    }).catch((error) => {
                        if (axios.isCancel(error)) return;
                    });
            }
           
            return () => source.cancel();
        }
    }, [inputValue])

    const PopperCustom = React.useCallback((props) => {
        return <Popper 
                    {...props} 
                    placement='bottom-start' 
                    disablePortal={true}
                    modifiers={[
                        {
                          name: 'flip',
                          enabled: false,
                          options: {
                            altBoundary: true,
                            rootBoundary: 'document',
                            padding: 8,
                          },
                        },
                      ]}
                />;
    }, []);

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { error } }) => {
                return (
                    <>
                    <Autocomplete
                        ref={ref}
                        id={name}
                        disabled={props.disabled || null}
                        fullWidth={fullWidth}
                        size={"small"}
                        autoComplete
                        options={optionsValueAuto}
                        value={value || null}
                        getOptionLabel={(itemOption: any) => {
                            return `${itemOption.label}`
                        }}
                        filterOptions={(x) => x}
                        PopperComponent={PopperCustom}
                        noOptionsText={txtOption}
                        blurOnSelect
                        includeInputInList
                        filterSelectedOptions
                        disableClearable={!isClearable}
                        limitTags={limitTag}
                        onBlur={onBlur}
                        onChange={(event: any, newValue) => {
                            if (newValue) {
                                if (newValue.value) {
                                    setValue(name, newValue, { shouldValidate: true });
                                    setOptionsValueAuto([]);
                                }
                            } else {
                                setValue(name, null)
                            }
                            if (props.onChange) {
                                props.onChange(newValue);
                            }
                            onChange(newValue != null ? newValue : null);
                        }}
                        onInputChange={async (event, newInputValue) => {
                            settxtOption("Enter To Search")
                            setInputValue(newInputValue);
                            if (newInputValue === null) {
                                setOptionsValueAuto([]);
                                return undefined;
                            } else if (newInputValue.length >= TextFilterCont) {
                                // await handleSearchChange(newInputValue);
                                // loadData(newInputValue);
                            } else {
                                setOptionsValueAuto([]);
                            }
                        }}
                        sx={{
                            ".MuiOutlinedInput-root": {
                                paddingRight: "10px !important"
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name={name}
                                error={error?.message != null}
                                required={props.required}
                                size={"small"}
                                label={
                                    shrink === true
                                      ? label
                                      : ""
                                  }
                                placeholder={!shrink ? label : ""}
                                fullWidth={fullWidth}
                                style={{ width: '100%' }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            <IconButton 
                                                style={{ padding: '0px' }} 
                                                disabled={disabled}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </>
                                    )
                                }}
                                InputLabelProps={{
                                    disabled: disabled || false,
                                }}
                                //add asterisk red
                                sx={{
                                    "label.MuiInputLabel-shrink": {
                                        backgroundColor: "rgba(255,255,255,0.95)",
                                        top: "0px",
                                    },
                                    ".MuiInputLabel-outlined": {
                                        top: "0px",
                                    },
                                    ".MuiInputLabel-asterisk": {
                                        color: "red"
                                    }
                                }}
                            />
                        )
                        }
                        renderOption={(props, option: any) => {
                            return (
                                <li {...props} key={option.value}>
                                    {option.label}
                                </li>
                            );
                        }}
                        renderTags={(tagValue, getTagProps) => {
                            return (
                                <React.Fragment>
                                    {
                                        tagValue.slice(0, limitTag | 1).map((option: any, index) => (
                                            <Chip {...getTagProps({ index })} label={option.label} disabled={disabled || false} />
                                        ))
                                    }
                                </React.Fragment>
                            )
                        }}
                    />
                    {error ? (
                        <FormHelperText sx={{ color: "red" }}>
                            {error.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}}
        />
    )
});

export const AsyncAutoCompleteNoForm = forwardRef((props: AsyncAutoCompleteFormProps, ref) => {
    const {
        name,
        isClearable = true,
        label,
        disabled = false,
        limitTag = 1,
        required = false,
        fullWidth = false,
        onChange,
        sUrlAPI,
        sParam,
        TextFilterCont = 1,
        ParamUrl,
        sMethodAxios = 'GET',
        value,
    } = props; 
  
    const [inputValue, setInputValue] = useState(null);
    const [optionsValueAuto, setOptionsValueAuto] = useState([] as any);
    const [txtOption, settxtOption] = useState("Enter To Search") as any; //กรอกเพื่อค้นหา

    useEffect(() => {
        setInputValue(value ? value.label : null)
        if (value === null || value == undefined || value == "") {
            setOptionsValueAuto([])
        }
    }, [value])

    useEffect(() => {
        if (inputValue === '' || inputValue === null) {
            setOptionsValueAuto([])
        }
        else {
            const source = axios.CancelToken.source();
            const paramSearch = sParam === undefined ? { "strSearch": inputValue } : { "strSearch": inputValue, "sParam": sParam };
            const paramObj = { ...ParamUrl, ...paramSearch };
            const auth_token = AuthToken.get();
            const ConfigJwt = {
                Authorization: IsNullOrUndefined(auth_token) ? "" : `Bearer ${auth_token}`,
                Accept: "application/json",
                "Content-Type": "application/json",                
            };

            const newParam = sUrlAPI
            const baseUrl = process.env.REACT_APP_API_URL
            const sPathApi = `${baseUrl}api/${newParam}`
            const url = new URL(sPathApi, window.location.href);
          
            const sNewPath = url.origin + url.pathname + url.search;

            settxtOption("Searching")//กำลังค้นหา
            if(sMethodAxios === "POST")
            {
                axios.post(sNewPath, paramObj,{
                    headers: ConfigJwt,
                    cancelToken: source.token,
                    paramsSerializer: (params) => {
                        return qs.stringify(params);
                    },
                    }).then((response) => {
                        if (response.data != null && response.data.length > 0) {
                            setOptionsValueAuto(response.data || [])
                        }
                        else {
                            setOptionsValueAuto([])
                            settxtOption("No Information Found") //ไม่พบข้อมูล
                        }
                    }).catch((error) => {
                        if (axios.isCancel(error)) return;
                    });
            }
            else  //GET
            {
                axios.get(sNewPath, {
                    headers: ConfigJwt,
                    params: paramObj,
                    cancelToken: source.token,
                    paramsSerializer: (params) => {
                        return qs.stringify(params);
                    },
                    }).then((response) => {
                        if (response.data != null && response.data.length > 0) {
                            setOptionsValueAuto(response.data || [])
                        }
                        else {
                            setOptionsValueAuto([])
                            settxtOption("No Information Found")//ไม่พบข้อมูล
                        }
                    }).catch((error) => {
                        if (axios.isCancel(error)) return;
                    });
            }
           
            return () => source.cancel();
        }
    }, [inputValue])

    
    const PopperCustom = React.useCallback((props) => {
        return <Popper 
                    {...props} 
                    placement='bottom-start' 
                    disablePortal={true}
                    modifiers={[
                        {
                          name: 'flip',
                          enabled: false,
                          options: {
                            altBoundary: true,
                            rootBoundary: 'document',
                            padding: 8,
                          },
                        },
                      ]}
                />;
    }, []);

    return (
        <Autocomplete
            id={name}
            {...props}
            key={props.name}
            getOptionLabel={(itemOption: any) => {
                return `${itemOption.label}`
            }}
            filterOptions={(x) => x}
            options={optionsValueAuto}
            autoComplete
            PopperComponent={PopperCustom}
            size='small'
            noOptionsText={txtOption}
            blurOnSelect
            includeInputInList
            filterSelectedOptions
            disabled={disabled}
            disableClearable={!isClearable}
            limitTags={limitTag}
            value={value || null}
            onChange={(event: any, newValue) => {
                if (newValue) {
                    if (newValue.value) {
                        onChange && onChange(newValue);
                        setOptionsValueAuto([]);
                    }
                } else {
                    onChange && onChange(null);
                }
                onChange && onChange(newValue);
            }}
            onInputChange={async (event, newInputValue) => {
                settxtOption("Enter To Search")
                setInputValue(newInputValue);
                if (newInputValue === "") {
                    setOptionsValueAuto([]);
                    return undefined;
                } else if (newInputValue.length >= TextFilterCont) {
                    // await handleSearchChange(newInputValue);
                    // loadData(newInputValue);
                } else {
                    setOptionsValueAuto([]);
                }
            }}
            sx={{
                ".MuiOutlinedInput-root": {
                    paddingRight: "10px !important"
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    required={props.required}
                    disabled={props.disabled}
                    label={props.label}
                    size={"small"}
                    fullWidth={fullWidth}
                    style={{ width: '100%' }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                <IconButton style={{ padding: '0px' }} disabled={props.disabled}>
                                    <SearchIcon />
                                </IconButton>
                            </Fragment>
                        )
                    }}
                    InputLabelProps={{
                        disabled: props.disabled || false,
                    }}
                    sx={{
                        "label.MuiInputLabel-shrink": {
                            backgroundColor: "rgba(255,255,255,0.95)",
                            top: "0px",
                        },
                        ".MuiInputLabel-outlined": {
                            top: "0px",
                        },
                        ".MuiInputLabel-asterisk": {
                            color: "red"
                        },
                        ".MuiInputBase-adornedEnd": {
                            paddingRight: "12px !important"
                        }
                    }}
                />
            )}
            renderOption={(props, option: any) => {
                return (
                    <li {...props} key={option.value}>
                        {option.label}
                    </li>
                );
            }}
            renderTags={(tagValue, getTagProps) => {
                return (
                    <React.Fragment>
                        {
                            tagValue.slice(0, limitTag | 1).map((option: any, index) => (
                                <Chip {...getTagProps({ index })} label={option.label} disabled={disabled || false} />
                            ))
                        }
                    </React.Fragment>
                )
            }}
        />
    )
});