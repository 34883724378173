/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { Button, FormHelperText } from "@mui/material";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import axios from "axios";
import DisplayListRow from "./components/List/DisplayListRow";
import {
  Extension,
  FnDialog,
  IsNullOrUndefined,
} from "STM/utilities/ST_Function";
import { AlertTitle, AlertMsg } from "config/AlertMsgConfig";
import { AuthToken } from "config/AxiosConfig";
import { FnBlock_UI } from "STM/components/BlockUI";
import { SwAlert } from "STM/components/Alert";
import { UploadProps, cFile } from "./UploadFileProps";
import DisplayTableFile from "./components/Table/DisplayTableFile";
import DisplayGallery from "./components/Gallery/DisplayGallery";
import ModalError from "./components/PopUp/ModalError";
import "./StyleUploadFile.css";
import { useFormContext, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import { log } from "console";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { useTranslation } from "react-i18next";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const UploadFile = (props: UploadProps) => {
  const { name } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const DialogFn = FnDialog();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [arrFileUpload, setarrFileUpload] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrExtension, setarrExtension] = useState(
    props.Extension as any | any
  );
  const [nLimitFile, setnLimitFile] = useState(props.nLimitFile || 10);
  const [sLimitFile, setsLimitFile] = useState(props.sLimitFile || "MB");
  const [sExtension, setsExtension] = useState("");
  const [sAccept, setsAccept] = useState("");
  const [IsFolder, setIsFolder] = useState(props.IsFolder || false);
  const [IsFile, setIsFile] = useState(props.IsFile || false);
  const [IsComplete, setIsComplete] = useState(false);
  const [arrMimeType, setarrMimeType] = useState([]);
  const [arrSentFileComplete, setarrSentFileComplete] = useState([]);
  const [isOpenError, setisOpenError] = useState(false);
  const [arrMessageError, setarrMessageError] = useState([] as any);
  const [IsopenPopUp, setIsopenPopUp] = useState(false);

  // useEffect(() => {
  //   console.log("arrFile", arrFileUpload);
  //   if (arrFileUpload.length === 0) {
  //     setValue(name, null);
  //   } else {
  //     setValue(name, "Value");
  //   }
  // }, [arrFileUpload]);

  useEffect(() => {
    let arrFile = props.arrFile || [];
    setarrFileUpload(arrFile);
    if (arrFile.length === 0) {
      setValue(name, null);
    } else {
      setValue(name, "Value");
    }
  }, [props.arrFile]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    // Do something with the files
    let test =
      acceptedFiles.length || rejectedFiles.length
        ? `Accepted ${acceptedFiles.length}, rejected ${rejectedFiles.length} files`
        : "Try dropping some files.";
  }, []);

  //#region useEffect

  useEffect(() => {
    let arrTemp = [];
    let arrExt = [Extension as any];
    if (arrExtension === null || arrExtension === undefined) {
      let arrExtTemp = [];
      arrExt.forEach((f) => {
        for (var key in f) {
          let i = 0;
          for (var v in f[key]) {
            if (f[key][i] !== null && f[key][i] !== "") {
              arrTemp.push(f[key][i]);
            }
            i++;
          }
        }
      });
      arrTemp.push(arrExtTemp);
      setarrExtension([arrTemp]);
    } else {
      arrTemp = arrExtension;
      setarrExtension([arrTemp]);
    }
    let sFileType = "",
      sAcceptTemp = "";
    arrTemp.forEach((f, inx) => {
      if (f !== "") {
        sFileType += (inx === 0 ? "." : ", .") + f;
        if (sAcceptTemp !== "") {
          sAcceptTemp += ", ";
        }
        sAcceptTemp += "." + f;
      }
    });
    setsExtension(sFileType);
    setsAccept(sAcceptTemp);
    if (props.nLimitFile) {
      setnLimitFile(props.nLimitFile);
    }
  }, []);

  useEffect(() => {
    if (props.onClearFile) {
      props.onClearFile.current = () => {
        handleClickDeleteAllFile();
      };
    }
  }, [props.onClearFile]);

  useEffect(() => {
    let isCheckFile =
      arrMimeType.length > 0 &&
      arrFileUpload.length > 0 &&
      arrMimeType.length === arrFileUpload.length;
    if (isCheckFile) {
      if (arrMimeType.indexOf(false) === -1) {
        arrFileUpload.forEach(async (f, indx) => {
          await SendAPIFile(f, indx);
          setarrMimeType([]);
        });
      } else {
        // Delete file not upload
        clearNotUploadFile();
        setarrMimeType([]);
      }
    }
  }, [arrMimeType]);

  useEffect(() => {
    if (IsComplete) {
      props.setarrFile([...arrFileUpload]);
    }
  }, [IsComplete]);

  useEffect(() => {
    if (props.isFileChange) {
      setarrFileUpload(props.arrFile);
    } else {
      if (
        arrFileUpload.length === 0 &&
        props.arrFile != null &&
        props.arrFile.length > 0
      ) {
        setarrFileUpload(props.arrFile);
      }
    }
  }, [props.arrFile]);

  useEffect(() => {
    let arrFile = arrFileUpload.filter((w) => w.IsCompleted);
    props.setarrFile(arrFile);
  }, [arrSentFileComplete]);

  //#endregion

  //#region Function
  const CheckMimeFileType = async (arrFile) => {
    //#region Check MIME FILE 2
    if (window.FileReader && window.Blob) {
      const loadMime = (file, callback) => {
        let mimes = [
          {
            mime: "image/jpeg",
            pattern: [0xff, 0xd8, 0xff],
            mask: [0xff, 0xff, 0xff],
          },
          {
            mime: "image/png",
            pattern: [0x89, 0x50, 0x4e, 0x47],
            mask: [0xff, 0xff, 0xff, 0xff],
          },
          {
            mime: "image/gif",
            pattern: [0x47, 0x49, 0x46, 0x38],
            mask: [0xff, 0xff, 0xff, 0xff],
          },
          {
            mime: "application/pdf",
            pattern: [0x25, 0x50, 0x44, 0x46],
            mask: [0xff, 0xff, 0xff, 0xff],
          },
          {
            mime: "video/avi",
            pattern: [0x52, 0x49, 0x46, 0x46], //+ 41 56 49 20
            mask: [0xff, 0xff, 0xff, 0xff],
          },
          {
            mime: "text/plain UTF-16BE BOM",
            pattern: [0xfe, 0xff, 0x00, 0x00],
            mask: [0xff, 0xff, 0x00, 0x00],
          },
          {
            mime: "text/plain UTF-16LE BOM",
            pattern: [0xff, 0xfe, 0x00, 0x00],
            mask: [0xff, 0xff, 0x00, 0x00],
          },
          {
            mime: "text/plain UTF-8 BOM",
            pattern: [0xef, 0xbb, 0xbf, 0x00],
            mask: [0xff, 0xff, 0xff, 0x00],
          },
        ];

        const check = (bytes, mime) => {
          for (var i = 0, l = mime.mask.length; i < l; ++i) {
            if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
              return false;
            }
          }
          return true;
        };

        let blob = file.slice(0, 4); //read the first 4 bytes of the file

        let reader = new FileReader();
        reader.onloadend = function (e: any) {
          if (e.target.readyState === FileReader.DONE) {
            let bytes = new Uint8Array(e.target.result);
            for (var i = 0, l = mimes.length; i < l; ++i) {
              if (check(bytes, mimes[i])) return callback(mimes[i].mime);
            }

            return callback("unknown");
          }
        };
        reader.readAsArrayBuffer(blob);
      };

      arrFile.forEach((file) => {
        if (file.IsNew) {
          //New File
          loadMime(file, function (mime) {
            if (mime) {
              let arrMimeFileType = mime.split("/");
              let isCheckType =
                arrMimeFileType.length > 0 &&
                arrExtension[0].indexOf(arrMimeFileType[1]) > -1;
              if (isCheckType) {
                // ถูก Type
                let arrTemp = arrMimeType;
                arrTemp.push(true);
                setarrMimeType([...arrTemp]);
              } else {
                let arrTemp = arrMimeType;
                arrTemp.push(false);
                setarrMimeType([...arrTemp]);
                SwAlert.Warning(
                  AlertTitle.Warning,
                  `Original file type not include in "${sExtension}" !`,
                  () => {}
                );
                return false;
              }
            }
          });
        } else {
          //Old File ไม่ต้องเช็ค Mime Type
          let arrTemp = arrMimeType;
          arrTemp.push(true);
          setarrMimeType([...arrTemp]);
        }
      });
    } else {
      console.log("Can't Check MIME Type");
    }

    //#endregion
  };

  const SendCropImage = async (sUrl, base64, nFile_ID) => {
    const auth_token = AuthToken.get();
    const newParam = "Docman/CropImageUploadFile";
    const baseUrl = process.env.REACT_APP_API_URL;
    const sPathApi = `${baseUrl}api/${newParam}`;
    const url = new URL(sPathApi, window.location.href);
    const sNewPath = url.origin + url.pathname + url.search;

    await axios({
      url: sNewPath,
      method: "post",
      data: {
        sOldPath: sUrl,
        sBase64: base64,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: IsNullOrUndefined(auth_token)
          ? ""
          : `Bearer ${auth_token}`,
      },
    })
      .then(function (response) {
        setIsopenPopUp(false);
        let nIdexFile = arrFileUpload.findIndex((f) => f.nFile_ID == nFile_ID);
        if (nIdexFile > -1) {
          arrFileUpload[nIdexFile].sCropFileLink = response.data.sCropFileLink;
          arrFileUpload[nIdexFile].sCropPath = response.data.sCropPath;
          setarrFileUpload([...arrFileUpload]);
        }
      })
      .catch(function (error) {
        console.log("error Crop : ", error);
      });
  };

  const SendAPIFile = async (itemFile, index = 1) => {
    if (!itemFile.IsCompleted) {
      const formPayload = new FormData();
      itemFile.IsProgress = true;
      itemFile.sProgress = "0";
      formPayload.append("file", itemFile);
      if (props.dataID) {
        formPayload.append("dataID", props.dataID);
      }
      setIsComplete(false);
      try {
        BlockUI();
        const auth_token = AuthToken.get();

        const newParam = props.apiPath
          ? props.apiPath
          : "Docman/UploadFileToTemp";
        const baseUrl = process.env.REACT_APP_API_URL;
        const sPathApi = `${baseUrl}api/${newParam}`;
        const url = new URL(sPathApi, window.location.href);

        const sNewPath = url.origin + url.pathname + url.search;

        await axios({
          url: sNewPath,
          method: "post",
          data: formPayload,
          params: {
            sFolderTemp: props.sFolderTemp ?? "",
            isResize: props.IsResize ?? false, //Image Only
            nWidthResize: props.WidthResize ?? null,
            nHeigthResize: props.HeigthResize ?? null,
            nIndex: index,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: IsNullOrUndefined(auth_token)
              ? ""
              : `Bearer ${auth_token}`,
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;

            const percentageProgress = Math.floor((loaded / total) * 100);
            itemFile.sProgress = percentageProgress + "";
            itemFile.IsCompleted = false;
            setIsComplete(false);
          },
        })
          .then(function (response) {
            UnBlockUI();
            itemFile.sMsg = response.data.sMsg;
            itemFile.IsCompleted = response.data.IsCompleted;

            if (response.data.IsCompleted) {
              itemFile.IsProgress = false;
              itemFile.sFileLink = response.data.sFileLink;
              itemFile.sCropFileLink = response.data.sCropFileLink;
              itemFile.sCropPath = response.data.sCropPath;
              itemFile.sRootURL = process.env.REACT_APP_API_URL;
              itemFile.sFolderName = "";
              itemFile.sFileName = response.data.sFileName;
              itemFile.sSysFileName = response.data.sSaveToFileName;
              itemFile.IsNewTab = false;
              itemFile.sPath = response.data.sPath;
              itemFile.sSaveToPath = response.data.sSaveToPath;
              itemFile.sCrop = response.data.sUrl;
              itemFile.sUrl = response.data.sUrl;
              itemFile.nID = response.data.nID;
              setIsComplete(true);

              let arrComplete = arrSentFileComplete;
              arrComplete.push(true);
              setarrSentFileComplete([...arrComplete]);
            } else if (
              response.data.IsCompleted === true &&
              response.data.sMsg === ""
            ) {
              let oItemError = {
                ...itemFile,
                sFileName: itemFile.sFileName,
                Cause: itemFile.sMsg,
              };

              setarrMessageError((value) => [...value, ...[oItemError]]);
            }
          })
          .catch(function (error) {
            setIsComplete(false);
            itemFile.IsProgress = false;
            itemFile.IsCompleted = false;
            let oItemError = {
              sFileName: itemFile.sFileName,
              Cause: error.message,
            };
            setarrMessageError((value) => [...value, ...[oItemError]]);
          });
      } catch (error) {
        itemFile.IsProgress = false;
        itemFile.IsCompleted = false;
        let oItemError = {
          sFileName: itemFile.sFileName,
          Cause: "Error",
        };
        setarrMessageError((value) => [...value, ...[oItemError]]);
      }
    }
    return itemFile;
  };

  const onDeleteFileInLocation = async (nFile_ID) => {
    let oFileID = arrFileUpload.filter((f) => f.nFile_ID === nFile_ID);
    if (oFileID.length > 0) {
      let sPath = oFileID[0].sPath + "/" + oFileID[0].sSysFileName;
      let Param = {
        sPath: sPath,
      };
      const auth_token = AuthToken.get();

      const baseUrl = process.env.REACT_APP_API_URL;
      const sPathApi = `${baseUrl}api/Docman/DeleteInTemp`;
      const url = new URL(sPathApi, window.location.href);
      const sNewPath = url.origin + url.pathname + url.search;

      axios({
        url: sNewPath,
        method: "post",
        params: Param,
        headers: {
          Authorization: IsNullOrUndefined(auth_token)
            ? ""
            : `Bearer ${auth_token}`,
        },
      });
    }
  };

  const clearNotUploadFile = () => {
    //Delete arrFile
    let arrTemp = arrFileUpload.filter((f) => f.IsCompleted === true);
    setarrFileUpload(arrTemp);
  };

  const handleClickFile = async (arrFileItem) => {
    let arrTemp = [];
    arrFileUpload.forEach((val) => arrTemp.push(Object.assign({}, val)));
    arrTemp = [...arrTemp, ...arrFileItem];

    let ExtImage = props.Extension == Extension.Image;
    if (ExtImage) {
      //ถ้าแนบไฟล์แบบ Image ให้เช็ค Original File ด้วย
      //Check Mime Type
      CheckMimeFileType(arrFileItem);
      setarrFileUpload(arrFileItem);
    } else {
      let IsPass = true;
      await Promise.all(
        arrFileItem.map(async (itemFile, ind) => {
          if (!itemFile.IsCompleted && itemFile.IsNew) {
            let data = await SendAPIFile(itemFile, ind);
            itemFile = data;
            setarrMimeType([]);
            let isCheckCon =
              ((data.IsCompleted === false && data.sMsg === "") ||
                (data.IsCompleted === false && data.IsProgress === false)) &&
              IsPass === true;
            if (isCheckCon) {
              IsPass = false;
            }
          }
        })
      );

      //จะเอาแค่ที่อัปโหลดผ่านเท่านั้น
      let dataComplete = arrFileItem.filter((f) => f.IsCompleted === true);
      if (dataComplete.length > 0) {
        dataComplete.forEach((f, i) => {
          if (f.IsNew) {
            f.sFileMode = "Insert";
            f.sFileDetail = "";
            f.nFile_ID = i + 1;
          }
        });
      }
      setarrFileUpload(dataComplete);

      if (!IsPass) {
        setisOpenError(true);
      }
    }
  };

  const handleClickReload = async (rowData) => {
    // await handleClickFile([rowData]);
    // arrFileUpload.forEach((f) => {
    //   if (f.nFile_ID == nFile_ID) {
    //     return SendAPIFile(f);
    //   }
    // });
    // props.setarrFile(arrFileUpload);
  };

  const SaveDetailFile = (nFile_ID, detail) => {
    let oFile = arrFileUpload.find((w) => w.nFile_ID === nFile_ID);
    oFile.sFileDetail = detail;
  };

  const handleClickDeleteFile = (nFile_ID, isPopUp = true) => {
    if (isPopUp) {
      DialogFn.SubmitWarning(t("deletemsg"), () => {
        let arrNew = arrFileUpload.filter((w) => w.nFile_ID !== nFile_ID);
        setarrFileUpload(arrNew);
        // Axios Delete File
        onDeleteFileInLocation(nFile_ID);
        props.setarrFile(arrNew);
      });
    } else {
      let arrNew = arrFileUpload.filter((w) => w.nFile_ID !== nFile_ID);
      setarrFileUpload(arrNew);
      // Axios Delete File
      onDeleteFileInLocation(nFile_ID);
      props.setarrFile(arrNew);
    }
  };

  const handleClickDeleteAllFile = () => {
    setarrFileUpload([]);
    props.setarrFile([]);
  };

  const _addDirectory = (node) => {
    if (node) {
      node.webkitdirectory = true;
      node.directory = true;
    }
  };

  const onHandleUploadFolder = async (e) => {
    setAnchorEl(null);
    setarrMessageError([]);

    // let arrTemp = arrFileUpload as cFile | any;
    let arrTemp = [];
    arrFileUpload.forEach((val) => arrTemp.push(Object.assign({}, val)));
    //Check File Size
    for (let i = 0; i < e.target.files.length; i++) {
      if (
        parseInt((e.target.files[i].size / 1000000).toFixed(1)) > nLimitFile
      ) {
        e.preventDefault();
        DialogFn.Warning(
          `${t('limitfilewarning')} ${nLimitFile} ${sLimitFile}.`,
        );
        return false;
      }
    }

    for (let i = 0; i < e.target.files.length; i++) {
      const d = new Date();
      let genID = d.getMinutes() + d.getSeconds() + d.getMilliseconds() + "";

      let objFile = e.target.files[i] as cFile | any;
      objFile.sFileName = e.target.files[i].name;
      objFile.nFile_ID = i + "_" + genID;
      objFile.IsCompleted = false;
      objFile.IsProgress = true;
      objFile.sSizeName = (e.target.files[i].size / 1000000).toFixed(1) + "MB";
      objFile.sFolderName =
        objFile.webkitRelativePath != ""
          ? objFile.webkitRelativePath.replace("/" + objFile.sFileName, "")
          : "";
      objFile.sProgress = "0";
      let arrfilename = (objFile.sFileName + "").split(".");
      objFile.sFileType = arrfilename[arrfilename.length - 1].toLowerCase();
      objFile.IsNew = true;
      objFile.sCropFileLink = "";
      objFile.sCropPath = "";
      arrTemp.push(objFile);
    }

    e.target.files = null;

    let IsPass = true;
    arrTemp.forEach((item) => {
      if (arrExtension[0].indexOf(item.sFileType.toLowerCase()) < 0) {
        IsPass = false;
      }
    });
    if (!IsPass) {
      clearNotUploadFile();
      e.preventDefault();
      SwAlert.Warning(
        AlertTitle.Warning,
        `Upload file only "${sExtension}" !`,
        () => {}
      );
    } else {
      setarrFileUpload(arrTemp);
      await handleClickFile(arrTemp);
    }
  };

  const onHandleUploadFile = async (e) => {
    setAnchorEl(null);
    setarrMessageError([]);
    // let arrTemp = arrFileUpload as cFile | any;
    let arrTemp = [];
    arrFileUpload.forEach((val) => arrTemp.push(Object.assign({}, val)));

    let arrNewFile = [] as cFile | any;
    let nSizeFileType = sLimitFile === "GB" ? 1000000000 : 1000000;

    //Check File Size
    for (let i = 0; i < e.target.files.length; i++) {
      if (
        parseInt((e.target.files[i].size / nSizeFileType).toFixed(1)) >
        nLimitFile
      ) {
        e.preventDefault();
        DialogFn.Warning(
          `${t('limitfilewarning')} ${nLimitFile} ${sLimitFile}.`,
        );
        return false;
      }
    }
    for (let i = 0; i < e.target.files.length; i++) {
      const dNow = new Date();
      let genID =
        dNow.getMinutes() + dNow.getSeconds() + dNow.getMilliseconds() + "";

      let objFile = e.target.files[i] as cFile | any;

      objFile.sFileName = e.target.files[i].name;
      objFile.nFile_ID = i + "_" + genID;
      objFile.IsCompleted = false;
      objFile.IsProgress = true;
      objFile.sSizeName =
        (e.target.files[i].size / nSizeFileType).toFixed(1) + "MB";
      objFile.sFolderName =
        objFile.webkitRelativePath != ""
          ? objFile.webkitRelativePath.replace("/" + objFile.sFileName, "")
          : "";
      objFile.sProgress = "0";

      let arrfilename = (objFile.sFileName + "").split(".");
      objFile.sFileType = arrfilename[arrfilename.length - 1].toLowerCase();
      objFile.IsNew = true;
      arrTemp.push(objFile);
      arrNewFile.push(objFile);
    }
    e.target.files = null;
    let IsPass = true,
      IsPassName = true,
      IsPassNameOthre = true;

    arrTemp.forEach((item) => {
      if (arrExtension[0].indexOf(item.sFileType.toLowerCase()) < 0) {
        IsPass = false;
      }

      let arrFileName = item.sFileName.split("_");
      if (!IsNullOrUndefined(props.sFileName)) {
        if (arrFileName.length > 2) {
          if (!IsNullOrUndefined(props.sFileName)) {
            if (arrFileName[0].toLowerCase() != props.sFileName.toLowerCase()) {
              IsPassName = false;
            }
          } else {
            if (arrFileName[0].toLowerCase() === "sessions") {
              IsPassNameOthre = false;
            }
          }
        } else {
          IsPassName = false;
        }
      } else {
        if (arrFileName[0].toLowerCase() === "sessions") {
          IsPassNameOthre = false;
        }
      }
    });

    if (!IsPass) {
      clearNotUploadFile();
      e.preventDefault();
      SwAlert.Warning(
        AlertTitle.Warning,
        `Upload file only "${sExtension}" !`,
        () => {}
      );
    } else {
      if (!IsPassName && !IsNullOrUndefined(props.sFileName)) {
        clearNotUploadFile();
        e.preventDefault();
        SwAlert.Warning(
          AlertTitle.Warning,
          `Upload file name "` + props.sFileName + `" only.`,
          () => {}
        );
      } else {
        if (IsNullOrUndefined(props.sFileName)) {
          if (!IsPassNameOthre) {
            clearNotUploadFile();
            e.preventDefault();
            SwAlert.Warning(
              AlertTitle.Warning,
              `Upload can't file name sessions.`,
              () => {}
            );
          } else {
            setarrFileUpload(arrTemp);
            await handleClickFile(arrTemp);
          }
        } else {
          setarrFileUpload(arrTemp);
          await handleClickFile(arrTemp);
        }
      }
    }
  };

  const handleClick = (event) => {
    props.IsMultiple === false
      ? document
          .getElementById("contained-button-file" + props.keyID || "")
          .click()
      : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //#endregion

  const JsxButtonUpload = () => {
    return (
      <Grid
        container
        direction={props.IsDragDrop ? "column" : "row"}
        justifyContent="flex-start"
        alignItems="center"
        style={
          props.IsHiddenUploadBox
            ? { display: "none" }
            : {
                paddingTop: "0px",
                textAlign: props.IsDragDrop ? "center" : "left",
              }
        }
      >
        <Grid item xs={12} sm={"auto"} sx={{ paddingRight: "20px" }}>
          <Grid container>
          <Grid item xs={"auto"}>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              size="small"
              {...register(name)}
              onClick={handleClick}
              startIcon={<CloudUploadIcon />}
              disabled={
                (props.IsMultiple !== true && arrFileUpload.length > 0) ||
                props.disabled
              }
              className={`btn-uploadfile ${
                props.disabled ? "btn-uploadfile-disabled" : ""
              }`}
            >
              {t("uploadfile")}
            </Button>
          </Grid>
          <Grid item xs={"auto"} sx={{ml:0.5}}>
            <Grid item xs={12}>
              <Typography variant="caption">
                {`Allowed file types: ${sExtension}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                {`File size limits up to ${nLimitFile} ${sLimitFile}`}
              </Typography>
            </Grid>
            {props.sRemark ? (
              <Grid item xs={12} sm={"auto"}>
                <Typography variant="caption">{props.sRemark}</Typography>
              </Grid>
            ) : null}
          </Grid>
          </Grid>
          
        </Grid>
      </Grid>
    );
  };

  const handleClickDelete = () => {
    DialogFn.SubmitWarning(t("clearfilewarning"), () => {
      setarrFileUpload([]);
      props.setarrFile([]);
    });
  };

  const JsxButtonDelete = () => {
    return (
      <Grid
        container
        direction={props.IsDragDrop ? "column" : "row"}
        justifyContent="flex-start"
        alignItems="center"
        style={
          props.IsHiddenUploadBox
            ? { display: "none" }
            : {
                paddingTop: "0px",
                textAlign: props.IsDragDrop ? "center" : "left",
              }
        }
        sx={{ mt: 1 }}
      >
        <Grid item xs={12}>
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            size="small"
            {...register(name)}
            onClick={handleClickDelete}
            startIcon={<DeleteForeverIcon />}
            disabled={
              (props.IsMultiple !== true && arrFileUpload.length > 0) ||
              props.disabled
            }
            className={`btn-deletefile ${
              props.disabled ? "btn-deletefile-disabled" : ""
            }`}
          >
            <span className="button-text">{"Clear File"}</span>
            <span className="animation">
              <span className="paper-wrapper">
                <span className="paper"></span>
              </span>
              <span className="shredded-wrapper">
                <span className="shredded"></span>
              </span>
              <span className="lid"></span>
              <span className="can">
                <span className="filler"></span>
              </span>
            </span>
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Fragment>
      <Typography>
        <span
          style={{
            fontWeight: 400,
            fontSize: "1em",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {props.sTitle || ""}
        </span>
        {props.IsRequired && <span style={{ color: "red" }}> *</span>}
        {props.sSubTitle && (
          <span style={{ fontSize: "13px", color: "#B3B3B3" }}>
            {props.sSubTitle}
          </span>
        )}
      </Typography>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        style={
          props.IsHiddenUploadBox ? { display: "none" } : { paddingTop: "0px" }
        }
      >
        {props.IsDragDrop ? (
          <Grid item xs={12}>
            <Dropzone
              multiple={props.IsMultiple}
              disabled={
                (props.IsMultiple !== true && arrFileUpload.length > 0) ||
                props.disabled
              }
              onDrop={onDrop}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="drag-drop-container">
                  <div
                    {...getRootProps({
                      className: "dropzone",
                    })}
                  >
                    <input {...getInputProps()} />
                    <p className="s-paragraph">
                      Drag and drop files here, or click to upload file
                    </p>
                  </div>
                  <p className="s-paragraph">or</p>
                  <div>{JsxButtonUpload()}</div>
                </div>
              )}
            </Dropzone>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              {JsxButtonUpload()}
            </Grid>
          </>
        )}
      </Grid>
      {errors && errors[name] && arrFileUpload.length === 0 ? (
        <FormHelperText sx={{ color: "red" }}>
          {String(errors[name].message)}
        </FormHelperText>
      ) : null}
      {/* Display File Upload */}
      <Grid container spacing={2}>
        {props.modeDisplay === "table" && arrFileUpload.length > 0 ? (
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <DisplayTableFile
              arrFile={arrFileUpload}
              onDelete={handleClickDeleteFile}
              disabled={props.disabled}
              onOpenFile={props.onOpenFile}
              IsCrop={props.IsCrop}
              IsHiddenUploadBox={props.IsHiddenUploadBox}
              setStartVideoOn={props.setStartVideoOn}
              nStartVideoOn={props.nStartVideoOn}
              CannotSkipForward={props.CannotSkipForward}
              onVideoEnd={props.onVideoEnd}
              nRowperpageTable={props.nRowperpageTable}
              SendCropImage={SendCropImage}
              IsopenPopUp={IsopenPopUp}
              setIsopenPopUp={setIsopenPopUp}
            />
          </Grid>
        ) : props.modeDisplay === "gallery" && arrFileUpload.length > 0 ? (
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <DisplayGallery
              arrFile={arrFileUpload}
              onDelete={handleClickDeleteFile}
              disabled={props.disabled}
              onOpenFile={props.onOpenFile}
              IsCrop={props.IsCrop}
              IsHiddenUploadBox={props.IsHiddenUploadBox}
              setStartVideoOn={props.setStartVideoOn}
              nStartVideoOn={props.nStartVideoOn}
              CannotSkipForward={props.CannotSkipForward}
              onVideoEnd={props.onVideoEnd}
              IsopenPopUp={IsopenPopUp}
              setIsopenPopUp={setIsopenPopUp}
              SendCropImage={SendCropImage}
              IsCanAddDetail={props.IsCanAddDetail}
              onSaveDetail={SaveDetailFile}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <DisplayListRow
              arrFile={arrFileUpload}
              SetarrFile={setarrFileUpload}
              onDelete={handleClickDeleteFile}
              onLoad={handleClickReload}
              IsCanDel={props.IsCanDel ? props.IsCanDel : true}
              IsDrag={props.IsDrag || false}
              disabled={props.disabled}
              onOpenFile={props.onOpenFile}
              IsCrop={props.IsCrop}
              IsHiddenUploadBox={props.IsHiddenUploadBox}
              setStartVideoOn={props.setStartVideoOn}
              nStartVideoOn={props.nStartVideoOn}
              CannotSkipForward={props.CannotSkipForward}
              onVideoEnd={props.onVideoEnd}
              IsopenPopUp={IsopenPopUp}
              SendCropImage={SendCropImage}
              setIsopenPopUp={setIsopenPopUp}
            />
          </Grid>
        )}
      </Grid>
      {
        <Grid item xs={12}>
          {props.IsMultiple && arrFileUpload.length > 1 && JsxButtonDelete()}
        </Grid>
      }
      {/* SubMenu Option (Multi / Folder) */}
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {IsFolder ? (
          <StyledMenuItem>
            <input
              id={"contained-button-file-folder" + props.keyID || ""}
              name={"contained-button-file-folder" + props.keyID || ""}
              onChange={async (e) => {
                onHandleUploadFolder(e);
              }}
              onClick={(e: any) => {
                e.target.value = "";
              }}
              ref={(node) => _addDirectory(node)}
              multiple
              type="file"
              hidden
              accept={sAccept}
            />
            <label
              htmlFor={"contained-button-file-folder" + props.keyID || ""}
              style={{
                width: "100%",
                display: "contents",
                cursor: "pointer",
              }}
            >
              <ListItemIcon style={{ cursor: "pointer" }}>
                <DriveFolderUploadIcon className={classes.IConColor} />
              </ListItemIcon>
              <ListItemText
                primary={`${t("folderupload")}`}
                style={{ cursor: "pointer" }}
              />
            </label>
          </StyledMenuItem>
        ) : null}
        {IsFile &&
        (props.IsMultiple ||
          (props.IsMultiple !== true && arrFileUpload.length === 0)) ? (
          <StyledMenuItem>
            <input
              id={"contained-button-file" + props.keyID || ""}
              name={"contained-button-file" + props.keyID || ""}
              multiple={props.IsMultiple}
              type="file"
              hidden
              onChange={async (e) => {
                onHandleUploadFile(e);
              }}
              onClick={(e: any) => {
                e.target.value = "";
              }}
              accept={sAccept}
            />
            <label
              htmlFor={"contained-button-file" + props.keyID || ""}
              style={{
                width: "100%",
                display: "contents",
                cursor: "pointer",
              }}
            >
              <ListItemIcon style={{ cursor: "pointer" }}>
                <UploadFileIcon className={classes.IConColor} />
              </ListItemIcon>
              <ListItemText
                primary={t("uploadfile")}
                style={{ cursor: "pointer" }}
              />
            </label>
          </StyledMenuItem>
        ) : null}
      </StyledMenu>
      {/* Modal Error */}
      <ModalError
        open={isOpenError}
        setOpen={setisOpenError}
        arrDataError={arrMessageError}
        handleClickReload={handleClickReload}
        nRowperpageTable={props.nRowperpageTable}
      />
    </Fragment>
  );
};
export default UploadFile;

const StyledMenu = withStyles({
  paper: {
    // marginTop: "20px",
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // "&:focus": {
    //   backgroundColor: "#212529",
    //   "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
    //     color: theme.palette.common.white,
    //   },
    // },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  DarkColor: {
    color: "#fff ",
    backgroundColor: "#212529",
    borderColor: "#212529",
    "&:hover": {
      backgroundColor: "rgb(84, 87, 89)",
    },
  },
  IConColor: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}));
