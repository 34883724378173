import React, { Fragment, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, ButtonGroup, DialogContent, Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Extension} from "STM/utilities/ST_Function";
import ReactPlayer from "react-player";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import axios from "axios";
import { AuthToken } from "config/AxiosConfig";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import CropRoundedIcon from '@mui/icons-material/CropRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
            // marginRight: 17,
            backgroundImage:
                "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(25,29,30,1) 51%)",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        body: {
            backgroundColor: "#191d1e",
        },
        HeadLable: {
            fontSize: 15,
            color: "#868686",
        },
        SubLable: {
            fontSize: 13,
            color: "#E3E3E3",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    })
);

export default function FilePopup(props) {
    const classes = useStyles();

    const { SendCropImage, IsopenPopUp } = props;

    const cropperRef = useRef(null);

    const [lstDocumentFile, setlstDocumentFile] = useState([] as any)
    const [lstImage, setlstImage] = useState([] as any)

    // const [image, setImage] = useState<any>(!props.file??.sFileLink ? "" : props.file?.sFileLink.search("http") > -1 ? props.file?.sFileLink : process.env.REACT_APP_API_URL + props.file?.sFileLink);
    const [image, setImage] = useState<any>(props.file?.sCropFileLink ? (props.file?.sCropFileLink.search("http") > -1 ? props.file?.sCropFileLink : process.env.REACT_APP_API_URL + props.file?.sCropFileLink) : "");
    //// const [image, setImage] = useState<any>("");
    const [indexlstImage, setindexlstImage] = useState(props.indexActive)
    const [isCrop, setisCrop] = useState(false)
    //Video
    const [isReady, setIsReady] = useState(false);
    const playerRef = useRef({} as any);
    const [nPlayedTime, setPlayedTime] = useState(props.nStartVideoOn || 0);
    const [isShowAlert, setIsShowAlert] = useState(false);
    console.log("indexActive",props.indexActive)

    useEffect(() => {
        setindexlstImage(props.indexActive)
    }, [props.indexActive])

    useEffect(() => {
        setlstDocumentFile([])
        if (Extension.Document.indexOf(props.file?.sCropFileLink) > -1 && props.file?.sFileLink) {
            let sURI = props.file?.sFileLink.search("http") > -1 ? props.file?.sFileLink : process.env.REACT_APP_API_URL + props.file?.sFileLink;
            setlstDocumentFile([{ uri: sURI }])
        }
        if (props.file?.sCropFileLink) {
            let sPathImage = props.file?.sCropFileLink.search("http") > -1 ? props.file?.sCropFileLink : process.env.REACT_APP_API_URL + props.file?.sCropFileLink;
            setImage(sPathImage)
        }
        console.log("file ", props.file)
    }, [props.file]);


    useEffect(() => {
        console.log("arrFile ", props.arrFile)
        if (props.arrFile && props.isGallery) {
            let lstSrc = [] as any
            props.arrFile.forEach(element => {
                //// if (Extension.Image.indexOf(element.sFileType.toLowerCase()) > -1 && props.nFile_ID !== element.nFile_ID) {
                    if (Extension.Image.indexOf(element.sFileType.toLowerCase()) > -1 ) {
                    let sSrc = element.sFileLink.search("http") > -1 ? element.sFileLink : process.env.REACT_APP_API_URL + element.sFileLink
                    lstSrc.push({ src: sSrc, sFileName: element.sFileName, sFileType : element.sFileType })
                }
            });
            setlstImage(lstSrc)
        }
    }, [props.arrFile]);

    useEffect(() => {
        console.log("props.indexActive", props.indexActive);
    }, [props.indexActive])

    const DownloadOriginal = () => {

        let sImage = props.isGallery ? lstImage[indexlstImage]?.src : image;
        let sFileName = props.isGallery ? lstImage[indexlstImage]?.sFileName : props.file?.sFileName;

        console.log("lstImage",lstImage)
        console.log("indexlstImage",indexlstImage)
        console.log("sFileName",sFileName)



        if (props.isGallery || Extension.Video.indexOf(props.file?.sFileType.toLowerCase()) > -1) {
            console.log("if")
            console.log("sImage",sImage)

            let sMimeType = "";
            switch (props.file?.sFileType.toLowerCase()) {
                case "png":
                    sMimeType = "image/png";
                    break;
                case "jpeg":
                    sMimeType = "image/jpeg";
                    break;
                case "mp4":
                    sMimeType = "video/mp4";
                    break;
                case "avi":
                    sMimeType = "video/AV1";
                    break;
                case "zip":
                    sMimeType = "application/zip";
                    break;
                case "rar":
                    sMimeType = "application/x-rar-compressed";
                    break;
                default:
                    sMimeType = "image/png";
                    break;
            }

            const auth_token = AuthToken.get();
            axios({
                method: 'GET',
                headers: { Authorization: `Bearer ${auth_token}` },
                url: sImage,
                responseType: 'blob'
            })
                .then(response => {
                    const blob = new Blob([response.data], { type: sMimeType })

                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    console.log("url",url)
                    console.log("sFileName",sFileName)
                    a.href = url;
                    a.download = sFileName;
                    a.click();
                    document.body.removeChild(a);
                });
        }
        else {
            console.log("else")
            let link = document.createElement("a"); //Create <a>
            console.log("image",image)
            console.log("sFileName",props.file?.sFileName)
            link.href = image; //Image Goes here
            link.target = "_blank"; //Image Goes here
            link.download = props.file?.sFileName; //File name Here
            document.body.appendChild(link);
            link.click(); //Downloaded file
            document.body.removeChild(link);
        }
    };

    const onReady = useCallback(() => {
        if (!isReady) {
            if (props.nStartVideoOn) {
                const timeToStart = props.nStartVideoOn;
                playerRef.current.seekTo(timeToStart, "seconds");
            }
            setIsReady(true);
        }
    }, [isReady]);

    const onClosePopup = () => {
        props.setStartVideoOn && props.setStartVideoOn(nPlayedTime);
        props.ClosePopUp();
    };

    const onCloseAlert = (
        event: SyntheticEvent | Event,
        reason?: string
    ) => {
        if(reason){
            if (reason === "clickaway") {
                return;
            }
        }
        setIsShowAlert(false);
    };

    const onActionAlert = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onCloseAlert}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );

    const onSetCrop = () => {
        let sPath = "";
        if (props.file?.sFileLink) {
            let sFileLink = props.file.sFileLink + "";
            sPath = sFileLink.search("http") > -1 ? sFileLink : process.env.REACT_APP_API_URL + sFileLink;
            setImage(sPath)
            setisCrop(true)
        }
    }

    const onSubmitCrop = async () => {
        const imageElement = cropperRef?.current;
        if(imageElement){
            const cropper = imageElement?.cropper;
            if(cropper){
                const base64 = cropper.getCroppedCanvas().toDataURL();
                SendCropImage(props.file?.sUrl, base64,props.file?.nFile_ID);
                setisCrop(false)
                onClosePopup()
            }
        }
    };

    const rotate = () => {
        const imageElement = cropperRef?.current;
        if(imageElement){
            const cropper = imageElement?.cropper;
            if(cropper){
                cropper.rotate(90);
            }
        }
    };

    const deleteInDialog = () => {
        props.onDelete(props.file?.nFile_ID, false);
        onClosePopup();
    }

    return (
        <div>
            <Dialog
                fullScreen
                open={IsopenPopUp}
                className={classes.body}
                onClose={onClosePopup}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={7} md={"auto"} >
                                <Grid container spacing={2}>
                                    <Grid item xs={10} md={"auto"} className={classes.HeadLable}>
                                        Name:{" "}
                                        <div className={classes.SubLable}>
                                            {props.isGallery ? lstImage[indexlstImage]?.sFileName : props.file?.sFileName}
                                            </div>
                                    </Grid>
                                    <Grid item xs={2} md={"auto"} className={classes.HeadLable}>
                                        Type:{" "}
                                        <div className={classes.SubLable}>
                                            {props.isGallery ? lstImage[indexlstImage]?.sFileType.toLowerCase() : props.file?.sFileType.toLowerCase()
                                            }</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5} md={"auto"} >
                                <Grid container justifyContent={'end'}>
                                    <Grid item xs={"auto"}>
                                        {
                                            isCrop ?

                                                <Grid item xs={"auto"}>
                                                    <Grid container spacing={1} justifyContent={'end'}>
                                                        <Grid item xs={"auto"}>
                                                            <Button variant="outlined" className="btn-cancel-crop" onClick={() => { setisCrop(false) }}>Cancel</Button>
                                                        </Grid>
                                                        <Grid item xs={"auto"}>
                                                            <Button variant="outlined" className="btn-download-modal" onClick={() => { onSubmitCrop(); }}>Confirm</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid item xs={"auto"}>
                                                    <Button variant="outlined" className="btn-download-modal" onClick={() => { DownloadOriginal(); }}>Download</Button>
                                                </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={"auto"} style={{ display: isCrop ? "none" : "block" }}>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={onClosePopup}
                                            aria-label="close"
                                        >
                                            <CloseIcon sx={{color:"white"}} />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.body} dividers>
                    <>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            style={{ height: "85vh", marginTop: '0rem', position: 'relative' }}
                        >
                            {
                                props.isGallery ?
                                    <>
                                        <Grid item xs={12}>
                                        <Carousel
                                            className="div-gallery"
                                            images={lstImage}
                                            key={"crs-image"}
                                            index={props.indexActive}
                                            onIndexChange={({ curIndex, curIndexForDisplay }) => {
                                                setindexlstImage(curIndex)
                                            }}
                                            isAutoPlaying={false}
                                            canAutoPlay={false}
                                        />
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        {/* Image */}
                                        {Extension.Image.indexOf(props.file?.sFileType.toLowerCase()) > -1 && (
                                            <Grid item xs={12} style={{ display: "flex" }}>
                                                {
                                                    isCrop ?
                                                        <>
                                                            <Cropper
                                                                src={image}
                                                                id="images"
                                                                initialAspectRatio={0}
                                                                viewMode={0}
                                                                className="image-modal"
                                                                autoCropArea={1}
                                                                guides={false}
                                                                checkOrientation={false}
                                                                responsive={true}
                                                                ref={cropperRef}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <img src={image} alt="" className="image-modal" />
                                                        </>
                                                }
                                            </Grid>

                                        )}
                                        {/* Video */}
                                        {Extension.Video.indexOf(props.file?.sFileType.toLowerCase()) > -1 && (
                                            <Grid item xs={12} >
                                                <div className="player-wrapper">
                                                    <ReactPlayer
                                                        ref={playerRef}
                                                        config={{
                                                            file: {
                                                                attributes: { controlsList: "nodownload" },
                                                            },
                                                        }}
                                                        className="react-player"
                                                        url={image}
                                                        width="100%"
                                                        height="100%"
                                                        playing={true}
                                                        controls={true}
                                                        pip={true}
                                                        stopOnUnmount={true}
                                                        onProgress={(playedSeconds) => {
                                                            let nProgress = playedSeconds.playedSeconds || 0;
                                                            if (props.CannotSkipForward) {
                                                                if (nProgress > nPlayedTime) {
                                                                    if (nProgress - nPlayedTime < 2) {
                                                                        setPlayedTime(nProgress);
                                                                    }
                                                                }
                                                            } else {
                                                                setPlayedTime(nProgress);
                                                            }
                                                        }}
                                                        onReady={onReady}
                                                        onSeek={(nSeekTo) => {
                                                            if (props.CannotSkipForward) {
                                                                if (nSeekTo > nPlayedTime) {
                                                                    playerRef.current.seekTo(
                                                                        nPlayedTime,
                                                                        "seconds"
                                                                    );
                                                                    setIsShowAlert(true);
                                                                }
                                                            }
                                                            props.onVideoEnd && props.onVideoEnd(false);
                                                        }}
                                                        onEnded={() => {
                                                            props.onVideoEnd && props.onVideoEnd(true);
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                        {/* PDF */}
                                        {/* {(Extension.PDF.indexOf(props.file?.sFileType.toLowerCase()) > -1 || Extension.Word.indexOf(props.file?.sFileType.toLowerCase()) > -1 || Extension.Excel.indexOf(props.file?.sFileType.toLowerCase()) > -1 || Extension.PowerPoint.indexOf(props.file?.sFileType.toLowerCase()) > -1) && ( */}
                                        {Extension.PDF.indexOf(props.file?.sFileType.toLowerCase()) > -1 && (
                                            <>
                                                <Grid item xs={12} >
                                                    <DocViewer
                                                        documents={lstDocumentFile}
                                                        pluginRenderers={DocViewerRenderers}
                                                        config={{
                                                            header: {
                                                                disableHeader: false,
                                                                disableFileName: false,
                                                                retainURLParams: false,
                                                            },
                                                            pdfZoom: {
                                                                defaultZoom: 0.8, // 1 as default,
                                                                zoomJump: 0.2, // 0.1 as default,
                                                            },
                                                            pdfVerticalScrollByDefault: true, // false as default
                                                        }}
                                                        theme={{
                                                            primary: "#5296d8",
                                                            secondary: "#ffffff",
                                                            tertiary: "#5296d899",
                                                            textPrimary: "#ffffff",
                                                            textSecondary: "#5296d8",
                                                            textTertiary: "#00000099",
                                                            disableThemeScrollbar: false,
                                                        }}
                                                        style={{ width: "100%", height: "100%" }}
                                                    />
                                                </Grid>
                                            </>

                                        )}
                                    </>
                            }
                            <div style={{ display: props.IsCrop && Extension.Image.indexOf(props.file?.sFileType.toLowerCase()) > -1 ? "block" : "none" }}>
                                <ButtonGroup
                                    disableElevation
                                    variant="text"
                                    aria-label="Disabled elevation buttons"
                                    style={{
                                        borderColor: '#fff'
                                    }}
                                >
                                    {
                                        !isCrop ?
                                            <Button
                                                startIcon={<CropRoundedIcon style={{ color: '#fff' }} />}
                                                onClick={() => { onSetCrop() }}
                                                style={{ color: '#fff' }}
                                            >
                                                Crop
                                            </Button>
                                            :
                                            <Button
                                                startIcon={<RefreshRoundedIcon style={{ color: '#fff' }} />}
                                                onClick={() => { rotate() }}
                                                style={{ color: '#fff' }}
                                            >
                                                Rotate
                                            </Button>
                                    }
                                    <Button
                                        startIcon={<DeleteForeverRoundedIcon style={{ color: '#fff' }} />}
                                        onClick={deleteInDialog}
                                        style={{ color: '#fff' }}
                                    >
                                        Delete
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Grid>
                    </>
                </DialogContent>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={isShowAlert}
                autoHideDuration={3500}
                onClose={onCloseAlert}
            >
                <MuiAlert
                    severity="warning"
                    onClose={onCloseAlert}
                    action={onActionAlert}
                >
                    {"ไม่สามารถกดข้ามวิดีโอไปข้างหน้าได้!"}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}