import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Divider, Radio } from "@mui/material";
import {
  TextBoxForm,
  SelectFormItem,
  AsyncAutoCompleteForm,
  SwitchForm,
  SelectMultipleFormItem,
  AutocompleteFormItem,
} from "STM/components/Input";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import yupFormSchemas from "STM/components/FormItem/yupFormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { GridColumns } from "@mui/x-data-grid-pro";
import { initRows, PaginationInterface } from "STM/components/DataGrid";
import { DataGridMui } from "STM/components/DataGrid";
import { AxiosGet, AxiosPost } from "STM/utilities/ST_Axios";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { HeaderInput } from "STM/components/Input";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BtnBackForm,
  BtnCreateForm,
  BtnDanger,
} from "STM/components/Button";

import { generate } from "@wcj/generate-password";
import { EnumPermission, EnumUserType } from "STM/enum/enumSystem";
import { useTranslation } from "react-i18next";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { FnDialog } from "STM/utilities/ST_Function";

export default function UserPermissionForm() {
  const DialogFn = FnDialog();
  const navigate = useNavigate();
  const location = useLocation();
  const [isEdit, setisEdit] = useState(false);
  const [DropDownUserGroup, setDropDownUserGroup] = useState([]);
  const [DropDownUserType, setDropDownUserType] = useState([]);
  const [DropDownUnit, setDropDownUnit] = useState([]);
  const [oldGroup, setOldGroup] = useState([]);
  const [sTypeName, setTypeName] = useState("");
  const [sUnitCode, setUnitCode] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [isRole, setIsRole] = React.useState(false);
  const [isPerm, setIsPerm] = React.useState(false);
  const [isActive, setIsActive] = React.useState(true);
  const [nPermission, setnPermission] = useState(2);
  const sID = location.state ? location.state["sID"] : null;
  const { t } = useTranslation();

  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "dUpdate",
    sSortDirection: "desc",
  });
  const objSchema = yup.object().shape({
    sUserCode: yupFormSchemas.object(t("employeeid"), {
      required: sTypeName === EnumUserType.PTT && !isEdit,
      translation: t,
    }),
    sAutoCompleteDivision: yupFormSchemas.object(t("division"), {
      required: sTypeName === EnumUserType.Contract,
      translation: t,
    }),
    sUserID: yupFormSchemas.string(t("employeeid"), {
      required: sTypeName !== EnumUserType.PTT && !isEdit,
      translation: t,
    }),
    sUserType: yupFormSchemas.string(t("usertype"), {
      required: true,
      translation: t,
    }),
    sName: yupFormSchemas.string(t("name"), {
      required: true,
      translation: t,
    }),
    sSurname: yupFormSchemas.string(t("surname"), {
      required: true,
      translation: t,
    }),
    sDivision: yupFormSchemas.string(t("division"), {
      required: sTypeName !== EnumUserType.Contract,
      translation: t,
    }),
    sPosition: yupFormSchemas.string(t("position"), {
      required: true,
      translation: t,
    }),
    sEmail: yupFormSchemas.email(t("email"), {
      required: true,
      translation: t,
    }),
    sTel: yupFormSchemas.string(t("tel"), { required: false }),
    sGroup: yupFormSchemas.stringArray(
      isRole ? t("userrole") : t("usergroup"),
      { required: false }
    ),
    sStatus: yupFormSchemas.boolean(t("status"), { required: false }),
    sUsername: yupFormSchemas.string(t("username"), {
      required: sTypeName !== EnumUserType.PTT && !isEdit,
      translation: t,
    }),
  });

  const form = useForm({
    resolver: yupResolver(objSchema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const handleChangeRadio = (sID, sField) => {
    let oCheck = dataRow.arrRows.find((f) => f.sID === sID);
    if (oCheck) {
      oCheck.isDisable = sField === "isDisable";
      oCheck.isReadOnly = sField === "isReadOnly";
      oCheck.isEnable = sField === "isEnable";
    }
    setDataRow({ ...dataRow });
  };

  const OnGetData = async () => {
    let param = {
      sID: sID,
    };
    DialogFn.BlockUI();
    setLoadingTable(true);
    await AxiosPost(
      "User/GetDataUser",
      param,
      (res) => {
        setDropDownUserGroup(res.lstDropDownGroup);
        setDropDownUserType(res.lstDropDownType);
        setIsRole(res.IsRole);
        setIsPerm(res.isPerm);
        form.setValue("sStatus", true);
        if (res.IsEdit) {
          setisEdit(true);
          form.setValue("sUserType", res.sUserType);
          form.setValue("sUserID", res.sUserCode);
          form.setValue("sName", res.sName);
          form.setValue("sSurname", res.sSurname);
          form.setValue("sTel", res.sTel);
          form.setValue("sPosition", res.sPosition);
          form.setValue("sDivision", res.sDivision);
          form.setValue("sEmail", res.sEmail);
          form.setValue("sUsername", res.sUsername);
          form.setValue("sGroup", res.sGroupName);
          form.setValue("sAutoCompleteDivision", res.sUnitcode);
          form.setValue("sStatus", res.IsActive);
          setOldGroup(res.sGroupName);
          let sTypeName = res.lstDropDownType
            .filter((type) => type.value === form.getValues("sUserType"))
            .map((obj) => obj.label);
          setTypeName(sTypeName[0]);
          setUnitCode(res.sUnitcode.value);
        }
        if (res.isPerm) {
          setDataRow({
            ...dataRow,
            arrRows: res.lstPermission || [],
            nDataLength: res.nDataLength,
            nPageIndex: res.nPageIndex,
          });
        }
        setLoadingTable(false);
      },
      (err) => {
        if (!err.response) {
          DialogFn.Warning(err.Message);
        }
      }
    );
  };

  useEffect(() => {
    OnGetDropdownUnit();
    OnGetData().then(
      () => {
        DialogFn.UnBlockUI();
      },
      () => {}
    );
  }, []);

  const OnSetDataPIS = (data) => {
    if (data === null) {
      form.setValue("sName", "");
      form.setValue("sSurname", "");
      form.setValue("sTel", "");
      form.setValue("sPosition", "");
      form.setValue("sDivision", "");
      form.setValue("sEmail", "");
      setUnitCode("");
    } else {
      const sFullname = data.EmployeeNameTH.split(" ");
      form.setValue("sName", sFullname[0]);
      form.setValue("sSurname", sFullname[1]);
      form.setValue("sEmail", data.EMailAddress || "");
      form.setValue("sDivision", data.DepartmentAbbr);
      form.setValue("sTel", data.sTel || "");
      form.setValue("sPosition", data.PositionABBR || "");
      setUnitCode(data.sUnitcode);
    }
    form.clearErrors();
  };

  const OnGetDropdownUnit = async () => {
    await AxiosGet("DropDown/GetUnit", {}, (res) => {
      let result = res ?? [];
      setDropDownUnit(result);
    });
  };

  const onSubmit = (e) => {
    let password = generate({
      length: 8,
      lowerCase: true,
      upperCase: true,
      numeric: true,
      special: true,
    });
    console.log("Division",e['sAutoCompleteDivision']?.value)
    let objParam = {
      sID: sID ?? "",
      sEmployeeCode:
        isEdit || sTypeName !== EnumUserType.PTT
          ? e["sUserID"]
          : e["sUserCode"]?.value,
      sUsername:
        sTypeName === EnumUserType.PTT
          ? e["sUserCode"]?.value
          : e["sUsername"] || "",
      sPassword: sTypeName === EnumUserType.PTT ? null : password,
      sFirstNameTH: e["sName"],
      sLastNameTH: e["sSurname"],
      sFullNameTH: e["sName"] + e["sSurname"],
      sFirstNameEN: "",
      sLastNameEN: "",
      sFullNameEN: "",
      sUserType: e["sUserType"],
      sEmail: e["sEmail"],
      sTel: e["sTel"],
      sPositionName: e["sPosition"],
      sDivision: sTypeName === EnumUserType.Other ? e["sDivision"] : sUnitCode,
      IsActive: e["sStatus"],
      sUserMapping: e["sGroup"],
      lstPermission: dataRow.arrRows,
    };
    DialogFn.Submit(t("confirmsave"), () => {
      DialogFn.BlockUI();
      AxiosPost(
        "User/OnSave",
        objParam,
        (result) => {
          DialogFn.Success(t("formsuccess"));
          navigate(`/UserPermission`);
        },
        (err) => {
          DialogFn.UnBlockUI();
          if (!err.response) {
            DialogFn.Warning(err.Message);
          }
        }
      );
    });
  };

  const onChangeGroup = (e) => {
    if (isPerm) {
      if (isEdit) {
        DialogFn.SubmitWarning(
          t("changegroup"),
          () => {
            let lstGroup = e.map((obj) => obj.value);
            let param = { lstUserGroup: lstGroup || [], sLang: "th-TH" };
            setLoadingTable(true);
            AxiosPost("User/GetPermGroup", param, (res) => {
              setLoadingTable(false);
              setDataRow({
                ...dataRow,
                arrRows: res.lstPermission || [],
                nDataLength: res.nDataLength,
                nPageIndex: res.nPageIndex,
              });
            });
            setOldGroup(lstGroup);
          },
          () => {
            form.setValue("sGroup", oldGroup);
          }
        );
      } else {
        let lstGroup = e.map((obj) => obj.value);
        let param = { lstUserGroup: lstGroup || [], sLang: "th-TH" };
        setLoadingTable(true);
        AxiosPost("User/GetPermGroup", param, (res) => {
          setLoadingTable(false);
          setDataRow({
            ...dataRow,
            arrRows: res.lstPermission || [],
            nDataLength: res.nDataLength,
            nPageIndex: res.nPageIndex,
          });
        });
      }
    }
  };

  const dataColumn: GridColumns = [
    {
      field: "nNo",
      headerName: t("no"),
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: false,
      minWidth: 50,
      renderCell: (item) => !item.row.isSubMenu && <>{item.row.nNo}</>,
    },
    {
      field: "sMenuName",
      headerName: t("menufunction"),
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: false,
      flex: 2,
      minWidth: 200,
      renderCell: (item) =>
        item.row.isSubMenu ? (
          <div>
            <FiberManualRecordIcon sx={{ fontSize: "0.6em", mr: 0.5 }} />{" "}
            {item.value}{" "}
          </div>
        ) : (
          item.value
        ),
      colSpan: ({ row }) => {
        if (row.isHasSub) {
          return 4;
        }
        return 1;
      },
      cellClassName: (params) => (params.row.isHasSub ? "sMenuHead" : ""),
    },
    {
      field: "isDisable",
      headerName: t("disable"),
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: false,
      minWidth: 200,
      renderCell: (item) =>
        !item.row.isHasSub &&
        item.row.isManagePRM && (
          <>
          <Radio
            checked={item.value}
            onChange={() => handleChangeRadio(item.row.sID, "isDisable")}
            disabled={nPermission !== EnumPermission.Enable}
          />
          </>
          
        ),
    },
    {
      field: "isReadOnly",
      headerName: t("view"),
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: false,
      minWidth: 200,
      renderCell: (item) =>
        !item.row.isHasSub &&
        item.row.isManagePRM && (
          <Radio
            checked={item.value}
            onChange={() => handleChangeRadio(item.row.sID, "isReadOnly")}
            disabled={
              nPermission !== EnumPermission.Enable ||
              item.row.nHighPerm < EnumPermission.ReadOnly
            }
          />
        ),
    },
    {
      field: "isEnable",
      headerName: t("addeditdelete"),
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: false,
      minWidth: 200,
      renderCell: (item) =>
        !item.row.isHasSub &&
        item.row.isManagePRM && (
          <Radio
            checked={item.value}
            onChange={() => handleChangeRadio(item.row.sID, "isEnable")}
            disabled={
              nPermission !== EnumPermission.Enable ||
              item.row.nHighPerm < EnumPermission.Enable
            }
          />
        ),
    },
  ];

  const OnClearForm = () => {
    form.clearErrors();
    form.setValue("sUserID", "");
    form.setValue("sUserCode", { label: "", value: "" });
    form.setValue("sName", "");
    form.setValue("sSurname", "");
    form.setValue("sTel", "");
    form.setValue("sPosition", "");
    form.setValue("sDivision", "");
    form.setValue("sEmail", "");
    form.setValue("sUsername", "");
    setUnitCode("");
  };

  return (
    <FormProvider {...form}>
      <Grid
        container
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexFlow: "row wrap",
          marginTop: "24px",
          width: "calc(100% - 24px)",
          justifyContent: "center",
        }}
      >
        <Grid item xs={11}>
          <Grid item xs={12} className="Card-form">
            <Grid
              item
              xs={12}
              sx={{
                color: "rgb(52, 71, 103)",
              }}
              className="Header-grid-form"
            >
              <Typography
                sx={{ color: "rgb(52, 71, 103)" }}
                className="Header-title-form"
              >
                {isEdit ? t("updateUserInfo") : t("createUser")}
              </Typography>
              <Divider className="Divider-header" />
              <Grid container spacing={2} sx={{}}>
                <Grid item xs={5}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={t("usertype")} required />
                    <SelectFormItem
                      id={"sUserType"}
                      name={"sUserType"}
                      label={``}
                      required={false}
                      disabled={isEdit}
                      options={DropDownUserType}
                      onChange={(e) => {
                        OnClearForm();
                        if (e !== null) {
                          setTypeName(e.label);
                        } else {
                          setTypeName("");
                        }
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={7}></Grid>
                {sTypeName !== EnumUserType.PTT &&
                  sTypeName !== "" &&
                  sID == null && (
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          color: "rgb(52, 71, 103)",
                        }}
                        className=".Box-content-form"
                      >
                        <HeaderInput
                          text={t("username")}
                          required={sTypeName !== EnumUserType.PTT}
                        />
                        <TextBoxForm
                          name="sUsername"
                          label={""}
                          isshowTextCont={false}
                          disabled={isEdit}
                        />
                      </Box>
                    </Grid>
                  )}

                {sTypeName !== "" ? (
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        color: "rgb(52, 71, 103)",
                      }}
                      className=".Box-content-form"
                    >
                      <HeaderInput text={t("employeeid")} required />
                      {sTypeName !== EnumUserType.PTT ||
                      sID ? (
                        <TextBoxForm
                          name="sUserID"
                          label={""}
                          isshowTextCont={false}
                          disabled={isEdit}
                        />
                      ) : (
                        <AsyncAutoCompleteForm
                          name="sUserCode"
                          sUrlAPI={"AutoComplete/GetUserPTTFromPIS"}
                          label={""}
                          required={false}
                          disabled={isEdit}
                          onChange={(e) => OnSetDataPIS(e)}
                        />
                      )}
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={6}></Grid>
                )}
                <Grid item xs={12}></Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={t("name")} required />
                    <TextBoxForm
                      name="sName"
                      label={""}
                      isshowTextCont={false}
                      disabled={sTypeName === EnumUserType.PTT || isEdit}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={t("surname")} required />
                    <TextBoxForm
                      name="sSurname"
                      label={""}
                      isshowTextCont={false}
                      disabled={sTypeName === EnumUserType.PTT || isEdit}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={t("position")} required />
                    <TextBoxForm
                      name="sPosition"
                      label={""}
                      isshowTextCont={false}
                      disabled={sTypeName === EnumUserType.PTT || isEdit}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={t("division")} required />
                    {sTypeName === EnumUserType.Contract ? (
                      <AutocompleteFormItem
                        label={""}
                        id="sAutoCompleteDivision"
                        name="sAutoCompleteDivision"
                        disabled={isEdit}
                        TextFilterCount={1}
                        fnLoadData={(sSearch, setloaded) => {
                          setloaded(true);
                          let lst = DropDownUnit;
                          setloaded(false);
                          return lst;
                        }}
                        onSelect={(e, v) => {
                          console.log("v",v)
                          if(v != null){
                            setUnitCode(v.value)
                          }
                        }}
                      />
                    ) : (
                      <TextBoxForm
                        name="sDivision"
                        label={""}
                        isshowTextCont={false}
                        disabled={sTypeName === EnumUserType.PTT || isEdit}
                      />
                    )}
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={t("email")} required />
                    <TextBoxForm
                      name="sEmail"
                      label={"eg. support@mail.com"}
                      isshowTextCont={false}
                      disabled={sTypeName === EnumUserType.PTT}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={t("tel")} />
                    <TextBoxForm
                      name="sTel"
                      label={""}
                      isshowTextCont={false}
                      disabled={sTypeName === EnumUserType.PTT}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput
                      text={isRole ? t("userrole") : t("usergroup")}
                    />
                    <SelectMultipleFormItem
                      name={"sGroup"}
                      required={false}
                      label={``}
                      options={DropDownUserGroup}
                      id="sGroup"
                      onChange={(e) => onChangeGroup(e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ display: isPerm ? "block" : "none" }}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={t("permission")} required />
                    <DataGridMui
                      isLoading={loadingTable}
                      columns={dataColumn}
                      rows={dataRow}
                      onLoadData={() => {}}
                      isNotShowPagination={true}
                      isNotShowTotal={true}
                      onCickRow={() => {}}
                      isShowColomnTool={false}
                      isHiddenToolHead={true}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ width: "auto" }}>
                    <Box
                      sx={{
                        color: "rgb(52, 71, 103)",
                      }}
                      className=".Box-content-form"
                    >
                      <HeaderInput
                        text={t("status")}
                        subtext={isActive ? t("active") : t("inactive")}
                        required
                      />
                      <SwitchForm
                        name={"sStatus"}
                        label=""
                        required={false}
                        disabled={false}
                        width={60}
                        IsClassName={false}
                        onChange={(e) => setIsActive(e)}
                        onColor="#8bc34a"
                        offColor="#c41411"
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex" }}
                  justifyContent={"space-between"}
                >
                  <BtnBackForm
                    txt={t("back")}
                    onClick={() => {
                      DialogFn.SubmitWarning(t("cancelmsg"), () => {
                        navigate("/UserPermission");
                      });
                    }}
                  />
                  <Box sx={{ display: "flex" }}>
                    {isEdit && <BtnDanger />}
                    <Box sx={{ width: "10px" }}></Box>
                    <BtnCreateForm
                      txt={isEdit ? t("savechange") : t("usercreate")}
                      onClick={form.handleSubmit((e) => onSubmit(e))}
                      icon={<SaveAltIcon />}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
