import React from 'react';
import { useEffect, useState } from "react";

import "./Login.css";

///STM Modul
import { InputPassword, TextBoxForm } from 'STM/components/Input';
import { FnDialog, GetSystemInfo } from 'STM/utilities/ST_Function';
import { AxiosPost, AxiosGet } from 'STM/utilities/ST_Axios';
import { SwAlert } from 'STM/components/Alert';

import { GetDataSession } from '../../../../utilities/ST_Function';
import secureLocalStorage from "react-secure-storage";

///libraby MUI
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LoginIcon from "@mui/icons-material/Login";
import { FaUnlockAlt } from "react-icons/fa";



import { Link, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import yupFormSchemas from "STM/components/FormItem/yupFormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";


export default function Login({ OpenLogin, setOpenLogin }) {

   const DialogFn = FnDialog();
   const navigate = useNavigate();
   const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');

   const objSchema = {
   };
   const schema = yup.object().shape(objSchema);
   const formResolver = yupResolver(schema);
   const form = useForm({
      resolver: formResolver,
      shouldUnregister: false,
      shouldFocusError: true,
      mode: "all",
   });

   useEffect(() => {

   }, []);


   const onLogin = () => {
      let param = {
         sUsername: form.getValues("sUsername") || "",
         sSecureCode: form.getValues("sPassword") || "",
         sMode: ""
      };
      /*console.log("onLogin param", param);*/
      DialogFn.BlockUI()
      AxiosPost("Login/OnLogin", param, (result) => {
         DialogFn.UnBlockUI()
         secureLocalStorage.setItem(
            process.env.REACT_APP_JWT_KEY as string,
            result.token
         );
         GetDataSession();
         // console.log("OnLogin result", result);
         handleClose();
         navigate("/News");
      }, (err) => {
         DialogFn.UnBlockUI();
         SwAlert.Warning("", err.Message, () => {
            form.setValue("sUsername", "");
            form.setValue("sPassword", "");
         });
      });
   };


   // const getUserSession = () => {
   //    let param = {};
   //    AxiosGet("Utility2/GetUserSession", param, (res) => {
   //       console.log("Utility2/GetUserSession ", res);
   //    })
   // }


   const handleClose = () => {
      setOpenLogin(false);
   };


   return (
      <>
         <FormProvider {...form}>

            <Dialog maxWidth={maxWidth} open={OpenLogin} onClose={handleClose}>
               <DialogActions sx={{ paddingBottom: "0px" }}>
                  <IconButton aria-label="delete" size="large" onClick={handleClose}>
                     <CloseIcon fontSize="inherit" />
                  </IconButton>
               </DialogActions>
               <DialogContent sx={{ paddingTop: "0" }}>
                  <Grid container flexDirection="column" justifyContent="center" rowGap="15px">
                     <Box className="head-login">
                        <FaUnlockAlt className="IconLock" />
                        <Box className="head-title">LOGIN</Box>
                     </Box>

                     <Box className="body-login">
                        <Box>
                           <Box sx={{ display: "flex", columnGap: "7px" }}>
                              <Box sx={{ color: "#4f4f4f" }}>Username </Box>
                              <Box sx={{ color: "red" }}>*</Box>
                           </Box>
                           <Box>
                              <TextBoxForm name={"sUsername"} label={"Enter your Username"} />
                           </Box>
                        </Box>

                        <Box>
                           <Box sx={{ display: "flex", columnGap: "7px" }}>
                              <Box sx={{ color: "#4f4f4f" }}>Password</Box>
                              <Box sx={{ color: "red" }}>*</Box>
                           </Box>
                           <Box>
                              <InputPassword name="sPassword" label={"Enter your Password"} type={"password"}
                                 onKeyDown={event => {
                                    if (event.keyCode === 13) {
                                       onLogin();
                                       // if (form.getValues("sUsername") === "") {
                                       //    SwAlert.Warning("", "Please Enter your Password", () => {
                                       //    });
                                       // } else {
                                       //    onLogin();
                                       // }
                                    }
                                 }}
                              />
                           </Box>
                        </Box>
                     </Box>

                     <Box className="footer-login">
                        <Button variant="contained" color="info" style={{ color: "white" }}
                           onClick={form.handleSubmit(() => { onLogin() })}>
                           <LoginIcon style={{ marginRight: "1rem" }} /> Login
                        </Button>
                     </Box>
                  </Grid>
               </DialogContent>
            </Dialog>


         </FormProvider >
      </>
   )
}
