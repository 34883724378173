
import { useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {IconButton, Typography } from "@mui/material";
import { Link } from 'react-router-dom';

import { ShowUnderConstraction } from "view/UnderConstraction/UnderConstraction";

import AOS from 'aos';
import 'aos/dist/aos.css';

export default function HomePageMobile() {
   const frontURL = process.env.REACT_APP_Front_URL;

   AOS.init({
      duration: 400
   });

   // // const openUnderConstruction = () => {
   // //    ShowUnderConstraction();
   // // }

   return (
      <Grid container className="home-page-mobile">
         <Grid item xs={12} sx={{ padding: "0.7rem", paddingBottom: "0" }}>
            <Link to={"/AboutUs"}>
               {/* <Box className="home-aboutus-section animate3s fadeInRightBig" onClick={openUnderConstruction} */}
               <Box className="home-aboutus-section animate3s fadeInRightBig"
                  sx={{ backgroundImage: `url(${frontURL + "home/AboutUs.png"})` }}>
                  <Box sx={{ color: "#fff" }}>
                     <Typography sx={{ fontSize: "1.2rem", fontWeight: 500, lineHeight: 1 }}></Typography>
                     <Typography sx={{ fontSize: "1.2rem", fontWeight: 500, lineHeight: 1 }}></Typography>
                  </Box>
                  <Box sx={{
                     position: "absolute",
                     bottom: "10px",
                     color: "#fff",
                     display: "flex",
                     justifyContent: "space-between",
                     alignItems: "flex-end",
                     width: "100%",
                  }}>
                     <Typography sx={{ fontSize: "1.75rem", fontWeight: 500 }}>
                        ABOUT US
                     </Typography>
                     <Box className="aboutus-icons-box">
                        <IconButton className="mobile-icons icons-boat"
                           sx={{
                              backgroundImage: `url(${frontURL + "home/boat.png"})`,
                           }}>
                        </IconButton>
                        <IconButton className="mobile-icons icons-electric-pole"
                           sx={{
                              backgroundImage: `url(${frontURL + "home/electric-pole.png"})`,
                           }}>
                        </IconButton>
                     </Box>
                  </Box>
               </Box>
            </Link>
         </Grid>
         <Grid item xs={12} sx={{ padding: "0.7rem", paddingBottom: "0" }}>
            <Link to={"/Network"}>
               <Box className="home-network-section animate4s fadeInLeftBig"
                  sx={{ backgroundImage: `url(${frontURL + "home/network.jpg"})` }}>
                  <Box sx={{ color: "#fff", textAlign: "right" }}>
                     <Typography sx={{ fontSize: "1.2rem", fontWeight: 500, lineHeight: 1 }}>
                     </Typography>
                     <Typography sx={{ fontSize: "1.2rem", fontWeight: 500, lineHeight: 1 }}>
                     </Typography>
                  </Box>
                  <Box
                     sx={{
                        position: "absolute",
                        bottom: "10px",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        width: "100%",
                     }}>
                     <Box className="aboutus-icons-box">
                        <IconButton
                           className="mobile-icons icons-gas-station"
                           sx={{
                              backgroundImage: `url(${frontURL + "home/gas-station.png"})`,
                           }}
                        ></IconButton>
                        <IconButton
                           className="mobile-icons icons-gas-tank"
                           sx={{
                              backgroundImage: `url(${frontURL + "home/gas-tank.png"})`,
                           }}
                        ></IconButton>
                     </Box>
                     <Box sx={{ textAlign: "right", marginRight: "2rem" }}>
                        <Typography sx={{ fontSize: "1.75rem", fontWeight: 500 }}>
                           NETWORK
                        </Typography>
                     </Box>
                  </Box>
               </Box>
            </Link>
         </Grid>
         <Grid item xs={12} sx={{ padding: "0.7rem", paddingBottom: "0" }}>
            <Link to={"/NewsActivity"}>
               {/* <Box className="home-aboutus-section animate5s fadeInRightBig" onClick={openUnderConstruction} */}
               <Box className="home-aboutus-section animate5s fadeInRightBig"
                  sx={{ backgroundImage: `url(${frontURL + "home/news.png"})` }}>
                  <Box sx={{ color: "#fff" }}>
                     <Typography sx={{ fontSize: "1.2rem", fontWeight: 500, lineHeight: 1 }}>
                        {/* COMPANY */}
                     </Typography>
                     <Typography sx={{ fontSize: "1.2rem", fontWeight: 500, lineHeight: 1 }}>
                        {/* PROFILE */}
                     </Typography>
                  </Box>
                  <Box sx={{
                     position: "absolute",
                     bottom: "10px",
                     color: "#fff",
                     display: "flex",
                     justifyContent: "space-between",
                     alignItems: "flex-end",
                     width: "100%",
                  }}>
                     <Typography sx={{ fontSize: "1.75rem", fontWeight: 500, lineHeight: 1 }}>
                        NEWS&
                        <br></br>
                        ACTIVITIES
                     </Typography>
                     <Box className="aboutus-icons-box">
                        <IconButton className="mobile-icons icons-faucet"
                           sx={{
                              backgroundImage: `url(${frontURL + "home/faucet.png"})`,
                           }}></IconButton>
                     </Box>
                  </Box>
               </Box>
            </Link>
         </Grid>
         <Box sx={{ width: "100%", height: "25px" }}></Box>

      </Grid>
   )
}
