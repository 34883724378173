
import Layout from 'layout/Layout_Main/Front/index'
import HomePage from 'view/Home/HomePage';
import ContactUs from 'view/ContactUS/ContactUs';
import NetworkMap from 'view/Networks/NetworkMap2';
import { CookiesPolicy } from 'view/CookiesPolicy/CookiesPolicy';
////import { UnderConstraction } from 'view/UnderConstraction/UnderConstraction';
import AboutUs from 'view/AboutUs/AboutUs';
import NewsActivity from 'view/NewsActivity/NewsActivity';
import NewsActivityAll from 'view/NewsActivity/NewsActivityAll';
import NewsDetail from 'view/NewsActivity/NewsDetail';
import Login from 'view/Backend/login/login';
const route = [
    {
        exact: true,
        path: "/",
        component: HomePage,
        layout: Layout,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/Homepage",
        component: HomePage,
        layout: Layout,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/AboutUs",
        component: AboutUs,
        layout: Layout,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/ContactUS",
        component: ContactUs,
        layout: Layout,
        props: { MenuID_Selected: 1 }
    },
    
    {
        exact: true,
        path: "/NewsActivity",
        component: NewsActivity,
        layout: Layout,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/NewsActivityAll",
        component: NewsActivityAll,
        layout: Layout,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/NewsActivityDetail",
        component: NewsDetail,
        layout: Layout,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/Network",
        component: NetworkMap,
        layout: Layout,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/CookiesPolicy",
        component: CookiesPolicy,
        layout: Layout,
        props: { MenuID_Selected: 1 }
    },
    {
        exact: true,
        path: "/Login",
        component: Login,
        layout: "",
        props: { MenuID_Selected: 1 }
    }
];
export default route;