
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import yupFormSchemas from 'STM/components/FormItem/yupFormSchemas';
import { Box, Button, Grid, Typography } from '@mui/material';
import { InputPassword, TextBoxForm } from 'STM/components/Input';
import { FnBlock_UI } from 'STM/components/BlockUI';
import { AxiosPost } from 'STM/utilities/ST_Axios';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoginIcon from "@mui/icons-material/Login";
import secureLocalStorage from "react-secure-storage";
import { SwAlert } from 'STM/components/Alert';
import { FnDialog, GetSystemInfo } from 'STM/utilities/ST_Function';
import { useTranslation } from 'react-i18next';
const Login = () => {
    const { t } = useTranslation()
    const [sysInfo, setSystemInfo] = useState({ SystemName: "", SystemReleaseInfo: "" });
    useEffect(() => {
        let sysInfo = GetSystemInfo();
        setSystemInfo(sysInfo);
        ////console.log(sysInfo.SystemName + "" + sysInfo.SystemReleaseInfo);
    }, []);


    const DialogFn = FnDialog();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const navigate = useNavigate();
    const [ShowLoginPanel, setShowLoginPanel] = useState(false);

    const objSchema = yup.object().shape({
        sUsername: yupFormSchemas
            .string("UserName", { required: true, translation: t })
            .max(50),
        sPassword: yupFormSchemas
            .string("Password", { required: true, translation: t })
            .max(20),
    });

    const form = useForm({
        resolver: yupResolver(objSchema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const onLoginAD = () => {
        let anchor = document.createElement("a");
        let ad_url = process.env.REACT_APP_REDIRECT_AD as string;
        anchor.href = ad_url;
        anchor.click();
    };

    const onLogin = () => {
        let param = {
            sUsername: form.getValues("sUsername") || "",
            sSecureCode: form.getValues("sPassword") || "",
            sMode: ""
        };
        DialogFn.BlockUI()
        AxiosPost("Login/OnLogin", param, (result) => {
            DialogFn.UnBlockUI()
            secureLocalStorage.setItem(
                process.env.REACT_APP_JWT_KEY as string,
                result.token
            );
            navigate("/News");
        }, (err) => {
            DialogFn.UnBlockUI();
            SwAlert.Warning("", err.Message, () => {
                form.setValue("sUsername", "");
                form.setValue("sPassword", "");
            });
        });
    };


    return (
        <FormProvider {...form}>
            <Box sx={{ height: "100vh", width: "100vw" }}>
                <Box sx={{
                    width: "100%", height: "100%",
                    paddingTop: "7rem", paddingBottom: "7rem", paddingLeft: "5rem", paddingRight: "5rem",
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "500px",
                        height: "100%"
                    }}>

                        <Grid container rowGap="15px">
                            <Grid item xs={12} id="login_SystemName">
                                <Typography sx={{ color: "rgb(52, 71, 103)" }} className="Header-title-SystemName">
                                    {sysInfo.SystemName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ color: "rgb(52, 71, 103)" }} className="Header-title-form">
                                    Login
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12} style={ShowLoginPanel === false ? {} : { display: "none" }}>
                                <Button variant="contained" style={{ width: '100%' }} onClick={onLoginAD}>
                                    Login with AD
                                </Button>
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <Button variant="contained" style={{ width: '100%' }}
                                    onClick={(e) => {
                                        setShowLoginPanel(true);
                                        form.reset();
                                    }}>
                                    Login with Username/Password
                                </Button>
                            </Grid> */}
                            {/* <Grid item xs={12} style={ShowLoginPanel === false ? { display: "none" } : { paddingTop: "10px" }}>
                                <Link to={"/"} style={{ fontSize: "0.85rem" }} onClick={(e) => { setShowLoginPanel(false) }}>
                                    <span style={{ display: "flex", alignItems: "center" }}>
                                        <ArrowBackIcon style={{ fontSize: 15, marginRight: "5px" }} />Back
                                    </span>
                                </Link>
                            </Grid> */}
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box sx={{ color: "rgb(52, 71, 103)", }} className=".Box-content-form">
                                            <TextBoxForm name="sUsername" label="Username" maxLength={20} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ color: "rgb(52, 71, 103)", }} className=".Box-content-form">
                                            <InputPassword
                                                name="sPassword"
                                                label={"Password"}
                                                isshowTextCont={false}
                                                type={"password"}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
                                <Button variant="contained" color="info" style={{ color: "white" }} onClick={form.handleSubmit(() => { onLogin() })}>
                                    Login <LoginIcon style={{ marginLeft: "1rem" }} />
                                </Button>
                            </Grid>
                        </Grid>

                    </Box>

                </Box>
            </Box>

        </FormProvider>
    )
};
export default Login;