import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import yupFormSchemas from "STM/components/FormItem/yupFormSchemas";
import { Box, Divider, Grid, Radio, Typography } from "@mui/material";
import { HeaderInput, SwitchForm, TextBoxForm } from "STM/components/Input";
import { FnBlock_UI } from "STM/components/BlockUI";
import { AxiosPost } from "STM/utilities/ST_Axios";
import { useLocation, useNavigate } from "react-router-dom";
import DataGrid, {
  PaginationInterface,
  initRows,
} from "STM/components/DataGrid";
import { GridColumns } from "@mui/x-data-grid-pro";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { BtnBackForm, BtnCreateForm } from "STM/components/Button";
import { SwAlert } from "STM/components/Alert";
import { useTranslation } from "react-i18next";
import { EnumPermission } from "STM/enum/enumSystem";
import { FnDialog } from "STM/utilities/ST_Function";
import TablePermission from "./TablePermission";

const UserRoleForm = () => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const navigate = useNavigate();
  const DialogFn = FnDialog();
  const [nPermission, setnPermission] = useState(2);
  const [loadingTable, setLoadingTable] = useState(false);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [isActive, setIsActive] = useState(true);
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const [isEdit, setisEdit] = useState(false);

  const [dataRowFront, setDataRowFront] = useState<PaginationInterface>({
    ...initRows,
  });

  const [dataRowBack, setDataRowBack] = useState<PaginationInterface>({
    ...initRows,
  });

  const objSchema = yup.object().shape({
    sUserRoleName: yupFormSchemas.string(t("userrolename"), {
      required: true,
      translation: t,
    }),
  });

  const form = useForm({
    resolver: yupResolver(objSchema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: {
      sStatus: true,
    } as any,
  });

  useEffect(() => {
    onGetData().then(
      () => {
        DialogFn.UnBlockUI();
      },
      () => {}
    );
  }, []);

  const onGetData = async () => {
    let param = { sUserRoleID: sID || "" };
    DialogFn.BlockUI();
    setLoadingTable(true);
    await AxiosPost(
      "UserRole/GetUserRole",
      param,
      (res) => {
        form.setValue("sStatus", true);
        console.log("res ", res);
        if (sID) {
          form.setValue("sUserRoleName", res.sUserRoleName);
          form.setValue("sUserRoleDescription", res.sUserRoleDescription);
          form.setValue("sStatus", res.isActive);
          setIsActive(res.isActive);
          setisEdit(true);
        }
        setDataRowFront({
          ...dataRowFront,
          arrRows: res.lstPermissionFront || [],
          nDataLength: res.nDataLength,
          nPageIndex: res.nPageIndex,
        });
        setDataRowBack({
          ...dataRowBack,
          arrRows: res.lstPermissionBack || [],
          nDataLength: res.nDataLength,
          nPageIndex: res.nPageIndex,
        });
        setLoadingTable(false);
      },
      (err) => {
        if (!err.response) {
          DialogFn.Warning(err.Message);
        }
      }
    );
  };

  const onSubmit = () => {
    let data = {
      sUserRoleID: sID || "",
      sUserRoleName: form.getValues("sUserRoleName") || "",
      sUserRoleDescription: form.getValues("sUserRoleDescription") || "",
      lstPermissionFront: dataRowFront.arrRows,
      lstPermissionBack: dataRowBack.arrRows,
      isActive: form.getValues("sStatus"),
    };
    console.log("data",data)
    DialogFn.Submit(t("confirmsave"), () => {
      DialogFn.BlockUI();
      AxiosPost(
        "UserRole/SaveUserRole",
        data,
        (result) => {
          DialogFn.UnBlockUI();
          DialogFn.Success(t("formsuccess"));
          navigate(`/UserRole`);
        },
        (err) => {
          DialogFn.UnBlockUI();
          if (!err.response) {
            DialogFn.Warning(err.Message);
          }
        }
      );
    });
  };

  return (
    <FormProvider {...form}>
      <Grid
        container
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexFlow: "row wrap",
          marginTop: "24px",
          width: "calc(100% - 24px)",
          justifyContent: "center",
        }}
      >
        <Grid item xs={11}>
          <Grid item xs={12} className="Card-form">
            <Grid
              item
              xs={12}
              sx={{ color: "rgb(52, 71, 103)" }}
              className="Header-grid-form"
            >
              <Typography
                sx={{ color: "rgb(52, 71, 103)" }}
                className="Header-title-form"
              >
                {isEdit ? t("updateUserRole") : t("userrolecreate")}
              </Typography>
              <Divider className="Divider-header" />
              <Grid container spacing={2} sx={{}}>
                <Grid item xs={6}>
                  <Box
                    sx={{ color: "rgb(52, 71, 103)" }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={t("userrolename")} required />
                    <TextBoxForm
                      name="sUserRoleName"
                      label={""}
                      maxLength={150}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={10}>
                  <Box
                    sx={{ color: "rgb(52, 71, 103)" }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={t("description")} />
                    <TextBoxForm
                      name="sUserRoleDescription"
                      label={""}
                      multiline={true}
                      rows={3}
                      maxLength={1000}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <HeaderInput text={"Front" + t("permission")} required />
                  <TablePermission
                    dataRow={dataRowFront}
                    setDataRow={setDataRowFront}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderInput text={"Back" + t("permission")} required />
                  <TablePermission
                    dataRow={dataRowBack}
                    setDataRow={setDataRowBack}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ width: "60px" }}>
                    <Box
                      sx={{ color: "rgb(52, 71, 103)" }}
                      className=".Box-content-form"
                    >
                      <HeaderInput
                        text={t("status")}
                        subtext={isActive ? t("active") : t("inactive")}
                        required
                      />
                      <SwitchForm
                        name={"sStatus"}
                        label=""
                        required={false}
                        disabled={false}
                        width={60}
                        IsClassName={false}
                        onChange={(e) => setIsActive(e)}
                        onColor="#8bc34a"
                        offColor="#c41411"
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex" }}
                  justifyContent={"space-between"}
                >
                  <BtnBackForm
                    txt={t("back")}
                    onClick={() => {
                      DialogFn.SubmitWarning(t("cancelmsg"), () => {
                        DialogFn.CloseSubmitWarning();
                        navigate("/UserRole");
                      });
                    }}
                  />
                  <BtnCreateForm
                    txt={t("save")}
                    onClick={form.handleSubmit((e) => onSubmit())}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
export default UserRoleForm;
