import { createTheme } from "@mui/material/styles";
import { blue, orange, pink, green } from "@mui/material/colors";

// or
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(23, 193, 232)",
    },
    info: {
      main: "#5bc0de",
      light: "#46b8da",
      dark: "#00a5d6",
    },
  },
  typography: {
    fontFamily: `"Kanit", "Helvetica", "Kanit", sans-serif`,
    fontSize: 14,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ".MuiTabList-indicator" : {
          color:"red"
        },
        ".MuiFormControlLabel-label": {
          margin: 0,
          fontSize: "0.8rem !important",
          lineHeight: "1.5 !important",
          letterSpacing: "0.02857em !important",
          fontWeight: "400 !important",
          color: "rgb(103, 116, 142)",
          fontFamily: `"Kanit", "Helvetica", "Kanit", sans-serif !important`,
        },
        ".MuiDataGrid-main": {
          // backgroundColor: blue[700],
          // backgroundColor: "#d6d6d64d"
        },
        ".MuiDataGrid-columnHeaders": {
          backgroundColor: blue[700],
          display: "flex",
        },
        ".MuiDataGrid-row.Mui-selected": {
          backgroundColor: "#eff5ff !important",
          // color:"white",
          ".MuiCheckbox-root": {
            // color: "rgb(23, 193, 232) !important",
          },
        },
        ".MuiDataGrid-cell": {
          minHeight: "40px !important",
        },
        ".MuiDataGrid-columnHeaderTitle": {
          color: "white",
        },
        ".MuiDataGrid-columnHeaderTitleContainer": {
          ".MuiCheckbox-root": {
            color: "white !important",
          },
        },
        ".MuiCheckbox-root.Mui-checked": {
          // color: "white !important",
        },
        ".MuiChip-root": {
          background: "#3a3f69 !important" ,
          color: "#fff !important",
        },
        ".MuiChip-deleteIcon": {
            color:"#fff !important"
        },
        ".MuiDataGrid-columnSeparator": {
          display: "flex",
          height: "100%",
          ".MuiDivider-root": {
            height: "100%",
            margin: "0 10px",
            borderColor: "white",
          },
        },

        ".MuiDataGrid-root.show-line": {
          ".MuiDataGrid-row": {
            ".MuiDataGrid-cell": {
              position: "relative",
              "::after": {
                position: "absolute",
                content: '""',
                height: "100%",
                width: "1px",
                backgroundColor: "rgba(0, 0, 0, 0.12)",
                right: "-2px",
              },
            },
          },
        },
        ".MuiDataGrid-footerContainer": {
          overflow: "auto",
        },
        ".MuiDataGrid-row": {
          backgroundColor: "#fff",
          // "&:hover": {
          //     backgroundColor: "#fff !important",
          //     fontWeight: "bold",
          //     border: ".5px solid black",
          //     color: "black",
          //     ".MuiCheckbox-root": {
          //         color: "black !important",
          //     }
          // },
          "&:nth-of-type(even)": {
            background: "#f0f0f0",
            "&:hover": {
              backgroundColor: "#f0f0f0 !important",
            },
          },
        },
        ".MuiDataGrid-virtualScrollerRenderZone": {
          width: "100%",
        },
        ".MuiDataGrid-virtualScroller": {
          overflow: "auto!important",
        },

        // "@media only screen and (max-width:600px)": {
        //   ".MuiDataGrid-virtualScrollerContent": {
        //     width: "auto !important",
        //   },
        //   ".MuiDataGrid-columnHeaders": {
        //     display: "none !important",
        //   },
        //   ".MuiDataGrid-virtualScroller": {
        //     marginTop: "0px !important",
        //     overflow: "hidden!important",
        //   },

        //   ".MuiDataGrid-row": {
        //     borderBottom: "1px solid #f0f0f0",
        //     counterReset: "n",
        //     position: "relative",
        //     flexDirection: "column !important",
        //     width: "100% !important",
        //     ".MuiDataGrid-cell": {
        //       borderBottom: "none !important",
        //       maxWidth: "none !important",
        //       width: "100%",
        //       justifyContent: "start !important",
        //       alignItems: "start !important",
        //       "::before": {
        //         content: "attr(data-columnname)",
        //         width: "5em",
        //         overflowWrap: "break-word",
        //       },
        //     },
        //     ".MuiDataGrid-cell:not([data-columnname])": {
        //       counterIncrement: "n",
        //       position: "absolute",
        //       width: "40px",
        //       height: "40px",
        //       minWidth: "0 !important",
        //       bottom: 10,
        //       ".MuiButtonBase-root": {
        //         width: "30px",
        //         height: "30px",
        //         minHeight: 0,
        //         svg: {
        //           fontSize: "18px",
        //         },
        //       },
        //     },
        //     ".MuiDataGrid-cell.checkbox-actions": {
        //       position: "absolute",
        //       width: "10px",
        //       height: "10px",
        //       minWidth: "0 !important",
        //       top: -5,
        //       left: -12,
        //     },
        //     ".cell-actions-0": {
        //       right: "5px",
        //     },
        //     ".cell-actions-1": {
        //       right: "55px",
        //     },
        //     ".cell-actions-2": {
        //       right: "105px",
        //     },
        //     ".cell-actions-3": {
        //       right: "155px",
        //     },
        //     ".cell-actions-4": {
        //       right: "205px",
        //     },

        //     ".head-cell.MuiDataGrid-cell::before": {
        //       fontWeight: "bold",
        //     },
        //     ".head-cell.MuiDataGrid-cell>div": {
        //       fontWeight: "bold",
        //     },
        //   },

        //   ".MuiDataGrid-row:has(.MuiDataGrid-cell:not([data-columnname]):not(.checkbox-actions))":
        //     {
        //       paddingBottom: "4em",
        //     },
        //   ".MuiDataGrid-row:has(.MuiDataGrid-cell.checkbox-actions)": {
        //     paddingLeft: "1.2em",
        //   },
        // },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          // boxShadow: "rgba(0, 0, 0, 0.189) 0px 5px 15px"
        },
        input: {
          color: "#4a4a4a",
          backgroundColor: "rgba(255,255,255,0.95)",
          borderRadius: "10px",
          padding: "10px",
        },
        multiline: {
          backgroundColor: "rgba(255,255,255,0.95)",
          padding: 0,
          input: {
            padding: "4px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          backgroundColor: "rgba(255,255,255,0.95)",
        },
        input: {
          color: "#4a4a4a",
          backgroundColor: "rgba(255,255,255,0.95)",
          borderRadius: "10px",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          backgroundColor: "rgba(255,255,255,0.95)",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          zIndex: 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          backgroundColor: "transparent",
        },
      },
    },
  },
});
export default theme;
