import React from "react";
import { useEffect, useState, forwardRef, useRef, Fragment } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { SwitchFromProps, SwitchNoFromProps, } from "./SwitchProps";
import Switch from "react-switch";
import { Grid, Typography, FormHelperText, FormControl, FormLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export const SwitchForm = forwardRef((props: SwitchFromProps, ref) => {
    const {
        label,
        name,
        required,
        LeftText,
        RightText,
        disabled,
        width = 80,
        value,
        isNoLabel,
        IsClassName = true
    } = props;
    
    const {
        control,
        register,
        getValues,
        setValue,
    } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, error }, }) => (
                <>
                    <FormLabel
                        component="legend"
                        style={{display: isNoLabel ? "none" : 'block'}}
                        sx={{
                            color: error && error[name] ? "red !important" : "",
                            ".MuiFormLabel-root": {
                            asterisk: {
                                color: '#db3131',
                                '&$error': {
                                color: '#db3131'
                                },
                            }
                            },
                        }}
                    >{label} <span style={{ color: '#FF0000', fontSize: '15px', marginLeft: '-4px',display:"none" }}>{required === true ? " *" : ""}</span>
                    </FormLabel>
                    <Typography component="div">
                        <Grid item
                            component="label"
                            container
                            alignItems="center"
                            alignContent="space-between"
                            xs={12}
                            spacing={3}
                        >
                            <Grid item>
                                <label htmlFor="small-radius-switch" style={{color:"#ffffff"}}>
                                    <Switch
                                        id={name}
                                        {...props}
                                        {...register(name)}
                                        key={name}
                                        name={name}
                                        checked={value || false}
                                        // value={String(value || 'true')}
                                        value={value || true}
                                        onChange={(e) => {
                                            setValue(name, e, { shouldValidate: true });
                                            onChange(e);
                                            props.onChange && props.onChange(e);
                                        }}
                                        handleDiameter={20}
                                        offColor={props.offColor ? props.offColor  : "#ebecf0"}
                                        onColor={props.onColor ? props.onColor  :"#3c4573"}
                                        // offHandleColor="#0ff"
                                        // onHandleColor="#08f"
                                        height={28}
                                        width={width}
                                        // borderRadius={6}
                                        activeBoxShadow="0px 0px 1px 2px #fff"
                                        disabled={disabled}
                                        // uncheckedIcon={
                                        //     <div style={{fontSize: '12px', paddingTop: "4px", textAlign: "center", marginLeft:"-12px" }}> 
                                        //         {RightText || "Inactive"}
                                        //     </div>
                                        // }
                                        // checkedIcon={
                                        //     <div style={{ display:'flex', fontSize: '12px', paddingTop: "4px", textAlign: "center", marginLeft:"10px" }}> 
                                        //         {LeftText || "Active"}
                                        //     </div>
                                        // }
                                        // className="react-switch"
                                        // uncheckedHandleIcon={
                                        //     <div
                                        //       style={{
                                        //         display: "flex",
                                        //         justifyContent: "center",
                                        //         alignItems: "center",
                                        //         height: "100%",
                                        //         color: "red",
                                        //         fontSize: 18
                                        //       }}
                                        //     >
                                        //       <CancelIcon />
                                        //     </div>
                                        //   }
                                        // checkedHandleIcon={
                                        //     <div
                                        //       style={{
                                        //         display: "flex",
                                        //         justifyContent: "center",
                                        //         alignItems: "center",
                                        //         height: "100%",
                                        //         color: "limegreen",
                                        //         fontSize: 18
                                        //       }}
                                        //     >
                                        //       <CheckCircleIcon />
                                        //     </div>
                                        //   }
                                        className={IsClassName ? `react-switch react-switch-${value ? "active" : "inactive"}` : "react-switch"}
                                    />
                                </label>
                            </Grid>
                        </Grid>
                    </Typography>
                    {error ? (
                        <FormHelperText sx={{ color: "red" }}>
                            {error.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
});

export default SwitchForm;

export const SwitchNoForm = forwardRef((props: SwitchNoFromProps, ref) => {
    const {
        label,
        isNoLabel,
        name,
        LeftText,
        RightText,
        disabled,
        width = 80,
        value,
    } = props;

    return (
        <Fragment>
            <FormLabel
                component="legend"
                style={{ marginBottom: '8px', fontSize:"14px", display: isNoLabel ? "none" : 'block' }}
            >
                {label}
            </FormLabel>
            <Typography component="div">
                <Grid item
                    component="label"
                    container
                    alignItems="center"
                    alignContent="space-between"
                    xs={12}
                    spacing={3}
                >
                    <Grid item>
                        <label htmlFor="small-radius-switch" style={{color:"#ffffff"}}>
                            <Switch
                                id={name}
                                checked={value || false}
                                // value={String(value || 'true')}
                                value={value || true}
                                onChange={(e) => {
                                    props.onChange && props.onChange(e);
                                }}
                                handleDiameter={26}
                                offColor="#ee3a0d"
                                height={28}
                                width={width}
                                disabled={disabled}
                                uncheckedIcon={
                                    <div style={{ fontSize: '12px', paddingTop: "4px", textAlign: "center", marginLeft:"-18px" }}> 
                                        {RightText || "Active"}
                                    </div>
                                }
                                checkedIcon={
                                    <div style={{ fontSize: '12px', paddingTop: "4px", textAlign: "center", marginLeft:"10px" }}> 
                                        {LeftText || "Inactive"}
                                    </div>
                                }
                                className="react-switch"
                            />
                        </label>
                    </Grid>
                </Grid>
            </Typography>
        </Fragment>
    );
});