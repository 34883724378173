import React, { useCallback } from "react";
import { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { makeStyles, useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReplayIcon from "@mui/icons-material/Replay";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FilePopup from "../PopUp/FilePopup";
import CardMedia from "@mui/material/CardMedia";
import {
  CardActionArea,
  CircularProgress,
  CircularProgressProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Extension } from "STM/utilities/ST_Function";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";
import { BsFillFileEarmarkWordFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { SiMicrosoftpowerpoint } from "react-icons/si";
import { BiMessageEdit } from "react-icons/bi";
import { TextBoxNoForm } from "STM/components/Input/TextBox";
import { BtnBackForm, BtnCreateForm } from "STM/components/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useTranslation } from "react-i18next";
import HeaderInput from "STM/components/Input/HeaderInput";
import { Close } from "@mui/icons-material";

const ItemCard = (props) => {
  const classes = useStyles();
  const { IsopenPopUp, setIsopenPopUp } = props;

  const [indexActive, setindexActive] = useState(0);
  const [IsOpenTextArea, setIsOpenTextArea] = useState(false);
  const [IsopenPopUp2, setIsopenPopUp2] = useState(false);
  const matches440 = useMediaQuery("(min-width:440px)");
  const { t } = useTranslation();

  const ClosePopUp = () => {
    setIsopenPopUp2(false);
  };

  const OpenPopUp = () => {
    setindexActive(props.nIndexFile);
    props.onOpenFile && props.onOpenFile(props.nFile_ID);
    setIsopenPopUp2(true);
  };

  const onDownload = () => {
    let a = document.createElement("a");
    a.href = props.sFileLink + "";
    a.download = props.sFileName;
    a.click();
  };

  const onClickFile = () => {
    props.onOpenFile && props.onOpenFile(props.nFile_ID);
    let isCheck =
      Extension.Image.indexOf(props.sFileType.toLowerCase()) > -1 ||
      Extension.Video.indexOf(props.sFileType.toLowerCase()) > -1 ||
      Extension.PDF.indexOf(props.sFileType.toLowerCase()) > -1;

    isCheck ? OpenPopUp() : onDownload();
    // onClickFileNewWindow();
  };

  return (
    <Fragment>
      <Card sx={{ maxWidth: matches440 ? 320 : 400, height: 187 }}>
        <CardActionArea style={{ position: "relative" }}>
          {props.sProgress !== "100" && !props.IsCompleted ? (
            <Box
              display="flex"
              alignItems="center"
              className="div-gallery-icon"
            >
              <Box
                width="100%"
                p={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgressWithLabel
                  value={parseInt(props.sProgress) || 0}
                  style={{
                    width: "60px",
                    height: "60px",
                  }}
                />
              </Box>
            </Box>
          ) : Extension.Image.indexOf(props.sFileType.toLowerCase()) > -1 ? (
            <CardMedia
              component="img"
              image={props.sFileLink}
              alt="Image"
              className="div-gallery-img"
              height={150}
              onClick={onClickFile}
            />
          ) : Extension.Video.indexOf(props.sFileType.toLowerCase()) > -1 ? (
            // <div className="div-gallery-icon" onClick={onClickFile}>
            //   <VideoLibraryIcon className={classes.IConColor} />
            // </div>
            <CardMedia
            component='video'
            className={"div-gallery-img"}
            image={props.sFileLink}
            autoPlay={false}
            height={150}
              onClick={onClickFile}
        />
          ) : Extension.PDF.indexOf(props.sFileType.toLowerCase()) > -1 ? (
            <div className="div-gallery-icon" onClick={onClickFile}>
              <PictureAsPdfIcon className={classes.PDFColor} />
            </div>
          ) : Extension.Word.indexOf(props.sFileType.toLowerCase()) > -1 ? (
            <div className="div-gallery-icon" onClick={onClickFile}>
              <BsFillFileEarmarkWordFill className={classes.WordColor} />
            </div>
          ) : Extension.Excel.indexOf(props.sFileType.toLowerCase()) > -1 ? (
            <div className="div-gallery-icon" onClick={onClickFile}>
              <SiMicrosoftexcel className={classes.ExcelColor} />
            </div>
          ) : Extension.PowerPoint.indexOf(props.sFileType.toLowerCase()) > -1 ? (
            <div className="div-gallery-icon" onClick={onClickFile}>
              <SiMicrosoftpowerpoint className={classes.PowerPointColor} />
            </div>
          ) : (
            <div className="div-gallery-icon" onClick={onClickFile}>
              <DownloadIcon className={classes.IConColor} />
            </div>
          )}
          <div style={{ position: "absolute", right: 4, top: 4 }}>
            {props.IsCompleted ? (
              !props.disabled ? (
                <Tooltip title="Remove">
                  <IconButton
                    size="small"
                    onClick={() => {
                      props.onDelete(props.nFile_ID);
                    }}
                    className={classes.DangerColor}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              ) : null
            ) : null}
          </div>
          <div style={{ position: "absolute", right: 45, top: 4 }}>
            {props.IsCompleted ? (
              !props.disabled && props.IsCanAddDetail == true ? (
                <Tooltip title="Add Description">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setIsOpenTextArea(true);
                      // console.log("props.arrFile",props.arrFile)
                      // console.log("props.nFile_ID",props.nFile_ID)
                      // console.log("find",props.arrFile.find(w => w.nFile_ID !== 0 && w.nFile_ID === props.nFile_ID))
                    }}
                    className={classes.WordColor}
                  >
                    <BiMessageEdit style={{ fontSize: "1.5rem" }} />
                  </IconButton>
                </Tooltip>
              ) : null
            ) : null}
          </div>
        </CardActionArea>
        <CardContent style={{ padding: "8px", background: "#f5f5f5" }}>
          <Tooltip title={props.sFileName}>
            <Typography
              variant="body2"
              color="text.secondary"
              className="div-gallery-title"
            >
              {props.sFileName}
            </Typography>
          </Tooltip>
        </CardContent>
      </Card>
      {IsopenPopUp2 ? (
        <FilePopup
          file={props}
          IsopenPopUp={IsopenPopUp2}
          ClosePopUp={ClosePopUp}
          sCropFileLink={props.sCropFileLink}
          IsCrop={false} //No Crop Gallery
          nFile_ID={props.nFile_ID}
          isGallery={Extension.Image.indexOf(props.sFileType.toLowerCase()) > -1 && props.arrFile.length > 1}
          arrFile={props.arrFile}
          setStartVideoOn={props.setStartVideoOn}
          nStartVideoOn={props.nStartVideoOn}
          CannotSkipForward={props.CannotSkipForward}
          onVideoEnd={props.onVideoEnd}
          onDelete={props.onDelete}
          indexActive={indexActive}
        />
      ) : null}
      {IsOpenTextArea && (
        <Dialog
          open={IsOpenTextArea}
          onClose={() => setIsOpenTextArea(false)}
          sx={{ padding: "0 30px" }}
        >
          <Box
          onClick={() => setIsOpenTextArea(false)}
          sx={{
            cursor: "pointer",
            bgcolor: "rgb(92 101 106)",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "1.7em",
            height: "1.7em",
            borderRadius: "50%",
            position: "absolute",
            zIndex: 9999,
            top: "20px",
            right: "20px",
          }}
        >
          <Close sx={{ fontSize: "1em", alignSelf: "center" }} />
        </Box>
          <DialogTitle sx={{ pb: 0 }}>
            {props.sFileName}
          </DialogTitle>
          <DialogContent sx={{ minWidth: "500px", pb: 0 }}>
            <Box
              sx={{
                color: "rgb(52, 71, 103)",
              }}
              className=".Box-content-form"
            >
              <HeaderInput text={t("description")} />
              <TextBoxNoForm
                id={`sDescription_${props.nFile_ID}`}
                name={`sDescription_${props.nFile_ID}`}
                label={"Explain Something..."}
                multiline
                rows={3}
                shrink={false}
                maxLength={100}
                defaultValue={
                  props.arrFile.find(w => w.nFile_ID !== 0 && w.nFile_ID === props.nFile_ID)?.sFileDetail ?
                  props.arrFile.find(w => w.nFile_ID === props.nFile_ID)?.sFileDetail : ""
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {/* <BtnBackForm
              txt={t("back")}
              onClick={() => setIsOpenTextArea(false)}
            /> */}
            <BtnCreateForm
              txt={"Save"}
              onClick={() => {
                let sFileDetail = document.getElementById(`sDescription_${props.nFile_ID}`) as HTMLInputElement;
                if(sFileDetail){
                  props.onSaveDetail(props.nFile_ID,sFileDetail.value)
                  setIsOpenTextArea(false);
                }
              }}
              icon={<SaveAltIcon />}
            />
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

export default ItemCard;

const useStyles = makeStyles((theme: Theme) => ({
  WordColor: {
    color: "#fff !important",
    backgroundColor: "#2372ba !important",
    borderColor: "#2372ba !important",
    margin: theme.spacing(1),
    fontSize: "6em",
    "&:hover": {
      backgroundColor: "rgb(56, 151, 234) !important",
    },
  },
  ExcelColor: {
    color: "#fff !important",
    backgroundColor: "#14a73c !important",
    borderColor: "#14a73c !important",
    margin: theme.spacing(1),
    fontSize: "6em",
    "&:hover": {
      backgroundColor: "rgb(66, 201, 127) !important",
    },
  },
  PDFColor: {
    color: "#fff !important",
    backgroundColor: "#ff0000 !important",
    borderColor: "#ff0000 !important",
    margin: theme.spacing(1),
    fontSize: "6em !important",
    "&:hover": {
      backgroundColor: "rgb(255, 79, 79) !important",
    },
  },
  PowerPointColor: {
    color: "#fff !important",
    backgroundColor: "#f26522 !important",
    borderColor: "#f26522 !important",
    margin: theme.spacing(1),
    fontSize: "6em",
    "&:hover": {
      backgroundColor: "rgb(255, 79, 79) !important",
    },
  },

  FileItem: {
    margin: "0 !important",
    padding: "20px 16px 20px 22px !important",
    // borderBottom: "1px solid #ebeef1 !important",
    animationDuration: ".6s !important",
  },
  ColumnThumbnail: {
    paddingLeft: "10px",
  },
  ColumnTitle: {
    flex: "1 !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
    color: "#74809d !important",
    overflow: "hidden !important",
    backgroundColor: "#fffff !important",
    cursor: "pointer !important",
  },
  ColumnAction: {
    margin: "0 16px",
  },
  Title: {
    fontWeight: 600,
  },
  DangerColor: {
    color: "#fff !important",
    backgroundColor: "#ed3847 !important",
    borderColor: "#ed3847 !important",
    margin: theme.spacing(1),
    fontSize: "6em !important",
    "&:hover": {
      backgroundColor: "rgb(220, 53, 69) !important",
    },
  },
  WarningColor: {
    color: "#fff !important",
    backgroundColor: "#ffce3d !important",
    borderColor: "#ffce3d !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(252, 189, 0) !important",
    },
  },

  IConColor: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}));

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
