import { Typography, Tooltip, Box, Radio } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid-pro";
import { BtnEditOnTable, BtnPreview } from "STM/components/Button";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { EnumPermission } from "STM/enum/enumSystem";
import DataGrid from "STM/components/DataGrid";

function TablePermission({ dataRow, setDataRow }) {
  const { t } = useTranslation();
  const [nPermission, setnPermission] = useState(2);

  const handleChangeRadio = (sID, sField) => {
    let oCheck = dataRow.arrRows.find((f) => f.sID === sID);
    if (oCheck) {
      oCheck.oDisable.IsPermission = sField === "isDisable";
      oCheck.oReadOnly.IsPermission = sField === "isReadOnly";
      oCheck.oEnable.IsPermission = sField === "isEnable";
    }
    setDataRow({ ...dataRow });
  };
  const dataColumn: GridColumns = [
    {
      field: "nNo",
      headerName: t("no"),
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: false,
      minWidth: 50,
      renderCell: (item) => !item.row.isSubMenu && <>{item.row.nNo}</>,
    },
    {
      field: "sMenuName",
      headerName: t("menufunction"),
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (item) =>
        item.row.isSubMenu ? (
          <div>
            <FiberManualRecordIcon sx={{ fontSize: "0.6em", mr: 0.5 }} />{" "}
            {item.value}{" "}
          </div>
        ) : (
          item.value
        ),
      colSpan: ({ row }) => {
        if (row.isHasSub) {
          return 4;
        }
        return 1;
      },
      cellClassName: (params) => (params.row.isHasSub ? "sMenuHead" : ""),
    },
    {
      field: "Disable",
      headerName: t("disable"),
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: false,
      minWidth: 200,
      renderCell: (item) =>
        !item.row.isHasSub &&
        item.row.isManagePRM &&
        !item.row.oDisable.IsHidePermission && (
          <Radio
            checked={item.row.oDisable.IsPermission}
            onChange={() => handleChangeRadio(item.row.sID, "isDisable")}
            disabled={nPermission !== EnumPermission.Enable}
          />
        ),
    },
    {
      field: "isReadOnly",
      headerName: t("view"),
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: false,
      minWidth: 200,
      renderCell: (item) =>
        !item.row.isHasSub &&
        item.row.isManagePRM &&
        !item.row.oReadOnly.IsHidePermission && (
          <Radio
            checked={item.row.oReadOnly.IsPermission}
            onChange={() => handleChangeRadio(item.row.sID, "isReadOnly")}
            disabled={
              nPermission !== EnumPermission.Enable ||
              item.row.nHighPerm < EnumPermission.ReadOnly
            }
          />
        ),
    },
    {
      field: "isEnable",
      headerName: t("addeditdelete"),
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: false,
      minWidth: 200,
      renderCell: (item) =>
        !item.row.isHasSub &&
        item.row.isManagePRM &&
        !item.row.oEnable.IsHidePermission && (
          <Radio
            checked={item.row.oEnable.IsPermission}
            onChange={() => handleChangeRadio(item.row.sID, "isEnable")}
            disabled={
              nPermission !== EnumPermission.Enable ||
              item.row.nHighPerm < EnumPermission.Enable
            }
          />
        ),
    },
  ];
  return (
    <DataGrid
      isLoading={false}
      columns={dataColumn}
      rows={dataRow}
      isNotShowPagination={true}
      isNotShowTotal={true}
      isHiddenToolHead={true}
      isHideFooter={true}
    />
  );
}

export default TablePermission;
