import React, { useState, useEffect } from "react";
import { DynamicLangCol, FnDialog } from "STM/utilities/ST_Function";
import { useTranslation } from "react-i18next";
import DataGrid, {
  PaginationInterface,
  initRows,
} from "STM/components/DataGrid";
import { ChangePin, Delete, GetContentNews, GetDataTable, GetOrder } from "./CallAPI";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Tooltip, Box } from "@mui/material";
import {
  BtnEditOnTable,
  BtnViewImageOnTable,
  BtnPinOnTable,
  BtnPreviewOnTable,
} from "STM/components/Button";
import ChangeOrder from "../Banner/ChangeOrder";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { GridColumns } from "@mui/x-data-grid-pro";
import FilePopup from "STM/components/Input/UploadFile/components/PopUp/FilePopup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import FilterNews from "./FilterNews";
import NewsPreview from "STM/layout/LayoutPreview/NewsLayout/NewsPreview";
import { BsFillTagsFill } from "react-icons/bs";
import Modal from "STM/components/Modal";
import { AnimatePresence } from "framer-motion";
import { dropIn,flip,newspaper } from "STM/components/Modal/ModalVariants";
import { useNewsDropdown } from "./useNewsDropdown";

export default function News() {
  const DialogFn = FnDialog();
  const navigate = useNavigate();
  const [column, setColumn] = useState([] as any);
  const [lstTag, setLstTag] = useState([] as any);
  const { t } = useTranslation();
  const [loadingTable, setLoadingTable] = useState(false);
  const [optOrder, setoptOrder] = useState([]);
  const [IsopenPopUp, setIsopenPopUp] = useState<any>(false);
  const [fileImage, setFileImage] = useState();
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState({} as any);
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "nSortOrder",
    sSortDirection: "desc",
  });

  const { dropdownTag } = useNewsDropdown();
  const [modalOpen, setModalOpen] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  useEffect(() => {
    GetData(dataRow);
  }, []);

  const GetData = (p) => {
    OnGetDataTable(p).then(
      () => {
        DialogFn.UnBlockUI();
      },
      () => {}
    );
  };

  const OnGetDataTable = async (p) => {
    let param = {
      ...p,
      sNewsName: form.getValues("sNewsName"),
      sPin: form.getValues("sPin"),
      lstTag: form.getValues("sTag"),
      sStatus: form.getValues("sStatus"),
      sStart: form.getValues("sStartDate")
        ? moment(form.getValues("sStartDate")).format("DD/MM/YYYY")
        : null,
      sEnd: form.getValues("sEndDate")
        ? moment(form.getValues("sEndDate")).format("DD/MM/YYYY")
        : null,
    };
    DialogFn.BlockUI();
    setLoadingTable(true);
    await GetDataTable(
      param,
      (res) => {
        setDataRow({
          ...p,
          arrRows: res.lstData || [],
          nDataLength: res.nDataLength,
          nPageIndex: res.nPageIndex,
        });
        let arrOpt = [];
        for (let i = 1; i <= res.nOrder; i++) {
          arrOpt.push({ label: `${i}`, value: `${i}` });
        }
        console.log("res",res)
        setoptOrder([...arrOpt]);
        setLoadingTable(false);
      },
      (err) => {
        if (!err.response) {
          DialogFn.Warning(err.Message);
        }
      }
    );
  };

  const dataColumn: GridColumns = [
    {
      headerName: t("action"),
      field: "Button (Add/Edit)",
      type: "actions",
      resizable: false,
      sortable: false,
      minWidth: 100,
      flex: 1,
      getActions: (item) => {
        return [
          <Box
            key={item.row.sID}
            sx={{ display: "flex", width: "100%", alignItems: "flex-start" }}
          >
            <BtnEditOnTable
              txt={t("edit")}
              onClick={() => goToEdit(item.row.sID)}
            />
            <BtnPreviewOnTable
              txt={t("preview")}
              onClick={() => {
                onGetContentNews(item.row.sID);
                
              }}
            />
            {item.row.NewsFile && (
              <BtnViewImageOnTable
                txt="View"
                onClick={() => {
                  OpenPopUp(item.row);
                }}
              />
            )}
          </Box>,
        ];
      },
    },
    {
      field: "nSortOrder",
      headerName: t("order"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 100,
      // flex: 1,
      renderCell: (item) => {
        return (
          <ChangeOrder
            item={item}
            onChangeOrder={onChangeOrder}
            optOrder={optOrder}
          />
        );
      },
    },
    {
      field: "isPin",
      headerName: t("pin"),
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      width: 60,
      // flex: 1,
      renderCell: (item) => {
        return (
          <BtnPinOnTable
            onClick={() => {
              onChangePin(item.row.sID, item.row.isPin).then(
                () => {},
                () => {}
              );
            }}
            IsPin={item.row.isPin}
          />
        );
      },
    },
    {
      field: "sNewsName",
      headerName: t("newsname"),
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      minWidth: 200,
      flex: 2,
      renderCell: (item) => {
        return (
          <>
            <Tooltip
              disableHoverListener={item.row.sNewsName.length < 45}
              title={item.row.sNewsName}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                }}
              >
                {item.row.sNewsName}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "Tag",
      headerName: t("tags"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: false,
      minWidth: 80,
      flex: 1,
      renderCell: (item) => {
        return (
          <BsFillTagsFill
            style={{ fontSize: "1.25rem", cursor: item.row.lstTag.length !== 0 ? "pointer" : "default", color: item.row.lstTag.length !== 0 ? "#2765b6" : "#e0e0e0" }}
            onClick={() => {
              if(item.row.lstTag.length !== 0){
                setLstTag(item.row.lstTag);
              modalOpen ? close() : open();
              }
              
            }}
          />
        );
      },
    },
    {
      field: "sStartDate",
      headerName: t("startdate"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 110,
      // flex: 1,
    },
    {
      field: "sEndDate",
      headerName: t("enddate"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 110,
      // flex: 1,
    },
    {
      field: "sUpdateDate",
      headerName: t("lastupdate"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 120,
      // flex: 1,
      renderCell: (item) => {
        return [
          <Box
            key={item.row.sID}
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: "0.8rem" }}>
                {moment(item.row.sUpdateDate, "DD-MM-YYYY HH:mm")
                  .format("DD/MM/YYYY")
                  .toString()}
              </Typography>

              <Tooltip
                title={
                  <span style={{ textAlign: "center" }}>
                    {[
                      item.row.sUpdateDate,
                      <br key={item.row.sID} />,
                      item.row.sCreateBy,
                    ]}
                  </span>
                }
              >
                <AccessTimeIcon
                  sx={{
                    fontSize: "1.2rem",
                    ml: 1,
                    color: "rgb(45, 206, 137)",
                  }}
                />
              </Tooltip>
            </Box>
          </Box>,
        ];
      },
    },
    {
      field: "isActive",
      headerName: t("status"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 85,
      // flex: 1,
      renderCell: (item) => {
        return [
          item.row.isActive ? (
            <Typography className="typography-status">
              <span className="span-status">
                <span
                  className="span-text-status"
                  style={{
                    background:
                      "linear-gradient(310deg, rgb(45, 206, 137), rgb(45, 206, 204))",
                    color: "#fff",
                  }}
                >
                  {t("active")}
                </span>
              </span>
            </Typography>
          ) : (
            <Typography className="typography-status">
              <span className="span-status">
                <span
                  className="span-text-status"
                  style={{
                    background: "linear-gradient(310deg, #B4324A, #DA5A73)",
                    color: "#fff",
                  }}
                >
                  {t("inactive")}
                </span>
              </span>
            </Typography>
          ),
        ];
      },
    },
  ];

  const onGetContentNews = (sID) => {
    DialogFn.BlockUI();
    GetContentNews(
      { sID: sID },
      (res) => {
        setPreviewData(res);
        setIsShowPreview(true);
        DialogFn.UnBlockUI();
      },
      (err) => {
        if (!err.response) {
          DialogFn.Warning(err.Message);
        }
        DialogFn.UnBlockUI();
      }
    );
  };

  const OpenPopUp = (item) => {
    setFileImage(item.NewsFile[0]);
    setIsopenPopUp(true);
  };

  const ClosePopUp = () => {
    setIsopenPopUp(false);
  };

  const goToEdit = (sID: string) => {
    navigate(`/NewsForm`, {
      state: {
        sID: sID,
      },
    });
  };

  const onChangeOrder = async (sID, nOrder) => {
    let param = {
      sID: sID,
      nOrder: nOrder,
      IsPin: false,
    };
    DialogFn.BlockUI();
    await GetOrder(
      param,
      (res) => {
        GetData(dataRow);
      },
      (err) => {
        if (!err.response) {
          DialogFn.Warning(err.Message);
        }
        DialogFn.UnBlockUI();
      }
    );
  };

  const onChangePin = async (sID, IsPin) => {
    let param = {
      sID: sID,
      IsPin: IsPin,
    };
    DialogFn.BlockUI();
    await ChangePin(
      param,
      (res) => {
        GetData(dataRow);
      },
      (err) => {
        if (!err.response) {
          DialogFn.Warning(err.Message);
        }
        DialogFn.UnBlockUI();
      }
    );
  };

  const OnDelete = (e) => {
    DialogFn.Submit(t("deletemsg"), async () => {
      DialogFn.BlockUI();
      setLoadingTable(true);
      await Delete(
        { lstNewsDelete: e },
        (res) => {
          form.reset();
          OnGetDataTable(dataRow).then(
            () => {
              DialogFn.Success(t("deletesuccess"));
              DialogFn.UnBlockUI();
            },
            () => {}
          );
        },
        (err) => {
          if (!err.response) {
            DialogFn.Warning(err.Message);
          }
          DialogFn.UnBlockUI();
        }
      );
    });
  };

  const schema = yup.object().shape({});
  const formResolver = yupResolver(schema);
  const form = useForm({
    resolver: formResolver,
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: {} as any,
  });

  const OnClearFilter = () => {
    form.reset();
    GetData(dataRow);
  };

  useEffect(() => {
    if (dataRow.arrRows.length > 0) {
      setColumn(
        DynamicLangCol(dataRow.arrRows[0].lstLanguageCode, dataColumn, 4)
      );
    }
  }, [dataRow]);
  return (
    <Grid
      container
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexFlow: "row wrap",
        marginTop: "24px",
        width: "calc(100% - 24px)",
        justifyContent: "center",
      }}
    >
      <AnimatePresence
        mode="wait"
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {modalOpen && (
          <Modal
            // modalOpen={modalOpen}
            variants={dropIn}
            comp={
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    padding: "0.5rem 1rem",
                    backgroundColor: "#4d9cf5",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BsFillTagsFill style={{ marginRight: "0.35rem" }} /> {t('tags')}
                </div>
                <div style={{ padding: "1rem",height:"300px",overflowY:"scroll" }}>
                  {lstTag.map((tag) => (
                    <span
                      key={tag}
                      className="badge-news"
                      style={{
                        color: "rgb(52, 71, 103)",
                        background: "#b0bcd4",
                        textAlign: "left",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        borderTopRightRadius:"1rem",borderBottomRightRadius:"1rem"
                      }}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            }
            handleClose={close}
          />
        )}
      </AnimatePresence>

      <NewsPreview
        open={isShowPreview}
        handleClose={() => setIsShowPreview(false)}
        data={previewData}
      />
      <Grid item xs={12}>
        <Grid item xs={12} className="Card-form">
          <Grid
            item
            xs={12}
            sx={{ color: "rgb(52, 71, 103)" }}
            className="Header-grid-form"
          >
            <Grid item xs={12}>
              {IsopenPopUp && (
                <FilePopup
                  file={fileImage}
                  isGallery={false}
                  IsopenPopUp={IsopenPopUp}
                  ClosePopUp={ClosePopUp}
                />
              )}
              <DataGrid
                isLoading={loadingTable}
                columns={column}
                rows={dataRow}
                onLoadData={(e) => {
                  GetData(e);
                }}
                isNotShowPagination={false}
                isNotShowTotal={true}
                isShowCheckBox={true}
                onCickRow={() => {}}
                modeFilter={1}
                onDelete={(d) => OnDelete(d)}
                isAddData
                linkAdd={"/NewsForm"}
                customFilterComponent={
                  <FormProvider {...form}>
                    <FilterNews
                      onSearch={(e) => GetData(dataRow)}
                      onClear={() => OnClearFilter()}
                      dropdownTag={dropdownTag}
                    />
                  </FormProvider>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
