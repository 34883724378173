import React from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { enumMenu } from "enum/enumSystem2";

import { ShowUnderConstraction } from "view/UnderConstraction/UnderConstraction";
import PopupUnderConstraction from "view/UnderConstraction/UnderConstraction2";


function LayoutNav({ isPreview, lstMenu }) {
  const navigate = useNavigate();
  const location = useLocation();
  const frontURL = process.env.REACT_APP_Front_URL;
  const [OpenUnderContraction, setOpenUnderContraction] = useState(false);

  const FunctionCloseDialog = () => {
    setOpenUnderContraction(false);
  }
  const renderMenu = () => {

    return lstMenu.map((menu, index) => {

      if (isPreview === true) {
        return (
          <li key={index} className="nav-item">
            <div className="nav-link collapsed">
              <span>{menu.sMenuName}</span>
            </div>
          </li>
        )

      } else {

        if (menu.sLink === enumMenu.UnderConstruction.toString()) {
          //กรณีที่เป็น construction 
          return (
            <li className="nav-item" key={index}>
              <div className={location.pathname && location.pathname.includes(menu.sLink)
                ? "nav-link menu-link collapsed active" : "nav-link menu-link collapsed inactive"}
                onClick={() => { setOpenUnderContraction(true) }}>
                <span>{menu.sMenuName}</span>
              </div>
            </li>
          );
        } else {
          return (
            <li key={index} className="nav-item">
              <a className={location.pathname && location.pathname.includes(menu.sLink)
                ? "nav-link menu-link collapsed active" : "nav-link menu-link collapsed inactive"}
                href={frontURL + menu.sLink}>
                <span>{menu.sMenuName}</span>
              </a>
            </li>
          );
        }
      }
    });
  };
  return (
    <>
      <ul className="navbar-nav" id="navbar-nav">
        {renderMenu()}
      </ul>

      <PopupUnderConstraction open={OpenUnderContraction} handleClose={FunctionCloseDialog} />
    </>
  );
}




export default LayoutNav;