import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import { Box, Grid, Typography, Button } from "@mui/material";

import parse from 'html-react-parser';

export default function Overview({ OverviewHtmlTop, OverviewHtmlBottom, OverviewList }) {
   const frontURL = process.env.REACT_APP_Front_URL;

   const jsonOverview = OverviewList[0];
   // console.log("jsonOverview", OverviewList[0]);
   // const jsonOverview = [
   //    {
   //       "header": [
   //          {
   //             "id": 1,
   //             "headTitle": "expanding domestic and internation markets"
   //          },
   //          {
   //             "id": 2,
   //             "headTitle": "assets management & utilization"
   //          },
   //          {
   //             "id": 3,
   //             "headTitle": "building competitive supply portfolio"
   //          }
   //       ],
   //       "body": [
   //          {
   //             "groupId": 1,
   //             "bodyTitle1": "collaborate with ptt for domestic and internation market",
   //             "bodyTitle2": "maximize return to shareholder",
   //             "bodyTitle3": "build competitive lng portfolio"
   //          },
   //          {
   //             "groupId": 2,
   //             "bodyTitle1": "build credential and experience in lng market",
   //             "bodyTitle2": "UTILIZE PTT GROUP LNG INFASTRUCTURE",
   //             "bodyTitle3": "SELECTIVE M&A OPPORTUNITIES"
   //          },
   //          {
   //             "groupId": 3,
   //             "bodyTitle1": "synergy with ptt group to fulfill customer's needs",
   //             "bodyTitle2": "asset - backed portfolio player",
   //             "bodyTitle3": "diversify sources of supply in term of location, term volume and price index for flexibility"
   //          }
   //       ]
   //    }
   // ]
   return (
      <div className="sec_overview">
         {/* <div className="text-topic">about us</div>
         <div className="text-title">Overview & <br></br> Our Journey</div>

         <div className="overview-box-image">
            <div className="sec_overview_img ImgZoomIn"
               style={{ backgroundImage: `url(${frontURL + "assets/aboutus/overview.png"})` }}>
            </div>
         </div>

         <div className="sec_overview_body">
            PTT Global LNG Company Limited (PTTGL), a subsidiary of PTT, was established in 2017 with an ambition to be a flagship of PTT Group in the LNG business. PTTGL was the first step of PTT group in integrated LNG value chain investment expansion with a first investment project in Petronas LNG 9 Sdn Bhd (PL9SB). PTTGL focuses on building LNG supply portfolio to strengthen Thailand energy security while providing flexibility to customers and gas end-users by synergizing the various capabilities of PTT Group. PTTGL is also expanding its marketing network internationally. PTTGL’s scope of investment includes the whole LNG value chain from LNG liquefaction plant to gas end-users, with a vision to be an Integrated LNG Player by 2030.
         </div>

         <div className="overview-title">PTT Vision</div>
         <div className="overview-topic">Powering Life with Future Energy and Beyond</div>
         <div className="overview-sub">
            PTT Public Company Limited (PTT), formerly Petroleum Authority of Thailand, was established on December 29, 1978. PTT commenced its primary mission to secure energy for domestic consumption and to support Thailand’s growing economy.
         </div>

         <div className="overview-title">PTT MISSION</div>
         <div className="overview-sub">
            To conduct an integrated energy and petrochemical business as the national energy company with a mission to serve all stakeholders equitably.
         </div>

         <div className="overview-pttmission-image">
            <div className="sec_overview_img ImgZoomIn"
               style={{ backgroundImage: `url(${frontURL + "assets/aboutus/overview-pttmission.png"})` }}>
            </div>
         </div>

         <div className="overview-title">PTT GL</div>
         <div className="overview-sub">
            PTT Global LNG Company Limited (PTTGL), a subsidiary of PTT, was established in 2017 with an ambition to be a flagship of PTT Group in the LNG business. PTTGL was the first step of PTT Group's integrated LNG value chain investment expansion with a first investment project in PETRONAS LNG 9 Sdn Bhd (PL9SB). PTTGL focuses on building an LNG supply portfolio to strengthen Thailand's energy security while providing flexibility to customers and gas end-users by synergizing the various capabilities of PTT Group. PTTGL is also expanding its marketing network internationally.
         </div>
         <div className="overview-sub">
            PTTGL’s scope of investment includes the whole LNG value chain from the LNG liquefaction plant to the gas end-users, with a vision to become an Integrated LNG company by 2030.
         </div> */}

         {parse(OverviewHtmlTop.sContents)}

         <Grid container className="strategic-framework">

            <Grid item xs={12}>
               <Grid container sx={{ justifyContent: "space-between" }}>
                  {
                     jsonOverview.map((item, Index) => (
                        item.header.map((subItem, subIndex) => (
                           <Grid key={subItem.id} item xs={3.8} className="strategic-item strategic-head">
                              <Box>
                                 {subItem.headTitle}
                              </Box>
                           </Grid>
                        ))
                     ))
                  }
               </Grid>
            </Grid>

            {
               jsonOverview.map((Item, Index) => (
                  Item.body.map((SubItem, SubIndex) => (
                     <Grid key={SubItem.groupId} item xs={12}>
                        <Grid container sx={{ justifyContent: "space-between" }}>
                           <Grid item xs={3.8} className="strategic-item strategic-body">
                              <Box>
                                 {SubItem.bodyTitle1}
                              </Box>
                           </Grid>
                           <Grid item xs={3.8} className="strategic-item strategic-body">
                              <Box>
                                 {SubItem.bodyTitle2}
                              </Box>
                           </Grid>
                           <Grid item xs={3.8} className="strategic-item strategic-body">
                              <Box>
                                 {SubItem.bodyTitle3}
                              </Box>
                           </Grid>
                        </Grid>
                     </Grid>
                  ))
               ))
            }

         </Grid>

         {/* {parse(OverviewHtmlBottom.sContents)} */}

         <div className="overview-line">
            <hr></hr>
         </div>

         <div className="sec_overview_spirit">
            <div className="gl_spirit">

               <div className="gl-spirit__item">
                  <Tooltip title="Globalization" placement="top">
                     <div className="gl-spirit_alphabet bgcolor-g">G</div>
                  </Tooltip>
                  <div className="gl-spirit__title color-g">
                     Globalization
                  </div>
               </div>

               <div className="gl-spirit__item">
                  <Tooltip title="Leadership" placement="top">
                     <div className="gl-spirit_alphabet bgcolor-l">L</div>
                  </Tooltip>
                  <div className="gl-spirit__title color-l">
                     Leadership
                  </div>
               </div>

               <div className="gl-spirit__item">
                  <Tooltip title="Synergy" placement="top">
                     <div className="gl-spirit_alphabet bgcolor-s">S</div>
                  </Tooltip>
                  <div className="gl-spirit__title color-s">
                     Synergy
                  </div>
               </div>

               <div className="gl-spirit__item">
                  <Tooltip title="Performance Excellence" placement="top">
                     <div className="gl-spirit_alphabet bgcolor-p">P</div>
                  </Tooltip>
                  <div className="gl-spirit__title color-p">
                     Performance <br></br> Excellence
                  </div>
               </div>

               <div className="gl-spirit__item">
                  <Tooltip title="Innovation" placement="top">
                     <div className="gl-spirit_alphabet bgcolor-i1">I</div>
                  </Tooltip>
                  <div className="gl-spirit__title color-i1">
                     Innovation
                  </div>
               </div>

               <div className="gl-spirit__item">
                  <Tooltip title="Responsibility" placement="top">
                     <div className="gl-spirit_alphabet bgcolor-r">R</div>
                  </Tooltip>
                  <div className="gl-spirit__title color-r">
                     Responsibility
                  </div>
               </div>

               <div className="gl-spirit__item">
                  <Tooltip title="Integrity & Ethics" placement="top">
                     <div className="gl-spirit_alphabet bgcolor-i2">I</div>
                  </Tooltip>
                  <div className="gl-spirit__title color-i2">
                     Integrity <br></br> & <br></br> Ethics
                  </div>
               </div>

               <div className="gl-spirit__item">
                  <Tooltip title="Trust & Respect" placement="top">
                     <div className="gl-spirit_alphabet bgcolor-t">T</div>
                  </Tooltip>
                  <div className="gl-spirit__title color-t">
                     Trust <br></br> & <br></br> Respect
                  </div>
               </div>

            </div>
         </div>

      </div>
   )
}
