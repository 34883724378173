
import { Calendar } from 'primereact/calendar';
import { forwardRef, useState } from 'react';

// CSS
import "./TimePicker.css";
// import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import 'primeflex/primeflex.css';
import { Controller, useFormContext } from 'react-hook-form';
import { TimePickerProps } from './TimePickerProps';
import { FormHelperText } from '@mui/material';

const TimePicker = forwardRef((props: TimePickerProps, ref) => {
    const {
        name,
        required = false,
        fullWidth = true
    } = props;

    const {
        control,
        setValue,
        clearErrors
    } = useFormContext();

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { invalid, error } }) => {
                    return (
                        <>
                            <div className="card flex flex-wrap gap-3 p-fluid">
                                <div className="flex-auto">
                                    <span className={props.disabled === true ? "p-float-label p-label-disabled" : "p-float-label"}>
                                        <Calendar
                                            inputRef={ref}
                                            id="calendar-timeonly"
                                            value={value || null}
                                            className={invalid ? "p-invalid" : props.disabled === true ? "p-disabled" : ""}
                                            onChange={(e) => {
                                                onChange(e.value);
                                                props.onChange && props.onChange(e.value);
                                            }}
                                            timeOnly
                                            onShow={() => {
                                                if(!value) {
                                                    setValue(name, new Date());
                                                    clearErrors(name);
                                                }
                                            }}
                                            required={required}
                                            icon={props.icon}
                                            iconPos={props.iconPosition}
                                            showIcon={props.showIcon}
                                            placeholder={props.hourFormat === "12" ? "12:00 AM" : "00:00"}
                                            hourFormat={props.hourFormat}
                                            mask={props.hourFormat === "12" ? "99:99 aa" : "99:99"}
                                            disabled={props.disabled}
                                            minDate={props.minDate}
                                            maxDate={props.maxDate}
                                        />
                                        <label htmlFor="time_picker_label" className={invalid ? "time-picker-invalid" : ""}>{props.label}
                                            {required &&
                                                <span className="time-picker-required">
                                                    *
                                                </span>
                                            }
                                        </label>
                                    </span>
                                    {error ? (
                                        <FormHelperText sx={{ color: "red" }}>
                                            {error.message}
                                        </FormHelperText>
                                    ) : null}
                                </div>
                            </div>
                        </>
                    )
                }
                }
            />
        </>
    );
})

export default TimePicker;