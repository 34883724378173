import { AxiosGet } from "STM/utilities/ST_Axios";
import { useEffect, useState } from "react";

export const useNewsDropdown = () => {
    const [dropdownTag, setDropdownTag] = useState([] as any);
    useEffect(() => {
        const GetDropdownNewsTag = async () => {
          await AxiosGet("News/GetNewsTag", {}, (res) => {
            setDropdownTag(res);
          });
        };
        GetDropdownNewsTag();
      }, []);

    return { dropdownTag }
}