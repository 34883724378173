import { useState, forwardRef, useCallback } from "react";
import {
  SelectFromProps,
  SelectNoFromProps,
  FilmOptionType,
} from "./SelectProps";
import { useFormContext, Controller } from "react-hook-form";
import {
  Autocomplete,
  TextField,
  FormHelperText,
  Popper,
  createFilterOptions,
  Tooltip,
  Box,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ParseHtml } from "STM/utilities/ST_Function";
import { useTranslation } from "react-i18next";
import "./CustomSelect.css";

const filter = createFilterOptions<FilmOptionType>();

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const SelectFormItem = forwardRef((props: SelectFromProps, ref) => {
  const { name, fullWidth = true } = props;

  const { control } = useFormContext();
  const [t] = useTranslation();
  const PopperCustom = useCallback((props) => {
    return (
      <Popper
        {...props}
        placement="bottom"
        modifiers={[
          {
            name: "flip",
            enabled: false,
            options: {
              altBoundary: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
        ]}
      />
    );
  }, []);

  const getOpObjValue = (val) => {
    let res = null;
    if (val) res = props.options.find((op) => op.value === val);
    return res;
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { invalid, error },
        }) => {
          return (
            <>
              <Autocomplete
                {...ref}
                ref={ref}
                id={props.id}
                disabled={props.disabled || null}
                PopperComponent={PopperCustom}
                fullWidth={fullWidth}
                size={"small"}
                options={props.options || []}
                value={getOpObjValue(value)}
                noOptionsText={props.notOptionsText || t("datanotfound")}
                disableClearable={props.isClearable || null}
                renderOption={(propsOption, option: any, state) => {
                  return (
                    <li
                      {...propsOption}
                      key={option.value}
                      style={{ background: "white",padding:"0 0.5rem" }}
                    >
                      <span className="minimal-option" style={state.selected ? {backgroundColor:"#e0e0e0",fontWeight:500,color:"rgba(52, 71, 103,1)"} : {}}>
                        <span className="label-options" style={props.isPressToSelect && props.isPressToSelect === true ? {} : {width:"100%"}}>{option.label}</span>
                        {
                          props.isPressToSelect && props.isPressToSelect === true && (
                            <span className="press-to-select">press to select</span>
                          ) 
                        }
                      </span>
                    </li>
                    // <li {...props} key={option.value}
                    // // style={option.color ? { backgroundColor: option.color } : {}}
                    // style={{padding:"0 2rem",borderRadius:"10px"}}
                    // >
                    //         {option.label}
                    // </li>
                  );
                }}
                getOptionLabel={(itemOption: any) => {
                  return `${itemOption?.label}`;
                }}
                // isOptionEqualToValue={(option, value) =>
                //     option.value === value.value
                // }
                renderInput={(params) => {
                  let TooltipTitle = null;
                  if (props.disabled) {
                    TooltipTitle = params.inputProps.value;
                  }
                  return (
                    <Tooltip
                      title={ParseHtml(TooltipTitle)}
                      disableHoverListener={TooltipTitle ? false : true}
                      disableFocusListener
                    >
                      <TextField
                        {...params}
                        name={name}
                        error={error?.message != null}
                        required={props.required}
                        disabled={props.disabled}
                        label={
                          props.shrink && props.shrink === true
                            ? props.label
                            : ""
                        }
                        placeholder={!props.shrink ? props.label : ""}
                        size={"small"}
                        fullWidth={fullWidth}
                        sx={{
                          "label.MuiInputLabel-shrink": {
                            backgroundColor: "rgb(255 255 255 / 18%)",
                            //backgroundColor: "rgba(255,255,255,0.95)",
                            top: "0px",
                          },
                          ".MuiInputLabel-outlined": {
                            top: "0px",
                          },
                          ".MuiInputLabel-asterisk": {
                            color: "red",
                          },
                        }}
                      />
                    </Tooltip>
                  );
                }}
                onChange={(event, value) => {
                  onChange(value != null ? value["value"] : null);
                  props.onChange && props.onChange(value, event);
                }}
                onBlur={(event) => {
                  onBlur();
                  props.onBlur && props.onBlur(value, event);
                }}
                onKeyPress={(event) => {
                  props.onKeyPress && props.onKeyPress(event);
                }}
                onKeyDown={(event) => {
                  props.onKeyDown && props.onKeyDown(event);
                }}
                onKeyUp={(event) => {
                  props.onKeyPress && props.onKeyPress(event);
                }}
              />
              {error ? (
                <FormHelperText sx={{ color: "red" }}>
                  {error.message}
                </FormHelperText>
              ) : null}
            </>
          );
        }}
      />
    </>
  );
});

export default SelectFormItem;

export const SelectMultipleFormItem = forwardRef(
  (props: SelectFromProps, ref) => {
    const { name, fullWidth = true, limitTags = 2 } = props;
    const { control } = useFormContext();
    const [t] = useTranslation();
    const getOpObjValue = (val) => {
      let res = [];
      if (val) {
        val.forEach((element) => {
          res.push(props.options.find((op) => op.value === element));
        });
      }
      return res;
    };

    const PopperCustom = useCallback((props) => {
      return (
        <Popper
          {...props}
          placement="bottom"
          disablePortal={true}
          modifiers={[
            {
              name: "flip",
              enabled: false,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
          style={{ width: 300 }}
        />
      );
    }, []);

    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { invalid, error },
        }) => {
          return (
            <>
              <Autocomplete
                multiple
                disableCloseOnSelect
                PopperComponent={PopperCustom}
                ref={ref}
                id={props.id}
                disabled={props.disabled || null}
                fullWidth={fullWidth}
                size={"small"}
                options={props.options || []}
                value={getOpObjValue(value)}
                noOptionsText={props.notOptionsText || t("datanotfound")}
                disableClearable={props.isClearable || null}
                limitTags={limitTags}
                // isOptionEqualToValue={(option, value) => option.value === value.value}
                // getOptionDisabled={option => true}
                renderOption={(props_, option, { selected }) => {
                  return (
                    <li {...props_} key={option.value} style={{ background: "white" }} >
                      <span className="minimal-option" style={selected ? {backgroundColor:"#e0e0e0",fontWeight:500,color:"rgba(52, 71, 103,1)"} : {}}>
                        <span className="label-options" style={props.isPressToSelect && props.isPressToSelect === true ? {} : {width:"100%"}}>
                        <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        onChange={(e, v) => {
                          let val = [];
                          if (value) {
                            if (e.target.checked) {
                              let lst: any = (value || []).filter(
                                (f) => f != option.value
                              );
                              lst.push(option.value);
                              val = lst;
                            } else {
                              val = (value || []).filter(
                                (f) => f != option.value
                              );
                            }
                          } else {
                            if (e.target.checked) {
                              val.push(option.value);
                            }
                          }

                          onChange && onChange(val);
                          props.onChange && props.onChange(val, e);
                        }}
                      />
                      {option.label}
                      </span>
                      {
                          props.isPressToSelect && props.isPressToSelect === true && (
                            <span className="press-to-select">press to select</span>
                          ) 
                        }
                      </span>
                    </li>
                  );
                }}
                getOptionLabel={(itemOption: any) => {
                  return `${itemOption?.label}`;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={name}
                    error={error?.message != null}
                    required={props.required}
                    disabled={props.disabled}
                    label={
                      props.shrink && props.shrink === true
                        ? props.label
                        : ""
                    }
                    placeholder={!props.shrink ? props.label : ""}
                    size={"small"}
                    fullWidth={fullWidth}
                    sx={{
                      "label.MuiInputLabel-shrink": {
                        backgroundColor: "rgb(255 255 255 / 18%)",
                        //backgroundColor: "rgba(255,255,255,0.95)",
                        top: "0px",
                      },
                      ".MuiInputLabel-outlined": {
                        top: "0px",
                      },
                      ".MuiInputLabel-asterisk": {
                        color: "red",
                      },
                      ".Mui-disabled":{
                        background:"#fafafacc"
                      }
                    }}
                  />
                )}
                onChange={(event, value) => {
                  let arr = [];
                  if (value) {
                    value.forEach((f) => {
                      arr.push(f["value"]);
                    });
                  }
                  onChange(arr != null ? arr : null);
                  props.onChange && props.onChange(value, event);
                }}
                onBlur={(event) => {
                  onBlur();
                  props.onBlur && props.onBlur(value, event);
                }}
                onKeyPress={(event) => {
                  props.onKeyPress && props.onKeyPress(event);
                }}
                onKeyDown={(event) => {
                  props.onKeyDown && props.onKeyDown(event);
                }}
                onKeyUp={(event) => {
                  props.onKeyPress && props.onKeyPress(event);
                }}
              />
              {error ? (
                <FormHelperText sx={{ color: "red" }}>
                  {error.message}
                </FormHelperText>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

export const SelectMultipleAddOptionsFormItem = forwardRef(
  (props: SelectFromProps, ref) => {
    const { name, fullWidth = true, limitTags = 2 } = props;
    const [options_, setoptions] = useState<any>(props.options || []);
    const { control, getValues, setValue } = useFormContext();
    const [t] = useTranslation();
    const fixedOptions = [];
    const getOpObjValue = (val) => {
      let res = [];
      if (val) {
        val.forEach((element) => {
          res.push(props.options.find((op) => op.value === element));
        });
      }
      return res;
    };

    const handleToggleSelectAll = (selectedOptions) => {
      const allSelected =
        props.options.length === (getValues(name) || []).length;
      let chkFix = (getValues(name) || []).filter((f) => f == fixedOptions[0]);
      let chkData = chkFix.length > 0 ? [] : fixedOptions[0] ?? [];
      if (!allSelected) {
        setValue(name, [...chkData, ...props.options.map((m) => m.value)], {
          shouldValidate: true,
        });
        props.onChange && props.onChange(props.options, null);
      } else {
        setValue(name, fixedOptions);
        props.onChange && props.onChange([], null);
      }
    };

    const handleChange = (event, selectedOptions, reason) => {
      let chkFix = (getValues(name) || []).filter((f) => f == fixedOptions[0]);
      let chkData = chkFix.length > 0 ? [] : fixedOptions[0] ?? [];
      if (reason === "selectOption" || reason === "removeOption") {
        if (selectedOptions.find((option) => option?.value === "999")) {
          handleToggleSelectAll(selectedOptions);
        } else if (selectedOptions.find((option) => option.value === "9999")) {
          props.options.push({
            value: selectedOptions.find((option) => option?.value === "9999")[
              "newVaue"
            ],
            label: selectedOptions.find((option) => option?.value === "9999")[
              "newVaue"
            ],
            Isnew: true,
          });
          let v = selectedOptions
            .filter((f) => f?.value != "9999")
            .map((m) => m["value"]);
          let o = selectedOptions
            .filter((f) => f?.value == "9999")
            .map((m) => m["newVaue"]);
          setValue(name, [...chkData, ...v, ...o], { shouldValidate: true });
          // setValue(name, [...chkData, ...selectedOptions.filter(f => f?.value != "9999").map(m => m["value"]), ...selectedOptions.filter(f => f?.value == "9999").map(m => m["newVaue"])], { shouldValidate: true })
          props.onChange && props.onChange(selectedOptions, event);
        } else {
          // onToggleOption && onToggleOption(selectedOptions);
          setValue(name, [...chkData, ...selectedOptions.map((m) => m.value)], {
            shouldValidate: true,
          });
          props.onChange && props.onChange(selectedOptions, event);
        }
      } else if (reason === "clear") {
        setValue(name, fixedOptions, { shouldValidate: true });
        props.onChange && props.onChange([], event);
      }
    };

    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { invalid, error },
        }) => {
          return (
            <>
              <Autocomplete
                multiple
                disableCloseOnSelect
                ref={ref}
                id={name}
                disabled={props.disabled || null}
                fullWidth={fullWidth}
                size={"small"}
                options={props.options || []}
                value={getOpObjValue(value)}
                noOptionsText={props.notOptionsText || t("datanotfound")}
                disableClearable={props.isClearable || null}
                limitTags={limitTags}
                renderOption={(props_, option, { selected }) => {
                  return (
                    <li {...props_} key={option.value}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={
                          ((getValues(name) || []).length > 0 &&
                            option.value === "999" &&
                            props.options.length ===
                              (getValues(name) || []).length) ||
                          selected
                            ? true
                            : selected
                        }
                      />
                      {option.label}
                    </li>
                  );
                }}
                getOptionLabel={(itemOption: any) => {
                  return `${itemOption?.label}`;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params.inputValue !== "") {
                    if (
                      props.options.filter((f) => f.value == params.inputValue)
                        .length == 0
                    ) {
                      filtered.push({
                        value: "9999",
                        label: `Add "${params.inputValue}"`,
                        newVaue: params.inputValue,
                      });
                    }
                  }
                  return [{ label: "Select All", value: "999" }, ...filtered];
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={name}
                    error={error?.message != null}
                    required={props.required}
                    disabled={props.disabled}
                    label={props.label}
                    size={"small"}
                    fullWidth={fullWidth}
                    sx={{
                      "label.MuiInputLabel-shrink": {
                        backgroundColor: "rgb(255 255 255 / 18%)",
                        //backgroundColor: "rgba(255,255,255,0.95)",
                        top: "0px",
                      },
                      ".MuiInputLabel-outlined": {
                        top: "0px",
                      },
                      ".MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    }}
                  />
                )}
                onChange={handleChange}
                onBlur={(event) => {
                  onBlur();
                  props.onBlur && props.onBlur(value, event);
                }}
                onKeyPress={(event) => {
                  props.onKeyPress && props.onKeyPress(event);
                }}
                onKeyDown={(event) => {
                  props.onKeyDown && props.onKeyDown(event);
                }}
                onKeyUp={(event) => {
                  props.onKeyPress && props.onKeyPress(event);
                }}
              />
              {error ? (
                <FormHelperText sx={{ color: "red" }}>
                  {error.message}
                </FormHelperText>
              ) : null}
            </>
          );
        }}
      />
    );
  }
);

export const SelectNoFormItem = forwardRef((props: SelectNoFromProps, ref) => {
  const { name, fullWidth = true } = props;
  const [t] = useTranslation();
  const [value, setValue] = useState<string>(props.value);

  const PopperCustom = useCallback((props) => {
    return (
      <Popper
        {...props}
        placement="bottom"
        modifiers={[
          {
            name: "flip",
            enabled: false,
            options: {
              altBoundary: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
        ]}
      />
    );
  }, []);

  const getOpObjValue = (val) => {
    let res = null;
    if (val) res = props.options.find((op) => op.value === val);
    return res;
  };

  return (
    <Autocomplete
      ref={ref}
      id={name}
      disabled={props.disabled || null}
      PopperComponent={PopperCustom}
      fullWidth={fullWidth}
      size={props.size || "small"}
      options={props.options || []}
      value={getOpObjValue(value)}
      noOptionsText={props.notOptionsText || t("datanotfound")}
      disableClearable={props.isClearable || null}
      renderOption={(props, option: any) => {
        return (
          <li
            {...props}
            key={option.value}
            style={option.color ? { backgroundColor: option.color } : {}}
          >
            {option.label}
          </li>
        );
      }}
      getOptionLabel={(itemOption: any) => {
        return `${itemOption?.label}`;
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          disabled={props.disabled}
          label={props.label}
          size={props.size || "small"}
          fullWidth={fullWidth}
          sx={{
            "label.MuiInputLabel-shrink": {
              backgroundColor: "rgb(255 255 255 / 18%)",
              //backgroundColor: "rgba(255,255,255,0.95)",
              top: "0px",
            },
            ".MuiInputLabel-outlined": {
              top: "0px",
            },
            ".MuiInputLabel-asterisk": {
              color: "red",
            },
          }}
        />
      )}
      onChange={(event, value) => {
        setValue(value != null ? value["value"] : null);
        props.onChange && props.onChange(value, event);
      }}
      onBlur={(event) => {
        props.onBlur && props.onBlur(value, event);
      }}
      onKeyPress={(event) => {
        props.onKeyPress && props.onKeyPress(event);
      }}
      onKeyDown={(event) => {
        props.onKeyDown && props.onKeyDown(event);
      }}
      onKeyUp={(event) => {
        props.onKeyPress && props.onKeyPress(event);
      }}
    />
  );
});

export const SelectMultipleNoFormItem = forwardRef(
  (props: SelectNoFromProps, ref) => {
    const { name, fullWidth = true, limitTags = 2 } = props;

    const [value, setValue] = useState<string[]>(props.value);

    const [t] = useTranslation();
    const getOpObjValue = (val) => {
      let res = [];
      if (val) {
        val.forEach((element) => {
          res.push(props.options.find((op) => op.value === element));
        });
      }
      return res;
    };

    return (
      <Autocomplete
        multiple
        disableCloseOnSelect
        ref={ref}
        id={props.id}
        disabled={props.disabled || null}
        fullWidth={fullWidth}
        size={"small"}
        options={props.options || []}
        value={getOpObjValue(value)}
        noOptionsText={props.notOptionsText || t("datanotfound")}
        disableClearable={props.isClearable || null}
        limitTags={limitTags}
        renderOption={(props_, option, { selected }) => {
          return (
            <li {...props_} key={option.value}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  option.value === "All999"
                    ? (value || []).length == (props.options || []).length
                    : selected
                }
                onChange={(e, v) => {
                  if (option.value === "All999" && !v) {
                    props.onChange && props.onChange([], e);
                    setValue([]);
                  } else {
                    let val = [];
                    if (value) {
                      if (e.target.checked) {
                        let lst: any = (value || []).filter(
                          (f) => f != option.value
                        );
                        lst.push(option.value);
                        val = lst;
                      } else {
                        val = (value || []).filter((f) => f != option.value);
                      }
                    } else {
                      if (e.target.checked) {
                        val.push(option.value);
                      }
                    }
                    props.onChange && props.onChange(val, e);
                  }
                }}
              />
              {option.label}
            </li>
          );
        }}
        filterOptions={(options, params) => {
          const filtered = params.inputValue
            ? options.filter((f: any) =>
                f.label.toLowerCase().includes(params.inputValue.toLowerCase())
              )
            : options;
          return [{ label: "Select All", value: "All999" }, ...filtered];
        }}
        getOptionLabel={(itemOption: any) => {
          return `${itemOption?.label}`;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            disabled={props.disabled}
            label={props.label}
            size={"small"}
            fullWidth={fullWidth}
            sx={{
              "label.MuiInputLabel-shrink": {
                backgroundColor: "rgb(255 255 255 / 18%)",
                top: "0px",
              },
              ".MuiInputLabel-outlined": {
                top: "0px",
              },
              ".MuiInputLabel-asterisk": {
                color: "red",
              },
            }}
          />
        )}
        onChange={(event, selectedOptions, reason) => {
          if (reason === "selectOption" || reason === "removeOption") {
            if (selectedOptions.find((option) => option.value === "All999")) {
              const allSelected = props.options.length === (value || []).length;
              if (!allSelected) {
                setValue(props.options.map((m) => m.value));
                props.onChange &&
                  props.onChange(
                    props.options.map((m) => m.value),
                    event
                  );
              } else {
                setValue(
                  selectedOptions
                    .filter((f) => f.value != "All999")
                    .map((m) => m.value)
                );
                props.onChange &&
                  props.onChange(
                    selectedOptions
                      .filter((f) => f.value != "All999")
                      .map((m) => m.value),
                    event
                  );
              }
            } else {
              let arr = (selectedOptions || [])
                .filter((f) => f.value != "All999")
                .map((m) => m["value"]);
              setValue(arr || []);
              props.onChange &&
                props.onChange(
                  selectedOptions.filter((f) => f.value != "All999"),
                  event
                );
            }
          } else if (reason === "clear") {
            setValue([]);
            props.onChange && props.onChange([], event);
          }
        }}
        onBlur={(event) => {
          props.onBlur && props.onBlur(value, event);
        }}
        onKeyPress={(event) => {
          props.onKeyPress && props.onKeyPress(event);
        }}
        onKeyDown={(event) => {
          props.onKeyDown && props.onKeyDown(event);
        }}
        onKeyUp={(event) => {
          props.onKeyPress && props.onKeyPress(event);
        }}
      />
    );
  }
);

export const SelectMultipleAddOptionsNoFormItem = forwardRef(
  (props: SelectNoFromProps, ref) => {
    const { name, fullWidth = true, limitTags = 2 } = props;

    const [value, setValue] = useState<string[]>(props.value);
    const [t] = useTranslation();
    const fixedOptions = [];
    const getOpObjValue = (val) => {
      let res = [];
      if (val) {
        val.forEach((element) => {
          res.push(props.options.find((op) => op.value === element));
        });
      }
      return res;
    };

    const handleToggleSelectAll = (selectedOptions) => {
      const allSelected = props.options.length === (value || []).length;
      let chkFix = (value || []).filter((f) => f == fixedOptions[0]);
      let chkData = chkFix.length > 0 ? [] : fixedOptions[0] ?? [];
      if (!allSelected) {
        setValue([...chkData, ...props.options.map((m) => m.value)]);
        props.onChange && props.onChange(props.options, null);
      } else {
        setValue(fixedOptions);
        props.onChange && props.onChange([], null);
      }
    };

    const handleChange = (event, selectedOptions, reason) => {
      let chkFix = (value || []).filter((f) => f == fixedOptions[0]);
      let chkData = chkFix.length > 0 ? [] : fixedOptions[0] ?? [];
      if (reason === "selectOption" || reason === "removeOption") {
        if (selectedOptions.find((option) => option?.value === "ALL999")) {
          handleToggleSelectAll(selectedOptions);
        } else if (
          selectedOptions.find((option) => option.value === "ADD9999")
        ) {
          props.options.push({
            value: selectedOptions.find(
              (option) => option?.value === "ADD9999"
            )["newVaue"],
            label: selectedOptions.find(
              (option) => option?.value === "ADD9999"
            )["newVaue"],
            Isnew: true,
          });
          let v = selectedOptions
            .filter((f) => f?.value != "ADD9999")
            .map((m) => m["value"]);
          let o = selectedOptions
            .filter((f) => f?.value == "ADD9999")
            .map((m) => m["newVaue"]);
          setValue([...chkData, ...v, ...o]);
          props.onChange && props.onChange(selectedOptions, event);
        } else {
          setValue([...chkData, ...selectedOptions.map((m) => m.value)]);
          props.onChange && props.onChange(selectedOptions, event);
        }
      } else if (reason === "clear") {
        setValue(fixedOptions);
        props.onChange && props.onChange([], event);
      }
    };

    return (
      <>
        <Autocomplete
          multiple
          disableCloseOnSelect
          ref={ref}
          id={name}
          disabled={props.disabled || null}
          fullWidth={fullWidth}
          size={"small"}
          options={props.options || []}
          value={getOpObjValue(value)}
          noOptionsText={props.notOptionsText || t("datanotfound")}
          disableClearable={props.isClearable || null}
          limitTags={limitTags}
          renderOption={(props_, option, { selected }) => {
            return (
              <li {...props_} key={option.value}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    ((value || []).length > 0 &&
                      option.value === "ALL999" &&
                      props.options.length === (value || []).length) ||
                    selected
                      ? true
                      : selected
                  }
                />
                {option.label}
              </li>
            );
          }}
          getOptionLabel={(itemOption: any) => {
            return `${itemOption?.label}`;
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== "") {
              if (
                props.options.filter((f) => f.value == params.inputValue)
                  .length == 0
              ) {
                filtered.push({
                  value: "ADD9999",
                  label: `Add "${params.inputValue}"`,
                  newVaue: params.inputValue,
                });
              }
            }
            return [{ label: "Select All", value: "ALL999" }, ...filtered];
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              disabled={props.disabled}
              label={props.label}
              size={"small"}
              fullWidth={fullWidth}
              sx={{
                "label.MuiInputLabel-shrink": {
                  backgroundColor: "rgb(255 255 255 / 18%)",
                  top: "0px",
                },
                ".MuiInputLabel-outlined": {
                  top: "0px",
                },
                ".MuiInputLabel-asterisk": {
                  color: "red",
                },
              }}
            />
          )}
          onChange={handleChange}
          onBlur={(event) => {
            props.onBlur && props.onBlur(value, event);
          }}
          onKeyPress={(event) => {
            props.onKeyPress && props.onKeyPress(event);
          }}
          onKeyDown={(event) => {
            props.onKeyDown && props.onKeyDown(event);
          }}
          onKeyUp={(event) => {
            props.onKeyPress && props.onKeyPress(event);
          }}
        />
      </>
    );
  }
);
