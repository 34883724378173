//enumSystem2.tsx ใช้กับระบบ ปัจจุบัน ถ้าเป็น enumSystem.tsx คือ softthai module
export const enum Test{
    PTT = "PTT",
}

//sCMSCode of TM_CMSDBDetail
export const enum enumCMSCode{
    CookiePolicy = "CookieP1",
    DirectorPrivacyPolicy = "PrivateP1", //
    HumanResourcesPrivacyPolicy = "PrivateP2",
    ExternalPartyPrivacy = "PrivateP3",
    ContactUs1 = "ContactUs1",
    PrivacyNotice="PrivacyNotice",
    AboutUsVision="AboutUsVision",
    AboutUsMission="AboutUsMission",
    AboutUsOverView="AboutUsOverView",
    AboutUsOrganization="AboutUsOrganization",
    AboutUsDirector= "AboutUsDirector",
}


export const enum enumMenu{
    UnderConstruction = "UnderConstruction", //
}

