import React from "react";
import { Box, Button, Fab, Theme, Tooltip } from "@mui/material";
import {
  Search,
  ArrowBackIos,
  DeleteForever,
  Add,
  Info,
  Cancel,
  Summarize,
  CloseRounded,
  Home,
  SaveAlt,
  RefreshRounded,
  RemoveRedEye,
  Image,
  Edit,
  Link,
  Delete,
  Print,
  Clear,
  Visibility,
  Undo,
  CheckCircle,
  UploadFile,
  Logout,
  AddCircle,
  LockReset,
  HighlightOff,
} from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { styled } from "@mui/material/styles";
import { BtnProp, ButtonCustomProp } from "./Button";
import { MdRecycling } from "react-icons/md";
import { HiOutlineSave } from "react-icons/hi";
import { BiEdit } from "react-icons/bi";
import { BsFileEarmarkExcel } from "react-icons/bs";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { FiSend } from "react-icons/fi";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { TbCalendarTime } from "react-icons/tb";
import DescriptionIcon from "@mui/icons-material/Description";
import { blue } from "@mui/material/colors";
import { SiMicrosoftword } from "react-icons/si";
import MenuIcon from "@mui/icons-material/Menu";
import { ParseHtml } from "STM/utilities/ST_Function";
import { useTranslation } from "react-i18next";
import { TiPin } from "react-icons/ti";
import VisibilityIcon from '@mui/icons-material/Visibility';

//import { LoadingButton } from "@mui/x-data-grid";

const sizeDefault = {
  width: 40,
  height: 40,
  heightText: "40px",
  widthText: "40px",
};
const styleX = {
  btnCircle: {
    padding: "8px",
    // my: 2,
    // border: "1px solid #f2f2f2",
    // borderRadius: "50%",
    // height: sizeDefault.heightText,
    // ".MuiFilledInput-input": { paddingTop: "10px" },
  },
  btnRadius: {
    my: 2,
    border: "1px solid #f2f2f2",
    borderRadius: "20px",
    height: sizeDefault.heightText,
    ".MuiFilledInput-input": { paddingTop: "10px" },
  },
  btnRadiusProfile: {
    my: 2,
    // border: "1px solid #f2f2f2",
    borderRadius: "20px",
    height: sizeDefault.heightText,
    width: "50%",
    ".MuiFilledInput-input": { paddingTop: "10px" },
  },
  btnPrimaryColor: {
    color: "white",
    // padding: "0.5em 2em",
    borderRadius: "2em",
    backgroundColor: "#31409c",
    ":hover": {
      backgroundColor: "#5a66af",
    },
    width: "max-content",
  },
  btnSubmit: {
    color: "white",
    backgroundColor: "#31409c",
    ":hover": {
      backgroundColor: "#5a66af",
    },
    borderRadius: "20px",
  },
  btnCancel: {
    color: "white",
    backgroundColor: "#ed3847",
    ":hover": {
      backgroundColor: "rgb(220, 53, 69)",
    },
    borderRadius: "20px",
  },
  btnBack: {
    backgroundColor: "#a7a7a7",
    ":hover": {
      backgroundColor: "#878787",
    },
    borderRadius: "20px",
  },
  btnPreview: {
    backgroundColor: "#e39a2d",
    ":hover": {
      backgroundColor: "#b9812d",
    },
  },
  btnBooking: {
    backgroundColor: "#3aba18", //#f5945c
    ":hover": {
      backgroundColor: "#39a21d",
    },
    borderRadius: "20px",
  },
  btnCreateBook: {
    backgroundColor: "#f1a43f", //#f5945c
    ":hover": {
      backgroundColor: "#e0932d",
    },
    borderRadius: "20px",
  },
  btnDelete: {
    backgroundColor: "#d00000",
    ":hover": {
      backgroundColor: "#9d0208",
    },
    borderRadius: "20px",
  },
  btnAdd: {
    backgroundColor: "#0096c7",
    ":hover": {
      backgroundColor: "#0077b6",
    },
    borderRadius: "20px",
  },
  btnSave: {
    backgroundColor: "#70e000",
    ":hover": {
      backgroundColor: "#38b000",
    },
    borderRadius: "20px",
  },
  btnNote: {
    backgroundColor: "#0096c7",
    ":hover": {
      backgroundColor: "#0077b6",
    },
    borderRadius: "20px",
  },
  btnReject: {
    backgroundColor: "#d00000",
    ":hover": {
      backgroundColor: "#9d0208",
    },
    borderRadius: "20px",
  },
};

const BaseButton = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  // fontSize: 16,
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#3dcc00",
  borderColor: "#3dcc00",
  [theme.breakpoints.down("sm")]: {
    width: "40px",
    minWidth: "40px",
    height: "40px",
    fontSize: "0px",
    lineHeight: "0px",
    "& .MuiButton-startIcon": { margin: 0 },
  },
}));

const adjustedButtonProps = (disabled, onClick) => {
  return {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onClick,
  };
};

export const BtnConfirmSearch = ({
  IsDisabled = false,
  IsHidden = false,
  onClick = () => {},
  txt = "Search",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return IsHidden ? null : (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          startIcon={<Search />}
          disabled={IsDisabled}
          onClick={onClick}
          sx={styleX.btnPrimaryColor}
          {...adjustedButtonProps(IsDisabled, onClick)}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnPrimaryColor, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="search"
        >
          <Search />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnSearchCustom = ({
  isDisabled = false,
  isHidden = false,
  onClick = () => { },
  txt = "Search",
  isCircleWithOutText = false,
  Color = "#169bd5"
}) => {
  return (
      <Tooltip title={txt}>
          <Box className="btn-custom-filter" onClick={onClick}>
              <Search style={{ color: "#ffffff" }} />
          </Box>
      </Tooltip>
  );
};

export const BtnClearCustom = ({
  isDisabled = false,
  isHidden = false,
  onClick = () => { },
  txt = "Clear filter",
  isCircleWithOutText = false,
  Color = "#169bd5"
}) => {
  return (
      <Tooltip title={txt}>
          <Box className="btn-clear-filter" onClick={onClick}>
              <HighlightOff style={{ color: "rgb(33, 82, 255)" }} />
          </Box>
      </Tooltip>
  );
};

export const BtnClearFilter = ({
  IsDisabled = false,
  IsHidden = false,
  onClick = () => {},
  txt = "Clear",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return IsHidden ? null : (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          startIcon={<RefreshRounded />}
          disabled={IsDisabled}
          onClick={onClick}
          sx={styleX.btnBack}
          {...adjustedButtonProps(IsDisabled, onClick)}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          onClick={onClick}
          sx={{ width: 40, height: 40, color: "#a6a6a6" }}
          aria-label="back"
        >
          <RefreshRounded />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnBooking = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => {},
  txt = "กลับ",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
  ICon = false,
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          startIcon={ICon ? <CalendarTodayIcon /> : ""}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnBooking}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          onClick={onClick}
          sx={{
            width: 40,
            height: 40,
            color: "#fffffffd",
            backgroundColor: "#888888",
            ":hover": { backgroundColor: "#6e6d6d" },
          }}
          aria-label="back"
        >
          <ArrowBackIos style={{ marginLeft: "18%" }} />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnUnavailable = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => {},
  txt = "กลับ",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
  ICon = false,
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          startIcon={ICon ? <EventBusyIcon /> : ""}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnCancel}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          onClick={onClick}
          sx={{
            width: 40,
            height: 40,
            color: "#fffffffd",
            backgroundColor: "#888888",
            ":hover": { backgroundColor: "#6e6d6d" },
          }}
          aria-label="back"
        >
          <ArrowBackIos style={{ marginLeft: "18%" }} />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnCreateBook = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => {},
  txt = "กลับ",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          // startIcon={<ArrowBackIos />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnCreateBook}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          onClick={onClick}
          sx={{
            width: 40,
            height: 40,
            color: "#fffffffd",
            backgroundColor: "#888888",
            ":hover": { backgroundColor: "#6e6d6d" },
          }}
          aria-label="back"
        >
          <ArrowBackIos style={{ marginLeft: "18%" }} />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnRemove = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => {},
  txt = "Remove",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="medium"
          startIcon={<DeleteForever />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnCancel}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnCancel, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="cancel"
          disabled={IsDisabled === true ? true : false}
        >
          <DeleteForever />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnCustomIcon = ({
  IsDisabled = false,
  IsHidden = false,
  onClick = (e?) => {},
  txt = "",
  isCircleWithOutText = false,
  sIconComponent = null,
  eIconComponent = null,
  sx = {},
  tooltipPlacement = "bottom",
}) => {
  return IsHidden ? null : (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={sIconComponent ? sIconComponent : null}
          endIcon={eIconComponent ? eIconComponent : null}
          // disabled={IsDisabled}
          // onClick={onClick}
          sx={[styleX.btnBack, { ...sx }]}
          {...adjustedButtonProps(IsDisabled, onClick)}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnBack, { ...sizeDefault, ...sx }]}
          onClick={onClick}
          disabled={IsDisabled}
        >
          {sIconComponent ? sIconComponent : null}
        </Fab>
      )}
    </Tooltip>
  );
};

// ********************************************************************************************อันใหม่ อันบนอันเก่าจะลบ

export const BtnAddWithIcon = ({
  isDisabled = false,
  isHidden = false,
  onClick = () => {},
  txt = "Create",
  sx = {},
}) => {
  return (
    <Button
      sx={{
        position: "relative",
        boxSizing: "border-box",
        outline: 0,
        border: 0,
        m: 0,
        cursor: "pointer",
        verticalAlign: "middle",
        appearance: "none",
        textDecoration: "none",
        letterSpacing: "0.02857em",
        minWidth: "64px",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.75rem",
        fontWeight: "700",
        borderRadius: "0.5rem",
        lineHeight: 1.4,
        textAlign: "center",
        textTransform: "uppercase",
        userSelect: "none",
        transition: "all 150ms ease-in 0s",
        minHeight: "2rem",
        boxShadow:
          "rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
        padding: "0.5rem 1.2rem",
        backgroundImage:
          "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
        color: "#fff",
        backgroundSize: "150%",
        backgroundPositionX: "25%",
      }}
      onClick={onClick}
      disabled={isDisabled}
      hidden={isHidden}
      startIcon={<AddCircle />}
    >
      {txt}
    </Button>
  );
};
export const BtnBackForm = ({
  isDisabled = false,
  isHidden = false,
  onClick = () => { },
  txt = "back",
  sx = {},
  icon = <ArrowBackIos />
}) => {
  const { t } = useTranslation();
  return (
      <Button
      sx={{
          position: "relative",
          boxSizing: "border-box",
          outline: 0,
          border: 0,
          m: 0,
          cursor: "pointer",
          verticalAlign: "middle",
          appearance: "none",
          textDecoration: "none",
          letterSpacing: "0.02857em",
          minWidth: "64px",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "0.75rem",
          fontWeight: "700",
          borderRadius: "0.5rem",
          lineHeight: 1.4,
          textAlign: "center",
          textTransform: "uppercase",
          userSelect: "none",
          transition: "all 150ms ease-in 0s",
          minHeight: "2.5rem",
          boxShadow:
            "rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
          padding: "0.75rem 1.5rem",
          backgroundColor: "rgb(233, 236, 239)",
          color: "rgb(58, 65, 111)",
          backgroundSize: "150%",
          backgroundPositionX: "25%",
        }}
                  onClick={onClick}
                  disabled={isDisabled}
                  hidden={isHidden}
                  startIcon={icon}
                >
                  {t(txt)}
                </Button>
  );
};

export const BtnCreateForm = ({
  isDisabled = false,
  isHidden = false,
  onClick = () => {},
  txt = "Create User",
  sx = {},
  icon = <LockReset />,
}) => {
  return (
    <Button
      sx={{
        position: "relative",
        boxSizing: "border-box",
        outline: 0,
        border: 0,
        m: 0,
        cursor: "pointer",
        verticalAlign: "middle",
        appearance: "none",
        textDecoration: "none",
        letterSpacing: "0.02857em",
        minWidth: "64px",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.75rem",
        fontWeight: "700",
        borderRadius: "0.5rem",
        lineHeight: 1.4,
        textAlign: "center",
        textTransform: "uppercase",
        userSelect: "none",
        transition: "all 150ms ease-in 0s",
        minHeight: "2.5rem",
        boxShadow:
          "rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
        padding: "0.75rem 1.5rem",
        backgroundImage:
          "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
        color: "#fff",
        backgroundSize: "150%",
        backgroundPositionX: "25%",
      }}
      onClick={onClick}
      disabled={isDisabled}
      hidden={isHidden}
      startIcon={icon}
    >
      {txt}
    </Button>
  );
};
export const BtnBaseButton = (props: ButtonCustomProp) => {
  const {
    id = "",
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    startIcon = <Info />,
    txt = "ต้นแบบ",
    isCircleWithOutText = false,
    tooltipPlacement = "bottom",
    size = "medium",
    fontColor = "#fff",
    bgcolor = "#5a915a",
    bgcolorHover = "#ffc107",
    isRadius = true,
    sx,
  } = props;

  return (
    <Tooltip placement={tooltipPlacement} title={ParseHtml(txt)}>
      {!isCircleWithOutText ? (
        <BaseButton
          variant="contained"
          size={size}
          id={id}
          startIcon={startIcon}
          disabled={isDisabled}
          hidden={isHidden}
          onClick={onClick}
          sx={(theme: Theme) => ({
            ...sx,
            color: fontColor,
            backgroundColor: bgcolor,
            backgroundImage: bgcolor,
            borderColor: bgcolor,
            borderRadius: isRadius ? "20px" : "8px",
            ":hover": {
              bgcolor: bgcolorHover,
              borderColor: bgcolorHover,
            },
            ".Mui-disabled": {
              color: "rgba(0, 0, 0, 0.26)",
              boxShadow: "none",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
          })}
        >
          {txt}
        </BaseButton>
      ) : (
        <Fab
          onClick={onClick}
          id={id}
          sx={{
            ...sx,
            width: 40,
            height: 40,
            color: fontColor,
            backgroundColor: bgcolor,
            borderColor: bgcolor,
            ":hover": {
              bgcolor: bgcolorHover,
              borderColor: bgcolorHover,
            },
            ".MuiSvgIcon-root": {
              color: "#ffffff !important",
            },
          }}
        >
          {startIcon}
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnSave = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Save",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<SaveIcon />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#5cb85c"
      bgcolorHover="#398439 "
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnConfirm = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Confirm",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<TaskAltOutlinedIcon />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#1976d2"
      bgcolorHover="#1976d2"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnDelete = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Delete",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<DeleteForever />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#ed3847"
      bgcolorHover="#ed3847"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnCancelForm = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Cancel",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<CloseRounded />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#df4f2c"
      bgcolorHover="#bc4326"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnPreview = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Preview",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<VisibilityIcon />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#e39a2d"
      bgcolorHover="#e39a2d"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnClose = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Close",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<Cancel />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#8c98ac"
      bgcolorHover="#8c98ac"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnCancel = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Cancel",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<CloseRounded />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="rgb(228 67 67)"
      bgcolorHover="rgb(228 67 67)"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnBack = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Back",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<ArrowBackIos />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="rgb(233, 236, 239)"
      bgcolorHover="rgb(233, 236, 239)"
      fontColor="rgb(58, 65, 111)"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnAdd = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Add",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<Add />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#05a6e8"
      bgcolorHover="#05a6e8"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnSearch = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Search",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<Search />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#6794dc"
      bgcolorHover="#6794dc"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnClear = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Clear",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<RefreshRounded />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#dcdbdb"
      bgcolorHover="#b7b7b7"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnHome = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Home",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<Home />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#dcdcdc"
      bgcolorHover="#dcdcdc"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnEdit = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Edit",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<BiEdit />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#b8b8b8"
      bgcolorHover="#b8b8b8"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnExportExcel = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Excel",
    isCircleWithOutText = false,
    isRadius = true,
    icon = <BsFileEarmarkExcel />,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={icon}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#66cc66"
      bgcolorHover="#5a915a"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnDownload = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Download",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<SaveAlt />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#1000f3"
      bgcolorHover="#1000f3"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnDraft = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Draft",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<HiOutlineSave />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#3cc1ac"
      bgcolorHover="#239b88"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnRecycle = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Recycle",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<MdRecycling />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#f59a23"
      bgcolorHover="#e6880c"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnNote = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Note",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<Summarize />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#f59a23"
      bgcolorHover="#e6880c"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

//#region OnTable
export const BtnAddOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Add",
  onClick = () => {},
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Add />
      </Tooltip>
    }
    label={txt}
    sx={{
      bgcolor: "#1976d2",
      color: "white",
      ":hover": {
        bgcolor: "#0946a2",
      },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnApproveOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Approve",
  onClick = () => {},
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <CheckCircle />
      </Tooltip>
    }
    label={txt}
    sx={{
      bgcolor: "#33a64c",
      color: "white",
      ":hover": {
        bgcolor: "#33a64c",
      },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnPreviewOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Preview",
  onClick = () => {},
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <RemoveRedEye />
      </Tooltip>
    }
    label={txt}
    sx={(theme: Theme) => ({
      color: "#fff",
      backgroundColor: theme.palette.info.main,
      borderColor: theme.palette.info.light,
      ":hover": { bgcolor: theme.palette.info.dark },
    })}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnLinkOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Link",
  onClick = () => {},
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Link />
      </Tooltip>
    }
    label={txt}
    sx={(theme: Theme) => ({
      color: "#fff",
      backgroundColor: "#9e3eda",
      borderColor: "#9e3eda",
      ":hover": { bgcolor: "#671d96" },
    })}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnViewImageOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "View Image",
  onClick = () => {},
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Image />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "tranparent",
      color: "#4f5bef",
      ":hover": { bgcolor: "#d7d7d7" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnPinOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "pin", //"Edit",
  onClick = () => { },
  IsPin = false
}) =>{
  const { t } = useTranslation();
  return (
      <Tooltip title={IsPin ? t('clickfor') + " " + t('unpin') : t('clickfor') + " " + t('pin')}>
          <GridActionsCellItem
              disabled={IsDisabled}
              hidden={IsHisabled}
              icon={<TiPin />}
              label={IsPin ? t('pin') : t('unpin')}
              sx={
                  IsHisabled !== true ? {
                      boxShadow: "0px 3px 3px -2px rgb(175 191 255 / 20%), 0px 3px 4px 0px rgb(175 191 255 / 14%), 0px 1px 8px 0px rgb(175 191 255 / 12%)",
                      backgroundImage: IsPin ? "linear-gradient(310deg, #2765b6, #14a0d3)" : "linear-gradient(310deg, #b3afaf, #d7d7d7)",
                      color: "white",
                      ":hover": { bgcolor: "rgb(20, 23, 39)" },
                      margin: "1%",
                  }
                      :
                      {
                          display: "none",
                          backgroundColor: "#ffc107",
                          color: "white",
                          ":hover": { bgcolor: "#cc9900" },
                          margin: "1%",
                      }
              }
              onClick={onClick}
              aria-label={IsPin ? t('pin') : t('unpin')}
          />
      </Tooltip>
  )
}

export const BtnEditOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Edit",
  onClick = () => {},
}) => (
  <Tooltip title={txt}>
    <GridActionsCellItem
      disabled={IsDisabled}
      hidden={IsHisabled}
      icon={<Edit />}
      label={txt}
      sx={
        IsHisabled !== true
          ? {
              backgroundColor: "#ffc107",
              color: "white",
              ":hover": { bgcolor: "#cc9900" },
              margin: "1%",
            }
          : {
              display: "none",
              backgroundColor: "#ffc107",
              color: "white",
              ":hover": { bgcolor: "#cc9900" },
              margin: "1%",
            }
      }
      onClick={onClick}
      aria-label={txt}
    />
  </Tooltip>
);

export const BtnViewOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "View",
  onClick = () => {},
}) => (
  <Tooltip title={txt}>
    <GridActionsCellItem
      disabled={IsDisabled}
      hidden={IsHisabled}
      icon={<Visibility />}
      label={txt}
      sx={{
        backgroundColor: "#6FDBFF",
        color: "white",
        ":hover": { bgcolor: "#5DB6D4" },
        margin: "1%",
      }}
      onClick={onClick}
      aria-label={txt}
    />
  </Tooltip>
);

export const BtnDeleteOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Delete",
  onClick = () => {},
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Delete />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#f30800",
      color: "white", //black
      ":hover": { bgcolor: "#d8352f" },
      margin: "1%",
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnSearchOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Search",
  onClick = () => {},
}) => (
  <Tooltip title={txt}>
    <GridActionsCellItem
      disabled={IsDisabled}
      hidden={IsHisabled}
      icon={<Search />}
      label={txt}
      sx={{
        backgroundColor: "#096ddd",
        // backgroundColor: "#1000f3",
        color: "white", //black
        ":hover": { bgcolor: "#004290" },
        // ":hover": { bgcolor: "#2d22b9" },
      }}
      onClick={onClick}
      aria-label={txt}
    />
  </Tooltip>
);

export const BtnPrintOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Print",
  onClick = () => {},
}) => (
  <Tooltip title={txt}>
    <Print
      //disabled={IsDisabled}
      //hidden={IsHisabled}
      sx={{
        color: "orange",
        ":hover": { cursor: "pointer" },
      }}
      onClick={onClick}
    />
  </Tooltip>
);

export const BtnReserveOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Reserve",
  onClick = () => {},
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Edit />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#1000f3",
      color: "white", //black
      ":hover": { bgcolor: "#2d22b9" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnDownloadOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Download",
  onClick = () => {},
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <SaveAlt />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#4db9cf",
      color: "white", //black
      ":hover": { bgcolor: "#4db9cf" },
      margin: "1%",
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnUploadOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Upload",
  onClick = () => {},
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <UploadFile />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#4db9cf",
      color: "white", //black
      ":hover": { bgcolor: "#4db9cf" },
      margin: "1%",
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnClearOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Clear",
  onClick = () => {},
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Clear />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#f32400",
      color: "white",
      ":hover": { bgcolor: "#f32400" },
      margin: "1%",
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BottonBooking = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Save",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<TbCalendarTime style={{ fontSize: "1.5rem" }} />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#0474ae"
      bgcolorHover="#3daae3"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BottonCreateBook = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Save",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#f1a43f"
      bgcolorHover="#e0932d"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BottonUnavailable = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Save",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      isRadius={isRadius}
      startIcon={<EventBusyIcon />}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#ed3847"
      bgcolorHover="rgb(220, 53, 69)"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnReject = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Reject",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<Undo />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#ab0a0a"
      bgcolorHover="#820707"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnDes = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Description",
    isCircleWithOutText = false,
    isRadius = true,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={<DescriptionIcon />}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#5197ff"
      bgcolorHover="#0f80d7"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnExportExcelTemplate = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Excel",
    isCircleWithOutText = false,
    isRadius = true,
    icon = <DescriptionIcon />,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={icon}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor={blue[500]}
      bgcolorHover={blue[700]}
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnLogout = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => {},
  txt = "Logout",
  isCircleWithOutText = false,
  sx = {},
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Logout />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={{ ...styleX.btnCancel, ...sx }}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{
            width: 40,
            height: 40,
            bgcolor: "#ed3847",
            ...styleX.btnCancel,
            ...sx,
          }}
          onClick={onClick}
          aria-label="logout"
        >
          <Logout />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnExportWord = (props: BtnProp) => {
  const {
    isDisabled = false,
    isHidden = false,
    onClick = () => {},
    txt = "Excel",
    isCircleWithOutText = false,
    isRadius = true,
    icon = <SiMicrosoftword />,
  } = props;

  return (
    <BtnBaseButton
      id={props.id}
      txt={txt}
      size={"medium"}
      startIcon={icon}
      isRadius={isRadius}
      isDisabled={isDisabled}
      isHidden={isHidden}
      onClick={onClick}
      isCircleWithOutText={isCircleWithOutText}
      bgcolor="#1000f3"
      bgcolorHover="#1000f3"
      fontColor="#ffffff"
      tooltipPlacement="bottom"
    />
  );
};

export const BtnPreviewTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "รายละเอียด",
  onClick = () => {},
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <MenuIcon />
      </Tooltip>
    }
    label={txt}
    sx={(theme: Theme) => ({
      color: "#fff",
      backgroundColor: theme.palette.info.main,
      borderColor: theme.palette.info.light,
      ":hover": { bgcolor: theme.palette.info.dark },
    })}
    onClick={onClick}
    aria-label={txt}
  />
);

//#endregion
