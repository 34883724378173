import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { BtnAddWithIcon, BtnDelete } from "STM/components/Button";
import { TextBoxForm } from "STM/components/Input";
import { useFormContext } from "react-hook-form";
import { SwAlert } from "STM/components/Alert";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import yupFormSchemas from "../FormItem/yupFormSchemas";

const Reference = ({ arrTopicRef, setArrTopicRef, IsReference, setYupRef }) => {
  const form = useFormContext();
  const [lstOrder, setLstOrder] = useState([1]);
  const { t } = useTranslation();

  const yupRef = {
    
  };

  useEffect(() => {
    let arrOrder = [];
    console.log("arrTopicRef",arrTopicRef)
    if(arrTopicRef != null){
      arrTopicRef.forEach((item, i) => {
        arrOrder.push(i + 1);
        yupRef[`sTopicRef_${i + 1}`] = yupFormSchemas.string(t('topicreference'), {
          required: IsReference,
          translation: t
        });
        yupRef[`sLinkRef_${i + 1}`] = yupFormSchemas.string(t('urlref'), {
          required: IsReference,
          translation: t
        }).url();
        form.setValue(`sTopicRef_${i + 1}`, item.sText);
        form.setValue(`sLinkRef_${i + 1}`, item.sUrl);
        setYupRef(yupRef)
      });
      if (arrOrder.length > 1) {
        setLstOrder(arrOrder);
      }
    }
  }, [arrTopicRef,IsReference]);


  const setOrder = (oldOrder, newOrder) => {
    if (newOrder - 1 >= arrTopicRef.length) {
      let k = newOrder - 1 - arrTopicRef.length + 1;
      while (k--) {
        arrTopicRef.push(undefined);
      }
    }
    arrTopicRef.splice(newOrder - 1, 0, arrTopicRef.splice(oldOrder - 1, 1)[0]);
    setArrTopicRef([...arrTopicRef]);
  };

  const onAddMore = () => {
    let arrOption = arrTopicRef;
    let nMaxData =
      arrOption.length > 0 ? Math.max(...arrOption.map((o) => o.nID)) + 1 : 1;

    let moreData = {
      nID: nMaxData,
      sText: "",
      sUrl: "",
      nRefID: null,
      sRefMode: "N",
    };
    setArrTopicRef([...arrOption, moreData]);
  };

  const onDelete = (nID) => {
    SwAlert.Confirm(t("confirmation"), t("deletemsg"), () => {
      form.unregister(`sTopicRef_${lstOrder.length}`);
      form.unregister(`sLinkRef_${lstOrder.length}`);
      arrTopicRef.splice(nID - 1, 1);
      lstOrder.pop();
      setLstOrder([...lstOrder]);
      setArrTopicRef([...arrTopicRef]);
    });
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        border: "1px solid #f4f4f4",
        margin: "0.5rem",
        pr: 1.5,
        pb: 1.5,
      }}
    >
      {(arrTopicRef || [])
        .filter((f) => f.sRefMode !== "D")
        .map((m, i) => (
          <>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={1}>
                <Select
                  fullWidth
                  size="small"
                  value={i + 1}
                  key={m.nID}
                  onChange={(e) => {
                    setOrder(i + 1, e.target.value);
                  }}
                >
                  {lstOrder.map((item, index) => (
                    <MenuItem key={`item_${index + 1}`} value={index + 1}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    color: "rgb(52, 71, 103)",
                  }}
                  className=".Box-content-form"
                >
                  <TextBoxForm
                    id={`sTopicRef_${i + 1}`}
                    name={`sTopicRef_${i + 1}`}
                    label={""}
                    isshowTextCont={false}
                    disabled={false}
                    maxLength={100}
                    onChange={(e) => {
                      arrTopicRef[i].sText = e;
                      setArrTopicRef([...arrTopicRef]);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    color: "rgb(52, 71, 103)",
                  }}
                  className=".Box-content-form"
                >
                  <TextBoxForm
                    id={`sLinkRef_${i + 1}`}
                    name={`sLinkRef_${i + 1}`}
                    label={"http://www"}
                    isshowTextCont={false}
                    disabled={false}
                    maxLength={100}
                    onChange={(e) => {
                      arrTopicRef[i].sUrl = e;
                      setArrTopicRef([...arrTopicRef]);
                    }}
                  />
                </Box>
              </Grid>
              {i !== 0 && (
                <Grid item display={"flex"} alignItems={"center"} sx={{paddingTop:"10px !important",height:"55px",width:"45px"}}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <BtnDelete
                      isCircleWithOutText={true}
                      onClick={() => onDelete(i + 1)}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </>
        ))}
      <Grid item container xs={12} spacing={2} justifyContent={"start"}>
        <Grid item>
          <Box>
            <BtnAddWithIcon
              txt={t("addreference")}
              onClick={() => onAddMore()}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Reference;
