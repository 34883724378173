import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./NewsActivity.css";
import { motion } from "framer-motion";
import ContentManagementFrame from "STM/components/ContentManagement/ContentManagementFrame";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FilePopup from "STM/components/Input/UploadFile/components/PopUp/FilePopup";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import { TextBoxNoForm } from "STM/components/Input";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function NewsActivityDetail({ data, sID, IsPreview }) {
  const sliderRefNews = useRef(null);

  const settings = {
    className: "NewsSlideSwiper",
    useCSS: true,
    dots: false,
    speed: 1000,
    slidesToShow:
      data.lstAlbum != null
        ? data.lstAlbum.length < 5
          ? data.lstAlbum.length
          : 5
        : 0,
    slidesToScroll: 1,
    // autoplay: true,
    // pauseOnHover: true,
    arrows: true,
    initialSlide: 0,
    swipe: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1198,
        settings: {
          dots: false,
          speed: 1000,
          autoPlaySpeed: 1000,
          slidesToShow:
            data.lstAlbum != null
              ? data.lstAlbum.length < 3
                ? data.lstAlbum.length
                : 3
              : 0,
          slidesToScroll: 3,
          // autoplay: true,
          arrows: true,
          initialSlide: 0,
          swipe: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          speed: 1000,
          autoPlaySpeed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          // autoplay: true,
          arrows: true,
          initialSlide: 0,
          swipe: true,
        },
      },
    ],
  };

  const frontURL = process.env.REACT_APP_Front_URL;
  const [IsopenPopUp, setIsopenPopUp] = useState<any>(false);
  const [Isopen, setIsopen] = useState<any>(false);
  const [IsCopy, setIsCopy] = useState<any>(false);
  const [fileImage, setFileImage] = useState();
  const [indexActive, setIndexActive] = useState(0);
  const OpenPopUp = (item, index) => {
    setFileImage(item);
    setIndexActive(index);
    setIsopenPopUp(true);
  };
  const ClosePopUp = () => {
    setIsopenPopUp(false);
  };

  const handleClose = () => {
    setIsCopy(false);
    setIsopen(false);
  };

  const sPathWeb = process.env.REACT_APP_Front_URL;

  return (
    <Grid
      container
      className="news-activity-container"
      sx={{
        backgroundImage: `url(${frontURL + "images/newsbg.png"})`,
        backgroundSize: "cover",
        backgroundPosition: "50% 55%",
        position: "relative",
      }}
    >
      <Dialog onClose={handleClose} open={Isopen}>
        <DialogTitle id="alert-dialog-title">
          <Typography
            sx={{ color: "rgb(52, 71, 103)" }}
            className="Header-title-form news_link_text"
          >
            Link To '{data.sNewsName}' {IsCopy && <>copied</>}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: "300px" }}>
          <Grid container spacing={2} sx={{ flexWrap: "nowrap" }}>
            <Grid
              item
              xs={12}
              sx={{ maxWidth: "calc(100% - 80px) !important" }}
            >
              <TextBoxNoForm
                id="copylink"
                defaultValue={
                  sPathWeb + `NewsActivityDetail?sID=${encodeURI(sID)}`
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                variant="outlined"
                startIcon={<ContentCopyIcon />}
                sx={{
                  padding: "8.5px 15px",
                  borderRadius: "2rem",
                  backgroundColor: IsCopy ? "aliceblue" : "",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    sPathWeb + `NewsActivityDetail?sID=${encodeURI(sID)}`
                  );
                  setIsCopy(true);
                }}
              >
                Copy
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <FilePopup
        arrFile={
          data.lstAlbum != null && data.lstAlbum.length > 0 ? data.lstAlbum : []
        }
        file={fileImage}
        isGallery={true}
        IsopenPopUp={IsopenPopUp}
        ClosePopUp={ClosePopUp}
        indexActive={indexActive}
      />
      <Grid item xs={12} className="news-detail-container">
        {/* <Grid item xs={12}>
          <Box className="top-news-header">
            <motion.p
            initial={{ x:60, opacity: 1 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
              style={{
                margin: "0",
                fontSize: "0.875rem",
                lineHeight: 1.25,
                letterSpacing: "0.0333em",
                opacity: 1,
                textTransform: "none",
                verticalAlign: "unset",
                textDecoration: "none",
                color: "rgb(52, 71, 103)",
                fontWeight: 400,
              }}
            >
              Published in{" "}
            </motion.p>
            <motion.p
            initial={{ x:60, opacity: 1 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1.25,
              ease: [0, 0.71, 0.2, 1.01],
            }}
              style={{
                margin: "0 0.5rem",
                fontSize: "0.875rem",
                lineHeight: 1.25,
                letterSpacing: "0.0333em",
                opacity: 1,
                textTransform: "none",
                verticalAlign: "unset",
                textDecoration: "none",
                color: "rgb(52, 71, 103)",
                fontWeight: 700,
              }}
            >
              PTT Global LNG
            </motion.p>
          </Box>
        </Grid> */}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              startIcon={<ShareIcon />}
              onClick={() => setIsopen(true)}
              disabled={IsPreview}
            >
              Share
            </Button>
          </Grid>

        {/* News Name */}
        <Grid item xs={12}>
          <motion.p
            initial={{ y: 60, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              duration: 1.2,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            className="news-name-detail"
          >
            {data.sNewsName}
          </motion.p>
        </Grid>
        {/* News Description */}
        <Grid item xs={12}>
          <Typography
            sx={{
              mb: 1,
              fontSize: "1rem",
              lineHeight: 1.2,
              fontWeight: 700,
              color: "#67748e",
            }}
          >
            {data.sDescription}
          </Typography>
        </Grid>
        {/* Content Management */}
        <Grid item xs={12} sx={{ padding: "0 1rem" }}>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <ContentManagementFrame CMSData={data.lstCMSContent} />
          </motion.div>
        </Grid>
        {/* News Album */}
        {data.lstAlbum != null && data.lstAlbum.length > 0 && (
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "1rem",
                lineHeight: 1.25,
                letterSpacing: "0.0333em",
                opacity: 1,
                textTransform: "none",
                verticalAlign: "unset",
                textDecoration: "none",
                color: "rgb(52, 71, 103)",
                fontWeight: 700,
              }}
            >
              Album
            </Typography>
            <Box className="news_divider" sx={{ mb: 1 }}></Box>
            <Slider {...settings} ref={sliderRefNews}>
              {data.lstAlbum?.map((file, i) => (
                <Grid
                  container
                  sx={{ padding: "0.5rem" }}
                  className={`slide_${data.lstAlbum.length} slide_album`}
                  onClick={() => OpenPopUp(file, i)}
                >
                  <Grid
                    item
                    xs={12}
                    className="image-album-box"
                    // style={{ backgroundColor: "#ffffffcc" }}
                  >
                    <img
                      alt=""
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      src={`${file.sFileLink}`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ fontWeight: 400, lineHeight: 1.2, mt: 0.5 }}
                    >
                      {file.sFileDetail}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Slider>
          </Grid>
        )}
        {/* News Tags */}
        {data.lstTag != null && data.lstTag.length > 0 && (
          <Grid item xs={12} sx={{ mt: 1 }} display={"flex"} flexWrap={"wrap"}>
            <Typography
              sx={{
                fontSize: "1rem",
                lineHeight: 1.25,
                letterSpacing: "0.0333em",
                opacity: 1,
                textTransform: "none",
                verticalAlign: "unset",
                textDecoration: "none",
                color: "rgb(52, 71, 103)",
                fontWeight: 700,
                mr: 0.5,
                marginTop: "1rem",
              }}
            >
              Tags :
            </Typography>
            <Box className="news_divider"></Box>
            {data.lstTag.map((tag, i) => (
              <span
                key={tag}
                className="badge-news"
                style={{
                  color: "rgb(52, 71, 103)",
                  background: "#b0bcd4",
                  marginTop: "1rem",
                  borderTopRightRadius: "1rem",
                  borderBottomRightRadius: "1rem",
                  marginLeft: i === 0 ? "2.5rem" : "0",
                }}
              >
                {tag}
              </span>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
