import React from "react";
import './ItemMobile.css'

function ItemMobile({ icon, name, active }) {
  return (
    <div
      className={`mobile-menu-item ${active ? "mobile-menu-item-active" : ""}`}
    >
      <div style={{ display: "flex" }}>
        <div className={`icon-mobie ${active ? "icon-mobie-active" : ""}`}>
          {icon}
        </div>

        <div
          style={{
            paddingLeft: "0.875rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span
            className="mobile-menu-name"
            style={{ fontWeight: active ? 700 : 400 }}
          >
            {name}
          </span>
        </div>
      </div>
      {active && <div className="circle-active"></div>}
    </div>
  );
}

export default ItemMobile;
