import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import * as yup from "yup";
import yupFormSchemas from "STM/components/FormItem/yupFormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosPost,AxiosGet } from "STM/utilities/ST_Axios";
import { FnBlock_UI } from "STM/components/BlockUI";
import { HeaderInput } from "STM/components/Input";
import {
  TextBoxForm,
  SelectFormItem,
  InputNumberForm,
} from "STM/components/Input";
import { BtnCreateForm } from "STM/components/Button";
import { SwAlert } from "STM/components/Alert";

export default function ConfigSetting() {
  const [DropDown, setDropDown] = useState([]);
  const [lstConfig, setLstConfig] = useState([]);
  const objSchema = yup.object().shape({});

  const form = useForm({
    resolver: yupResolver(objSchema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const onGetDropDown = () => {
    AxiosGet("DropDown/GetConfigType", {}, (res) => {
      setDropDown(res);
    });
  };

  const onGetConfig = (type) => {
    if (type !== null) {
      AxiosPost(
        "ConfigSetting/GetConfig",
        { sConfigType: type.value },
        (res) => {
          setLstConfig(res.lstConfig);
        }
      );
    }
  };

  useEffect(() => {
    onGetDropDown();
  }, []);

  useEffect(() => {
  }, [lstConfig]);

  return (
    <FormProvider {...form}>
      <Grid
        container
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexFlow: "row wrap",
          marginTop: "24px",
          width: "calc(100% - 24px)",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          <Grid item xs={12} className="Card-form">
            <Grid
              item
              xs={12}
              sx={{
                color: "rgb(52, 71, 103)",
              }}
              className="Header-grid-form"
            >
              {/* <Typography
                sx={{ color: "rgb(52, 71, 103)" }}
                className="Header-title-form"
              >
                Config Setting
              </Typography> */}
              <Grid container spacing={2} sx={{ mt: 1.2 }}>
                <Grid item xs={5}>
                  <Box
                    sx={{
                      color: "rgb(52, 71, 103)",
                    }}
                    className=".Box-content-form"
                  >
                    <HeaderInput text={"Config Type"} />
                    <SelectFormItem
                      id={"sConfigType"}
                      name={"sConfigType"}
                      label={"Config Type"}
                      required={false}
                      disabled={false}
                      options={DropDown}
                      onChange={(e) => onGetConfig(e)}
                    />
                  </Box>
                </Grid>
              </Grid>

              {lstConfig.length > 0 && (
                <>
                  {lstConfig.map((config) => {
                    return (
                      <Box sx={{ mt: 1.2 }} key={config.sConfigName}>
                        <Typography
                          sx={{ color: "rgb(52, 71, 103)" }}
                          className="Header-title-form"
                        >
                          {config.sConfigName}
                        </Typography>

                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <HeaderInput text={"sValue"} />
                              <TextBoxForm
                                name={config.sConfigName + "_sValue"}
                                isshowTextCont={false}
                                disabled={false}
                                defaultValue={config.sValue}
                              />
                            </Grid>
                            <Grid item xs={2}>
                            <HeaderInput text={"nValue"} />
                              <InputNumberForm
                                name={config.sConfigName + "_nValue"}
                                // label={"Input Number"}
                                defaultValue={config.nValue}
                              />
                            </Grid>
                            <Grid item xs={12}>
                            <HeaderInput text={"Description"} />
                              <TextBoxForm
                                name={config.sConfigName + "_sDes"}
                                isshowTextCont={false}
                                disabled={false}
                                defaultValue={config.sDescription}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                  <Grid container justifyContent={"flex-end"} sx={{mt:1.2}}>
                  <BtnCreateForm
                    txt={"Save Change"}
                    onClick={form.handleSubmit((e) => {
                      let data = [];
                      if(e){
                        lstConfig.forEach((d,i)=> {
                            let objData = {
                              sConfigName : d.sConfigName,
                              sValue : e[`${d.sConfigName}_sValue`],
                              nValue : e[`${d.sConfigName}__nValue`],
                              sDescription : e[`${d.sConfigName}_sDes`]
                            };
                            data.push(objData);
                        })
                      }
                      let oConfig = {
                        lstConfig: data,
                      };
                      SwAlert.Confirm("Confirmation", "Do you want to save ?", () => {
                        AxiosPost(
                          "ConfigSetting/UpdateConfig",
                          oConfig,
                          (result) => {
                            SwAlert.Success("Success","Changes saved successfully.")
                          },
                          (err) => {
                            if (!err.response) {
                              SwAlert.Warning("Warning", err.Message, () => {});
                            }
                          }
                        );
                      });
                    })}
                  />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
