
import parse from 'html-react-parser';

export function NetworkContent({contentHtml}){
  ////const frontURL = process.env.REACT_APP_Front_URL;
  if(contentHtml != null){
    return (<>{parse(contentHtml)}</>);
  }else{
    return (<></>);
  }
}