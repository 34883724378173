import { IconsProp } from "./Icons";
import { AiFillSetting, AiOutlineHome, AiOutlinePicRight, AiOutlineFileDone } from 'react-icons/ai';
import {BiTask,BiCalendarStar,BiSolidUserCircle,BiNews} from 'react-icons/bi';
import { BsFillSendFill, BsFillClipboard2CheckFill } from 'react-icons/bs';
import { FaAlignRight, FaFolder, FaUserPlus, FaRegEdit } from 'react-icons/fa';
import { FiUserPlus, FiFileText } from 'react-icons/fi';
import { GrAnnounce } from 'react-icons/gr';
import { HiOutlineDocumentText, HiUserGroup, HiUserCircle, HiOutlinePencil } from 'react-icons/hi';
import { ImDownload3, ImUpload3, ImImages } from 'react-icons/im';
import {MdSearch,MdOutlineFactCheck,MdFactory} from 'react-icons/md';
import {RiQuestionnaireFill,RiFolderUploadLine,RiBuildingFill,RiUserSettingsFill,RiUserShared2Line,RiFileSearchLine,RiBook2Fill,RiSettings5Fill} from 'react-icons/ri';
import {TiLocation} from 'react-icons/ti';

const dataIcons = {
    AiFillSetting,
    AiOutlineFileDone,
    AiOutlineHome,
    AiOutlinePicRight,
    BiTask,
    BiCalendarStar,
    BsFillClipboard2CheckFill,
    FaAlignRight,
    FaFolder,
    FaUserPlus,
    FiFileText,
    FiUserPlus,
    GrAnnounce,
    HiOutlineDocumentText,
    MdSearch,
    MdOutlineFactCheck,
    ImDownload3,
    ImUpload3,
    ImImages,
    RiQuestionnaireFill,
    RiFolderUploadLine,
    MdFactory,
    RiBuildingFill,
    TiLocation,
    RiUserSettingsFill,
    HiUserGroup,
    HiUserCircle,
    RiUserShared2Line,
    BsFillSendFill,
    HiOutlinePencil,
    FaRegEdit,
    RiFileSearchLine,
    RiBook2Fill,
    BiSolidUserCircle,
    RiSettings5Fill,
    BiNews
};

const GetIcon = (iconName,className, dataIcon) => {
  const IconComponent = dataIcon[iconName];
  if (!IconComponent) {
    console.error(`Icon '${iconName}' not found.`);
    return null;
  }
  return <IconComponent data-testid={iconName} className={className} />;
};

const DynamicIcon = (iconName,props: IconsProp) => {
  let className = props.className;
  return GetIcon(iconName,className,dataIcons);
};

const ReactIcons = (props: IconsProp) => {
  let iconName = props.IconName ?? "FaAlignRight";
  const IconReact = DynamicIcon(iconName,props);
  return IconReact;
}
export default ReactIcons;