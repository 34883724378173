
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
///Import Page
import BannerPage from '../Home/components/banner/Banner';
import HomePageIPad from './HomePageIPad';
import HomePageMobile from "./HomePageMobile";
import './HomePage.css';
import './../../../src/view/Animation.css';
import PopupUnderConstraction from "view/UnderConstraction/UnderConstraction2";
import AOS from 'aos';
import 'aos/dist/aos.css';
////MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { AxiosGet } from "utilities/ST_Axios";
import parse from 'html-react-parser';
import { SetSeoMenuToHtml } from "STM/utilities/ST_Function";

function HomePage() {
  useEffect(() => {
    SetSeoMenuToHtml();
  }, []);
  const frontURL = process.env.REACT_APP_Front_URL;

  const [bannerTopicMain, setBannerTopicMain] = useState("");
  const [bannerTopicSub, setbannerTopicSub] = useState("");

  const [BannerBeforeChangeID, setBannerBeforeChangeID] = useState(0);
  const [BannerAfterChangeID, setBannerAfterChangeID] = useState(-1);
  const [BannerRender, setBannerRender] = useState("");

  const [bannerData, setBannerData] = useState([]);
  const [lblAboutUs, setlblAboutUs] = useState("");
  const [lblNetwork, setlblNetwork] = useState("");
  const [lblNews, setlblNews] = useState("");

  const [OpenUnderContraction, setOpenUnderContraction] = useState(false);



  useEffect(() => {
    AOS.init();
  }, []);

  ///JSONดึงBackEnd
  // // const BannerJson = [
  // //   {
  // //     "sID": 0,
  // //     "topic": "PTT Global LNG",
  // //     "content": "A subsidiary of PTT with an ambition to be a flagship of PTT Group in the LNG business",
  // //     "img": frontURL + "banner/banner1.png"
  // //   },
  // //   {
  // //     "sID": 1,
  // //     "topic": "LNG Portfolio Player",
  // //     "content": "building LNG portfolio to strengthen Thailand energy security with flexibility",
  // //     "img": frontURL + "banner/banner2.png"
  // //   },
  // //   {
  // //     "sID": 2,
  // //     "topic": "Investment in LNG Value Chain",
  // //     "content": "creating sustainable revenue streams through LNG value chain",
  // //     "img": frontURL + "banner/banner3.png"
  // //   }
  // // ]
  ///// console.log("BannerJson", BannerJson);

  useEffect(() => {
    AxiosGet("Utility2/GetHomeInfo", {}, (res) => {
      setBannerData(res.bannerInfoList);
      setlblAboutUs(res.homeLabel.AboutUs);
      setlblNetwork(res.homeLabel.Network);
      setlblNews(res.homeLabel.News);
      setBannerAfterChangeID(0);
    });
  }, []);


  useEffect(() => {
    if (BannerAfterChangeID != -1) {

      ///let person = BannerJson.find(obj => obj.sID === BannerAfterChangeID);
      let person = bannerData.find(obj => obj.sID === BannerAfterChangeID);
      //// console.log(person);


      const ArrayTopicMain = [];
      const TopicSplitMain = person.topic.split(' ');
      TopicSplitMain.forEach(value => {
        ArrayTopicMain.push("<span>" + value + " " + "</span>");
      });
      const textBannerTopicMain = ArrayTopicMain.join('');
      setBannerTopicMain(textBannerTopicMain);


      //// setBannerTopicMain(person.topic);
      const TextFirstUperCase = person.content.charAt(0).toUpperCase() + person.content.slice(1);
      const ArrayTopicSub = [];
      const TopicSplitSub = TextFirstUperCase.split(' ');
      TopicSplitSub.forEach(value => {
        ArrayTopicSub.push("<span>" + value + " " + "</span>");
      });
      const textBannerTopicSub = ArrayTopicSub.join('');
      setbannerTopicSub(textBannerTopicSub);
    }

  }, [BannerAfterChangeID]);



  const FunctionCloseDialog = () => {
    setOpenUnderContraction(false);
  }


  return (
    <>
      {/* <NetworkMap /> */}
      <Box className="body-homepage">
        <Grid container sx={{ height: '100%' }} className="home-page-desktop">
          <Grid container className="home-page-desktop-sub">

            {/* Welcome */}
            <Grid container className="welcome-head animate4s fadeIn" lg={6} md={6} sm={12} sx={{}}>
              <Grid item md={12} sm={12} className="welcome-tag">
                <Box className={"welcome-first " + BannerRender}>{parse(bannerTopicMain)}</Box>
                <Box className={"welcome-sec " + BannerRender}>{parse(bannerTopicSub)}</Box>
              </Grid>
            </Grid>

            <Grid container lg={6} md={6} sm={12} className="welcome-content animate4s fadeIn" sx={{}}>
              {/* บน */}
              <Grid container md={12}>
                <Grid container md={7} sx={{}}>
                  <Grid container md={4} sx={{}}>
                    <Grid item md={12} className="boxicon-50percen" sx={{}}>
                      <Box></Box>
                    </Grid>
                    <Grid item md={12} className="boxicon-50percen" sx={{}}>
                      <IconButton className="Profile-Icon icons-electric-pole"
                        sx={{
                          backgroundImage: `url(${frontURL + "home/electric-pole.png"})`
                        }}>
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container md={4} sx={{}}>
                    <Grid item md={12} className="boxicon-50percen" sx={{}}>
                      <IconButton className="Profile-Icon icons-boat"
                        sx={{
                          backgroundImage: `url(${frontURL + "home/boat.png"})`
                        }}>
                      </IconButton>
                    </Grid>
                    <Grid item md={12} className="boxicon-50percen" sx={{}}>
                      <Box></Box>
                    </Grid>
                  </Grid>

                  <Grid container md={4} sx={{}}>
                    <Grid item md={12} className="boxicon-50percen" sx={{}}>
                      <Box></Box>
                    </Grid>
                    <Grid item md={12} className="boxicon-50percen" sx={{}}>
                      <IconButton className="Profile-Icon icons-gas-station"
                        sx={{
                          backgroundImage: `url(${frontURL + "home/gas-station.png"})`
                        }}>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={5} sx={{ overflow: "hidden" }}>
                  <Link to={"/AboutUs"}>
                    {/* <Grid item className="Profile-AboutUs ImgZoomIn" onClick={openUnderConstruction} */}
                    <Grid item className="Profile-AboutUs ImgZoomIn"
                      sx={{
                        backgroundImage: `url(${frontURL + "home/aboutUs.png"})`,
                        position: "relative",
                      }}>
                      <Box>
                        {/* COMPANY <br></br> PROFILE */}
                      </Box>
                      <Box className="Profile-AboutUs-Text rotateSlow1">
                        {/* ABOUT US */}
                        <span>A</span><span>B</span><span>O</span><span>U</span><span>T</span><span>&nbsp;</span><span>U</span><span>S</span>
                      </Box>
                    </Grid>
                  </Link>
                </Grid>

              </Grid>

              {/* กลาง */}
              <Grid container md={12} sx={{}}>

                <Grid container md={7} sx={{}}>
                  <Grid container md={4} sx={{}}>
                    <Grid item md={12} sx={{}}>
                      <Box></Box>
                    </Grid>
                    <Grid item md={12} sx={{}}>
                      <Box></Box>
                    </Grid>
                  </Grid>

                  <Grid item md={8} sx={{ overflow: "hidden" }}>
                    <Link to={"/NetWork"}>
                      <Box className="Profile-NetWork d-flex" sx={{
                        height: "100%", backgroundImage: `url(${frontURL + "home/network.jpg"})`, position: "relative",
                        transition: ".3s ease", '&:hover': { transform: "scale(1.25)" }
                      }}>
                        <Box sx={{ left: "3%", top: "3%", position: "absolute", fontSize: `clamp(0.7rem, 2.5vw, 1rem)`, fontWeight: 400, color: "#fff" }}>
                          {/* CONNECT <br></br> WITH */}
                        </Box>

                        {/* OUR NETWORK */}
                        <Box className="" sx={{ left: "12%", bottom: "21%", position: "absolute", fontSize: `clamp(1rem, 2.5vw, 2rem)`, fontWeight: 500, color: "#fff" }}>
                        </Box>
                        <Box className="Profile-NetWork-Text rotateSlow2">
                          <span>N</span><span>E</span><span>T</span><span>W</span><span>O</span><span>R</span><span>K</span>
                        </Box>
                      </Box>
                    </Link>
                  </Grid>
                </Grid>

                <Grid container md={5} sx={{}}>
                  <Grid container md={5} sx={{}}>
                    <Grid item md={12} sx={{}}>
                      <Box></Box>
                    </Grid>
                    <Grid item md={12} sx={{}}>
                      <Box></Box>
                    </Grid>
                  </Grid>

                  <Grid container md={7} sx={{}}>
                    <Grid item md={12} sx={{}}>
                      <Box></Box>
                    </Grid>
                    <Grid item md={12} sx={{ cursor: "pointer" }}>
                      <Link to={"/NewsActivity"}>
                        <Box className="Profile-News iconanimation" sx={{ backgroundImage: `url(${frontURL + "home/news2.png"})` }}>
                        </Box>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>

              {/* ล่าง */}
              <Grid container md={12} sx={{}}>

                <Grid container md={7} sx={{}}>
                  <Grid container md={4} sx={{}}>
                    <Grid item md={12} sx={{}}>
                      <Box>
                      </Box>
                    </Grid>
                    <Grid item md={12} sx={{}}>
                      <Box></Box>
                    </Grid>
                  </Grid>

                  <Grid container md={4} sx={{}}>
                    <Grid item md={12} className="boxicon-50percen" sx={{}}>
                      <IconButton className="Profile-Icon icons-faucet"
                        sx={{
                          backgroundImage: `url(${frontURL + "home/faucet.png"})`
                        }}>
                      </IconButton>
                    </Grid>
                    <Grid item md={12} className="boxicon-50percen" sx={{}}>
                      <Box></Box>
                    </Grid>
                  </Grid>

                  <Grid container md={4} sx={{}}>
                    <Grid item md={12} className="boxicon-50percen" sx={{}}>
                      <Box></Box>
                    </Grid>
                    <Grid item md={12} className="boxicon-50percen" sx={{}}>
                      <IconButton className="Profile-Icon icons-gas-tank"
                        sx={{
                          backgroundImage: `url(${frontURL + "home/gas-tank.png"})`
                        }}>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container md={5} sx={{}}>
                  <Grid container md={5} sx={{}}>
                    <Grid item md={12} sx={{}}>
                      <Box></Box>
                    </Grid>
                    <Grid item md={12} sx={{}}>
                      <Box></Box>
                    </Grid>
                  </Grid>

                  <Grid container md={7} sx={{}}>
                    {/* <Grid item md={12} className="" onClick={openUnderConstruction} sx={{ cursor: "pointer" }}> */}
                    <Grid item md={12} className="" sx={{ cursor: "pointer" }}>
                      <Link to={"/NewsActivity"}>
                        <Box className="boxicon-50percen Profile-NewActivity iconanimation" sx={{ position: "relative" }}>
                          <Box sx={{ left: "4%", bottom: "4%", position: "absolute", fontSize: `clamp(1rem, 2.5vw, 1rem)`, fontWeight: 400, color: "#ffff", lineHeight: "23px" }}>
                            {parse(lblNews)}
                          </Box>
                        </Box>
                      </Link>
                    </Grid>
                    <Grid item md={12} sx={{}}>
                      <Box className="boxicon-50percen">
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Box >

      <BannerPage setBannerBeforeChangeID={setBannerBeforeChangeID} setBannerAfterChangeID={setBannerAfterChangeID} setBannerRender={setBannerRender} />

      <HomePageIPad />

      <HomePageMobile />

      <PopupUnderConstraction open={OpenUnderContraction} handleClose={FunctionCloseDialog} />
    </>
  );
}

export default HomePage;
