import * as yup from "yup";

import moment from "moment";

const yupFormSchemas = {
    generic(label: any) {
        return yup.mixed().label(label);
    },
    object(label: any, config?: any) {
        config = config || {};

        let yupChain = yup
            .object()
            .label(label)
            .default(null)
            .nullable()
        if (config.required) {
            if(config.translation){
                config.msg = config.translation('pleasespecify') + " "  + label;
            }else{
                config.msg = "โปรดระบุ " + label;
            }
            yupChain = yupChain.required(config.msg);
        }
        return yupChain;
    },
    string(label: any, config?: any) {
        config = config || {};
        let yupChain = yup
            .string()
            .transform((cv, ov) => {
                return ov === "" ? null : cv;
            })
            .nullable()
            .trim()
            .label(label);

        if (config.required) {
            if(config.translation){
                config.msg = config.translation('pleasespecify') + " "  + label;
            }else{
                config.msg = "โปรดระบุ " + label;
            }
            yupChain = yupChain.required(config.msg);
        }

        if (config.min || config.min === 0) {
            yupChain = yupChain.min(config.min);
        }

        if (config.max) {
            yupChain = yupChain.max(config.max);
        }

        if (config.matches) {
            yupChain = yupChain.matches(config.matches, config.labelmatches);
        }

        return yupChain;
    },
    boolean(label: any, config?: any) {
        return yup.bool().default(false).label(label);
    },
    relationToOne(label: any, config?: any) {
        config = config || {};

        let yupChain = yup
            .mixed()
            .nullable()
            .label(label)
            .transform((value, originalValue) => {
                if (!originalValue) {
                    return null;
                }

                return originalValue.id;
            });

        if (config.required) {
            yupChain = yupChain.required();
        }

        return yupChain;
    },
    stringArray(label: any, config?: any) {
        config = config || {};

        let yupChain = yup
            .array()
            .compact()
            .ensure()
            .of(
                yup
                    .string()
                    .transform((cv, ov) => {
                        return ov === "" ? null : cv;
                    })
                    .trim()
            )
            .label(label);
        if (config.min || config.min === 0) {
            yupChain = yupChain.min(config.min);
        } else if (config.required) {
            if(config.translation){
                yupChain = yupChain.min(1,config.translation('pleasespecify') + " "  + label);
            }else{
                yupChain = yupChain.min(1, "โปรดระบุ " + label);
            }
            
        }

        if (config.max) {
            yupChain = yupChain.max(config.max);
        }

        return yupChain;
    },
    array(label: any, config?: any) {
        config = config || {};

        let yupChain = yup.array().nullable().label(label);

        if (config.required || config.min) {
            yupChain = yupChain.required();
        }

        if (config.min || config.min === 0) {
            yupChain = yupChain.min(config.min);
        } else if (config.required) {
            yupChain = yupChain.min(1);
        }

        if (config.max) {
            yupChain = yupChain.max(config.max);
        }

        return yupChain;
    },
    relationToMany(label: any, config?: any) {
        config = config || {};

        let yupChain = yup
            .array()
            .nullable()
            .label(label)
            .transform((value, originalValue) => {
                if (!originalValue || !originalValue.length) {
                    return [];
                }

                return originalValue.map((item: any) => item.id);
            });

        if (config.required || config.min) {
            yupChain = yupChain.required();
        }

        if (config.min || config.min === 0) {
            yupChain = yupChain.min(config.min);
        } else if (config.required) {
            yupChain = yupChain.min(1);
        }

        if (config.max) {
            yupChain = yupChain.max(config.max);
        }

        return yupChain;
    },
    integer(label: any, config?: any) {
        config = config || {};

        let yupChain = yup
            .number()
            .transform((cv, ov) => {
                return ov === "" ? null : cv;
            })
            .integer()
            .nullable()
            .label(label);
        if (config.required) {
            if(config.translation){
                config.msg = config.translation('pleasespecify') + " "  + label;
            }else{
                config.msg = "โปรดระบุ " + label;
            }
            yupChain = yupChain.required(config.msg);
        }
        if (config.min || config.min === 0) {
            yupChain = yupChain.min(config.min);
        }

        if (config.max) {
            yupChain = yupChain.max(config.max);
        }

        return yupChain;
    },
    images(label: any, config?: any) {
        config = config || {};

        let yupChain = yup.array().nullable().label(label);

        if (config.required || config.min) {
            yupChain = yupChain.required();
        }

        if (config.min || config.min === 0) {
            yupChain = yupChain.min(config.min);
        } else if (config.required) {
            yupChain = yupChain.min(1);
        }

        if (config.max) {
            yupChain = yupChain.max(config.max);
        }

        return yupChain;
    },
    files(label: any, config?: any) {
        config = config || {};

        let yupChain = yup.array().compact().ensure().nullable().label(label);

        if (config.required || config.min) {
            yupChain = yupChain.required();
        }

        if (config.min || config.min === 0) {
            yupChain = yupChain.min(config.min);
        } else if (config.required) {
            yupChain = yupChain.min(1);
        }

        if (config.max) {
            yupChain = yupChain.max(config.max);
        }

        return yupChain;
    },
    enumerator(label: any, config?: any) {
        config = config || {};

        let yupChain = yup
            .string()
            .transform((cv, ov) => {
                return ov === "" ? null : cv;
            })
            .label(label)
            .nullable()
            .oneOf([null, ...(config.options || [])]);

        if (config.required) {
            yupChain = yupChain.required("select");
        }

        return yupChain;
    },
    email(label: any, config?: any) {
        config = config || {};

        let yupChain = yup
            .string()
            .transform((cv, ov) => {
                return ov === "" ? null : cv;
            })
            .nullable()
            .trim()
            .label(label)
            .email(config.translation ? config.translation('formatmail') : "รูปแบบอีเมลไม่ถูกต้อง");
        if (config.required) {
            if(config.translation){
                config.msg = config.translation('pleasespecify') + " "  + label;
            }else{
                config.msg = "โปรดระบุ " + label;
            }
            yupChain = yupChain.required(config.msg);
        }

        if (config.min || config.min === 0) {
            yupChain = yupChain.min(config.min);
        }

        if (config.max) {
            yupChain = yupChain.max(config.max);
        }

        if (config.matches) {
            yupChain = yupChain.matches(config.matches);
        }

        return yupChain;
    },
    decimal(label: any, config?: any) {
        config = config || {};
        let yupChain = yup
            .number()
            .transform((cv, ov) => {
                return ov === "" ? null : cv;
            })
            .nullable()
            .label(label);

        if (config.required) {
            yupChain = yupChain.required();
        }

        if (config.min || config.min === 0) {
            yupChain = yupChain.min(config.min);
        }

        if (config.max) {
            yupChain = yupChain.max(config.max);
        }

        return yupChain;
    },
    datetime(label: any, config?: any) {
        config = config || {};
        let yupChain = yup
            .mixed()
            .nullable()
            .label(label)
            .transform((value, originalValue) =>
                value ? moment(originalValue, "YYYY-MM-DDTHH:mm:ss").toISOString() : null
            );

        if (config.required) {
            yupChain = yupChain.required();
        }

        return yupChain;
    },
    datetimeRange(label: any, config?: any) {
        config = config || {};
        let yupChain = yup.array().of(yup
            .mixed()
            .nullable()
            .label(label)
            .transform((value, originalValue) =>
                value ? moment(value).format("YYYY-MM-DDTHH:mm:ss") : null
            ));

        if (config.required) {
            yupChain = yupChain.required();
        }

        return yupChain;
    },
    date(label: any, config?: any) {
        config = config || {};
        let yupChain = yup
            .mixed()
            .nullable()
            .label(label)
            .test("is-date", "Please Specify", (value) => {
                if (!value) {
                    return true;
                }

                return moment(value, "YYYY-MM-DD").isValid();
            })
            .transform((value) =>
                value ? moment(value).format("YYYY-MM-DD") : null
            );
        if (config.required) {
            if(config.translation){
                config.msg = config.translation('pleasespecify') + " "  + label;
            }else{
                config.msg = "โปรดระบุ " + label;
            }
            yupChain = yupChain.required(config.msg);
        }

        return yupChain;
    },
    time(label: any, config?: any) {
        config = config || {};
        let yupChain = yup
            .mixed()
            .nullable()
            .label(label)
            .test("is-time", "Please Specify", (value) => {
                if (!value) {
                    return true;
                }

                return moment(value, "HH:mm:ss").isValid();
            })
            .transform((value) =>
                value ? moment(value).format("HH:mm:ss") : null
            );
        if (config.required) {
            config.msg = "Please Specify" + " " + label;
            yupChain = yupChain.required(config.msg);
        }

        return yupChain;
    },
};


export default yupFormSchemas;

export const RegexpPasswork = () => {
    let regexp1 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))"; //พิมพ์ใหญ่และตัวเลขและอักขระพิเศษ
    let regexp2 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.{8,}))";
    let regexp3 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))";
    let regexp4 = "((?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))";
    let regexp5 = "((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,}))";
    let regexp6 = "((?=.*[a-z])(?=.*[A-Z])(?!.*[ก-ฮ])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))"; //พิมพ์เล็กและพิมพ์ใหญ่และตัวเลขและอักขระพิเศษ
    // let egexp = "^(" + regexp1 + "|" + regexp2 + "|" + regexp3 + "|" + regexp4 + "|" + regexp5 + "|" + regexp6 + ")"
    let egexp = "^(" + regexp6 + ")";

    return egexp;
};

export const RegexpPassword = () => {

    let regexp1 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))";//พิมพ์ใหญ่และตัวเลขและอักขระพิเศษ
    let regexp2 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.{8,}))";
    let regexp3 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))";
    let regexp4 = "((?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))";
    let regexp5 = "((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,}))";
    let regexp6 = "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}))";//พิมพ์เล็กและพิมพ์ใหญ่และตัวเลขและอักขระพิเศษ 
    let egexp = "^(" + regexp1 + "|" + regexp2 + "|" + regexp3 + "|" + regexp4 + "|" + regexp5 + "|" + regexp6 + ")"
    return egexp;
}
export const MsgValidate = {
    PleaseSpecify: "โปรดระบุ ",
    SecurityCode: "รหัสผ่านต้องมีอักขระอย่างน้อย 8 ตัวและมีอย่างน้อยสามรายการต่อไปนี้: ตัวพิมพ์ใหญ่, ตัวพิมพ์เล็ก, ตัวเลข, สัญลักษณ์",
    SecurityCodeEN: "password must be at least 8 characters long and contain: a uppercase letter, an lowercase letter, a number, a sign. (!, @, #, $, %, ^, &, * )",
    InvalidEmail: "อีเมลไม่ถูกต้อง",
    ConfirmSecurityCode: "รหัสผ่านไม่ถูกต้อง"
}
