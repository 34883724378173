import React from "react";
import { motion } from "framer-motion";
import Backdrop from "./Backdrop";
import "./Modal.css";
import { BsFillTagsFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";


const data = ["tag", "news", "pttgl", "softthai", "finger policeman closer get bag top people someone partly upper high train fruit he alphabet corner place sweet tea neighbor stopped similar beyond suddenly"];
const Modal = ({ handleClose, comp, variants  }) => {
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal"
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {comp}
        {/* <button onClick={handleClose}>Close</button> */}
        <AiFillCloseCircle style={{position:"absolute",color:"white",top:"10px",right:"10px",fontSize:"1.5rem",cursor:"pointer"}} onClick={handleClose} />
      </motion.div>
    </Backdrop>
  );
};

export default Modal;
