import Crypto from "crypto-js";
import { keyCrypto } from "config/AppConfig";
import parse from "html-react-parser";
import Moment from "moment";
import { Tooltip } from "@mui/material";
import { EnumStatus } from "STM/enum/enumSystem";
import { GridColumns } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { Trans,initReactI18next} from 'react-i18next';
import { useDispatch } from "react-redux";
import BlockUIActionCreators from "storeIntarnet/redux/BlockUI";
import { DialogActionCreators } from "storeIntarnet/redux/DialogAlert";
import secureLocalStorage from "react-secure-storage";
import {GetSeoMenu } from "./CallAPI";
import i18next,{ t } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import cookies from "js-cookie";


export const STMService =() =>{
  const dialogFn = FnDialog();
  //logout clear success clear jwt and call back function
  const logout  = (callback)=>{
      dialogFn.Submit(t('confirmlogout'),async () => {
          const jwtkey =  process.env.REACT_APP_JWT_KEY;
          secureLocalStorage.setItem(jwtkey,"");
          callback();
      });
  }

  return {logout}
}

export const initialI18next =()=>{
  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    //.use(resourcesToBackend((language, namespace) => import(`../src/assets/Locales/${language}/${namespace}.json`)))
    .init({
        // the translations ./locales/${language}/${namespace}.json
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        ns: ["translation", "caption"],

        backend: {
            loadPath: 'assets/Locales/{{lng}}/{{ns}}.json',
        },
        detection: {
            order: ['cookie'],
            lookupCookie: process.env.REACT_APP_JWT_i18next, // Name of the cookie that holds the language information
            caches: ['cookie'],
            ////order: ['cookie', 'querystring', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            ////caches: ['cookie'],
        },
        fallbackLng: "en-US",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });
};

//getCurrent Language
export const GetLanguage =()=>{
  let i18next = process.env.REACT_APP_JWT_i18next;
  let value = cookies.get(i18next);
  ////let value =  secureLocalStorage.getItem(i18next) as string;
  return value;
}
//set SetLanguage "en-US,th-TH"
export const SetLanguage =(value)=>{
  let i18next = process.env.REACT_APP_JWT_i18next;
  cookies.set(i18next, value);
  ////secureLocalStorage.setItem(i18next,value);
} 

// export const combineBackUrl = (sWebMethodName:any) => {
//     const baseUrl = process.env.REACT_APP_API_URL;
//     const sPathApi = `${baseUrl}api/${sWebMethodName}`;
//     const url = new URL(sPathApi, window.location.href);
//     const sNewPath = url.origin + url.pathname + url.search;
//     return sNewPath;
// }

// export const getBackUrl = () => {
//   const baseUrl = `${process.env.REACT_APP_API_URL}`;
//   return baseUrl;
// };

// export const getPathFront = (sWebMethodName:any) => {
//   const baseUrl = process.env.REACT_APP_Front_URL;
//   const sPath = `${baseUrl}${sWebMethodName}`;
//   const url = new URL(sPath, window.location.href);
//   const sNewPath = url.origin + url.pathname + url.search;
//   return sNewPath;
// };

// export const getFrontUrl = () => {
//   const url = `${process.env.REACT_APP_Front_URL}`;
//   return url;
// };

//set SEO from GetSeoMenu to html
export const SetSeoMenuToHtml =() =>{
  GetSeoMenu((res)=>{
    console.log("GetSeoMenu:" + res);
    let metaList = document.getElementsByTagName("meta");
    if (metaList) {
      let elements  = Array.from(metaList);
      elements.forEach(element => {
        if(element.name =="title"){
          element.remove();
        }
        if(element.name =="description"){
          element.remove();    
        }
        if(element.name =="keywords")
        {
          element.remove();    
        }
        let propertyAttribute = element.getAttribute("property");
        if(propertyAttribute){
           if(propertyAttribute === "og:title") {
              element.setAttribute("content",res.Title);
           }else if(propertyAttribute === "og:description"){
              element.setAttribute("content",res.Description);
           }
        }
      });
    }

    document.title =res.Title;
    ///document.head.appendChild(meta);  
    let meta1 = document.createElement('meta');
    meta1.name = "description";
    meta1.content = res.Description;
    document.head.appendChild(meta1);  
    let meta2 = document.createElement('meta');
    meta2.name = "keywords";
    meta2.content = res.sKey;
    document.head.appendChild(meta2);
    ///meta = document.createElement('meta');
    ///meta.name = "robots";
    ///meta.content = res.Description;
    ///document.head.appendChild(meta);
  })
}

interface SeoModel {
  Description: string;
  Keywords:string;
  Tags:[],
}
export const SetSeoToHtml =(seo:SeoModel) =>{
    ///console.log("GetSeoMenu:" + seo);
    let metaList = document.getElementsByTagName("meta");
    if (metaList) {
      let elements  = Array.from(metaList);
      elements.forEach(element => {
        if(element.name =="description"){
          element.remove();    
        }
        if(element.name =="keywords")
        {
          element.remove();    
        }
        if(element.name =="robots")
        {
          element.remove();    
        }
      });
    }
    let sTag = seo.Tags.toString();
    let meta = document.createElement('meta');
    meta.name = "description";
    meta.content = seo.Description;
    document.head.appendChild(meta);
    let meta2 = document.createElement('meta');
    meta2.name = "keywords";
    meta2.content = seo.Keywords;
    if(sTag != ""){
      if(meta2.content == ""){
        meta2.content = sTag;
      }else{
        meta2.content += "," + sTag;
      }
      
    }
    document.head.appendChild(meta2);
    ///let meta3 = document.createElement('meta');
    ///meta3.name = "robots";
    ///meta3.content = seo.Keywords;
    ///document.head.appendChild(meta3);

}


interface SystemInfoModel {
  SystemName: string;
  SystemReleaseInfo: string;
}

/////{SystemName,SystemReleaseInfo}
export const  GetSystemInfo = () => {
  let systemInfo = process.env.REACT_APP_SystemInfo;
  let json  = secureLocalStorage.getItem(systemInfo) as any;
  ////alert(JSON.stringify(result2.SystemReleaseInfo));
  const result: SystemInfoModel = json;
  return result;
}

//#region Function
export const IsNullOrUndefined = (Value: any) => {
  let Result = true;
  if (Value) {
    Result = false;
  }
  return Result;
};
export const ToInt = (Value: string) => {
  let Result = 0;
  if (Value) {
    Result = parseInt(Value);
  }
  return Result;
};
/**
 * แปลง string เป็น html
 * @param val
 * @returns Tag html
 */
export const ParseHtml = (val: string) => {
  if (val) return parse(val);
  else return val;
};
export const OpenNewTab = (sUrl) => {
  let newWindow = window.open(sUrl, "_blank", 'noopener,noreferrer')
  if (newWindow) {
      newWindow.opener = null;
  }
};
/**
 * แปรง Date เป็น String
 * @param value
 * @param format
 * @returns Date string
 */
export const DateTimeToString = (value, format = "DD/MM/YYYY") => {
  Moment.locale("en");
  return Moment(value).format(format);
};

export const DynamicLangCol = (lstData, dataCol ,indexMap) => {
  let arrCol: GridColumns = dataCol;
  let arrNewCol = [];
  lstData.forEach((item, index) => {
    let objColAdd = {
      field: `sLanguageName${item.label}`,
      headerName: item.label === "US" ? "EN" : item.label,
      headerAlign: "center",
      align: "center",
      minWidth: 70,
      flex:1,
      sortable: false,
      renderCell: (p) => {
        let status = p.row.lstLanguageCode.find(
          (f) => f.label === item.label
        ).value;
        return [
          status === EnumStatus.Submit.toString() && (
            <Tooltip title="Complete">
              <CheckCircleIcon
                sx={{
                  fontSize: "1.5rem",
                  color: "rgb(45, 206, 137)",
                  // cursor: "pointer",
                }}
              />
            </Tooltip>
          ),
          status === EnumStatus.Draft.toString() && (
            <Tooltip title="draft">
              <HourglassBottomIcon
                sx={{
                  fontSize: "1.5rem",
                  color: "rgb(223, 173, 21)",
                  // cursor: "pointer",
                }}
              />
            </Tooltip>
          ),
        ];
      },
    };
    arrNewCol = insert(arrCol, indexMap + index, objColAdd);
    arrCol = arrNewCol;
  });
  return arrCol;
};

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];
//#endregion

//#region Crypto
export const Encrypt = (dataEncrypt: any) => {
  let data = dataEncrypt.toString();
  let result = Crypto.AES.encrypt(data, keyCrypto).toString();
  result = result.replace(/\//g, "s14sh").replace(/\+/g, "p1u5");

  return result;
};
export const Decrypt = (dataDecrypt: any) => {
  if (dataDecrypt && dataDecrypt != null && dataDecrypt !== "null") {
    dataDecrypt = dataDecrypt.replace(/s14sh/g, "/").replace(/p1u5/g, "+");
    let bytes = Crypto.AES.decrypt(dataDecrypt, keyCrypto);
    let result = bytes.toString(Crypto.enc.Utf8);
    return result;
  } else {
    return "";
  }
};

export const GetTranslation = (name: any) => {
  return <Trans>{name}</Trans>;
};
//#endregion

//#region Extension Type For Upload file

export const Extension = {
  Image: ["jpg", "jpeg", "png", "gif"],
  Video: ["mov", "wmv", "avi", "mp4"],
  PDF: ["pdf"],
  Document: ["doc", "docx", "xls", "xlsx", "txt"],
  Word: ["doc", "docx"],
  Excel: ["xls", "xlsx"],
  PowerPoint: ["pptx", "ppt"],
  txt: ["txt"],
  Email: ["msg"],
  Other: ["rar", "zip"],
  AllType: null,
  Image_New: ["jpg", "jpeg", "png"],
  Video_New: ["mp4"],
};
//#endregion

export const FnDialog = () => {
  const Dispatch = useDispatch() as any;
  const BlockUI = () => Dispatch(BlockUIActionCreators.BlockUI());
  const UnBlockUI = () => Dispatch(BlockUIActionCreators.UnBlockUI());

  const Success = (msg?) =>
    Dispatch(DialogActionCreators.OpenDialogSuccess(msg));
  const Warning = (msg?) =>
    Dispatch(DialogActionCreators.OpenDialogWarning(msg));
  const Error = (msg?) => Dispatch(DialogActionCreators.OpenDialogError(msg));

  const Submit = (msg, fn?) => {
    Dispatch(DialogActionCreators.CloseDialogSuccess());
    Dispatch(DialogActionCreators.CloseDialogError());
    Dispatch(DialogActionCreators.CloseDialogWarning());
    return Dispatch(DialogActionCreators.OpenDialogSubmit(msg, fn));
  }
  const SubmitWarning = (msg, fn?, fnC?) => {
    Dispatch(DialogActionCreators.CloseDialogSuccess());
    Dispatch(DialogActionCreators.CloseDialogError());
    Dispatch(DialogActionCreators.CloseDialogWarning());
    return Dispatch(DialogActionCreators.OpenDialogSubmitWarning(msg, fn, fnC));
  }
  const CloseSubmit = () => Dispatch(DialogActionCreators.CloseDialogSubmit());
  const CloseSubmitWarning = () =>
    Dispatch(DialogActionCreators.CloseDialogSubmitWarning());

  return {
    Success,
    Warning,
    Error,
    Submit,
    SubmitWarning,
    CloseSubmit,
    CloseSubmitWarning,
    BlockUI,
    UnBlockUI,
  };
};

export const CheckResourceInjection = (sUrl) => {
   let Result = true;
   let r = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
   Result = r.test(sUrl);
  return Result;
}

/**
 * Sanitizes the path to prevent path traversal attacks.
 *
 * @param {string} path - The path to sanitize.
 * @returns {string} The sanitized path.
 */
export function sanitizePath(path) {
  // Implement your path sanitization logic here
  // For simplicity, you can perform basic checks, such as removing ".." or invalid characters
  // Be sure to customize this based on your specific requirements and security needs
  // Example:
  return path.replace(/(\.\.\/|\/\.\.|\.{2,}\/|\/{2,})/g, '');
}