import { CookieConsent } from "react-cookie-consent";
import parse from 'html-react-parser';
import "./CookieConsentCP.css";
import { GetInitialInfo } from "utilities/ST_Function";
import { useState } from "react";
import { Close } from "@mui/icons-material";
export interface CookieConsentOption {
    cookieName: string;
    location: string;
    buttonText:string;
    buttonStyle: React.CSSProperties;
    expires: number
    style: React.CSSProperties;
    content:string;
    buttonClasses:string;
    buttonWrapperClasses:string;
}


export const CookieConsentCP = (props?:CookieConsentOption ) =>{
    const [showCookieConsent, setShowCookieConsent] = useState(true);
    const handleCloseCookieConsent = () => {
        setShowCookieConsent(false);
    };
    
    let info = GetInitialInfo() as any;//data from Utility2Controller/GetInitialData
    //let clean =parse(`${props.content}`);
    let clean =parse(info.cookieConsent);
    return (<CookieConsent 
        location = {`${props.location}`} 
        buttonText={`${props.buttonText}`} 
        cookieName={`${props.cookieName}`} 
        buttonStyle={props.buttonStyle}
        style= {showCookieConsent? props.style: {display:"none"}}
        expires={props.expires}
        buttonClasses={`${props.buttonClasses}`}
        buttonWrapperClasses={`${props.buttonWrapperClasses}`}
        ///onDecline={handleCloseCookieConsent}
    >
    <div className="cookie-content">{clean}</div>
    <button type="button" className="cookie-close-button" onClick={handleCloseCookieConsent}>
    <Close
              sx={{
                fontSize: "1.5rem",
                alignSelf: "center",
                color: "rgb(255 255 255)",
              }}
            />
    </button>
    </CookieConsent>)
} 
CookieConsentCP.defaultProps = {
    location:"bottom",
    buttonText:"Accept",
    cookieName:"PTTGLWebCookie",
    style:{backgroundColor:"rgb(0 18 89 / 92%)"},
    buttonStyle:{fontSize: "0.8rem"},
    expires:150,
    buttonClasses:"",
    buttonWrapperClasses:"button-wrapper",
    content:`<div id="onetrust-policy"><h2 id="onetrust-policy-title">Our use of cookies</h2><p id="onetrust-policy-text">We use necessary cookies which is required for the operations of our Website. We also would like to set optional analytics cookies to help us improve our Website. We will not set optional cookies unless you enable them.
    You can learn more about our use of cookies from our <a href="/Cookiespolicy.aspx" style="color: #60e2ff;" target="_blank">Cookies Policy</a>. By clicking “Accept All Cookies”, you agree to the storing and use of cookies on your device to enhance site navigation, analyze site usage, and to assist in our marketing efforts. 
    </p></div>`
 };
