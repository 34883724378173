
export const AlertTitle = {
    Success: "msgSuccess",
    Warning: "Warning",
    Error: "msgError",
    Info: "msgInfo",
    Confirm: "msgConfirm",
    Hint: "msgHint",
    Duplicate: "msgDuplicate",
};

export const AlertMsg = {
    SaveComplete: "msgSaveComplete",
    DeleteComplete: "msgDeleteComplete",
    Warning: "Warning",
    Error: "msgError",
    Info: "msgInfo",
    ConfirmSave: "msgConfirmSave",
    ConfirmSaveDraft: "msgConfirmSaveDraft",
    ConfirmDelete: "msgConfirmDelete",
    ConfirmApprove: "msgConfirmApprove",
    ConfirmRevisit: "msgConfirmRevisit",
    ConfirmCancel: "msgConfirmCancel",
    ConfirmReject: "msgConfirmReject",
    NoPermission: "msgNoPermission",
    SessionTimeOut: "msgSessionTimeOut",    //Please Log in 
    Duplicate: "msgDuplicate",
    DeleteFail: "msgDeleteFail",
    UploadImage: "msgUploadImage",
    ConfirmLogout: "msgConfirmLogout",
    ConfirmImport: "msgConfirmImport",
    ConfirmChange: "msgConfirmChange",
};

