import React from 'react';
import { useEffect, useState, useRef } from "react";

// import other page
import "./AboutUs.css";
import PageVision from '../AboutUs/component/Vision';
import PageMission from '../AboutUs/component/Mission';
import PageOverview from '../AboutUs/component/Overview';
import PageOrganization from '../AboutUs/component/Organization';

import { Box, Grid, Typography, Button } from "@mui/material";
import { AxiosGet } from 'utilities/ST_Axios';
import { AboutUsContent, Organization2 } from './AboutUsContent';
import { enumCMSCode } from 'enum/enumSystem2';
import { SetSeoMenuToHtml } from 'STM/utilities/ST_Function';




function AboutUs() {

   const frontURL = process.env.REACT_APP_Front_URL;

   const [OpenSessionWeb, setOpenSessionWeb] = useState("vision");
   const WebContentBodyElement = useRef();

   const [OpenSession, setOpenSession] = useState("vision");
   const inputElement = useRef();

   const [aboutUsList, setAboutUsList] = useState([]);
   const [orgList, setOrgList] = useState([]);
   const [AboutUsOrganization, setAboutUsOrganization] = useState([]);

   const [OverviewHtmlTop, setOverviewHtmlTop] = useState([]);
   const [OverviewHtmlBottom, setOverviewHtmlBottom] = useState([]);
   const [OverviewList, setOverviewList] = useState([]);


   ///ใช้เวลาเปลี่ยนเมนูย่อยจะสกอขึ้นไปด้านบน
   useEffect(() => {
      const scrollToRef = (ref) => {
         ref.current.scroll({
            top: 0,
            behavior: 'smooth'
         });
      };
      scrollToRef(inputElement);
   }, [OpenSession]);

   useEffect(() => {
      const scrollToRef = (ref) => {
         ref.current.scroll({
            top: 0,
            behavior: 'smooth'
         });
      };
      scrollToRef(WebContentBodyElement);
   }, [OpenSessionWeb]);


   const DataSession = (value: string) => {
      //// console.log("OpenSession", value);
      setOpenSession(value);
      setOpenSessionWeb(value);
   }

   useEffect(() => {
      SetSeoMenuToHtml();
      AxiosGet("Utility2/GetAboutUsInfo", {}, (res) => {
         // console.log("Utility2/GetAboutUsInfo ", res);
         setAboutUsList(res.dataList);
         setOrgList(res.organizationList);
         setAboutUsOrganization(res.dataList[2]);

         setOverviewHtmlTop(res.dataList[3]);
         setOverviewHtmlBottom(res.dataList[4]);
         setOverviewList([res.scopeInvestmentList]);
      })
   }, []);


   return (
      <>
         <Grid container className="aboutus-container fixed-height-aboutus">


            {/* Web Mode */}
            <Grid item xs={12} className="tag-mainBackground" sx={{
               backgroundImage: `url(${frontURL + "assets/aboutus/aboutus-display.png"})`,
            }}>
               <Grid item md={5.5} xs={12} className="tag-web-display">
               </Grid>

               <Grid item md={1.8} xs={12} className="tag-web-nav" sx={{
                  height: "100%", display: "flex", alignItems: "center", justifyContent: "end", backgroundColor: "#00000057"
               }}>
                  <Box className="navListTab">
                     <Box className="boxNavList" onClick={() => DataSession("vision")}>
                        <Box className={`text-submenu  ${OpenSession === "vision" ? "submenu-txtActive" : "submenu-txtInActive"}`}>vision</Box>
                        <Button className={`btnIcon-setting ${OpenSession === "vision" ? "submenu-iconActive" : "submenu-iconInActive"}`}
                           sx={{ backgroundImage: `url(${frontURL + "assets/aboutus/icon/vision.png"})` }}>
                        </Button>
                     </Box>

                     <Box className="boxNavList" onClick={() => DataSession("mission")}>
                        <Box className={`text-submenu  ${OpenSession === "mission" ? "submenu-txtActive" : "submenu-txtInActive"}`}>mission</Box>
                        <Button className={`btnIcon-setting ${OpenSession === "mission" ? "submenu-iconActive" : "submenu-iconInActive"}`}
                           sx={{ backgroundImage: `url(${frontURL + "assets/aboutus/icon/mission.png"})` }}> </Button>
                     </Box>

                     <Box className="boxNavList" onClick={() => DataSession("overview")}>
                        <Box className={`text-submenu  ${OpenSession === "overview" ? "submenu-txtActive" : "submenu-txtInActive"}`}>Overview & <br></br> our journey</Box>
                        <Button className={`btnIcon-setting ${OpenSession === "overview" ? "submenu-iconActive" : "submenu-iconInActive"}`}
                           sx={{ backgroundImage: `url(${frontURL + "assets/aboutus/icon/overview.png"})` }}> </Button>
                     </Box>

                     <Box className="boxNavList" onClick={() => DataSession("organization")}>
                        <Box className={`text-submenu  ${OpenSession === "organization" ? "submenu-txtActive" : "submenu-txtInActive"}`}>Organization & <br></br> management</Box>
                        <Button className={`btnIcon-setting ${OpenSession === "organization" ? "submenu-iconActive" : "submenu-iconInActive"}`}
                           sx={{ backgroundImage: `url(${frontURL + "assets/aboutus/icon/organization.png"})` }}> </Button>
                     </Box>
                  </Box>
               </Grid>

               <Grid item md={4.7} xs={12} className="tag-web-content_body" sx={{ backgroundColor: "white" }} ref={WebContentBodyElement}>
                  {
                     OpenSession === "vision" &&
                     <section className="section vision animate3s fadeIn" id="vision">
                        <AboutUsContent sCMSCode={enumCMSCode.AboutUsVision} dataList={aboutUsList} />
                     </section>
                  }
                  {
                     OpenSession === "mission" &&
                     <section className="section mission animate3s fadeIn" id="mission">
                        <AboutUsContent sCMSCode={enumCMSCode.AboutUsMission} dataList={aboutUsList} />
                     </section>
                  }
                  {
                     OpenSession === "overview" &&
                     <section className="section overview animate3s fadeIn" id="overview">
                        <PageOverview OverviewList={OverviewList} OverviewHtmlTop={OverviewHtmlTop} OverviewHtmlBottom={OverviewHtmlBottom} />
                     </section>
                  }
                  {
                     OpenSession === "organization" &&
                     <section className="section organization animate3s fadeIn" id="organization">
                        <PageOrganization TagHtml={AboutUsOrganization} jsonOrganization={orgList} />
                     </section>
                  }
               </Grid>
            </Grid>


            {/* Mobile Mode solution < 900 */}
            <Grid item md={5.5} xs={12} className="tag-display"
               sx={{ backgroundImage: `url(${frontURL + "assets/aboutus/aboutus-display.png"})` }}>
            </Grid>

            <Grid item md={1.8} xs={12} className="tag-nav">
               <Box className="navListTab">
                  <Box className="boxNavList" onClick={() => DataSession("vision")}>
                     <Box className={`text-submenu  ${OpenSession === "vision" ? "submenu-txtActive" : "submenu-txtInActive"}`}>vision</Box>
                     <Button className={`btnIcon-setting ${OpenSession === "vision" ? "submenu-iconActive" : "submenu-iconInActive"}`}
                        sx={{ backgroundImage: `url(${frontURL + "assets/aboutus/icon/vision.png"})` }}>
                     </Button>
                  </Box>

                  <Box className="boxNavList" onClick={() => DataSession("mission")}>
                     <Box className={`text-submenu  ${OpenSession === "mission" ? "submenu-txtActive" : "submenu-txtInActive"}`}>mission</Box>
                     <Button className={`btnIcon-setting ${OpenSession === "mission" ? "submenu-iconActive" : "submenu-iconInActive"}`}
                        sx={{ backgroundImage: `url(${frontURL + "assets/aboutus/icon/mission.png"})` }}> </Button>
                  </Box>

                  <Box className="boxNavList" onClick={() => DataSession("overview")}>
                     <Box className={`text-submenu  ${OpenSession === "overview" ? "submenu-txtActive" : "submenu-txtInActive"}`}>Overview & <br></br> our journey</Box>
                     <Button className={`btnIcon-setting ${OpenSession === "overview" ? "submenu-iconActive" : "submenu-iconInActive"}`}
                        sx={{ backgroundImage: `url(${frontURL + "assets/aboutus/icon/overview.png"})` }}> </Button>
                  </Box>

                  <Box className="boxNavList" onClick={() => DataSession("organization")}>
                     <Box className={`text-submenu  ${OpenSession === "organization" ? "submenu-txtActive" : "submenu-txtInActive"}`}>Organization & <br></br> management</Box>
                     <Button className={`btnIcon-setting ${OpenSession === "organization" ? "submenu-iconActive" : "submenu-iconInActive"}`}
                        sx={{ backgroundImage: `url(${frontURL + "assets/aboutus/icon/organization.png"})` }}> </Button>
                  </Box>
               </Box>
            </Grid>

            <Grid item md={4.7} xs={12} className="content_body" ref={inputElement}>
               {
                  OpenSession === "vision" &&
                  <section className="section vision animate3s fadeIn" id="vision">
                     <AboutUsContent sCMSCode={enumCMSCode.AboutUsVision} dataList={aboutUsList} />
                  </section>
               }

               {
                  OpenSession === "mission" &&
                  <section className="section mission animate3s fadeIn" id="mission">
                     <AboutUsContent sCMSCode={enumCMSCode.AboutUsMission} dataList={aboutUsList} />
                  </section>
               }

               {
                  OpenSession === "overview" &&
                  <section className="section overview animate3s fadeIn" id="overview">
                     <PageOverview OverviewList={OverviewList} OverviewHtmlTop={OverviewHtmlTop} OverviewHtmlBottom={OverviewHtmlBottom} />
                  </section>
               }

               {
                  OpenSession === "organization" &&
                  <section className="section organization animate3s fadeIn" id="organization">
                     <PageOrganization TagHtml={AboutUsOrganization} jsonOrganization={orgList} />
                  </section>
               }
            </Grid>



         </Grid>
      </>
   )
}

export default AboutUs