import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./NewsActivity.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import NewsCardSkeleton from "./NewsCardSkeleton";
import { GetNewsAll } from "./CallAPI";
import { FnDialog, SetSeoMenuToHtml } from "STM/utilities/ST_Function";

let easeing = [0.6, -0.05, 0.01, 0.99];

const fadeInUp = {
  rest: {
    y: 60,
    opacity: 0,
    transition: {
      duration: 0.6,
      ease: easeing,
    },
  },
  animate: {
    y: 0,
    opacity: 1,
  },
};

const titleMotion = {
  rest: { opacity: 1, ease: easeing, duration: 0.2, type: "between" },
  hover: {
    opacity: 1,
    transition: {
      duration: 0.6,
      type: "between",
      ease: easeing,
    },
  },
};

// const boxMotion = {
//   rest: {
//     opacity: 0,
//     ease: easeing,
//     duration: 0.2,
//     type: "between",
//     height: 40,
//   },
//   hover: {
//     opacity: 1,
//     height: 43,
//     transition: {
//       duration: 0.65,
//       type: "between",
//       ease: easeing,
//     },
//   },
// };

const introMotion = {
  rest: {
    opacity: 0,
    ease: easeing,
    duration: 0.2,
    type: "between",
    height: 0,
  },
  hover: {
    opacity: 1,
    height: 43,
    transition: {
      duration: 0.65,
      type: "between",
      ease: easeing,
    },
  },
};

const buttonMotion = {
  // rest: { opacity: 0, ease: easeing, duration: 0.2, type: "between" },
  hover: {
    opacity: 1,
    background: "#001858",
    transition: {
      duration: 0.65,
      type: "between",
      ease: easeing,
    },
  },
};

export default function NewsActivity() {
  const frontURL = process.env.REACT_APP_Front_URL;
  const navigate = useNavigate();
  const DialogFn = FnDialog();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    nPageIndex: 1,
    nPageSize: 3,
    arrRows: [],
    nDataLength: 0,
    sSortExpression: "aaa",
    sSortDirection: "aaa",
    sID: "",
  });

  const arrNews = [
    {
      url: "https://plus.unsplash.com/premium_photo-1661776217775-ba332ee9566e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fE5ld3N8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
      text: "World Class and Competitive service provider excellence in LNG",
    },
    {
      url: "https://images.unsplash.com/photo-1546422904-90eab23c3d7e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8TmV3c3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60",
      text: "World Class and Competitive service provider excellence in LNG",
    },
    {
      url: "https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fE5ld3N8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
      text: "World Class and Competitive service provider excellence in LNG",
    },
  ];

  useEffect(() => {
    scrollToTop();
    const onGetNewsPin = (p) => {
      setIsLoading(true);
      GetNewsAll(
        { ...p },
        (res) => {
          setData({
            ...p,
            arrRows: res.lstNewsAll || [],
            nDataLength: res.nDataLength,
            nPageIndex: res.nPageIndex,
          });
          setIsLoading(false);
        },
        (err) => {
          if (!err.response) {
            DialogFn.Warning(err.Message);
          }
        }
      );
    };
    onGetNewsPin(data);
    SetSeoMenuToHtml();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Grid
      container
      className="news-activity-container"
      sx={{
        backgroundImage: `url(${frontURL + "images/newsbg.png"})`,
        backgroundSize: "cover",
        backgroundPosition: "50% 55%",
      }}
    >
      <Grid container className="news-container" spacing={2}>
        <Grid
          item
          xs={12}
          justifyContent={"space-between"}
          display={"flex"}
          sx={{ mb: 1.5 }}
          className="pl-0"
        >
          <Grid item xs={"auto"}>
            <Typography className="header-news">News & Activities</Typography>
          </Grid>
          <Grid
            item
            xs={"auto"}
            justifyContent={"flex-end"}
            display={"flex"}
            alignItems={"center"}
          >
            {data.arrRows.length > 0 && (
              <motion.div
                initial={{ opacity: 0, x: 60 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  duration: 1,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
                className="view-all-news"
                onClick={() => navigate("/NewsActivityAll")}
              >
                View All{" "}
                <ArrowForwardIcon sx={{ ml: 0.5, fontSize: "1.2rem" }} />
              </motion.div>
            )}
          </Grid>
        </Grid>
        {arrNews.map((news, index) => (
          <>{isLoading && <NewsCardSkeleton key={news.url} />}</>
        ))}
        {data.arrRows.map((news, index) => (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={news.sID}
              className="height-card"
            >
              <motion.div
                style={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  cursor: "pointer",
                }}
                className="main-card main-news-card"
                transition={{ duration: 0.6 * (index + 1) }}
                variants={fadeInUp}
                initial="rest"
                whileHover="hover"
                animate="animate"
                onClick={() => {
                  navigate(`/NewsActivityDetail`, {
                    state: {
                      sID: news.sID,
                    },
                  });
                }}
              >
                <motion.div
                  className="main-news"
                  style={{
                    // overflow: "hidden",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      height: "250px",
                      background: "#ffffffcc",
                    }}
                  >
                    <motion.img
                      // whileHover={{ scale: 1.1 }}
                      className="news-card-img"
                      src={news.fNewsCover[0].sFileLink}
                    />
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      padding: "0.5rem",
                    }}
                    className="card-absolute card-absolute-news"
                  >
                    <motion.div style={{minHeight:"40px"}}>
                      <Typography
                        className="news-name-all"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                        }}
                      >
                        {news.sNewsName}
                      </Typography>
                      <motion.div
                        variants={introMotion}
                        className="hide-on-smartphone"
                      >
                        <Typography
                          style={{
                            fontSize: "0.75rem",
                            lineHeight: 1.25,
                            fontWeight: 400,
                            color: "#67748e",
                            // marginTop: "0.5rem",
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 3,
                            marginTop:"0.5rem"
                          }}
                        >
                          {news.sNewsIntro}
                        </Typography>
                      </motion.div>

                      <motion.div className="hide-on-desktop">
                        <Typography
                          style={{
                            fontSize: "0.75rem",
                            lineHeight: 1,
                            fontWeight: 400,
                            color: "#67748e",
                            // marginTop: "0.5rem",
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 3,
                            marginTop:"0.5rem"
                          }}
                        >
                          {news.sNewsIntro}
                        </Typography>
                      </motion.div>
                    </motion.div>
                    <motion.button
                      variants={buttonMotion}
                      style={{
                        width: "90px",
                        background: "#4fc3f7",
                        color: "#fff",
                        textAlign: "center",
                        marginTop: "0.75rem",
                        borderRadius: "15px",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        border: "none",
                        padding: "2px 0",
                        cursor: "pointer",
                      }}
                    >
                      More{" "}
                      <ArrowForwardIcon sx={{ ml: 0.5, fontSize: "1.2rem" }} />
                    </motion.button>
                  </div>
                </motion.div>
              </motion.div>
            </Grid>
          </>
        ))}
      </Grid>
    </Grid>
  );
}
