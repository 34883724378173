import React from 'react'

const Guideline = (props) => {
    const {text} = props;
  return (
    <div style={{color: "rgb(52, 71, 103)",fontSize:"0.8rem",fontWeight:500,marginTop:"0.5rem"}}>
        <span style={{fontSize:"0.75rem",fontWeight:400,color:"#A1A5B7"}}>{text}</span>
    </div>
  )
}

export default Guideline