import React, { useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import yupFormSchemas from 'STM/components/FormItem/yupFormSchemas';
import { SwAlert } from 'STM/components/Alert/index';
import { AlertTitle, AlertMsg } from 'config/AlertMsgConfig'
import { FnBlock_UI } from 'STM/components/BlockUI';
import { AxiosPost } from 'STM/utilities/ST_Axios';
import {  useNavigate } from 'react-router-dom';
import DataGrid, { PaginationInterface, initRows } from 'STM/components/DataGrid';
import { Container, Grid, Typography, Tooltip } from '@mui/material';
import { BtnEditOnTable, BtnPreview, BtnDelete, BtnCreate, BtnAddWithIcon, BtnMainLang } from 'STM/components/Button';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Fab from "@mui/material/Fab";
import { GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import PopupCreateLanguage from './PopupCreateLanguage';
import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import LanguageFilter from "./LanguageFilter";
import { FnDialog } from "STM/utilities/ST_Function";

const LanguageList = () => {
    const DialogFn = FnDialog();
    const { t, i18n } = useTranslation();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const navigate = useNavigate();
    const [openPopup, setopenPopup] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [nPermission, setnPermission] = useState(2);
    const [dataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,
    });

    useEffect(() => {
        OnGetData(dataRow);
    }, []);

    const handleClosePopup = () => {
        setopenPopup(false);
    };

    const GetData = (p) => {
        OnGetData(p).then(
            () => { DialogFn.UnBlockUI() },
            () => { }
        );
    }

    const OnClearFilter = () => {
        form.reset();
        GetData(dataRow);
    };

    const OnGetData = async (p: PaginationInterface) => {
        let param = {
            ...p, 
            sLanguageName: form.getValues("sLangName"), 
            sStatus: form.getValues("sStatus"),
        };
        console.log("param", param)
        BlockUI();
        setLoadingTable(true)
        AxiosPost("LanguageTranslation/GetList", param, (res) => {
            UnBlockUI();
            setLoadingTable(false)
            console.log("res ", res);
            setDataRow({
                ...p,
                arrRows: res.lstData || [],
                nDataLength: res.nDataLength,
                nPageIndex: res.nPageIndex,
            });
        },
        );
    };

    const ContentWrapper = styled("div")({
        marginTop: "16px",
        padding: "16px",
        backgroundColor: "#fff",
        width: "100%",
        border: "1px solid #d5d5d5 !important",
        borderRadius: "10px",
        marginBottom: "2rem"
    });

    const dataColumn: GridColumns = [
        {
            headerName: t('action'),
            field: "Button (Add/Edit)",
            type: "actions",
            resizable: false,
            sortable: false,
            width: 100,
            getActions: (item) => {
                return [
                    nPermission === 2  ? (
                        <div style={{display:"flex"}}>
                            <BtnEditOnTable txt="แก้ไข" onClick={() => goToEdit(item.row.sLink)} />
                            </div>
                    ) : (
                            <BtnPreview txt="ดู" onClick={() => goToEdit(item.row.sLink)} />
                    ),
                ];
            },
        },
        {
            field: "sNo",
            headerName: t('no'),
            headerAlign: "center",
            align: "center",
            sortable: false,
            resizable: false,
            width: 100,
            renderCell: (item) => {
                return <div> {item.row.sID}</div>;
            },
        },
        {
            field: "sLanguageName",
            headerName: t('LangName'),
            headerAlign: "center",
            align: "left",
            sortable: true,
            resizable: false,
            flex: 1,
        },
        {
            field: "sLanguageCode",
            headerName: t('LanguageCode'),
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            width: 150,
        },
        {
            field: "nTranslationCount",
            headerName: t('nTranslationCount'),
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            width: 250,
            renderCell: (item) => {
                return <div> <span style={{ color: "red" }}>{item.row.nEmptyWord}</span> / <span style={{ color: "green" }}>{item.row.nTranslationCount}</span></div>;
            },
        },
        {
            field: "isMainLanguage",
            headerName: t('MainLang'),
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            width: 150,
            renderCell: (item) => {
                return <div> {item.row.isMainLanguage === true ?
                    <Tooltip title={t('MainLang')} >
                        <CheckCircleIcon
                            sx={{
                                fontSize: "1.5rem",
                                color: "rgb(45, 206, 137)",
                                cursor: "pointer",
                            }}
                        />
                    </Tooltip>
                    : ""}</div>;
            },
        },
        {
        field: "isActive",
        headerName: t('status'),
        headerAlign: "center",
        editable: false,
        resizable: false,
        align: "center",
        sortable: true,
        width: 100,
        renderCell: (item) => {
          return [
            item.row.isActive ? (
              <Typography className="typography-status">
                <span className="span-status">
                  <span
                    className="span-text-status"
                    style={{
                      background:
                        "linear-gradient(310deg, rgb(45, 206, 137), rgb(45, 206, 204))",
                      color: "#fff",
                    }}
                  >
                    {t('active')}
                  </span>
                </span>
              </Typography>
            ) : (
              <Typography className="typography-status">
                <span className="span-status">
                  <span
                    className="span-text-status"
                    style={{
                      background: "linear-gradient(310deg, #B4324A, #DA5A73)",
                      color: "#fff",
                    }}
                  >
                    {t('inactive')}
                  </span>
                </span>
              </Typography>
            ),
          ];
        },
      },
    ];

    const goToEdit = (sID: string) => {
        navigate(`/LanguageForm?sID=${encodeURI(sID)}`);
    };

    const objSchema = yup.object().shape({
        DatePickerForm: yupFormSchemas.date("วันที่", { required: true }),
    });

    const form = useForm({
        resolver: yupResolver(objSchema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const onDelete = (lstID) => {
        console.log("lstID ", lstID);
        let param = {
            lstData: dataRow.arrRows,
            lstID: lstID
        };
        SwAlert.Confirm(t("confirmation"), t("deletemsg"), () => {
            AxiosPost("LanguageTranslation/OnDeleteLanguage", param, (res) => {
                window.location.reload();
            }, (err) => {
                if (!err.response) {
                    SwAlert.Warning("Warning", err.Message, () => { })
                }
            })
        })
    };

    return (<div>
        <PopupCreateLanguage
            open={openPopup}
            handleClose={handleClosePopup}
        />

        <Container maxWidth={false}>
            <ContentWrapper>
                
                <FormProvider {...form}>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                        <Grid item md={12}>
                            <BtnAddWithIcon txt={t('NewLanguage')} onClick={() => { setopenPopup(true) }} />
                        </Grid>
                        
                        <Grid item md={12}>
                            <DataGrid
                                isLoading={loadingTable}
                                columns={dataColumn}
                                rows={dataRow}
                                onLoadData={(e) => {
                                    GetData(e);
                                }}
                                isNotShowPagination={false}
                                isNotShowTotal={true}
                                isShowCheckBox={true}
                                onCickRow={() => { }}
                                modeFilter={1}
                                onDelete={(d) => onDelete(d)}
                                customFilterComponent={
                                    <FormProvider {...form}>
                                        <LanguageFilter
                                            onSearch={(e) => GetData(dataRow)}
                                            onClear={() => OnClearFilter()}
                                        />
                                    </FormProvider>
                                }
                            />
                        </Grid>
                    </Grid>
                </FormProvider>
            </ContentWrapper>
        </Container>
    </div>)
};
export default LanguageList;