import  { useEffect, useState } from "react";
import { AxiosGet } from "utilities/ST_Axios";
import parse from 'html-react-parser';
import { enumCMSCode } from 'enum/enumSystem2';

export default function PrivacyNotice() {
  const [contents, setcontents] = useState("");
  useEffect(() => {
    AxiosGet("Utility2/GetCMS", {sCMSCode:enumCMSCode.PrivacyNotice.toString()}, (res) => {
      setcontents(res.sContents);
    });
  },[]);
  
  return (<div className="Cms-Content">{parse(contents)}</div>);


}