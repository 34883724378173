
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import yupFormSchemas from 'STM/components/FormItem/yupFormSchemas';
import { Box, Container, Divider, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';
import { TextBoxNoForm } from 'STM/components/Input';
import { FnBlock_UI } from 'STM/components/BlockUI';
import { AxiosPost } from 'STM/utilities/ST_Axios';
import { useLocation, useNavigate } from 'react-router-dom';
import DataGrid, { PaginationInterface, initRows } from 'STM/components/DataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import MenuIcon from '@mui/icons-material/Menu';
import { BtnBack, BtnEditOnTable, BtnPreview, BtnSave } from 'STM/components/Button';
import ListIcon from '@mui/icons-material/List';
import { SwAlert } from 'STM/components/Alert';
import { useTranslation } from 'react-i18next';
import DialogPermission from "STM/components/Dialog/DialogPermission";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { FilterFieldInterface } from 'STM/components/DataGrid/DataGridProps';
import FilterUserRole from './FilterUserRole';
import { dataColumnStatus, dataColumnUpdate } from 'STM/components/DataGrid/DataGridCol/DataGridColumn';
import moment from 'moment';
import { FnDialog } from 'STM/utilities/ST_Function';


const UserRoleList = () => {
  const { t, i18n } = useTranslation();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const DialogFn = FnDialog();
  const [nPermission, setnPermission] = useState(2);
  const [loadingTable, setLoadingTable] = useState(false);
  const [lstPermission, setlstPermission] = useState([]);
  const [openPopup, setopenPopup] = useState(false);
  const [isShow, setisShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "dUpdate",
    sSortDirection: "desc"
  });

  const objSchema = yup.object().shape({
  });

  const form = useForm({
    resolver: yupResolver(objSchema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  useEffect(() => {
    onGetData(dataRow)
  }, []);

  const goToEdit = (sID: string) => {
    navigate(`/UserRoleForm?sID=${encodeURI(sID)}`);
  };

  const dataColumn: GridColumns = [
    {
      // renderHeader: (item) => "",
      headerName: t('action'),
      field: "Button (Add/Edit)",
      type: "actions",
      resizable: false,
      sortable: false,
      width: 70,
      getActions: (item) => {
        return [
          nPermission === 2 ? (
            <BtnEditOnTable txt={t('edit')} onClick={() => goToEdit(item.row.sID)} />
          ) : (
            <BtnPreview txt={t('view')} onClick={() => goToEdit(item.row.sID)} />
          ),
        ];
      },
    },
    {
      field: "sUserRoleID",
      headerName: t("no"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 100,
      renderCell: (item) => {
        return [
          <> {item.value}</>
        ];
      },
    },
    {
      field: "sUserRoleName",
      headerName: t("userrolename"),
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 2,
    },
    {
      field: "nPermission",
      headerName: t("permission"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 110,
      renderCell: (item) => {
        return [
          <>
            <Typography>{item.row.nPermission}</Typography>
            <ListIcon onClick={() => { onOpenPopup(item.row.lstPermission) }} sx={{ fontSize: "1.2rem", ml: 0.3, color: "rgb(52, 71, 103)", cursor: "pointer" }} />
          </>
        ];
      },
    },
    {
      field: "sUpdateDate",
      headerName: t("lastupdate"),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 160,
      renderCell: (item) => {
        return [
          <Box
            key={item.row.sID}
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: "0.8rem" }}>
                {moment(item.row.sUpdateDate, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY").toString()}
              </Typography>

              <Tooltip title={<span style={{ textAlign: "center" }}>{[item.row.sUpdateDate, <br key={item.row.sID} />, item.row.sCreateBy]}</span>}>
                <AccessTimeIcon
                  sx={{
                    fontSize: "1.2rem",
                    ml: 1,
                    color: "rgb(45, 206, 137)",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Box>
          </Box>,
        ];
      },
    },
    {
      field: "isActive",
      headerName: t('status'),
      headerAlign: "center",
      editable: false,
      resizable: false,
      align: "center",
      sortable: true,
      width: 100,
      renderCell: (item) => {
        return [
          item.row.isActive ? (
            <Typography className="typography-status">
              <span className="span-status">
                <span
                  className="span-text-status"
                  style={{
                    background:
                      "linear-gradient(310deg, rgb(45, 206, 137), rgb(45, 206, 204))",
                    color: "#fff",
                  }}
                >
                  {t('active')}
                </span>
              </span>
            </Typography>
          ) : (
            <Typography className="typography-status">
              <span className="span-status">
                <span
                  className="span-text-status"
                  style={{
                    background: "linear-gradient(310deg, #B4324A, #DA5A73)",
                    color: "#fff",
                  }}
                >
                  {t('inactive')}
                </span>
              </span>
            </Typography>
          ),
        ];
      },
    },
  ];

  const onGetData = (p) => {
    onGetDataTable(p).then(
      () => { DialogFn.UnBlockUI() },
      () => { }
    );
  }

  const onGetDataTable = async (p) => {
    let param = {
      ...p,
      sUserRoleName: form.getValues("sUserRoleName"),
      sStatus: form.getValues("sStatus"),
      sUpdate: form.getValues("sUpdateDate") ? moment(form.getValues("sUpdateDate")).format("DD/MM/YYYY") : null,
    };
    DialogFn.BlockUI();
    setLoadingTable(true);
    await AxiosPost("UserRole/GetUserRoleList", param, (res) => {
      setLoadingTable(false)
      console.log("res ", res);
      setDataRow({
        ...p,
        arrRows: res.lstData || [],
        nDataLength: res.nDataLength,
        nPageIndex: res.nPageIndex,
      });
    });
  };

  const onOpenPopup = (item) => {
    setopenPopup(true);
    setlstPermission(item)
  };

  const handleClosePopup = () => {
    setopenPopup(false);
    setlstPermission([])
  };

  const onDelete = (lstUserRoleID) => {
    DialogFn.Submit(t('deletemsg'), async () => {
      DialogFn.BlockUI();
      setLoadingTable(true);
      await AxiosPost("UserRole/Delete", lstUserRoleID, (res) => {
        onGetDataTable(dataRow).then(
          () => {
            DialogFn.Success(t('deletesuccess'));
            DialogFn.UnBlockUI();
          },
          () => { }
        );
      }, (err) => {
        if (!err.response) {
          DialogFn.Warning(err.Message);
        }
        DialogFn.UnBlockUI();
      });
    });
  };

  const onClearFilter = () => {
    form.setValue("sUserRoleName", "")
    form.setValue("sStatus", "")
    form.setValue("dUpdate", null)
    form.reset();
    onGetData(dataRow)
  }

  return (
    <Grid container sx={{ boxSizing: "border-box", display: "flex", flexFlow: "row wrap", marginTop: "24px", width: "calc(100% - 24px)", justifyContent: "center", }}>
      <DialogPermission
        open={openPopup}
        onClose={handleClosePopup}
        lstPerm={lstPermission}
      />
      <Grid item xs={12}>
        <Grid item xs={12} className="Card-form">
          <Grid item xs={12} sx={{ color: "rgb(52, 71, 103)", }} className="Header-grid-form">
            <Grid item xs={12}>
              <DataGrid
                isLoading={loadingTable}
                columns={dataColumn}
                rows={dataRow}
                onLoadData={(p) => { onGetData(p) }}
                isNotShowPagination={false}
                isNotShowTotal={true}
                isShowCheckBox={true}
                onCickRow={() => { }}
                modeFilter={1}
                onDelete={(d) => onDelete(d)}
                isAddData
                linkAdd={"/UserRoleForm"}
                customFilterComponent={
                  <FormProvider {...form}>
                    <FilterUserRole onClear={onClearFilter} onSearch={() => onGetData(dataRow)} />
                  </FormProvider>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};
export default UserRoleList;