import { useEffect, useState } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";

import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Box, Grid, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import "./Network.css";

import AOS from 'aos';
import 'aos/dist/aos.css';

import { useTranslation } from "react-i18next";
import { GetInitialInfo } from "utilities/ST_Function";
import { NetworkContent } from "./NetworkContent/NetworkContent";
import { AxiosGet } from "utilities/ST_Axios";
import { SetSeoMenuToHtml } from "STM/utilities/ST_Function";


function NetworkMap() {
  useEffect(()=>{
    SetSeoMenuToHtml();
  },[]);
  const [pageID, setPageID] = useState("default");
  const [content, setContent] = useState("");
  const [dataMap, setDataMap] = useState([]);
  const [defaultDataMap, setDefaultDataMap] = useState("");
  const { t } = useTranslation();

  const info = GetInitialInfo() as any;
  am4core.addLicense(info.amchartsKey);

  const LoadData = () => {
    NewWorksMap2();
  };

  useEffect(()=>{
        LoadData();
  }, [dataMap]);
  useEffect(()=>{
    AxiosGet("Utility2/GetMapInfo",{}, (res) => {
        setDataMap(res.mapPointModels);
        setDefaultDataMap(res.defaultData.content);
    })
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      disabled:'mobile'
    });
  }, []);


  const NewWorksMap2 = () => {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("chartdiv", am4maps.MapChart);
    chart.projection = new am4maps.projections.Mercator();
    chart.responsive.enabled = true;

    // Shared
    let mapColorHex = "#4682B4";
    let mapColor = am4core.color(mapColorHex);
    let hoverColorHex = "#9a7bca";
    let hoverColor = am4core.color(hoverColorHex);

    let originImageSeries = chart.series.push(new am4maps.MapImageSeries());
    originImageSeries.zIndex = 10;
    originImageSeries.tooltip.getFillFromObject = false;
    originImageSeries.tooltip.background.fill = am4core.color("#757575");
    let originImageTemplate = originImageSeries.mapImages.template;

    originImageTemplate.propertyFields.latitude = "latitude";
    originImageTemplate.propertyFields.longitude = "longitude";
    originImageTemplate.propertyFields.id = "id";

    originImageTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    originImageTemplate.nonScaling = true;
    //// originImageTemplate.tooltipText = "{title}";

    //// originImageTemplate.setStateOnChildren = true;
    //// originImageTemplate.states.create("hover");

    originImageTemplate.horizontalCenter = "middle";
    originImageTemplate.verticalCenter = "middle";

    //setting pin
    let pin = originImageTemplate.createChild(am4plugins_bullets.PinBullet);
    pin.isMeasured = true;
    pin.tooltipText = "{title}";
    originImageSeries.tooltip.pointerOrientation = "left";
    pin.background.fill = am4core.color("#fff");
    //// pin.background.stroke = am4core.color("#000");
    //// pin.background.strokeWidth = 2;
    ////pin.background.fillOpacity = 0.7;
    pin.background.pointerAngle = 90;
    pin.background.pointerLength = 30;
    pin.background.pointerBaseWidth = 50;
    ////pin.background.radius = 30;
    pin.scale = 0.5;

    pin.image = new am4core.Image();
    pin.image.propertyFields.href = "imageURL";

    originImageTemplate.events.on("hit", function (event) {
      ////console.log("event", event.target.dataItem.dataContext);
      let data: any = event.target.dataItem.dataContext;
      setPageID(data.id);
      setContent(data.content);
    });

    originImageSeries.data = dataMap;

    let countriesSeries = chart.series.push(new am4maps.MapPolygonSeries());
    let countries = countriesSeries.mapPolygons;
    countriesSeries.visible = false; // start off as hidden
    countriesSeries.exclude = ["AQ"];
    countriesSeries.geodata = am4geodata_worldLow;
    countriesSeries.useGeodata = true;

    let countryTemplate = countries.template;
    countryTemplate.applyOnClones = true;
    //// countryTemplate.tooltipText = "{name}";
    //// countryTemplate.fill = am4core.color("#003366");
    countryTemplate.fill = am4core.color("#c9e2fa");
    //// countryTemplate.fill = am4core.color("#5f9fdf");
    countryTemplate.fillOpacity = 1; // see continents underneath, however, country shapes are more detailed than continents.
    countryTemplate.strokeOpacity = 0;

    let as = countryTemplate.states.create("active");
    //// as.properties.fill = am4core.color("#fff");
    as.properties.fill = am4core.color("#4da7e3");

    let hs = countryTemplate.states.create("hover");
hs.properties.fill = am4core.color("#4da7e3");

    function highlightCountry(event) {
      let id: string = event.id;
      let code = countriesSeries.getPolygonById(id.toUpperCase());
      code.isActive = true;
    }

    function removeHighlight(event) {
      let id: string = event.id;
      let code = countriesSeries.getPolygonById(id.toUpperCase());
      code.isActive = false;
    }

    pin.events.on("over", function (event) {
      let data: any = event.target.dataItem.dataContext;
      highlightCountry(data);
    });

    pin.events.on("out", function (event) {
      let data: any = event.target.dataItem.dataContext;
      removeHighlight(data);
    });

    let ButtonZoomContainer = chart.createChild(am4core.Container);
    // ButtonZoomContainer.isMeasured = false;
    ButtonZoomContainer.x = 120;
    ButtonZoomContainer.y = 5;
    ButtonZoomContainer.layout = "vertical";
    ButtonZoomContainer.align = "right";

    let button = ButtonZoomContainer.createChild(am4core.Button);
    button.padding(5, 5, 5, 5);
    button.align = "right";
    button.marginRight = 15;
    button.events.on("hit", function () {
        chart.goHome();
    });
    button.icon = new am4core.Sprite();
    button.icon.path = "M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z";
    button.icon.width = 24;
    button.icon.height = 24;
    button.icon.scale = 0.8;
  };

  const frontURL = process.env.REACT_APP_Front_URL;

  return (
    <Grid container className="network-container fixed-height-network">
      <Grid
        item
        md={7}
        xs={12}
        className="network-map-area"
        sx={{
          // backgroundImage: `url(${frontURL + "images/networkbg.png"})`,
          background : "linear-gradient(104deg, rgba(0,51,102,1) 8%, rgba(34,78,122,1) 51%, rgba(65,102,140,1) 88%)"
        }}
      >
        <Box className="box-title-map">
          <Typography className="text-title-map">PTTGL</Typography>
          <Typography className="text-title-map">LNG Portfolio</Typography>
          {/* <Typography className="text-title-map">MAP</Typography> */}
        </Box>
        <div id="chartdiv" style={{ width: "100%", height: "100%" }}></div>
      </Grid>
      <Grid item md={5} xs={12} className="network-detail-area">
        {pageID !== "default" && (
          <Box
            onClick={() => {
              setPageID("default");
              NewWorksMap2();
            }}
            className="network-close-icon"
          >
            <Close
              sx={{
                fontSize: "1.5rem",
                alignSelf: "center",
                color: "rgb(92 101 106)",
              }}
            />
          </Box>
        )}
        {pageID === "default" && (
            <NetworkContent contentHtml={defaultDataMap} key={pageID} />
        )}
        {pageID !== "default" && (
        <NetworkContent contentHtml={content} key={pageID} />
        )}
      </Grid>
      {/* <div id="chartdiv" style={{ width: "100%", height: "calc(100vw * 9 / 16)", maxHeight: "calc(100vh - 64px)" }}></div> */}
    </Grid>
  );
}

export default NetworkMap;
