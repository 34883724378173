import { AxiosGet, AxiosPost } from "STM/utilities/ST_Axios";

export const Save = async (objJSON, fnSuccess, fnError) => {
    await AxiosPost("News/OnSave", objJSON, (res) => {
        fnSuccess(res);
    },(err) => {fnError(err)});
}

export const GetData = async (objJSON, fnSuccess, fnError) => {
    await AxiosPost("News/UpdateDataNews", objJSON, (res) => {
        fnSuccess(res);
    },(err) => {fnError(err)});
}

export const GetDataTable = async (objJSON, fnSuccess, fnError) => {
    await AxiosPost("News/GetList", objJSON, (res) => {
        fnSuccess(res);
    },(err) => {fnError(err)});
}
export const GetOrder = async (objJSON, fnSuccess, fnError) => {
    await AxiosPost("News/ChangeOrder", objJSON, (res) => {
        fnSuccess(res);
    },(err) => {fnError(err)});
}

export const ChangePin = async (objJSON, fnSuccess, fnError) => {
    await AxiosPost("News/ChangePin", objJSON, (res) => {
        fnSuccess(res);
    },(err) => {fnError(err)});
}
export const Delete = async (objJSON, fnSuccess, fnError) => {
    await AxiosPost("News/OnDelete", objJSON, (res) => {
        fnSuccess(res);
    },(err) => {fnError(err)});
}
export const GetContentNews = async (objJSON, fnSuccess, fnError) => {
    await AxiosGet("News/GetContentNews", objJSON, (res) => {
        fnSuccess(res);
    },(err) => {fnError(err)});
}