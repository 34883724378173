
import "view/UnderConstraction/UnderConstraction2.css";
///libraby MUI
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Grid } from '@mui/material';



export default function UnderConstraction2(prop) {
   const { open, handleClose } = prop;

   const LogoUrl = "ptt-logo.png";
   const UnderConstractionUrl = "images/underConstruction.png";
   const ParthLogo =  `${process.env.REACT_APP_Front_URL}${LogoUrl}`;
   const ParthUnderConstraction = `${process.env.REACT_APP_Front_URL}${UnderConstractionUrl}`;

   return (
      
         <Dialog maxWidth="md" open={open} onClose={handleClose}>
            <DialogActions sx={{ paddingBottom: "0px" }}>
               <IconButton aria-label="delete" size="large" onClick={handleClose}>
                  <CloseIcon fontSize="inherit" />
               </IconButton>
            </DialogActions>
            <DialogContent sx={{ paddingTop: "0" }}>
               <Grid container flexDirection="column" justifyContent="center">
                  <img className="underconstract-ptt-logo" src={ParthLogo}></img>
                  <Box className="underconstract-title">under Construction</Box>
                  <img className="underconstract-Image" src={ParthUnderConstraction} ></img>
               </Grid>
            </DialogContent>
         </Dialog>
      
   )
}
