import { useEffect, useState } from 'react';
import Slider from "react-slick";
import "../banner/banner.css";
import { Box } from "@mui/system";
import { AxiosGet } from 'utilities/ST_Axios';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = ({ setBannerBeforeChangeID, setBannerAfterChangeID, setBannerRender }) => {
    const [bannerData, setBannerData] = useState([]);
    useEffect(() => {
    }, []);

    const frontURL = process.env.REACT_APP_Front_URL;

    const settings = {
        dots: true,
        infinite: true,
        fade: true,
        speed: 2000,
        autoplaySpeed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: true,
        beforeChange: function (index) {
            setBannerBeforeChangeID(index);
            setBannerRender("");
        },
        afterChange: function (index) {
            setBannerAfterChangeID(index);
            setBannerRender("blur-fadein");
        }
    };

    // // const BannerJson = [
    // //     {
    // //         "sID": "1",
    // //         "topic": "PTT Global LNG",
    // //         "content": "A subsidiary of PTT with an ambition to be a flagship of PTT Group in the LNG business",
    // //         "img": frontURL + "banner/banner1.png"
    // //     },
    // //     {
    // //         "sID": "2",
    // //         "topic": "LNG Portfolio Player",
    // //         "content": "building LNG portfolio to strengthen Thailand energy security with flexibility",
    // //         "img": frontURL + "banner/banner2.png"
    // //     },
    // //     {
    // //         "sID": "3",
    // //         "topic": "Investment in LNG Value Chain",
    // //         "content": "creating sustainable revenue streams through LNG value chain",
    // //         "img": frontURL + "banner/banner3.png"
    // //     }
    // // ]

    useEffect(() => {
        AxiosGet("Utility2/GetHomeInfo", {}, (res) => {
            setBannerData(res.bannerInfoList);
        });
    }, []);

    return (
        <div className="DivBanner">
            <Slider {...settings}>
                {
                    bannerData.map((item) =>
                        <Box className="ImgBanner" key={item.sID} sx={{ backgroundImage: `url(${item.img})` }}>
                        </Box>
                    )
                    // <Box className="ImgBanner" sx={{ backgroundImage: `url(${frontURL + "banner/banner1.png"})` }}>
                    // </Box>

                }
            </Slider>
        </div>
    )

}
export default Banner;


