import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ImageIcon from "@mui/icons-material/Image";
import FilePopup from "../PopUp/FilePopup";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";
import { BsFillFileEarmarkWordFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { SiMicrosoftpowerpoint } from "react-icons/si";
import { Extension } from "STM/utilities/ST_Function";
import { AlertTitle, AlertMsg } from 'config/AlertMsgConfig'
import { SwAlert } from 'STM/components/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const ItemRow = (props) => {

  const classes = useStyles();
  const [IsopenPopUp, setIsopenPopUp] = useState(false);


  const ClosePopUp = () => {
    setIsopenPopUp(false);
  };
  const OpenPopUp = () => {
    props.onOpenFile && props.onOpenFile(props.nFile_ID);
    setIsopenPopUp(true);
  };

  const onDownload = () => {
    let a = document.createElement("a");
    a.href = props.sCropFileLink + "";
    a.download = props.sFileName;
    a.click();
  };

  const onClickFile = () => {
    props.onOpenFile && props.onOpenFile(props.nFile_ID);
    let isCheck = Extension.Image.indexOf(props.sFileType.toLowerCase()) > -1 || Extension.Video.indexOf(props.sFileType.toLowerCase()) > -1 || Extension.PDF.indexOf(props.sFileType.toLowerCase()) > -1;
    
    isCheck ? OpenPopUp() : onDownload()
  };

  console.log("props Uploadfile",props);
  

  return (
    <Fragment>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={0.5}
        className={classes.FileItem}
        style={{position:'relative'}}
      >
        <Grid item >
          {props.IsCompleted && !props.disabled && !props.IsHiddenUploadBox ? (
            <Tooltip title="ลบ" placement="top">
              <IconButton
                // className={classes.DangerColor}
                className={"btn-icon-delete"}
                size="small"
                style={{
                  padding: '2px'
                }}
                onClick={() => {
                    props.onDelete(props.nFile_ID);
                }}
              >
                <DeleteForeverIcon className={"icon-delete"}/>
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item className={classes.ColumnThumbnail}>
          {DisplaySubFile(props, classes, onClickFile)}
        </Grid>
        <Grid item xs className={classes.ColumnTitle} onClick={onClickFile}>
          <Typography className={classes.Title}>{props.sFileName}</Typography>
        </Grid>
        {
          props.sProgress === "100" ?
          <Grid item >
              <CheckCircleIcon className="icon-pass" />
          </Grid>
          :
            <Grid item xs={12} >
              <Box display="flex" alignItems="center" style={{ display: props.IsHiddenUploadBox && (props.sProgress === "100" || props.sProgress === undefined) ? 'none' : 'block' }}>
                <Box width="100%" mr={1}>
                  <LinearProgress
                    variant="determinate"
                    value={parseInt(props.sProgress) || 100}
                  />
                </Box>
                <Box minWidth={40}>
                  <Typography variant="body2" color="textSecondary">
                    {props.sProgress
                      ? props.sProgress + "%"
                      : props.IsCompleted
                        ? "100%"
                        : ""}
                  </Typography>
                </Box>
              </Box>
            </Grid>
        }
      </Grid>
          <FilePopup
            file={props}
            open={IsopenPopUp}
            ClosePopUp={ClosePopUp}
            IsCrop={props.IsCrop}
            setStartVideoOn={props.setStartVideoOn}
            nStartVideoOn={props.nStartVideoOn}
            CannotSkipForward={props.CannotSkipForward}
            onVideoEnd={props.onVideoEnd}
            onDelete={props.onDelete}
          />
    </Fragment>
  );
};

export default ItemRow;

const useStyles = makeStyles((theme: Theme) => ({
  WordColor: {
    color: "#fff !important",
    backgroundColor: "#2372ba !important",
    borderColor: "#2372ba !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(56, 151, 234) !important",
    },
  },
  ExcelColor: {
    color: "#fff !important",
    backgroundColor: "#14a73c !important",
    borderColor: "#14a73c !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(66, 201, 127) !important",
    },
  },
  PDFColor: {
    color: "#fff !important",
    backgroundColor: "#ff0000 !important",
    borderColor: "#ff0000 !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(255, 79, 79) !important",
    },
  },
  PowerPointColor: {
    color: "#fff !important",
    backgroundColor: "#f26522 !important",
    borderColor: "#f26522 !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(255, 79, 79) !important",
    },
  },

  FileItem: {
    margin: "0 !important",
    animationDuration: ".6s !important",
  },
  ColumnThumbnail: {
    paddingLeft: "10px !important",
  },
  ColumnTitle: {
    flex: "1 !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
    color: "#74809d !important",
    overflow: "hidden !important",
    backgroundColor: "#fffff !important",
    cursor: "pointer !important",
  },
  ColumnAction: {
    margin: "0 16px",
  },
  Title: {
    fontWeight: 600,
  },
  DangerColor: {
    color: "#fff !important",
    backgroundColor: "#ed3847 !important",
    borderColor: "#ed3847 !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(220, 53, 69) !important",
    },
  },
  WarningColor: {
    color: "#fff !important",
    backgroundColor: "#ffce3d !important",
    borderColor: "#ffce3d !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(252, 189, 0) !important",
    },
  },
  IConColor: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}));
 function DisplaySubFile(props: any, classes, onClickFile: () => void) {
  return <>
    {Extension.Image.indexOf(props.sFileType.toLowerCase()) > -1 ? (
      <Fragment>
        { props.sCropFileLink ? (
          <img src={props.sCropFileLink.search("http") > -1 ? props.sCropFileLink : process.env.REACT_APP_API_URL + props.sCropFileLink || ""} alt="" width={30} height={30} style={{ borderRadius: '6px' }} />
        ) : (
          <Tooltip title="">
            <IconButton
              className={classes.WordColor}
              size="small"
              onClick={onClickFile}
            >
              <ImageIcon className={classes.IConColor} />
            </IconButton>
          </Tooltip>
        )}
      </Fragment>
    ) : Extension.Video.indexOf(props.sFileType.toLowerCase()) > -1 ? (
      <Tooltip title="">
        <IconButton
          className={classes.WordColor}
          size="small"
          onClick={onClickFile}
        >
          <VideoLibraryIcon className={classes.IConColor} />
        </IconButton>
      </Tooltip>
    ) : Extension.PDF.indexOf(props.sFileType.toLowerCase()) > -1 ? (
      <Tooltip title="">
        <IconButton
          className={classes.PDFColor}
          size="small"
          onClick={onClickFile}
        >
          <PictureAsPdfIcon className={classes.IConColor} />
        </IconButton>
      </Tooltip>
    ) : Extension.Word.indexOf(props.sFileType.toLowerCase()) > -1 ? (
      <Tooltip title="">
        <IconButton
          className={classes.WordColor}
          size="small"
          onClick={onClickFile}
        >
          <BsFillFileEarmarkWordFill className={classes.IConColor} />
        </IconButton>
      </Tooltip>
    ) : Extension.Excel.indexOf(props.sFileType.toLowerCase()) > -1 ? (
      <Tooltip title="">
        <IconButton
          className={classes.ExcelColor}
          size="small"
          onClick={onClickFile}
        >
          <SiMicrosoftexcel className={classes.IConColor} />
        </IconButton>
      </Tooltip>
    ) : Extension.PowerPoint.indexOf(props.sFileType.toLowerCase()) > -1 ? (
      <Tooltip title="">
        <IconButton
          className={classes.PowerPointColor}
          size="small"
          onClick={onClickFile}
        >
          <SiMicrosoftpowerpoint className={classes.IConColor} />
        </IconButton>
      </Tooltip>
    ) : Extension.txt.indexOf(props.sFileType.toLowerCase()) > -1 ? (
      <Tooltip title="">
        <IconButton
          className={classes.WordColor}
          size="small"
          onClick={onClickFile}
        >
          <DownloadIcon className={classes.IConColor} />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title="">
        <IconButton
          className={classes.WordColor}
          size="small"
          onClick={onClickFile}
        >
          <DownloadIcon className={classes.IConColor} />
        </IconButton>
      </Tooltip>
    )}
  </>;
}

