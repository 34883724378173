import { useState, forwardRef, useCallback, useEffect, Fragment } from "react";
import { FormControl, FormHelperText, IconButton, InputAdornment, Stack, TextField, useMediaQuery, } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import "moment/locale/th";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { language } from "config/AppConfig";
import { DatePickerFromProps, DatePickerNoFromProps, DateRangePickerFromprop, DateRangePickerProFromProps } from "./DateTimePickerProps";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AiOutlineMinus } from "react-icons/ai";
import { RxValue } from "react-icons/rx";
// import { DateRangePicker } from "@mui/lab";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { Event } from "@mui/icons-material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
const localeMap = (language) => {
    switch (language) {
        case "th":
            moment.locale('th');
            break;
        case "en":
            moment.locale('en');
            break;
        default:
            break;
    }
};

/** class CustomAdapter */
class CustomAdapter extends AdapterMoment {
    constructor({ locale, formats, instance }) {
        super({ locale, formats, instance });
    }

    calYear(moment) {
        switch (this.locale) {
            case "th":
                return (parseInt(moment.format('YYYY')) + 543).toString();
            // return (parseInt(moment.format('YYYY'))).toString();
            case "en":
                return parseInt(moment.format('YYYY'));
            default:
                return parseInt(moment.format('YYYY'));
        }
    }

    renderLabel(moment, format) {
        switch (format) {
            case "year":
                return this.calYear(moment);
            case "month":
                return moment.format("MMMM");
            case "fullDate":
                return moment.format("DDMMYYYY");
            case "dayOfMonth":
                return moment.format("D");
            case "monthShort":
                return moment.format("MMMM");
            case "monthAndYear":
                return `${moment.format("MMMM ")} ${this.calYear(moment)}`;
            case "normalDate":
                return `${moment.format("dd")} ${moment.format("MMM")} ${moment.format("D")}`;
            default:
                return moment.format(format);
        }
    }
    startOfMonth = (date) => date ? date.clone().startOf("month") : moment(new Date());

    format = (date, formatKey) => {
        return date ? this.renderLabel(date, formatKey) : moment(new Date());
    };
}

export const DatePickerFromItem = forwardRef((props: DatePickerFromProps, ref) => {

    const { name, fullWidth = false, format = "DD/MM/YYYY", isMessageError } = props;
    const minDate = props.minDate ?? null;
    const maxDate = props.maxDate ?? null;
    const required = props.required ?? false;
    const disabled = props.disabled ?? false;
    const view = props.view ?? ['year', 'month', 'day'];
    const { control, } = useFormContext();
    const formatDefalut = format;
    const [open, setOpen] = useState(false);
    const [locale, setLocale] = useState(language);

    /** funtion */

    const ReplaceThFormat = (text) => {
        return text ? text.toLowerCase().replaceAll("d", "ว").replaceAll("m", "ด").replaceAll("y", "ป") : "";
    }

    const maskMap = {
        en: formatDefalut,
        th: ReplaceThFormat(formatDefalut),
    };

    const [objCheckReplace] = useState(() => {
        localeMap(locale);
        let objReturn = { isReplaceYear: false, indexArr: 0, splitText: "" };
        let formatLower = formatDefalut ? formatDefalut.toLowerCase() : "";
        if (formatLower.includes("YYYY")) {
            let splitText = (formatDefalut.substr(formatLower.indexOf("YYYY") - 1, 1)).toLowerCase();
            if (splitText !== "Y") {
                objReturn.splitText = splitText;
                objReturn.indexArr = formatLower.split(splitText).findIndex((e) => e === "YYYY");
            }
            objReturn.isReplaceYear = true;
        }
        return objReturn;
    });

    const getmoment = useCallback((params, value) => {
        if (locale === "th") {
            if (value != null && objCheckReplace.isReplaceYear && format.length === 10) {
                let convertDateToString: string = moment(value).format(format);
                let year = objCheckReplace.splitText ? convertDateToString.split(objCheckReplace.splitText)[objCheckReplace.indexArr] : convertDateToString.substr(0, 4);
                let addyear = parseInt(year) > 2500 ? parseInt(year) : parseInt(year) + 543;
                let replaceAllFormat = format.replaceAll("YYYY", "");
                params.inputProps.value = moment(value).format(replaceAllFormat) + addyear;
            }
            else if (format === "YYYY" && value != null && objCheckReplace.isReplaceYear) {
                let convertDateToString: string = moment(value).format(format);
                let addyear = parseInt(convertDateToString) > 2500 ? parseInt(convertDateToString) : parseInt(convertDateToString) + 543;
                params.inputProps.value = addyear.toString();

            }
            else if (format === "MM/YYYY" && value != null && objCheckReplace.isReplaceYear) {
                let convertDateToString: string = moment(value).format(format);
                let year = objCheckReplace.splitText ? convertDateToString.split(objCheckReplace.splitText)[1] : convertDateToString.substr(0, 2);
                let addyear = parseInt(year) > 2500 ? parseInt(year) : parseInt(year) + 543;
                params.inputProps.value = convertDateToString.replaceAll(`${year}`, `${addyear}`);
            }
        }
    }, [])

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, error }, }) => (
                <LocalizationProvider
                    locale={language}
                    dateAdapter={CustomAdapter}
                >
                    <FormControl
                        fullWidth={fullWidth}
                        sx={{
                            "label.MuiInputLabel-shrink": {
                                backgroundColor: "rgba(255,255,255,0.95)",
                                top: "0px",
                            },
                            ".MuiInputLabel-outlined": {
                                top: "0px",
                            },
                            ".MuiInputLabel-asterisk": {
                                color: "red"
                            }
                        }}
                    >
                        <DatePicker
                            key={name}
                            dayOfWeekFormatter={(day) => day.charAt(0).toUpperCase() === "เ" ? "ส" : day.charAt(0).toUpperCase()}
                            inputRef={ref}
                            value={value ? moment(value, formatDefalut) : null}
                            onChange={(e, key) => {
                                let s = moment(e).isValid();
                                if (s !== false) {
                                    let dInfo: Date = e;
                                    onChange(dInfo);
                                    props.onChange && props.onChange(dInfo);
                                }
                                else {
                                    onChange(null);
                                    props.onChange && props.onChange(null);
                                }
                            }}
                            open={open}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            minDate={minDate}
                            maxDate={maxDate}
                            label={props.label}
                            inputFormat={formatDefalut}
                            disabled={disabled}
                            views={view}
                            showToolbar={false}
                            DialogProps={{ sx: { '& .PrivateDatePickerToolbar-penIcon,& .MuiPickersToolbar-penIconButton': { display: 'none' } } }}
                            renderInput={(params) => {
                                params.inputProps.placeholder = maskMap[locale];
                                getmoment(params, value);
                                
                                return (
                                    <TextField
                                        {...params}
                                        sx={{
                                            ".MuiOutlinedInput-root": {
                                                padding: "0px 15px 0px 0px!important",
                                                " fieldset > legend > span": {
                                                    padding: "0px !important",
                                                },
                                            },
                                            ".MuiInputBase-inputSizeSmall":{
                                                padding:"8.5px"
                                              },
                                            fontWeight: 600,
                                        }}
                                        size={"small"}
                                        label={
                                          props.shrink && props.shrink === true ? props.label : ""
                                        }
                                        placeholder={!props.shrink ? props.label : ""}
                                        name={name}
                                        error={error?.message != null}
                                        required={required}
                                        disabled={disabled}
                                        // value={value || ""}
                                        autoComplete={"off"}
                                        onClick={(e) => {
                                            if(!disabled){
                                                setOpen(true)
                                            }
                                        }}
                                        // inputProps={{
                                        //     ...params.inputProps,
                                        //     placeholder: "tt.mm.jjjj"
                                        //   }}
                                    />
                                )
                            }}
                        />
                        {error ? (
                            <FormHelperText sx={{ color: "red",ml:0 }}>
                                {error.message}
                            </FormHelperText>
                        ) : null}
                    </FormControl>
                </LocalizationProvider>
            )
            }
        />
    );

});

export default DatePickerFromItem;

export const DatePickerNOFromItem = forwardRef((props: DatePickerNoFromProps, ref) => {

    const { id, fullWidth = false, format = "DD/MM/YYYY" } = props;
    const minDate = props.minDate ?? null;
    const maxDate = props.maxDate ?? null;
    const disabled = props.disabled ?? false;
    const view = props.view ?? ['year', 'month', 'day'];
    const { control, } = useFormContext();
    const formatDefalut = format;
    const [open, setOpen] = useState(false);
    const [locale, setLocale] = useState(language);
    const [value, setValue] = useState<string>(props.value);

    /** funtion */

    const ReplaceThFormat = (text) => {
        return text ? text.toLowerCase().replaceAll("d", "ว").replaceAll("m", "ด").replaceAll("y", "ป") : "";
    }

    const maskMap = {
        en: formatDefalut,
        th: ReplaceThFormat(formatDefalut),
    };

    const [objCheckReplace] = useState(() => {
        localeMap(locale);
        let objReturn = { isReplaceYear: false, indexArr: 0, splitText: "" };
        let formatLower = formatDefalut ? formatDefalut.toLowerCase() : "";
        if (formatLower.includes("yyyy")) {
            let splitText = (formatDefalut.substr(formatLower.indexOf("yyyy") - 1, 1)).toLowerCase();
            if (splitText !== "y") {
                objReturn.splitText = splitText;
                objReturn.indexArr = formatLower.split(splitText).findIndex((e) => e === "yyyy");
            }
            objReturn.isReplaceYear = true;
        }
        return objReturn;
    });

    const getmoment = useCallback((params, value) => {
        if (locale === "th") {
            if (value != null && objCheckReplace.isReplaceYear && format.length === 10) {
                let convertDateToString: string = moment(value).format(format);
                let year = objCheckReplace.splitText ? convertDateToString.split(objCheckReplace.splitText)[objCheckReplace.indexArr] : convertDateToString.substr(0, 4);
                let addyear = parseInt(year) > 2500 ? parseInt(year) : parseInt(year) + 543;
                let replaceAllFormat = format.replaceAll("YYYY", "");
                params.inputProps.value = moment(value).format(replaceAllFormat) + addyear;
            }
            else if (format === "YYYY" && value != null && objCheckReplace.isReplaceYear) {
                let convertDateToString: string = moment(value).format(format);
                let addyear = parseInt(convertDateToString) > 2500 ? parseInt(convertDateToString) : parseInt(convertDateToString) + 543;
                params.inputProps.value = addyear.toString();

            }
            else if (format === "MM/YYYY" && value != null && objCheckReplace.isReplaceYear) {
                let convertDateToString: string = moment(value).format(format);
                let year = objCheckReplace.splitText ? convertDateToString.split(objCheckReplace.splitText)[1] : convertDateToString.substr(0, 2);
                let addyear = parseInt(year) > 2500 ? parseInt(year) : parseInt(year) + 543;
                params.inputProps.value = convertDateToString.replaceAll(`${year}`, `${addyear}`);
            }
        }
    }, [])

    return (


        <LocalizationProvider
            locale={language}
            dateAdapter={CustomAdapter}
        >
            <DatePicker
                dayOfWeekFormatter={(day) => day.charAt(0).toUpperCase() === "เ" ? "ส" : day.charAt(0).toUpperCase()}
                // inputRef={ref}
                value={value || null}
                onChange={(e, key) => {
                    let s = moment(e).isValid();
                    if (s !== false) {
                        let dInfo: Date = e;
                        props.onChange(dInfo);
                        setValue(value != null ? value : null)
                        props.onChange && props.onChange(dInfo);
                    }
                    else {
                        props.onChange(null);
                        setValue(null)
                        props.onChange && props.onChange(null);
                    }
                }}
                open={open}
                onOpen={() => {if(!disabled){
                    setOpen(true)
                }}}
                onClose={() => setOpen(false)}
                minDate={minDate}
                maxDate={maxDate}
                label={props.label}
                inputFormat={formatDefalut}
                disabled={disabled}
                views={view}
                showToolbar={false}
                DialogProps={{ sx: { '& .PrivateDatePickerToolbar-penIcon,& .MuiPickersToolbar-penIconButton': { display: 'none' } } }}

                renderInput={(params) => {
                    params.inputProps.placeholder = maskMap[locale];
                    getmoment(params, value)
                    return (
                        <TextField
                            {...params}
                            sx={{
                                ".MuiOutlinedInput-root": {
                                    padding: "0px 15px 0px 0px!important",
                                    " fieldset > legend > span": {
                                        padding: "0px !important",
                                    },
                                },
                                fontWeight: 600,
                            }}
                            size={"small"}
                            label={props.label}
                            name={id}
                            // error={error?.message != null}
                            disabled={disabled}
                            value={value || ""}
                            autoComplete={"off"}
                            onClick={(e) => setOpen(true)}
                        />
                    )
                }}
            />
        </LocalizationProvider>
    );

});

export const DateTimePickerFromItem = forwardRef((props: DatePickerFromProps, ref) => {

    return (<>
    </>)

});

// export const DateRangePickerFrom = forwardRef((props: DateRangePickerFromprop, ref) => {
//     const {
//         control,
//         getValues,
//         setValue,
//         watch,
//         setError,
//         formState: { touchedFields, isSubmitted, errors },
//     } = useFormContext();

//     useEffect(() => {
//         if (getValues(props.nameStart)) {
//             if (getValues(props.nameEnd) < getValues(props.nameStart)) {
//                 setValue(props.nameEnd, null);
//                 setError(props.nameEnd, { type: "validate" }, { shouldFocus: false });
//             }
//         }
//     }, [watch(props.nameStart)])

//     useEffect(() => {
//         if (getValues(props.nameEnd)) {
//             if (getValues(props.nameEnd) < getValues(props.nameStart)) {
//                 setValue(props.nameStart, null);
//                 setError(props.nameStart, { type: "validate" }, { shouldFocus: false });
//             }
//         }

//     }, [watch(props.nameEnd)])

//     return (<div style={{ display: "flex" }}>
//         <DatePickerFromItem
//             name={props.nameStart || ""}
//             label={props.labelStart}
//             {...props}
//             required={true}
//             fullWidth={true}
//             maxDate={(props.view || []).length == 1 && (props.view || []).filter(f => f == "year").length > 0 ? getValues(props.nameEnd) + "" : getValues(props.nameEnd)}
//         />
//         <p style={{ padding: "0px", marginTop: "9px", paddingLeft: "10px", paddingRight: "10px", display: "flex" }}><AiOutlineMinus style={{ marginTop: "4px" }} /></p>
//         <DatePickerFromItem
//             name={props.nameEnd || ""}
//             label={props.labelEnd}
//             {...props}
//             required={true}
//             fullWidth={true}
//             minDate={(props.view || []).length == 1 && (props.view || []).filter(f => f == "year").length > 0 ? getValues(props.nameStart) + "" : getValues(props.nameStart)}
//         />
//     </div>)
// });
