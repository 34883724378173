import { AxiosPost, AxiosGet } from "STM/utilities/ST_Axios";
import secureLocalStorage from "react-secure-storage";
import route from 'router';

const {
    host, hostname, href, origin, pathname, port, protocol, search
} = window.location

export const GetLanguage = async (objJSON, fnSuccess, fnError) => {
    await AxiosGet("DropDown/GetLanguage", objJSON, (res) => {
        fnSuccess(res);
    },(err) => {fnError(err)});
}

export const GetAPISystemInfo = async() => {
    await AxiosGet("Utility/GetSystemInfo", {}, (res) => {
        let systemInfo = process.env.REACT_APP_SystemInfo;
        ////alert(JSON.stringify(res));
        secureLocalStorage.setItem(systemInfo,res);
    });
}
const GetUrlAction=()=>{

    const frontURL = process.env.REACT_APP_Front_URL;
    let actionUrl = "";
    if(host !== ""){
        actionUrl = href.replace(frontURL,"");
        actionUrl = actionUrl.replace(search,"");
        actionUrl = actionUrl.replace("/","");
    }
    
    if(actionUrl  === ""){
        route.forEach((o) => {
            if(o.path ==  "/"){
                actionUrl = o.component.name;
                return false;
            }
        });
    }
    return actionUrl
}

export const GetSeoMenu = async(callback) => {
    let actionUrl = GetUrlAction();
    await AxiosGet("Utility/GetSeoMenu", {"sUrl":actionUrl}, (res) => {
        callback(res);
    });
}

export const CallLogout = async(callback) => {
    await AxiosPost("Login/Logout", {}, (res) => {
        callback(res);
    });
}