import {
  Grid,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import "./NewsActivity.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";
import Pagination from "@mui/material/Pagination";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FnDialog } from "STM/utilities/ST_Function";
import { GetNewsAll } from "./CallAPI";
import NewsCardSkeletonAll from "./NewsCardSkeletonAll";
import {
  TextBoxForm,
  SelectMultipleFormItem,
  SelectFormItem,
  DateRangePickerProFrom,
} from "STM/components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { options } from "STM/enum/enumSystem";
import { BtnSearchCustom, BtnClearCustom } from "STM/components/Button";

let easeing = [0.6, -0.05, 0.01, 0.99];

const fadeInUp = {
  rest: {
    y: 60,
    opacity: 0,
    transition: {
      duration: 0.6,
      ease: easeing,
    },
  },
  animate: {
    y: 0,
    opacity: 1,
  },
};

const titleMotion = {
  rest: { opacity: 1, ease: easeing, duration: 0.2, type: "between" },
  hover: {
    opacity: 1,
    transition: {
      duration: 0.6,
      type: "between",
      ease: easeing,
    },
  },
};

const introMotion = {
  rest: {
    opacity: 0,
    ease: easeing,
    duration: 0.2,
    type: "between",
    height: 0,
  },
  hover: {
    opacity: 1,
    height: 43,
    transition: {
      duration: 0.65,
      type: "between",
      ease: easeing,
    },
  },
};

const buttonMotion = {
  // rest: { opacity: 0, ease: easeing, duration: 0.2, type: "between",color:"#" },
  hover: {
    opacity: 1,
    background: "#001858",
    color: "#fff",
    transition: {
      duration: 0.65,
      type: "between",
      ease: easeing,
    },
  },
};

const cardMotion = {
  rest: {
    opacity: 1,
    ease: easeing,
    duration: 0.2,
    type: "between",
    background: "transparent",
  },
  hover: {
    opacity: 1,
    background: "#ffffff",
    transition: {
      duration: 0.65,
      type: "between",
      ease: easeing,
    },
  },
};

export default function NewsActivityAll() {
  const frontURL = process.env.REACT_APP_Front_URL;
  const navigate = useNavigate();
  const DialogFn = FnDialog();

  const [dropdownTag, setDropdownTag] = useState([] as any);

  const schema = yup.object().shape({});
  const formResolver = yupResolver(schema);
  const form = useForm({
    resolver: formResolver,
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: {} as any,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [IsNoData, setIsNoData] = useState(false);
  ////const [IsShowDate, setIsShowDate] = useState(false);
  const [data, setData] = useState({
    nPageIndex: 1,
    nPageSize: 8,
    arrRows: [],
    nDataLength: 0,
    sSortExpression: "aaa",
    sSortDirection: "aaa",
    sID: "",
  });

  useEffect(() => {
    scrollToTop();
    onGetNewsAll(data);
  }, []);

  const onGetNewsAll = (p) => {
    setIsLoading(true);
    let daterange = form.getValues("sDateRange");
    let startdate = null;
    let enddate = null;
    if (daterange) {
      startdate =
        daterange[0] != null ? daterange[0].format("DD/MM/YYYY") : null;
      enddate = daterange[1] != null ? daterange[1].format("DD/MM/YYYY") : null;
    }
    GetNewsAll(
      {
        ...p,
        arrRows: [],
        sNewsName: form.getValues("sNewsName"),
        lstTag:
          form.getValues("sTag") != null && form.getValues("sTag").length > 0
            ? JSON.stringify(form.getValues("sTag"))
            : null,
        sSortBy: form.getValues("sSortBy"),
        sStart: startdate ?? null,
        sEnd: enddate ?? null,
      },
      (res) => {
        setIsNoData(res.lstNewsAll.length === 0);
        setData({
          ...p,
          arrRows: res.lstNewsAll || [],
          nDataLength: res.nDataLength,
          nPageIndex: res.nPageIndex,
        });
        setDropdownTag(res.lstTag);
        setIsLoading(false);
      },
      (err) => {
        if (!err.response) {
          DialogFn.Warning(err.Message);
        }
      }
    );
  };

  const arrNews = [
    {
      url: "https://images.unsplash.com/photo-1666919643134-d97687c1826c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHw2fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=800&q=60",
      text: "political ants bean baby pocket receive depth escape explanation basic thy anybody different planning afternoon according captured breathe refer pressure some lunch remarkable ago",
    },
    {
      url: "https://images.unsplash.com/photo-1674574124976-a56d9052c2f8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHwxMXx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      text: "useful lovely rate far easy living trouble stomach leaf classroom brief cat to teeth fort population wherever horn bat tightly tool unusual lonely shoulder",
    },
    {
      url: "https://images.unsplash.com/photo-1687252827323-6bb790696569?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxNXx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      text: "something other arrangement bottom finger stronger outline city fill burn grain successful sing hard six win frozen beneath brain fifty thou deeply exactly driver",
    },
    {
      url: "https://images.unsplash.com/photo-1687684514463-42d853944099?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyNHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      text: "blind cabin add tight ants let women today represent combination mission interest horn between soap wife wing thumb fur plan officer buffalo nature talk",
    },
    {
      url: "https://plus.unsplash.com/premium_photo-1661776217775-ba332ee9566e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fE5ld3N8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
      text: "fuel third feet yard apple look mother appropriate tea upon century bridge town another tank identity jump swept gasoline either importance bush darkness accurate",
    },
    {
      url: "https://images.unsplash.com/photo-1687812693663-c322b9af62a5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0MHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      text: "turn sleep loss slipped potatoes palace trouble trap raw wore dollar adult under fact pool whistle everyone industrial bicycle shape method person green consonant",
    },
    {
      url: "https://images.unsplash.com/photo-1674574124475-16dd78234342?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHw0N3x8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      text: "getting leg win recall memory ten factory silent judge rocket desk strip bent future break everywhere living stop themselves breakfast powder indicate ask drop",
    },
    {
      url: "https://images.unsplash.com/photo-1687622577762-2c693481b4fb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw3OXx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      text: "clean unknown standard border court troops corner beauty busy few children idea something got golden rough health mixture herself read purpose support lips huge",
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const pageNext = () => {
    return (
      <>
        <KeyboardArrowDownIcon
          className="icon-desktop"
          sx={{ fontSize: "1rem" }}
        />
        <KeyboardArrowRightIcon
          className="icon-mobile"
          sx={{ fontSize: "1rem" }}
        />
      </>
    );
  };

  const pagePrevious = () => {
    return (
      <>
        <KeyboardArrowUpIcon
          className="icon-desktop"
          sx={{ fontSize: "1rem" }}
        />

        <KeyboardArrowLeftIcon
          className="icon-mobile"
          sx={{ fontSize: "1rem" }}
        />
      </>
    );
  };

  const { t } = useTranslation();

  return (
    <FormProvider {...form}>
      <Grid
        container
        className="news-activity-container"
        sx={{
          backgroundImage: `url(${frontURL + "images/newsbg.png"})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 55%",
          position: "relative",
        }}
      >
        <Grid container className="news-container-all" spacing={2}>
          {/* <Grid item xs={12} md={12} lg={12}>
            <Stack className="news-activity-stack">
              <Typography
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: 700,
                  lineHeight: 1,
                  color: "rgb(52, 71, 103)",
                }}
              >
                News & Activities
              </Typography>
            </Stack>
          </Grid> */}
          <Grid item xs={12} sx={{ mb: 2 }} className="pl-0">
            <Grid
              container
              justifyContent={"space-between"}
              spacing={2}
              alignItems={"center"}
            >
              <Grid item xs={12} md={2.5}>
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                    fontWeight: 500,
                    lineHeight: 1,
                    color: "rgb(52, 71, 103)",
                    textTransform: "uppercase",
                  }}
                >
                  News & Activities
                </Typography>
              </Grid>
              <Grid item xs={12} md={9.5}>
                <Grid
                  container
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  className="filter-container"
                >
                  <Grid item xs={12} md={2.5}>
                    <TextBoxForm
                      name="sNewsName"
                      label={t("newsname") + " / " + t("introduction")}
                      isshowTextCont={false}
                      disabled={isLoading}
                      shrink
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !isLoading) {
                          setData({
                            ...data,
                            arrRows: [],
                          });
                          setIsLoading(true);
                          onGetNewsAll(data);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3.5} md={2.5}>
                    <SelectMultipleFormItem
                      id={"sTag"}
                      name={"sTag"}
                      label={t("tags")}
                      required={false}
                      disabled={isLoading}
                      options={dropdownTag}
                      limitTags={1}
                      shrink
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !isLoading) {
                          setData({
                            ...data,
                            arrRows: [],
                          });
                          setIsLoading(true);
                          onGetNewsAll(data);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={2.8}>
                    <DateRangePickerProFrom
                      name="sDateRange"
                      required={false}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3.5} md={2.5}>
                    <SelectFormItem
                      id={"sSortBy"}
                      name={"sSortBy"}
                      label={`Sort By`}
                      required={false}
                      disabled={isLoading}
                      options={options.sortby}
                      shrink
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !isLoading) {
                          setData({
                            ...data,
                            arrRows: [],
                          });
                          setIsLoading(true);
                          onGetNewsAll(data);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={"auto"} sm={1} md={"auto"}>
                    <BtnClearCustom
                      isDisabled={isLoading}
                      onClick={() => {
                        form.setValue("sNewsName", null);
                        form.setValue("sTag", null);
                        form.setValue("sSortBy", null);
                        // form.setValue("sDateRange", "");
                        form.resetField("sDateRange");
                        // form.reset();
                        setData({
                          ...data,
                          arrRows: [],
                        });
                        setIsLoading(true);
                        onGetNewsAll(data);
                      }}
                    />
                  </Grid>
                  <Grid item xs={"auto"} sm={1} md={"auto"}>
                    <BtnSearchCustom
                      onClick={() => {
                        if (!isLoading) {
                          setData({
                            ...data,
                            arrRows: [],
                          });
                          setIsLoading(true);
                          onGetNewsAll(data);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {arrNews.map((news, index) => (
            <>{isLoading && <NewsCardSkeletonAll key={news.url} />}</>
          ))}

          <>
            {IsNoData ? (
              <Grid
                container
                spacing={2}
                sx={
                  isLoading
                    ? { display: "none" }
                    : { height: "60vh", alignItems: "center" }
                }
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundImage: `url(${frontURL + "images/NoData.svg"})`,
                    height: "35vh",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    mt: 5,
                    position: "relative",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: 700,
                      lineHeight: 1,
                      color: "rgb(52, 71, 103)",
                      textAlign: "center",
                      position: "absolute",
                      bottom: "-35px",
                      left: 0,
                      right: 0,
                      margin: "0 auto",
                    }}
                  >
                    Data not found.
                  </Typography>
                </Grid>
                {/* <Grid item xs={12}>
              <Typography sx={{fontSize: "1.25rem",
                    fontWeight: 700,
                    lineHeight: 1,
                    color: "rgb(52, 71, 103)",textAlign:"center"}}>Data not found.</Typography>
            </Grid> */}
              </Grid>
            ) : (
              <>
                {data.arrRows.map((news, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    key={news.sID}
                    className="height-card-all"
                  >
                    <motion.div
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        cursor: "pointer",
                      }}
                      className="main-card main-news-card"
                      transition={{
                        duration:
                          (data.arrRows.length / (data.arrRows.length / 2)) *
                          0.25,
                      }}
                      variants={fadeInUp}
                      initial="rest"
                      whileHover="hover"
                      animate="animate"
                      onClick={() => {
                        navigate(`/NewsActivityDetail`, {
                          state: {
                            sID: news.sID,
                          },
                        });
                      }}
                    >
                      <motion.div
                        className="main-news"
                        style={{
                          // overflow: "hidden",
                          position: "relative",
                          width: "100%",
                        }}
                      >
                        <motion.div
                          className="news-image-box"
                          style={{ backgroundColor: "#ffffffcc" }}
                        >
                          <motion.img
                            // whileHover={{ scale: 1.1 }}
                            className="news-card-img"
                            src={news.fNewsCover[0].sFileLink}
                          />
                        </motion.div>

                        <motion.div
                          className="card-absolute card-absolute-all"
                          style={{
                            position: "absolute",
                            zIndex: 5,
                            padding: "0 0.5rem 0.5rem",
                            width: "100%",
                          }}
                        >
                          <motion.div style={{ minHeight: "40px" }}>
                            <Typography
                              className="news-name-all"
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                              }}
                            >
                              {news.sNewsName}
                            </Typography>
                            <motion.div
                              variants={introMotion}
                              className="hide-on-smartphone"
                            >
                              <Typography
                                style={{
                                  fontSize: "0.75rem",
                                  lineHeight: 1.25,
                                  fontWeight: 400,
                                  color: "#67748e",
                                  // marginTop: "0.5rem",
                                  display: "-webkit-box",
                                  overflow: "hidden",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 3,
                                  marginTop: "0.5rem",
                                }}
                              >
                                {news.sNewsIntro}
                              </Typography>
                            </motion.div>

                            <motion.div className="hide-on-desktop">
                              <Typography
                                style={{
                                  fontSize: "0.75rem",
                                  lineHeight: 1,
                                  fontWeight: 400,
                                  color: "#67748e",
                                  // marginTop: "0.5rem",
                                  display: "-webkit-box",
                                  overflow: "hidden",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 3,
                                  marginTop: "0.5rem",
                                }}
                              >
                                {news.sNewsIntro}
                              </Typography>
                            </motion.div>
                          </motion.div>
                          <motion.button
                            variants={buttonMotion}
                            style={{
                              width: "80px",
                              background: "#4fc3f7",
                              color: "#fff",
                              textAlign: "center",
                              marginTop: "0.75rem",
                              borderRadius: "15px",
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              border: "none",
                              padding: "2px 0",
                              cursor: "pointer",
                            }}
                          >
                            More{" "}
                            <ArrowForwardIcon
                              sx={{ ml: 0.5, fontSize: "1.2rem" }}
                            />
                          </motion.button>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </Grid>
                ))}
              </>
            )}
          </>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} className="stack-pagination">
            <Pagination
              count={Math.ceil(data.nDataLength / data.nPageSize)}
              page={data.nPageIndex}
              variant="outlined"
              color="primary"
              // sx={{
              //   ".MuiPagination-ul": {
              //     flexDirection: "column",
              //   },
              //   ".MuiPagination-ul li": {
              //     mb: 0.5,
              //   },
              // }}
              onChange={(event, value) => {
                if (!isLoading) {
                  scrollToTop();
                  let cloneData = { ...data, nPageIndex: value };
                  setData({
                    ...data,
                    arrRows: [],
                  });
                  cloneData.arrRows = [];
                  onGetNewsAll(cloneData);
                }
              }}
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: pagePrevious,
                    next: pageNext,
                  }}
                  {...item}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
