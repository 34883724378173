
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import yupFormSchemas from 'STM/components/FormItem/yupFormSchemas';
import { Box, Divider, Grid, Radio, Typography } from '@mui/material';
import { HeaderInput, SelectFormItem, SwitchForm, TextBoxForm } from 'STM/components/Input';
import { FnBlock_UI } from 'STM/components/BlockUI';
import { AxiosPost } from 'STM/utilities/ST_Axios';
import { useLocation, useNavigate } from 'react-router-dom';
import DataGrid, { PaginationInterface, initRows } from 'STM/components/DataGrid';
import { GridColumns } from '@mui/x-data-grid-pro';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { BtnBackForm, BtnCreateForm } from 'STM/components/Button';
import { SwAlert } from 'STM/components/Alert';
import { useTranslation } from 'react-i18next';
import { EnumPermission } from 'STM/enum/enumSystem';
import { FnDialog } from 'STM/utilities/ST_Function';

const UserGroupForm = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const navigate = useNavigate();
    const DialogFn = FnDialog();
    const [nPermission, setnPermission] = useState(2);
    const [loadingTable, setLoadingTable] = useState(false);
    const [isPermission, setisPermission] = useState(false);
    const [isEdit, setisEdit] = useState(false);
    const [oldRole, setOldRole] = useState([]);
    const [lstRoleID, setlstRoleID] = useState([]);
    const [isActive, setIsActive] = useState(true);
    const [DropDownUserRole, setDropDownUserRole] = useState([])
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get("sID");
    const [dataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,
    });

    const objSchema = yup.object().shape({
        sUserRole: yupFormSchemas.string(t('userrolename'), { required: true, translation: t }),
        sUserGroupName: yupFormSchemas.string(t('usergroupname'), { required: true, translation: t }),
    });

    const form = useForm({
        resolver: yupResolver(objSchema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
        defaultValues: {
            sStatus: true,
        } as any,
    });

    useEffect(() => {
        onGetData().then(
            () => { DialogFn.UnBlockUI() },
            () => { }
        );
    }, []);

    useEffect(() => {
        onGetDropDown().then(
            () => { },
            () => { }
        );
    }, [lstRoleID]);

    const handleChangeRadio = (sID, sField) => {
        let oCheck = dataRow.arrRows.find((f) => f.sID === sID);
        if (oCheck) {
            oCheck.isDisable = sField === "isDisable";
            oCheck.isReadOnly = sField === "isReadOnly";
            oCheck.isEnable = sField === "isEnable";
        }
        setDataRow({ ...dataRow });
    };

    const dataColumn: GridColumns = [
        {
            field: "nNo",
            headerName: t('no'),
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: false,
            minWidth: 50,
            renderCell: (item) =>
                !item.row.isSubMenu && (
                    <>{item.row.nNo}</>
                ),
        },
        {
            field: "sMenuName",
            headerName: t('menufunction'),
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: (item) =>
                item.row.isSubMenu ? (
                    <div>
                        <FiberManualRecordIcon sx={{ fontSize: "0.6em", mr: 0.5 }} />{" "}
                        {item.value}{" "}
                    </div>
                ) : (
                    item.value
                ),
            colSpan: ({ row }) => {
                if (row.isHasSub) {
                    return 4;
                }
                return 1;
            },
            cellClassName: (params) => params.row.isHasSub ? "sMenuHead" : "",
        },
        {
            field: "isDisable",
            headerName: t('disable'),
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: false,
            minWidth: 200,
            renderCell: (item) =>
                !item.row.isHasSub && item.row.isManagePRM && (
                    <Radio
                        checked={item.value}
                        onChange={() => handleChangeRadio(item.row.sID, "isDisable")}
                        disabled={nPermission !== EnumPermission.Enable}
                    />
                ),
        },
        {
            field: "isReadOnly",
            headerName: t('view'),
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: false,
            minWidth: 200,
            renderCell: (item) =>
                !item.row.isHasSub && item.row.isManagePRM && (
                    <Radio
                        checked={item.value}
                        onChange={() => handleChangeRadio(item.row.sID, "isReadOnly")}
                        disabled={nPermission !== EnumPermission.Enable ||
                            item.row.nHighPerm < EnumPermission.ReadOnly}
                    />
                ),
        },
        {
            field: "isEnable",
            headerName: t('addeditdelete'),
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: false,
            minWidth: 200,
            renderCell: (item) =>
                !item.row.isHasSub && item.row.isManagePRM && (
                    <Radio
                        checked={item.value}
                        onChange={() => handleChangeRadio(item.row.sID, "isEnable")}
                        disabled={nPermission !== EnumPermission.Enable ||
                            item.row.nHighPerm < EnumPermission.Enable}
                    />
                ),
        },
    ];

    const onGetDropDown = async () => {
        await AxiosPost("DropDown/GetUserRoleByID", lstRoleID, (res) => {
            console.log("DropDown ", res);
            setDropDownUserRole(res)
        }
        );
    };

    const onGetData = async () => {
        let param = { sUserGroupID: sID || "" };
        DialogFn.BlockUI();
        setLoadingTable(true);
        await AxiosPost("UserGroup/GetUserGroup", param, (res) => {
            console.log("res ", res);
            setlstRoleID([res.sUserRoleID])
            if (sID) {
                form.setValue("sUserGroupName", res.sUserGroupName)
                form.setValue("sUserGroupDescription", res.sUserGroupDescription)
                form.setValue("sUserRole", res.sUserRoleID)
                form.setValue("sStatus", res.isActive)
                setisPermission(res.isUsePermission)
                setOldRole(res.sUserRoleID)
                setIsActive(res.isActive)
                setisEdit(true)
            }
            setDataRow({
                ...dataRow,
                arrRows: res.lstPermission || [],
                nDataLength: res.nDataLength,
                nPageIndex: res.nPageIndex,
            });
            setLoadingTable(false);
        }, (err) => {
            if (!err.response) {
                DialogFn.Warning(err.Message);
            }
        }
        );
    };

    const onGetPermissionUserRole = (e) => {
        if (isEdit) {
            DialogFn.SubmitWarning(
                t("changegroup"),
                () => {
                    let param = { sUserRoleID: form.getValues("sUserRole") || "" };
                    console.log("param ", param)
                    setLoadingTable(true);
                    AxiosPost("UserGroup/GetPermissionUserRole", param, (res) => {
                        setLoadingTable(false);
                        console.log("res ", res)
                        setDataRow({
                            ...dataRow,
                            arrRows: res || [],
                            nDataLength: res.nDataLength,
                            nPageIndex: res.nPageIndex,
                        });
                    });
                },
                () => {
                    form.setValue("sUserRole", oldRole);
                }
            );
        } else {
            console.log("esssss", e)
            let param = { sUserRoleID: form.getValues("sUserRole") || "" };
            setLoadingTable(true);
            console.log("paramssss", param)
            AxiosPost("UserGroup/GetPermissionUserRole", param, (res) => {
                console.log("res ", res)
                setLoadingTable(false);
                setDataRow({
                    ...dataRow,
                    arrRows: res || [],
                    nDataLength: res.nDataLength,
                    nPageIndex: res.nPageIndex,
                });
            });
        }
    };

    const onSubmitGroupOnly = () => {
        console.log("dataRow ", dataRow.arrRows)
        let isCheckPermComplete = true;
        dataRow.arrRows.forEach((item) => {
            if (item.nMenuHeadID != null && !item.isDisable && !item.isReadOnly && !item.isEnable) {
                DialogFn.Warning(t('checkperm'))
                isCheckPermComplete = false;
                return false;
            }
        });

        if (isCheckPermComplete) {
            let data = {
                sUserGroupID: sID || "",
                sUserRoleID: form.getValues("sUserRole") || "",
                sUserGroupName: form.getValues("sUserGroupName") || "",
                sUserGroupDescription: form.getValues("sUserGroupDescription") || "",
                lstPermission: dataRow.arrRows,
                isActive: form.getValues("sStatus"),
            };
            console.log("objData ", data);
            DialogFn.Submit(t("confirmsave"), () => {
                DialogFn.BlockUI();
                AxiosPost("UserGroup/SaveUserGroup", data, (result) => {
                    DialogFn.UnBlockUI();
                    DialogFn.Success(t("formsuccess"));
                    navigate(`/UserGroup`);
                }, (err) => {
                    DialogFn.UnBlockUI();
                    if (!err.response) {
                        DialogFn.Warning(err.Message);
                    }
                });
            });
        }
    };

    const onSubmit = () => {
        console.log("dataRow ", dataRow.arrRows)
        let isCheckPermComplete = true;
        dataRow.arrRows.forEach((item) => {
            if (item.nMenuHeadID != null && !item.isDisable && !item.isReadOnly && !item.isEnable) {
                DialogFn.Warning(t('checkperm'))
                isCheckPermComplete = false;
                return false;
            }
        });

        if (isCheckPermComplete) {
            let data = {
                sUserGroupID: sID || "",
                sUserRoleID: form.getValues("sUserRole") || "",
                sUserGroupName: form.getValues("sUserGroupName") || "",
                sUserGroupDescription: form.getValues("sUserGroupDescription") || "",
                lstPermission: dataRow.arrRows,
                isActive: form.getValues("sStatus"),
            };
            console.log("objData ", data);
            DialogFn.Submit(t("confirmsave"), () => {
                DialogFn.BlockUI();
                AxiosPost("UserGroup/SaveApplyUserPermission", data, (result) => {
                    DialogFn.UnBlockUI();
                    DialogFn.Success(t("formsuccess"));
                    navigate(`/UserGroup`);
                }, (err) => {
                    DialogFn.UnBlockUI();
                    if (!err.response) {
                        DialogFn.Warning(err.Message);
                    }
                });
            });
        }
    };

    return (
        <FormProvider {...form}>
            <Grid container sx={{ boxSizing: "border-box", display: "flex", flexFlow: "row wrap", marginTop: "24px", width: "calc(100% - 24px)", justifyContent: "center", }}>
                <Grid item xs={11}>
                    <Grid item xs={12} className="Card-form">
                        <Grid item xs={12} sx={{ color: "rgb(52, 71, 103)" }} className="Header-grid-form">
                            <Typography sx={{ color: "rgb(52, 71, 103)" }} className="Header-title-form">
                                {isEdit ? t('usergroupupdate') : t('usergroupcreate')}
                            </Typography>
                            <Divider className="Divider-header" />
                            <Grid container spacing={2} sx={{}}>
                                <Grid item xs={6}>
                                    <Box sx={{ color: "rgb(52, 71, 103)", }} className=".Box-content-form">
                                        <HeaderInput text={t('userrolename')} required />
                                        <SelectFormItem
                                            id={"sUserRole"}
                                            name={"sUserRole"}
                                            label={t('userrolename')}
                                            required={true}
                                            disabled={false}
                                            options={DropDownUserRole}
                                            onChange={(e) => {
                                                console.log("e", e)
                                                if (e) {
                                                    setOldRole(e.value)
                                                }
                                                onGetPermissionUserRole(e)
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ color: "rgb(52, 71, 103)", }} className=".Box-content-form">
                                        <HeaderInput text={t('usergroupname')} required />
                                        <TextBoxForm name="sUserGroupName" label={""} maxLength={150} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={10}>
                                    <Box sx={{ color: "rgb(52, 71, 103)", }} className=".Box-content-form">
                                        <HeaderInput text={t('description')} />
                                        <TextBoxForm name="sUserGroupDescription" label={""} multiline={true} rows={3} maxLength={1000} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <HeaderInput text={t('permission')} required />
                                    <DataGrid
                                        isLoading={loadingTable}
                                        columns={dataColumn}
                                        rows={dataRow}
                                        isNotShowPagination={true}
                                        isNotShowTotal={true}
                                        isHiddenToolHead={true}
                                        isHideFooter={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ width: "60px" }}>
                                        <Box sx={{ color: "rgb(52, 71, 103)", }} className=".Box-content-form">
                                            <HeaderInput text={t('status')} subtext={isActive ? t("active") : t("inactive")} required />
                                            <SwitchForm
                                                name={"sStatus"}
                                                label=""
                                                required={true}
                                                disabled={false}
                                                width={60}
                                                IsClassName={false}
                                                onChange={(e) => setIsActive(e)}
                                                onColor="#8bc34a"
                                                offColor="#c41411"
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{ display: "flex" }} justifyContent={"space-between"}>
                                    <BtnBackForm
                                        txt={t('back')}
                                        onClick={() => {
                                            DialogFn.SubmitWarning(t("cancelmsg"), () => {
                                                DialogFn.CloseSubmitWarning();
                                                navigate("/UserGroup");
                                            });
                                        }}
                                    />
                                    <Box sx={{ display: "flex" }}>
                                        <BtnCreateForm
                                            txt={isPermission ? t('savegrouponly') : t('save')}
                                            onClick={form.handleSubmit((e) => onSubmitGroupOnly())}
                                        />
                                        <Box sx={{ display: isPermission ? "flex" : "none", ml: "10px" }}>
                                            <BtnCreateForm
                                                txt={t('save')}
                                                onClick={form.handleSubmit((e) => onSubmit())}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormProvider>
    )
};
export default UserGroupForm;