import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FnBlock_UI } from 'STM/components/BlockUI';
import { AxiosPost } from 'STM/utilities/ST_Axios';
import { SelectFormItem, RadioNoForm } from 'STM/components/Input';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import yupFormSchemas from 'STM/components/FormItem/yupFormSchemas';
import { SwAlert } from 'STM/components/Alert/index';
import { AlertTitle, AlertMsg } from 'config/AlertMsgConfig'
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useTranslation } from 'react-i18next';
import "./CMSStyle.css"
import Carousel from 'react-gallery-carousel';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import UploadFile from 'STM/components/Input/UploadFile/UploadFile';
import parse from 'html-react-parser';
import Radio from '@mui/material/Radio';
import { Extension } from 'STM/utilities/ST_Function';
import { FnDialog } from "STM/utilities/ST_Function";
import ReactPlayer from 'react-player'
import { Height } from "@mui/icons-material";

const ContentManagementFrame = (prop) => {
    const { t, i18n } = useTranslation();
    const { open, CMSData } = prop;
    const DialogFn = FnDialog();
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [nPage, setnPage] = useState(1);
    const [nID, setnID] = useState(0);
    const [arrPicture, setarrPicture] = useState([] as any)
    const [arrClip, setarrClip] = useState([] as any)
    const onClearFile = useRef(null);
    const onClearFile2 = useRef(null);
    const [playing, setplaying] = useState(false);
    const [lstTemplateData, setlstTemplateData] = useState([] as any);

    const objSchema = yup.object().shape({
    });

    useEffect(() => {
        console.log("CMSDataPreview", CMSData)
        console.log("screenHeight", screenHeight)
        
    }, [open]);

    const GetListLayout = () => {
        let param = {
        };
        AxiosPost("CMSComponent/GetListLayout", param, (res) => {
            setlstTemplateData(res)
        }, (err) => {
            if (!err.response) {
                DialogFn.UnBlockUI();
                DialogFn.Warning(err.Message);
            }
        })
    };

    useEffect(() => {
        GetListLayout();
        const handleResize = () => {
            setScreenHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const form = useForm({
        resolver: yupResolver(objSchema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    return (
        <>
            <Grid container className="CMSFrame" spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
                {CMSData !== null && CMSData.lstCMS.map((HeadItem, HeadNo) => (
                    <>
                        {lstTemplateData.filter(f => f.nTemplateID === HeadItem.nTemplateContentID).map((item, itemNo) => (
                            <Grid container key={itemNo} className={""} xs={12} style={{ /*height: screenHeight * 0.72 + "px",*/ display: "flex" }} onClick={(e) => { }}>
                                {item.lstSlot.map((itemSlot, SlotNo) => (
                                    <Grid container key={SlotNo} xs={itemSlot.nGridSize_Sm} md={itemSlot.nGridSize_Md} lg={itemSlot.nGridSize_Lg} style={{ display: 'flex' }}>
                                        <Grid container style={itemSlot.lstChild.length > 0 ? {} : {
                                            display: itemSlot.sSlot !== "" && itemSlot.nSlot !== null && CMSData.lstCMS[HeadNo].lstSlot[itemSlot.nSlot - 1].nType === 1 ? "block":"flex", alignItems:itemSlot.sValign, justifyContent: "center",
                                            //backgroundImage: itemSlot.sSlot !== "" && itemSlot.nSlot !== null && CMSData.lstCMS[HeadNo].lstSlot[itemSlot.nSlot - 1].nType === 2 && CMSData.lstCMS[HeadNo].lstSlot[itemSlot.nSlot - 1].lstPicture.length > 0 ? "url(" + CMSData.lstCMS[HeadNo].lstSlot[itemSlot.nSlot - 1].lstPicture[0].sFileLink.replace(/\\/g, '/') + ")" : "",
                                            //backgroundSize: 'contain',
                                            //height: getMeta(itemSlot.sSlot !== "" && itemSlot.nSlot !== null && CMSData.lstCMS[HeadNo].lstSlot[itemSlot.nSlot - 1].nType === 2 && CMSData.lstCMS[HeadNo].lstSlot[itemSlot.nSlot - 1].lstPicture.length > 0 ? CMSData.lstCMS[HeadNo].lstSlot[itemSlot.nSlot - 1].lstPicture[0].sFileLink.replace(/\\/g, '/') : ""),
                                            //backgroundPosition: 'center',
                                            //backgroundRepeat: "no-repeat",
                                            padding: "10px",
                                            //backgroundOrigin: "content-box"
                                        }}>
                                                                                     
                                            {itemSlot.sSlot !== "" && itemSlot.nSlot !== null && CMSData.lstCMS[HeadNo]?.lstSlot[itemSlot.nSlot - 1]?.nType === 1 ? parse(CMSData.lstCMS[HeadNo].lstSlot[itemSlot.nSlot - 1].sText) : ""}
                                            {itemSlot.sSlot !== "" && itemSlot.nSlot !== null && CMSData.lstCMS[HeadNo]?.lstSlot[itemSlot.nSlot - 1]?.nType === 2 ? <img style={{ maxWidth: '100%', height: 'auto', display: 'block' }} src={itemSlot.sSlot !== "" && itemSlot.nSlot !== null && CMSData.lstCMS[HeadNo]?.lstSlot[itemSlot.nSlot - 1]?.nType === 2 && CMSData.lstCMS[HeadNo]?.lstSlot[itemSlot.nSlot - 1]?.lstPicture?.length > 0 ? CMSData.lstCMS[HeadNo]?.lstSlot[itemSlot.nSlot - 1]?.lstPicture[0].sFileLink.replace(/\\/g, '/') : ""} alt="" /> : ""}
                                            {itemSlot.sSlot !== "" && itemSlot.nSlot !== null && CMSData.lstCMS[HeadNo]?.lstSlot[itemSlot.nSlot - 1]?.nType === 3 && CMSData.lstCMS[HeadNo]?.lstSlot[itemSlot.nSlot - 1]?.lstClipVideo?.length > 0 ? <ReactPlayer playing={true} controls={true} /*height={screenHeight * 0.7 + "px"}*/ url={CMSData.lstCMS[HeadNo]?.lstSlot[itemSlot.nSlot - 1]?.lstClipVideo[0]?.sFileLink.replace(/\\/g, '/')} /> : ""}
                                            {itemSlot.sSlot !== "" && itemSlot.nSlot !== null && CMSData.lstCMS[HeadNo]?.lstSlot[itemSlot.nSlot - 1]?.nType === 4 && CMSData.lstCMS[HeadNo]?.lstSlot[itemSlot.nSlot - 1]?.sUrlLinkVideo !== "" ? <ReactPlayer playing={true} controls={true}/*height={screenHeight * 0.7 + "px"}*/ url={CMSData.lstCMS[HeadNo]?.lstSlot[itemSlot.nSlot - 1]?.sUrlLinkVideo} /> : ""}

                                            {itemSlot.lstChild ? itemSlot.lstChild.map((child, j) => (
                                                <Grid container key={j} xs={child.nGridSize_Sm} md={child.nGridSize_Md} lg={child.nGridSize_Lg} style={{
                                                    display: child.sSlot !== "" && CMSData.lstCMS[HeadNo].lstSlot[child.nSlot - 1].nType === 1 ? "block" : "flex", alignItems: child.sValign, justifyContent: "center",
                                                    //backgroundImage: CMSData.lstCMS[HeadNo].lstSlot[child.nSlot - 1].nType === 2 && CMSData.lstCMS[HeadNo].lstSlot[child.nSlot - 1].lstPicture.length > 0 ? "url(" + CMSData.lstCMS[HeadNo].lstSlot[child.nSlot - 1].lstPicture[0].sFileLink.replace(/\\/g, '/') + ")" : "",
                                                    //backgroundSize: 'contain',
                                                    //backgroundRepeat: "no-repeat",
                                                    //backgroundPosition: 'center',
                                                    position: "relative",
                                                    padding: "10px",
                                                    //height: screenHeight * 0.35 + "px",
                                                    //backgroundOrigin: "content-box"
                                                }}>
                                                    {child.sSlot !== "" && CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.nType === 1 ? parse(CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.sText) : ""}
                                                    {child.sSlot !== "" && CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.nType === 2 ? (
                                                        <img
                                                            style={{ maxWidth: '100%', height: 'auto', display: 'block' }}
                                                            src={
                                                                CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.nType === 2 &&
                                                                    CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.lstPicture?.length > 0
                                                                    ? CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.lstPicture[0]?.sFileLink.replace(/\\/g, '/')
                                                                    : ""
                                                            }
                                                            alt=""
                                                        />
                                                    ) : ""}
                                                    {child.sSlot !== "" && CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.nType === 3 && CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.lstClipVideo?.length > 0 ? (
                                                        <ReactPlayer
                                                            playing={true}
                                                            controls={true}
                                                            /* height={screenHeight * 0.35 + "px"} */
                                                            url={CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.lstClipVideo[0]?.sFileLink.replace(/\\/g, '/')}
                                                        />
                                                    ) : ""}
                                                    {child.sSlot !== "" && CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.nType === 4 && CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.sUrlLinkVideo !== "" ? (
                                                        <ReactPlayer
                                                            playing={true}
                                                            controls={true}
                                                            /* height={screenHeight * 0.35 + "px"} */
                                                            url={CMSData.lstCMS[HeadNo]?.lstSlot[child.nSlot - 1]?.sUrlLinkVideo}
                                                        />
                                                    ) : ""}

                                                </Grid>
                                            )) : null}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </>
                ))}

            </Grid>
        </>
    )
};
export default ContentManagementFrame;