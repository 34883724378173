import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import yupFormSchemas from 'STM/components/FormItem/yupFormSchemas';
import { TextBoxNoForm, RadioForm, CheckboxNoFormItem } from 'STM/components/Input';
import { FnBlock_UI } from 'STM/components/BlockUI';
import { AxiosPost } from 'STM/utilities/ST_Axios';
import { useLocation, useNavigate } from 'react-router-dom';
import DataGrid, { PaginationInterface, initRows } from 'STM/components/DataGrid';
import { Container, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, Button, Box, } from '@mui/material';
import { BtnBackForm, BtnEditOnTable, BtnPreview, BtnSave, BtnCreate, BtnSearchCustom } from 'STM/components/Button';
import { GridColumns } from '@mui/x-data-grid-pro';
import { SwAlert } from 'STM/components/Alert/index';
import { AlertTitle, AlertMsg } from 'config/AlertMsgConfig'
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
    TextBoxForm,
    SelectFormItem,
    AsyncAutoCompleteForm,
} from "STM/components/Input";
import { DataGridMui } from "STM/components/DataGrid";
import styled from '@emotion/styled';

const LanguageForm = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [loadingTable, setLoadingTable] = useState(false);
    const [isEmptyOnly, setisEmptyOnly] = useState(false);
    const [nPermission, setnPermission] = useState(2);
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get("sID");
    const [sMainLang, setsMainLang] = useState("");
    const [sSecondLang, setsSecondLang] = useState("");
    const [oAllData, setoAllData] = useState({} as any);
    const [sEditLang, setsEditLang] = useState("");
    const [dataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,
        nPageSize:1000,
    });

    useEffect(() => {
        OnGetData(dataRow);
    }, []);

    const OnSaveTranslation = () => {
        
        let param = {
            lstData: oAllData,
            sID: sID,
            isActive: form.getValues("Status") === "1" ? true : false
        };
        console.log("oAllData", oAllData)
        console.log("param", param)
        console.log("x", form.getValues("Status"))
        SwAlert.Confirm(t("confirmation"), t('confirmsave'), () => {
            AxiosPost("LanguageTranslation/SaveDataForm", param, (res) => {
                navigate(`/LanguageList`);
                window.location.reload();
            },
            );
        })
    };

    const OnGetData = (p: PaginationInterface) => {
        let param = {
            ...p,
            sID: sID
        };
        BlockUI();
        setLoadingTable(true)
        AxiosPost("LanguageTranslation/GetDataForm", param, (res) => {
            UnBlockUI();
            setLoadingTable(false)
            console.log("GetDataForm ", res);
            for (const element of res.lstData) {
                if (element.sEditTranslation !== null && element.sEditTranslation !== "")
                    form.setValue("sEditTranslation" + element.sID, element.sEditTranslation)
            }
            form.setValue("Status", res.isActive === true ? "1" : "0");
            setsMainLang(res.sMainLanguage);
            setsSecondLang(res.sSecondLanguage);
            setsEditLang(res.sEditLanguage);
            setoAllData(res.lstData)
            setDataRow({
                ...p,
                arrRows: res.lstData || [],
                nDataLength: res.nDataLength,
                nPageIndex: res.nPageIndex,
            });
        },
        );
    };

    const onDelete = (lstID) => {
        console.log("lstID ", lstID);
        let param = {
            lstData: oAllData,
            lstID: lstID
        };
        SwAlert.Confirm(t("confirmation"), t("deletemsg"), () => {
            AxiosPost("LanguageTranslation/OnDelete", param, (result) => {
                window.location.reload();
            }, (err) => {
                if (!err.response) {
                    SwAlert.Warning("Warning", err.Message, () => { })
                }
            })
        })
    };

    const ContentWrapper = styled("div")({
        marginTop: "16px",
        padding: "16px",
        backgroundColor: "#fff",
        width: "100%",
        border: "1px solid #d5d5d5 !important",
        borderRadius: "10px",
        marginBottom: "2rem"
    });

    const dataColumn: GridColumns = [
        
        {
            field: "sNo",
            headerName: "No.",
            headerAlign: "center",
            
            align: "center",
            sortable: false,
            resizable: false,
            width: 100,
            renderCell: (item) => {
                return <div> {item.row.sID}</div>;
            },
        },
        {
            field: "sKey",
            headerName: "Key Word",
            headerAlign: "center",
            align: "left",
            sortable: false,
            resizable: false,
            width: 250,
        },
        {
            field: "sMainTranslation",
            headerName: "คำแปล (" + sMainLang +")",
            headerAlign: "center",
            hide: sMainLang === sEditLang,
            align: "left",
            resizable: false,
            sortable: false,
            flex: 1,
        },
        {
            field: "sSecondTranslation",
            headerName: "คำแปล (" + sSecondLang + ")",
            headerAlign: "center",
            hide: sSecondLang === sEditLang,
            align: "left",
            resizable: false,
            sortable: false,
            flex: 1,
        },
        {
            field: "sEditTranslation",
            headerName: "คำแปล (" + sEditLang + ")",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: false,
            flex: 1,
            renderCell: (item) => {
                return (
                    <div style={{padding:5,display:"block",width:"100%"}}>
                        <TextBoxForm
                            id={"sEditTranslation" + item.row.sID}
                            name={"sEditTranslation" + item.row.sID}
                            onBlur={(e) => {
                                item.row.sEditTranslation = e.target.value;
                            }}
                            label={"คำแปล (" + sEditLang + ")"}
                            isshowTextCont={false} />
                    </div>
                );
            },
        },
    ];

    const objSchema = yup.object().shape({
        DatePickerForm: yupFormSchemas.date("วันที่", { required: true }),
    });

    const form = useForm({
        resolver: yupResolver(objSchema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const onFilter = () => {
        console.log("oAllData", oAllData)
        let result = oAllData.filter(op => (op.sKey !== null && op.sKey.toLowerCase().indexOf(form.getValues("sKey").toLowerCase()) !== -1)
            || (op.sMainTranslation !== null && op.sMainTranslation.toLowerCase().indexOf(form.getValues("sKey").toLowerCase()) !== -1)
            || (op.sSecondTranslation !== null && op.sSecondTranslation.toLowerCase().indexOf(form.getValues("sKey").toLowerCase()) !== -1)
        );
        if (isEmptyOnly === true)
            result = result.filter(w => w.sEditTranslation === null || w.sEditTranslation === "")

        setDataRow({
            ...initRows,
            arrRows: result || [],
            nDataLength: oAllData.length,
            nPageIndex: 1,
        });
    }

    return (<div>
        <Container maxWidth={false}>
            <ContentWrapper>
                <FormProvider {...form}>
                    <Grid container spacing={2} direction="row" justifyContent="start" >
                        
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <TextBoxForm id="sKey" name="sKey" label={"ค้นหา Key Word, " + "คำแปล (" + sMainLang + "), " + "คำแปล (" + sSecondLang + ")"}
                                onKeyPress={(e) => {
                                    console.log("E",e)
                                    if (e.key === 'Enter' || e.keyCode === 13)
                                        onFilter();
                                }}
                               
                                isshowTextCont={false} />
                        </Grid>
                        <Grid item xs={0.7} sm={0.7} md={0.7} lg={0.7} >
                            <BtnSearchCustom onClick={() => { onFilter(); } } />
                        </Grid>
                        <Grid item xs={5.3} sm={5.3} md={5.3} lg={5.3}>
                            <CheckboxNoFormItem value={isEmptyOnly} name="isEmpty" id='isEmpty' label="แสดงเฉพาะคำที่ยังไม่มีคำแปล"
                                onChange={(e) => {
                                    let result = oAllData.filter(op => (op.sKey !== null && op.sKey.toLowerCase().indexOf(form.getValues("sKey").toLowerCase()) !== -1)
                                        || (op.sMainTranslation !== null && op.sMainTranslation.toLowerCase().indexOf(form.getValues("sKey").toLowerCase()) !== -1)
                                        || (op.sSecondTranslation !== null && op.sSecondTranslation.toLowerCase().indexOf(form.getValues("sKey").toLowerCase()) !== -1)
                                    );

                                    if (e === true)
                                        result = result.filter(w => w.sEditTranslation === null || w.sEditTranslation === "")
                                    setDataRow({
                                        ...initRows,
                                        arrRows: result || [],
                                        nDataLength: oAllData.length,
                                        nPageIndex: 1,
                                    });
                                setisEmptyOnly(e);
                            }} />
                        </Grid>
                        <Grid item md={12}>
                            <RadioForm
                                name="Status"
                                label="Status"
                                required={true}
                                row={true}
                                options={[
                                    { value: "1", label: "Active" },
                                    { value: "0", label: "Inactive" },
                                ]}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <DataGrid
                                isLoading={loadingTable}
                                columns={dataColumn}
                                rows={dataRow}
                                onLoadData={() => { }}
                                isNotShowPagination={true}
                                isShowCheckBox={true}
                                isNotShowTotal={true}
                                onDelete={(d) => onDelete(d)}
                                modeFilter={3}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: "flex",marginTop:"1rem" }} justifyContent={"end"}>
                        <BtnBackForm onClick={() => { navigate(`/LanguageList`) }}></BtnBackForm>
                        
                        <Button sx={{marginLeft:2}} variant="contained" color="success" onClick={(e) => { OnSaveTranslation() }}>
                            Save
                        </Button>
                    </Grid>

                </FormProvider>
            </ContentWrapper>
        </Container>
    </div>)
};
export default LanguageForm;