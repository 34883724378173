import {Component} from "react";
import { Grid, Typography } from "@mui/material";


type MyProps = { children: any };
type MyState = { hasError: boolean };

class ErrorBoundary extends Component<MyProps, MyState> {
    constructor(props){
        super(props)

        this.state = {
            hasError : false
        }
    }
    static getDerivedStateFromError(error) {
        return {
            hasError : true
        }
    }
    
    render() {
        const imageurl = "assets/images/Bg/unsuccess-medium.png";
        if(this.state.hasError){
            return <Grid container>
                <Grid item xs={12} sx={{display:"flex",justifyContent:"center"}}>
                    <img src={`${process.env.REACT_APP_Front_URL}${imageurl}`} />
                </Grid>
                <Grid item xs={12} sx={{textAlign:"center"}}>
                    <Typography sx={{fontSize:"1.85rem",fontWeight:700,color:"rgb(52, 71, 103)"}}>Ooups, something went wrong</Typography>
                    <Typography sx={{color:"#67748e",fontSize:"1rem"}}>We are very sorry for the inconvenience, This page is currently unavailable, please try again later.</Typography>
                </Grid>
            </Grid>
        }
        return this.props.children
    }
}

export default ErrorBoundary