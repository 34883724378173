import React from "react";
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { enumCMSCode } from "enum/enumSystem2";
import { Grid } from "@mui/material";

AboutUsContent.propTypes = {
   dataList: PropTypes.array.isRequired,
};


export function AboutUsContent({ sCMSCode, dataList }) {
   // const frontURL = process.env.REACT_APP_Front_URL;
   if (dataList) {
      ////console.log("aboutList=" +JSON.stringify(dataList) + "dataList.length" + dataList.length);
      if (dataList.length > 0) {
         let data = dataList.filter(f => f.sCMSCode == sCMSCode.toString())[0];
         if (data) {
            return (<>{parse(data.sContents)}</>);
         }
      } else {
         return (<></>);
      }

   } else {
      return (<></>);
   }
}

export function Organization2({ orgList }) {
   const frontURL = process.env.REACT_APP_Front_URL;
   ////let data =  dataList.filter(f=>f.sCMSCode == enumCMSCode.AboutUsDirector)[0];
   ////console.log("orgList=" +JSON.stringify(orgList));
   return (
      <>
         {
            orgList.map((items, index) => (
               <div key={index}>
                  <div className="text-title">{items.position}</div>
                  <Grid container justifyContent="center">
                     {
                        items.detail.map((subItems, sindex) => (
                           <Grid key={sindex} item md={6} sm={6} xs={12} className="organization-diagram">
                              <div style={{ overflow: "hidden" }}>
                                 <img className="organization-image" src={subItems.img}></img>
                              </div>
                              <div className="organization-name">{subItems.fullmane}</div>
                              <div className="organization-position">{subItems.position} </div>
                           </Grid>
                        ))
                     }
                  </Grid>
               </div>
            ))
         }
      </>
   )
}


