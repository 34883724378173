import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ChipData, InputChipProp } from "./ChipProps";
import { Box, Chip, FormHelperText, Grid, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { BtnAddWithIcon } from "STM/components/Button";
import { styled } from "@mui/material/styles";
import { SwAlert } from "STM/components/Alert";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { SxProps } from "@mui/material";

const InputChip = (props: InputChipProp) => {
  const {
    name,
    startAdornment,
    endAdornment,
    fullWidth = true,
    isMessageError = true,
    arrTags,
    setArrTags,
    isEditTag = false,
  } = props;
  const { t } = useTranslation();

  const {
    control,
    register,
    setValue,
    watch,
    getValues,
    formState: { touchedFields, isSubmitted, errors },
  } = useFormContext();

  const FieldName = useWatch({
    control,
    name: name,
  });

  const [Focus, SetFocus] = useState(false);
  const inputRefs = useRef(null);

  const [chipData, setChipData] = useState<ChipData[]>([]);

  const handleDelete = (chipToDelete: ChipData) => () => {
    let result: any = chipData
      .filter((chip) => chip.key !== chipToDelete.key)
      .map((m) => m);
    setChipData(result);
    setArrTags(result.map((m) => m.label));
  };

  const handleClick = (chipToClick) => {
    let result = chipData.filter((chip) => chip.key !== chipToClick);
    setChipData(result);
    setArrTags(result.map((m) => m.label));

    if (chipToClick != null) {
      setValue(name, chipToClick, { shouldValidate: true });
      inputRefs.current.focus();
    }
  };

  const handleAddChip = (value) => {
    if (value && value !== "") {
      let data: any = {
        key: value,
        label: value,
      };
      let IsExist = chipData.filter((f) => f.label === data.label).length > 0;
      if (IsExist) {
        SwAlert.Warning("Warning", "This keyword already exists", () => {});
        return false;
      }

      let result = [...chipData, data];
      setChipData(result);
      setArrTags(result.map((m) => m.label));
    }
  };

  useEffect(() => {
    // debugger
    console.log("arrTags", arrTags);
    if (arrTags.length === 0) {
      setChipData([]);
      setArrTags([]);
    }
    if (_.isEqual(chipData, arrTags) === false) {
      // if (chipData.length !== (arrTags || []).length) {
      let arr = arrTags;
      let data = [];
      if ((arrTags || []).length > 0) {
        arr.forEach((f) => {
          let arrChip = {
            key: f.toString(),
            label: f.toString(),
          };
          data.push(arrChip);
        });

        setChipData(data);
        setArrTags(data.map((m) => m.label));
      }
    }
  }, [arrTags]);

  const maxLengthText: SxProps = {
    // "& input":{
    "::after": {
      content: props.maxLength
        ? `"${getValues(name) ? getValues(name).length : 0}/${props.maxLength}"`
        : '""',
      position: "absolute",
      bottom: "5px",
      right: "10px",
      color: "#989898",
      fontSize: "10px",
    },
    // }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, error },
      }) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9.5} md={10.5}>
            <TextField
              {...props}
              key={name}
              inputRef={inputRefs}
              {...register(name)}
              name={name}
              value={value || null}
              label={props.shrink && props.shrink === true ? props.label : ""}
              placeholder={!props.shrink ? props.label : ""}
              // required={props.required || false}
              sx={[
                { ...maxLengthText },
                {
                  // padding:"8.5px",
                  ".MuiInputBase-inputSizeSmall": {
                    padding: "8.5px",
                  },
                },
              ]}
              error={error?.message != null}
              type={props.type || "text"}
              disabled={props.disabled}
              variant={props.variant || "outlined"}
              fullWidth={fullWidth}
              margin={props.margin}
              style={props.style}
              size={"small"}
              autoComplete={"off"}
              InputLabelProps={{
                shrink: FieldName || Focus ? true : false,
              }}
              inputProps={{
                maxLength: props.maxLength ? props.maxLength : null,
              }}
              InputProps={
                props.startAdornment ? { startAdornment } : { endAdornment }
              }
              onChange={(e) => {
                onChange(e);
                let sValueSet = e.target.value;
                setValue(name, sValueSet, { shouldValidate: true });
                props.onChange && props.onChange(sValueSet);
              }}
              onBlur={(event) => {
                SetFocus(false);
                onBlur();

                setValue(name, event.target.value, { shouldValidate: true });
                props.onBlur && props.onBlur(event);
              }}
              onKeyPress={(event) => {
                props.onKeyPress && props.onKeyPress(event);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter" && value) {
                  handleAddChip(value.trim());
                  setValue(name, "");
                  inputRefs.current.focus();
                }
                props.onKeyDown && props.onKeyDown(event);
              }}
              onKeyUp={(event) => {
                props.onKeyPress && props.onKeyPress(event);
              }}
              onFocus={() => {
                SetFocus(true);
              }}
            />
            </Grid>
            <Grid item xs={12} sm={2.5} md={1.5}>
              <Box>
                <BtnAddWithIcon
                  txt={t("addtag")}
                  onClick={() => {
                    if (value) {
                      handleAddChip(value.trim());
                      setValue(name, "");
                      inputRefs.current.focus();
                    }
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              backgroundColor: "transparent !important",
              flexWrap: "wrap",
              listStyle: "none",
              pt: 0.5,
              m: 0,
            }}
            component="ul"
          >
            
            {chipData.map((data, index) => {
              if(data){
              return (
                <ListItemA key={data.key ?? index}>
                  <Chip
                    sx={{ cursor: "pointer" }}
                    icon={data.icon}
                    label={data.label}
                    onDelete={handleDelete(data)}
                    disabled={props.disabled}
                    clickable={
                      value == null || value === "" ? isEditTag : false
                    }
                    onClick={() => {
                      if ((value == null || value === "") && isEditTag) {
                        handleClick(data.key);
                      }
                    }}
                  />
                  
                </ListItemA>
              );
              }else{
                return <></>
              }
            })}

          </Box>

          {isMessageError && error ? (
            <FormHelperText sx={{ color: "red" }}>
              {error.message}
            </FormHelperText>
          ) : null}
        </>
      )}
    />
  );
};

export default InputChip;

const ListItemA = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export function ChipsArray(props) {
  const { lstChip } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        backgroundColor: "transparent !important",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {lstChip.map((data) => {
        return (
          <ListItemA key={data.key}>
            <Chip
              icon={data.icon}
              label={data.label}
              // onDelete={() => handleDelete(data)}
            />
          </ListItemA>
        );
      })}
    </Box>
  );
}
