import { AxiosGet } from "utilities/ST_Axios";

export const GetNewsAll = async (objJSON, fnSuccess, fnError) => {
    await AxiosGet("NewsActivity/GetNewsAll", objJSON, (res) => {
        fnSuccess(res);
    },(err) => {fnError(err)});
}

export const GetContentNews = async (objJSON, fnSuccess, fnError) => {
    await AxiosGet("News/GetContentNews", objJSON, (res) => {
        fnSuccess(res);
    },(err) => {fnError(err)});
}