import { motion } from "framer-motion";
import React,{ useEffect, useState } from "react";
import { TocRounded } from "@mui/icons-material";
import "./AnimateLayout.css";
import Item from "./Item";
import ItemMobile from "./ItemMobile";
import { AxiosGet } from "STM/utilities/ST_Axios";
import ReactIcons from "STM/components/reactIcons";
import { Link, useLocation } from "react-router-dom";
import {
  Breadcrumbs,
  Box,
  Divider,
  Menu,
  Hidden,
  Grid,
  alpha,
  MenuProps,
  styled,
} from "@mui/material";
import {
  STMService,
  SetLanguage,
} from "STM/utilities/ST_Function";
import { IoIosArrowBack } from "react-icons/io";
import * as yup from "yup";
import { BtnLoguout } from "STM/components/Button";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import cookies from "js-cookie";


const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0",
      textAlign: "center",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function AnimateLayout(props) {
  const { logout } = STMService();
  const [open, setOpen] = useState(true);
  const [openMobile, setOpenMobile] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [lstMenu, setLstMenu] = useState([]);

  // const { oCountryDropdown, i18next, cookies, getUnicodeFlagIcon } =
  //   useI18Next();


  // for collpsing sidebar
  const handleToggle = () => {
    setOpen(!open);
    setOpenMobile(true);
  };

  const sideContainerVariants = {
    true: {
      width: "15rem",
    },
    false: {
      width: "7rem",
      transition: {
        delay: 0.6,
      },
    },
  };

  const sidebarVariants = {
    true: { width: "14rem", alignItems: "start" },
    false: {
      width: "5rem",
      alignItems: "center",
      transition: {
        delay: 0.4,
      },
    },
  };
  const heading = {
    true: {
      opacity: 1,
    },
    false: {
      opacity: 0,
      display: "none",
    },
  };

  useEffect(() => {
    OnGetMenu();
  }, []);

  const OnGetMenu = () => {
    let param = {
      sID: "",
      isFront: false,
      sLang: "en-US",
    };
    AxiosGet("User/GetMenuByUser", param, (res) => {
      // // if(res){
      // //   res.forEach(function(menu){
      // //     if(menu){
      // //       menu.lstSubMenu.forEach(function(submenu){
      // //         //const baseUrl = process.env.REACT_APP_Front_URL;
      // //         //const url = new URL(`${baseUrl}${submenu.sLink}`);
      // //         //submenu.sLink = url.origin + url.pathname + url.search;
      // //         ////console.log("submenu.sLink",submenu.sLink)
      // //       })
      // //     }
      // //   });
      // // }
      setLstMenu(res);
    });
  };

  const location = useLocation();
  const [sCurrentMenu, setCurrentMenu] = useState("");
  const [sIconMenu, setIconMenu] = useState("RiSettings5Fill");
  const [lstBreadcrumbs, setLstBreadcrumbs] = useState([]);
  const [userSession, setUserSession] = useState(null);
  const OnGetFlagData = () => {
    let param = {};
    AxiosGet("LanguageTranslation/GetDropdownCountryFlag", param, (res) => {
      console.log("oCountryDropdown ", res);
      setoCountryDropdown(res);
    });
  };
  const GetUserSession = () => {
    let param = {};
    AxiosGet("Utility2/GetUserSession", param, (res) => {
      console.log("GetUserSession", res);
      setUserSession(res);
    });
  };

  useEffect(() => {
    ////OnGetFlagData();
    ////setsActiveFlag(cookies.get(i18next));
    GetUserSession();
  }, []);

  ////const [sNotiMessage, setsNotiMessage] = useState("");

  const [lstData, setlstData] = useState([] as any);

  useEffect(() => {
    const OnQuickSearch_GetList = () => {
      let param = {};
      AxiosGet("LanguageTranslation/OnGetList_QuickSearch", param, (res) => {
        setlstData(res);
      });
    };
    OnQuickSearch_GetList();
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<any>(Boolean(anchorEl));

  const [oCountryDropdown, setoCountryDropdown] = useState([] as any);
  ////const [sActiveFlag, setsActiveFlag] = React.useState<string>();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const myLogout = () => {
    logout(() => {
      window.location.href = urlFont;
    });
  };

  useEffect(() => {
    let param = {
      sID: "",
      isFront: false,
      sLang: "en-US",
      sPath: location.pathname,
    };

    AxiosGet("User/GetBreadCrumb", param, (res) => {
      setLstBreadcrumbs(res);
      let currentMenu = res
        .filter((m) => location.pathname.includes(m.sLink))
        .map((obj) => obj)[0];
      setCurrentMenu(currentMenu.sMenuName);
      setIconMenu(currentMenu.sIcon);
    });
  }, [location.pathname]);

  const objSchema = yup.object().shape({});

  // // const form = useForm({
  // //   resolver: yupResolver(objSchema),
  // //   shouldUnregister: false,
  // //   shouldFocusError: true,
  // //   mode: "all",
  // // });


  let i18next = process.env.REACT_APP_JWT_i18next;

  const [anchorProfile, setAnchorProfile] = React.useState<null | HTMLElement>(
    null
  );

  const openProfile = Boolean(anchorProfile);

  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    console.log()
    setAnchorProfile(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorProfile(null);
  };

  const url2 = new URL(`${process.env.REACT_APP_Front_URL}`)
  const urlFont = url2.origin + url2.pathname + url2.search;

  const baseUrl = process.env.REACT_APP_Front_URL;
  const url = new URL(`${baseUrl}profile.png`);
  const urlProfile = url.origin + url.pathname + url.search;
  
  const iconUrl = new URL(`${baseUrl}assets/STM/images/IconSystem.png`);
  const iconSystemPng = iconUrl.origin + iconUrl.pathname + iconUrl.search; 

  return (
    <motion.div style={{ display: "flex", flexWrap: "wrap" }}>
      <Hidden lgUp>
        <Box
          sx={{
            position: "fixed",
            left: 0,
            backgroundColor: "rgba(0,0,0,0.7)",
            height: "100vh",
            zIndex: 9998,
            transition: " all 0.5s",
            width: openMobile ? "100vw" : 0,
            overflowX: "hidden",
          }}
          onClick={() => setOpenMobile(false)}
        >
          <Box
            className="animate-layout-mobile"
            onClick={(e) => e.stopPropagation()}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "5px 32px",
              }}
            >
              <div
                className="arrows-close"
                onClick={() => setOpenMobile(false)}
              >
                <IoIosArrowBack />
              </div>
            </Box>
            <Box className="head-box-menu-animate">
              <Link to={urlFont} className="mobile-system-link">
                <img
                  src={iconSystemPng}
                  alt=""
                  className="mobile-system-logo"
                />
                {/* <motion.p
                  className="mobile-system-name-side"
                  style={{ fontSize: "1rem", fontWeight: 700 }}
                  variants={heading}
                >
                  Softthai Application
                </motion.p> */}
              </Link>
            </Box>
            <Divider className="system-divider" />
            <Box className="box-menu-mobile">
              {lstMenu.map((menu, i) => (
                <>
                  <motion.p className="animate-title-menu">
                    {menu.sMenuName}
                  </motion.p>
                  {menu.lstSubMenu && (
                    <>
                      {menu.lstSubMenu.map((submenu, index) => (
                        <Link key={index} to={submenu.sLink}>
                          <ItemMobile
                            active={
                              location?.pathname &&
                              location?.pathname.includes(submenu.sLink)
                            }
                            icon={
                              <ReactIcons
                                IconName={submenu.sIcon}
                                className={""}
                              />
                            }
                            name={submenu.sMenuName}
                          />
                        </Link>
                      ))}
                    </>
                  )}
                </>
              ))}
            </Box>
          </Box>
        </Box>
      </Hidden>
      <motion.div
        data-Open={open}
        variants={sideContainerVariants}
        initial={`${open}`}
        animate={`${open}`}
        className="sidebar_container"
      >
        {/* sidebar div */}
        <motion.div
          className="sidebar"
          initial={`${open}`}
          animate={`${open}`}
          variants={sidebarVariants}
          style={{
            background: "#fff",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <Box className="head-box-menu-animate">
            <Link to={urlFont} className="main-system-link">
              <img src={iconSystemPng} alt="" className="system-logo" />
              {/* <motion.p
                className="system-name-side"
                style={{ fontSize: ".875rem", fontWeight: 700 }}
                variants={heading}
              >
                PTT Global LNG
              </motion.p> */}
            </Link>
          </Box>
          {/* <Divider className="system-divider" /> */}
          {lstMenu.map((menu, i) => (
            <div className="groups">
              <motion.p
                className="animate-title-menu"
                animate={{ opacity: open ? 1 : 0, height: open ? "auto" : 0 }}
              >
                {menu.sMenuName}
              </motion.p>
              {menu.lstSubMenu && (
                <>
                  {menu.lstSubMenu.map((submenu, index) => (
                    <Link to={submenu.sLink}>
                      <Item
                        active={
                          location.pathname &&
                          location.pathname.includes(submenu.sLink)
                        }
                        icon={
                          <ReactIcons IconName={submenu.sIcon} className={""} />
                        }
                        name={submenu.sMenuName}
                      />
                    </Link>
                  ))}
                </>
              )}
            </div>
          ))}
        </motion.div>
      </motion.div>

      <div
        className="body_container"
        style={{ maxWidth: `calc(100vw - ${open ? "16rem" : "7.5rem"})` }}
      >
        <Box className="header-page header-sticky">
          <Box className="tabs-header-page">
            <Box className="breadcrumbs-page">
              <Box
                sx={{
                  opacity: 1,
                  background: "transparent",
                  color: "#344767",
                  mr: "32px",
                }}
              >
                <Box
                  sx={{
                    m: 0,
                    fontSize: "1.25rem",
                    fontWeight: 400,
                    lineHeight: 1.625,
                    letterSpacing: "0.00938em",
                    color: "rgba(0,0,0,0.6)",
                  }}
                >
                  <Breadcrumbs className="custom-breadcrumbs">
                    <Link to={"#"} className="custom-breadcrumbs-list-first">
                      <span className="breadcrumbs-icons">
                        <ReactIcons
                          IconName={sIconMenu}
                          className={
                            "material-icons-round breadcrumbs-icon-dynamic"
                          }
                        />
                      </span>
                    </Link>
                    {lstBreadcrumbs?.map((b, i) => {
                      const keyLink = i;
                      return (
                        <Link
                          key={keyLink}
                          to={b.sLink}
                          style={{
                            cursor:
                              b.sLink == null || b.sLink === location.pathname
                                ? "default"
                                : "pointer",
                          }}
                          className={
                            b.nLevel === 1
                              ? "custom-breadcrumbs-list"
                              : "custom-breadcrumbs-list current-page-bc"
                          }
                        >
                          {b.sMenuName}
                        </Link>
                      );
                    })}
                  </Breadcrumbs>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                      rotate: 180,
                      backgroundColor: "rgba(255, 255, 255, 0.3)",
                      backdropFilter: "blur(3.5px)",
                      WebkitBackdropFilter: "blur(3.5px)",
                      border: "1px solid rgba( 255, 255, 255, 0.18 )",
                      transition: {
                        delay: 0.2,
                        duration: 0.4,
                      },
                      cursor: "pointer",
                    }}
                    style={{ marginRight: "0.75rem" }}
                    onClick={handleToggle}
                    className="lines_icon"
                  >
                    <TocRounded />
                  </motion.div>
                  {/* <Typography className="current-menu-name">
                    {sCurrentMenu}
                  </Typography> */}
                </Box>
              </Box>
            </Box>
            <Box className="tabs-system">
              <Box
                sx={{
                  opacity: 1,
                  color: "inherit",
                  background: "transparent",
                  display: "contents",
                }}
              >
                <Box
                  className="dropdown ms-1 topbar-head-dropdown header-item"
                  style={{ display: "none" }}
                >
                  <Box
                    onClick={handleClick}
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  >
                    {getUnicodeFlagIcon(cookies.get(i18next).split("-").pop())}
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openMenu}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    {oCountryDropdown.map((lang, index) => {
                      const keybox = index;
                      return (
                        <Box
                          key={keybox}
                          sx={{ pl: 0, fontSize: "0.9rem" }}
                          className="langSelect"
                          onClick={() => {
                            ////i18n.changeLanguage(lang.sLangCode);
                            ////cookies.set(i18next, lang.value);
                            SetLanguage(lang.value);
                            window.location.reload();
                          }}
                        >
                          <Box
                            className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                            style={{ height: "25px", width: "42px" }}
                          >
                            {getUnicodeFlagIcon(lang.sFlag)}
                          </Box>
                          {lang.label}
                        </Box>
                      );
                    })}
                  </Menu>
                </Box>
                <Grid
                  container
                  spacing={2}
                  className="logoutSession"
                  alignItems="center"
                >
                  <Box className="dropdown ms-sm-3 header-item topbar-user">
                    <Box
                      className="page-header-user-dropdown"
                      onClick={handleClickProfile}
                    >
                      <span
                        className="d-flex align-items-center"
                        style={{ columnGap: "10px" }}
                      >
                        <img
                          className="rounded-circle header-profile-user"
                          src={urlProfile}
                          alt="Header Avatar"
                        />
                        <span className="profile-detail text-start ms-xl-2">
                          <span className="d-none ms-1 fw-medium user-name-text text-infomation-setting">
                            {userSession?.userModel?.sFullNameEN}
                          </span>
                          <span className="d-none ms-1 fs-12 text-muted user-name-sub-text text-infomation-setting">
                            {userSession?.userModel?.sPositionName}
                          </span>
                        </span>
                        <Box className="dropdown-IconLogout">
                          <BtnLoguout onClick={myLogout} />
                          {/* <BtnLoguout onClick={myLogout} /> */}
                        </Box>
                      </span>
                    </Box>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorProfile}
                      open={openProfile}
                      onClose={handleCloseProfile}
                    >
                      {/* <Typography sx={{ fontSize: "0.85rem", mt: 1, ml: 1, textAlign: "left" }}>
                                    Administrator
                                 </Typography> */}
                      <Box className="profile-item profile-infomation">
                        {userSession == null ? (
                          <></>
                        ) : (
                          <>
                            <Box className="profile-username">
                              {userSession.userModel?.sFullNameEN}
                            </Box>
                            <Box className="profile-positionname">
                              {userSession.userModel?.sPositionName}
                            </Box>
                          </>
                        )}
                      </Box>

                      <Box
                        className="profile-item"
                        sx={{ columnGap: "8px" }}
                        onClick={myLogout}
                      >
                        <BtnLoguout onClick={myLogout} />
                        <Box sx={{ fontWeight: "500", fontSize: "14px" }}>
                          Logout
                        </Box>
                      </Box>
                    </StyledMenu>
                  </Box>
                  {/* <div style={{ paddingTop: "1rem" }}>
                  <img
                    src="profile.png"
                    alt=""
                    style={{
                      width: "auto",
                      height: "3rem",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div
                  style={{
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    paddingTop: "1rem",
                  }}
                  className="user_name"
                >
                  <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                    {" "}
                    {userSession?.userModel?.sFullNameEN}
                  </div>
                  <div style={{ fontSize: "0.7rem" }}>
                    {userSession?.userModel?.sPositionName}
                  </div>
                </div>
                <div style={{ paddingTop: "1.3rem" }} className="logout_admin">
                  <BtnLoguout onClick={myLogout} />
                </div> */}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
        {props.children}
      </div>
      <Box
        sx={{
          position: "fixed",
          right: 0,
          backgroundColor: "transparent",
          height: "100vh",
          zIndex: 9998,
          transition: " all 0.5s",
          width: openSetting ? "100vw" : 0,
          // opacity: openMobile ? "1" : "0",
          overflowX: "hidden",
          display: "flex",
          justifyContent: "flex-end",
        }}
        onClick={() => setOpenSetting(false)}
      >
        {/* <Box
          className="animate-layout-setting"
          onClick={(e) => e.stopPropagation()}
        >
          <FormProvider {...form}>
            <Grid container>
              <Grid item xs={12}>
                <HeaderInput text="Settings Management" />
              </Grid>
            </Grid>
          </FormProvider>
        </Box> */}
      </Box>
    </motion.div>
  );
}
